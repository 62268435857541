import { Button } from '@material-ui/core';
import { useOperation } from '~/hooks/useOperation';
import { normalizeBrl, normalizeCpfCnpj } from '~/pages/main/utils/normalize';
import { addHours, format } from 'date-fns';
import React from 'react';
import { FcMoneyTransfer } from 'react-icons/fc';

import { Container } from './styles';

interface IProps {
  open: boolean;
  setOpen: () => void;
  onConfirm: () => void;
  total: string;
  bank?: Bank;
}

const ModalConfirm: React.FC<IProps> = ({
  open,
  setOpen,
  onConfirm,
  total,
  bank,
}) => {
  const { titles } = useOperation();

  return (
    <Container show={open} onHide={() => setOpen()} size="lg" centered>
      <Container.Body>
        <div>
          <div className="header">
            <img src="/logo192.png" alt="BFC Digital" />
            <h4>Revisão da Operação</h4>
          </div>
          <hr />
          <div className="value">
            <div>
              <h5>Valor Bruto</h5>
              <h3>
                <strong>{total}</strong>
              </h3>
            </div>
            <FcMoneyTransfer size={56} />
          </div>
          <div className="bank">
            <h4>Conta Bancária Selecionada</h4>
            <table>
              <tbody>
                <tr>
                  <td>BANCO:</td>
                  <td>
                    {bank?.bank.code} - {bank?.bank.name}
                  </td>
                </tr>
                <tr>
                  <td>AGÊNCIA:</td>
                  <td>
                    {bank?.agency}
                    {bank?.agency_digit && `-${bank?.agency_digit}`}
                  </td>
                </tr>
                <tr>
                  <td>CONTA CORRENTE:</td>
                  <td>
                    {bank?.account_number}{bank?.account_digit && `-${bank?.account_digit}`}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="titles">
            <h4>Títulos</h4>
            <table>
              <tbody>
                {titles.map((title, index) => (
                  <tr key={String(index)}>
                    <td className="small">{title.payer?.name}<br/>{normalizeCpfCnpj(title.payer?.document_number)}</td>
                    <td>{title.number}</td>
                    <td>
                      {title.date
                        ? format(addHours(new Date(title.date), 3), 'dd/MM/yyyy')
                        : ''}
                    </td>
                    <td>{normalizeBrl(title.value - title.discount_value)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <hr />
        <div className="d-flex justify-content-end" style={{ padding: '15px' }}>
          <Button
            type="button"
            color="primary"
            variant="contained"
            onClick={() => {
              setOpen();
              onConfirm();
            }}
          >
            Enviar Operação
          </Button>
          <Button
            type="button"
            color="primary"
            variant="outlined"
            className="ml-2"
            onClick={setOpen}
          >
            Voltar
          </Button>
        </div>
      </Container.Body>
    </Container>
  );
};

export default ModalConfirm;

import React from "react";

import { Container } from "./styles";

const Header: React.FC = () => {
  return (
    <Container className="no-print">
      <div className="container">
        <div className="d-flex align-items-center">
          <span>
            <img src="/assets/images/logo-branca.png" alt="BFC Digital" />
          </span>
          <div className="mb-0 ml-2 company-slug">
            <h6 className="mb-1">Antecipação de Recebíveis</h6>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Header;

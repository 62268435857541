
import React, { createContext, useState, useContext } from 'react';
import { payerIsComplete } from '~/pages/main/utils/normalize';
import { useAuth } from './useAuth';

interface OperationContext {
  titles: Title[];
  handleTitle: (item: Title | Title[]) => void;
  bankSelected: string;
  selectBank: (b: string) => void;
  resetOperation: () => void;
}
const OperationC = createContext<OperationContext>({} as OperationContext);
export const OperationProvider: React.FC = ({ children }) => {
  const [titles, setTitles] = useState<Title[]>([]);
  const [bankSelected, setBankSelected] = useState('');

  const { currentAssignor } = useAuth();

  function handleTitle(item: Title | Title[]) {
    if (Array.isArray(item)) {
      let tt = titles;
    
      item.forEach((t) => {
        if (payerIsComplete(currentAssignor.require_payer_details, t)) {
          if (
            tt.find(
              (i) => i.id === t.id
            )
          ) {
            tt = tt.filter(
              (i) => i.id !== t.id
            );
          } else {
            tt = [...tt, t];
          }
        }
      });
      setTitles(tt);
    } else if (
      titles.find(
        (i) => i.id === item.id
      )
    ) {
      setTitles(
        titles.filter(
          (i) => i.id !== item.id
        )
      );
    } else {
      setTitles([...titles, item]);
    }
  }

  function resetOperation() {
    setTitles([]);
    setBankSelected('');
  }

  return (
    <OperationC.Provider
      value={{
        titles,
        handleTitle,
        bankSelected,
        selectBank: (b) => setBankSelected(b),
        resetOperation,
      }}
    >
      {children}
    </OperationC.Provider>
  );
};
export const useOperation = (): OperationContext => {
  const context = useContext(OperationC);
  if (!context) {
    throw new Error(
      'The hook useOperation must be used within an OperationProvider'
    );
  }
  return context;
};

import React from "react";

const ActionOperation: React.FC = () => {
  return (
    <div className="widget-anticipation-sale">
      <div className="widget-anticipation-sale__box_text">
        <p>
          Vamos
          <br /> começar a{" "}
          <strong>
            adiantar os <br /> recebíveis?
          </strong>
        </p>
        <a href="/operacoes/novo">quero antecipar</a>
      </div>
      <div className="widget-anticipation-sale__box_illustrator">
        <img src="/assets/images/antecipe.png" alt="" />
      </div>
    </div>
  );
};

export default ActionOperation;
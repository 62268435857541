import styled from 'styled-components'

export const Container = styled.div`
  padding: 2rem;
  box-shadow: 0 0 5px rgba(0,0,0,.1);
  background: #fff;
  border-radius: 1rem;
  .content_holdings {
    max-height: 150px;
    margin-top: 10px;
    background: #eee;
    border-radius: 4px;
    overflow: auto;
    padding: 4px;
  }
  .selected_hold {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    width: 100%;
    div {
      button {
        background: rgba(0, 0, 0, 0.1);
        border-radius: 50%;
      }
    }
  }
`

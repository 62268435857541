import styled, { keyframes } from "styled-components";

import { darken } from "polished";

const fadeContainer = keyframes`
  0%{
    opacity:0;
  }100%{
    opacity:1;
  }
`;

export const Container = styled.div`
  min-height: 100vh;
  background: ${(props) => darken(0.02, props.theme.colors.background)};
  animation: ${fadeContainer} 300ms linear;
  .limit-assignor {
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 50%;
    background: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.background};
    transition: 300ms;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    figure {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 5px;
      background: ${(props) => props.theme.colors.background};
      color: ${(props) => props.theme.colors.primary};
      border-radius: 15px;
      min-width: 40px;
      min-height: 40px;
      margin-right: 10px;
      background-size: contain;
      transition: 500ms;
      margin: 0px;
      img {
        width: 15px;
      }
    }
    div {
      transition: 500ms;
      text-align: right;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      h4 {
        font-size: 12px;
        font-weight: 900;
        margin: 0px;
        padding: 0px;
        color: #fff;
        transition: 500ms;
      }
      span {
        margin-top: 4px;
        font-size: 18px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: #fff;
        font-weight: 700;
        transition: 500ms;
        position: relative;
        &::before {
          content: "";
          width: 0%;
          height: 100%;
          background: rgba(255, 255, 255, 0.6);
          backdrop-filter: blur(6px);
          box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.1);
          position: absolute;
          top: 0;
          right: -2.5%;
          border-radius: 6px;
          transition: 350ms;
        }
        &.no-visible {
          &::before {
            width: 105%;
          }
        }
      }
    }
    &.opened-side {
      border-radius: 15px;
      button {
        width: 40px;
        display: block;
        margin-top: 18px;
      }
      > span {
        min-width: 50px;
        min-height: 50px;
        img {
          width: 15px;
        }
      }
      div {
        width: 100%;
        height: 55px;
      }
    }
  }
  div#section_main {
    padding: 30px 0px;
    min-height: 80vh;
    div.grid_main {
      padding: 0px 20px;
      display: grid;
      grid-template-columns: 1fr 18fr;
      transition: 500ms;
      div#side_menu_container {
        position: relative;
        button#bars_button {
          display: none;
        }
      }
      &.opened-side {
        grid-template-columns: 2fr 8fr;
      }
      grid-gap: 20px;
      @media screen and (max-width: 1650px) {
        &.opened-side {
          grid-template-columns: 2fr 7fr;
        }
      }
      @media screen and (max-width: 1400px) {
        &.opened-side {
          grid-template-columns: 2fr 5fr;
        }
      }
      @media screen and (max-width: 1040px) {
        &.opened-side {
          grid-template-columns: 1fr;
        }
      }
      @media (max-width: 980px) {
        grid-template-columns: 1fr;
        div#side_menu_container {
          position: fixed;
          width: 100%;
          max-width: 80px;
          height: 80px;
          bottom: 2%;
          right: 2%;
          overflow: hidden;
          z-index: 99;
          div {
            opacity: 0 !important;
          }
          &.opened {
            background: ${(props) => props.theme.colors.primary};
            width: 98%;
            max-width: 350px;
            height: 80%;
            padding: 15px;
            border-radius: 15px;
            overflow: auto;
            div {
              opacity: 1 !important;
            }
          }
          button#bars_button {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            color: ${(props) => props.theme.colors.primary};
            border: 3px solid ${(props) => props.theme.colors.primary};
            background: #fff;
            font-size: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            position: fixed;
            bottom: 25px;
            right: 25px;
            z-index: 100;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
            i.bar {
              width: 25px;
              height: 4px;
              border-radius: 2px;
              background: ${(props) => props.theme.colors.primary};
              transition: 1s ease-in-out;
              &:nth-child(1) {
                &.times {
                  transform: rotate(405deg) translateY(6px) translateX(5px);
                }
              }
              &:nth-child(2) {
                &.times {
                  transform: rotate(-495deg) translateY(0px) translateX(0px);
                }
              }
              &:nth-child(3) {
                &.times {
                  transform: rotate(135deg) translateY(6px) translateX(-5px);
                }
              }
            }
            i.bar + i.bar {
              margin-top: 4px;
            }
          }
        }
      }
    }
  }
`;

import { Button } from "react-bootstrap";
import React from "react";
import { useAuth } from "~/hooks/useAuth";
import { api } from "~/hooks/api";
import { toast } from "react-toastify";

const FinishRegister = () => {
    const { setLoading } = useAuth();

    async function handleSubmit() {
        setLoading(true);

        try {
            await api.post("register/finish");
            toast.success("Cadastro finalizado com sucesso");
            document.location.reload();
        } catch (err) {}
        setLoading(false);
    }

    return (
        <div>
            <h4>Finalizar Cadastro</h4>
            <h5>Atenção</h5>
            <p>
                Ao finalizar o cadastro nossos analistas irão avaliar as informações
                e documentos enviados.
            </p>
            <Button
                type="button"
                onClick={() => {
                    const confirm = window.confirm(
                        "Tem certeza que deseja finalizar? Esta ação irá bloquear o cadastro para alterações, sendo apenas possível alterar através de pendências."
                    );
                    if (confirm) {
                        handleSubmit();
                    }
                }}
            >
                Finalizar
            </Button>
        </div>
    )
}

export default FinishRegister;
import React, { useState, useEffect, useCallback } from 'react';

import { useHistory } from 'react-router-dom';

import { CircularProgress, Button } from '@material-ui/core';
import { api } from '~/hooks/api';
import { Layout } from '../components/Layout';

const ActiveAcc: React.FC = ({ match }: any) => {
  const history = useHistory();
  const [isTokenInvalid, setIsTokenInvalid] = useState(false);
  const [verified, setVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [msg, setMsg] = useState("");

  const { token } = match.params;

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        await api.post(`users/user/active-account`, {
          token,
        });
        setVerified(true);
      } catch (err) {
        // TODO: Aprimorar o tratamento de erros
        setIsTokenInvalid(true);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [isTokenInvalid, token]);

  const handleButtonClick = useCallback(() => {
    history.push(`auth/login`);
  }, [history]);

  return (
    <Layout>
      <div className="p-4">
        {loading && <CircularProgress />}
        {verified && (
          <>
            <h4><strong>Conta ativada com sucesso!</strong></h4>
            <p>Você já pode fazer o login na plataforma da BFC Digital</p>
            <div className="button-wrap">
              <Button
                onClick={handleButtonClick}
                type="submit"
                color="primary"
                size="large"
                variant="contained"
              >
                Entrar
              </Button>
            </div>
          </>
        )}
        {isTokenInvalid && <h4>Token inválido</h4>}
      </div>
    </Layout>
  )
};

export default ActiveAcc;

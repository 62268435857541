import React, { memo } from "react";

import { format } from "date-fns";

import { api } from "~/hooks/api";
import { TableComponent } from "~/pages/main/components/TableComponent";
import {
  normalizeNumber,
  subString,
  normalizeCpfCnpj,
  normalizeCurrency,
  formatDate,
} from "~/pages/main/utils/normalize";

interface INextItem {
  (): void;
}

interface IProps {
  handleNextItem: INextItem;
}

const Open: React.FC<IProps> = () => {
  return (
    <TableComponent
      title="Títulos Devolvidos"
      dataReciever={async (params) => {
        const { data } = await api.get("/assignor/titles", {
          params: {
            ...params,
            situation: "returned",
            startValue: params.start_value
              ? normalizeNumber(params.start_value)
              : "",
            endValue: params.end_value ? normalizeNumber(params.end_value) : "",
          },
        });
        return data;
      }}
      dataType={[]}
      exportAction={async (params) => {
        const obj = {
          ...params,
          payer_document: params.payer_document
            ? normalizeNumber(params.payer_document)
            : "",
          situation: "returned",
          startValue: params.start_value
            ? normalizeNumber(params.start_value)
            : "",
          endValue: params.end_value ? normalizeNumber(params.end_value) : "",
        };
        window.open(
          `${process.env.REACT_APP_API}/assignor/titles?${Object.keys(obj)
            .filter((key) => obj[key])
            .map((key) => `${key}=${obj[key]}`)
            .join("&")}`
        );
      }}
      traitResponse={(data: any) => {
        return data.map((d) => {
          const lastReturn =
            d.returns.length > 0 ? d.returns[d.returns.length - 1] : null;

          return {
            ...d,
            id: d.code,
            operation_id: d.operation.code,
            documentNumber: d.document_number,
            date: d.date ? format(new Date(d.date), "dd/MM/yyyy") : "",
            payerId: `${d.payer?.name || ""} - ${normalizeCpfCnpj(
              d.payer?.document_number
            )}`,
            value: `R$ ${normalizeCurrency(String(d.value))}`,
            openValue: `R$ ${normalizeCurrency(String(d.open_value))}`,
            titleTypeId: d?.operation?.titleType?.name,
            reason: "",
            returnedDate: lastReturn?.date
              ? format(new Date(lastReturn.date), "dd/MM/yyyy")
              : "",
          };
        });
      }}
      filters={[
        {
          name: "id",
          title: "ID",
          col: 1,
        },
        {
          name: "document_number",
          title: "Documento",
          col: 2,
        },
        {
          name: "payer",
          title: "Sacado",
          col: 2,
        },
        {
          name: "start_date",
          title: "Vencimento (de)",
          type: "date",
          col: 2,
        },
        {
          name: "end_date",
          title: "Vencimento (até)",
          type: "date",
          col: 2,
        },
        {
          name: "start_value",
          title: "Valor (de)",
          normalize: normalizeCurrency,
          col: 2,
        },
        {
          name: "end_value",
          title: "Valor (até)",
          normalize: normalizeCurrency,
          col: 2,
        },
      ]}
      labels={[
        {
          name: "operation.code",
          title: "Operação",
          formater: (_, title) => {
            return title.operation.code;
          },
        },
        {
          name: "titles.code",
          title: "Título",
          formater: (_, title) => {
            return title.code;
          },
        },
        {
          name: "titles.document_number",
          title: "Doc.",
          formater: (_, title) => {
            return title.document_number;
          },
        },
        {
          name: "payer.name",
          title: "Sacado",
          formater: (_, title) => {
            return (
              <div className="white-space-no-wrap">
                {subString(title.payer.name, 34)}
                <br />
                {normalizeCpfCnpj(title.payer.document_number)}
              </div>
            );
          },
        },
        {
          name: "titles.value",
          title: "Valor Original",
          formater: (_, title) => {
            return normalizeCurrency(title.value, true);
          },
          align: "right",
        },
        {
          name: "titles.open_value",
          title: "Valor em Aberto",
          formater: (_, title) => {
            return normalizeCurrency(title.open_value, true);
          },
          align: "right",
        },
        {
          name: "titles.date",
          title: "Vencimento",
          formater: (_, title) => {
            return formatDate(title.date);
          },
        },
        {
          name: "returnedDate",
          title: "Data da Devolução",
          ableToOrder: false,
        },
        {
          name: "reason",
          title: "Motivo",
          ableToOrder: false,
        },
      ]}
      editable={false}
      addItem={false}
    />
  );
};

export default memo(Open);

import React, { useState, useEffect } from "react";

import { Col, Row, Spinner } from "react-bootstrap";
import { Container, BlockDocuments } from "./styles";

import { api, useFetch } from "~/hooks/api";
import { DocItem } from "./components/DocItem";
import { useAuth } from "~/hooks/useAuth";
import { Alert } from "@material-ui/lab";

const AssignorDocuments: React.FC = () => {
  const { data: documentTypes } = useFetch("/document-types?type=assignor");

  const { pendencies, currentAssignor } = useAuth();
  const documentPendencies = pendencies.filter((p) => p.type === "document");

  const [documents, setDocuments] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  async function loadData() {
    setLoading(true);
    try {
      const { data } = await api.get(`/register/documents`);
      setDocuments(data);
    } catch (err) {
      //
    }
    setLoading(false);
  }
  useEffect(() => {
    loadData();
  }, []);

  return (
    <Container>
      <h4 className="mb-0">
        {loading && <Spinner animation="border" size="sm" />}Documentos da
        Empresa
      </h4>
      {documentPendencies.length > 0 && !currentAssignor.is_approved && (
        <Row>
          <Col lg={12}>
            <Alert severity="warning" className="mb-2">
              Você possui pendências neste módulo
            </Alert>
          </Col>
          {documentPendencies.map((p) => (
            <Col lg={12} key={p.id}>
              <Alert severity="error" className="mb-2">
                <strong className="d-block">{p.title}</strong>
                <small>{p.message}</small>
              </Alert>
            </Col>
          ))}
        </Row>
      )}
      {currentAssignor.is_approved && (
        <Alert severity="info" className="mb-3">
          Seu cadastro está aprovado, para atualizar as informações entre em
          contato com nossa Central de Atendimento.
        </Alert>
      )}
      <BlockDocuments className="mt-3">
        <div className="list_documents">
          {!loading &&
            documentTypes?.map((d) => {
              const items = documents
                .sort(
                  (a, b) =>
                    new Date(b.created_at).getTime() -
                    new Date(a.created_at).getTime()
                )
                .filter((i) => String(i.document_type_id) === String(d.id));
              const pendencies = documentPendencies.filter(
                (i) => i.document_type_id === d.id
              );
              return (
                <DocItem
                  type={d}
                  items={items}
                  key={d.id}
                  deletable={!currentAssignor.is_finished}
                  updatable={
                    currentAssignor.is_finished
                      ? currentAssignor.is_approved
                        ? false
                        : pendencies.length > 0
                      : true
                  }
                  onUpdate={(i) => {
                    loadData();
                  }}
                />
              );
            })}
        </div>
      </BlockDocuments>
    </Container>
  );
};

export default AssignorDocuments;

import { CircularProgress } from "@material-ui/core";
import { ExitToApp, ExpandMore, PermIdentity } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { api } from "~/hooks/api";
import { useAuth } from "~/hooks/useAuth";
import { normalizeCnpj } from "~/pages/main/utils/normalize";

import { Badge } from "react-bootstrap";
import { FaUser } from "react-icons/fa";
import { Container, Selection } from "./styles";

interface IProps {
  toggleTheme: (label: string) => void;
}

const Header: React.FC<IProps> = () => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [assignorsOptions, setAssignorsOptions] = useState<any[]>([]);

  useEffect(() => {
    api
      .get("me")
      .then((res) => {
        setAssignorsOptions(res.data.assignors);
      })
      .catch(() => {
        //
      });
  }, []);

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const {
    user: { name: fullname },
    currentAssignor: { name: companyName, cnpj, id: assignorId },
    signOut,
  } = useAuth();

  const subSocialReason = (value: string) => {
    if (!value) {
      return "[RAZÃO SOCIAL AUSENTE]";
    }
    return `${value.slice(0, 20)}${value.length > 20 ? "..." : ""}`;
  };

  async function handleSwitch(assignor_id: any) {
    setLoading(true);
    try {
      await api.post("/switch_company", {
        assignor_id,
      });

      const { data } = await api.get("me");

      if (data.current_assignor) {
        localStorage.setItem(
          "@:current_assignor",
          JSON.stringify(data.current_assignor)
        );
      }

      setOpen(false);

      setTimeout(() => {
        window.location.reload();
      }, 100);
    } catch (err) {
      //
    }
  }

  const homologEnv =
    window.location.href?.indexOf("cli.bfcdigital.com.br") === -1;

  return (
    <Container
      ref={wrapperRef}
      style={{ borderBottom: homologEnv ? "4px solid orange" : "" }}
    >
      <div className="container">
        <div className="d-flex align-items-center">
          <Link to="/dashboard">
            <img src="/assets/images/logo-branca.png" alt="BFC Digital" />
          </Link>
          <div className="mb-0 ml-2 company-slug">
            <h6 className="mb-0">Antecipação de Recebíveis</h6>
            {homologEnv ? (
              <Badge variant="warning">Ambiente de Homologação</Badge>
            ) : null}
          </div>
        </div>
        {loading && (
          <div
            className="d-flex align-items-center bg-white pt-2 pb-2 pl-3 pr-3"
            style={{ borderRadius: "4rem" }}
          >
            <CircularProgress size={14} />
            <strong className="ml-2 text-muted">Trocando de Empresa...</strong>
          </div>
        )}
        {!loading && (
          <>
            <div className="d-flex flex-column align-items-end">
              <button
                type="button"
                className="d-flex align-items-center radius-4 text-white pt-1 pb-1 pl-2 pr-2"
                onClick={() => setOpen(!open)}
                style={{ borderRadius: "4rem" }}
              >
                <FaUser className="mr-2" /> {fullname}
                <ExpandMore className={`icon-arrow ${open ? "opened" : ""}`} />
              </button>
              <Badge variant="primary" className="mt-1">
                {subSocialReason(companyName)} - {normalizeCnpj(cnpj)}
              </Badge>
            </div>
            <Selection
              className={
                open ? "section_company-open shadow" : "section_company-close"
              }
            >
              <div className="w-100">
                <small className="text-muted">
                  <i>Você está logado na empresa:</i>
                </small>
                <p className="text-left text-primary">
                  <strong>
                    {companyName}
                    <br />
                    {normalizeCnpj(cnpj)}
                  </strong>
                </p>
              </div>
              <hr />
              {assignorsOptions.filter((assignor) => assignor.id !== assignorId)
                .length > 0 && (
                <div className="w-100 p-2 container-switch-company">
                  <small className="text-muted text-left d-block">
                    <i>Outras Empresas</i>
                  </small>
                  {assignorsOptions
                    .filter((assignor) => assignor.id !== assignorId)
                    .map((assignor) => (
                      <button
                        className="btn-company-switch w-100 mt-2"
                        key={assignor.id}
                        onClick={() => handleSwitch(assignor.id)}
                      >
                        <strong>{assignor.name}</strong>
                        <div className="d-flex">
                          <small className="text-muted">
                            {normalizeCnpj(assignor.cnpj)}
                          </small>
                          <small className="text-primary ml-2">
                            Selecionar
                          </small>
                        </div>
                      </button>
                    ))}
                </div>
              )}
              <div
                className="d-flex justify-content-between align-items-center pt-3 pb-2 pl-1 pr-1"
                style={{ width: "100%" }}
              >
                <button
                  type="button"
                  className="logout text-muted"
                  onClick={() => {
                    setOpen(false);
                    history.push("/minha-conta");
                  }}
                >
                  <PermIdentity /> Perfil
                </button>
                <button
                  type="button"
                  className="logout text-muted"
                  onClick={() => signOut()}
                >
                  <ExitToApp /> Sair
                </button>
              </div>
            </Selection>
          </>
        )}
      </div>
    </Container>
  );
};

export default Header;

import React, { useState, useEffect } from "react";

import { Col, Modal, ProgressBar, Row, Spinner } from "react-bootstrap";

import { api, useFetch } from "~/hooks/api";
import { AiOutlineUpload } from "react-icons/ai";
import { FaCheckCircle, FaClock, FaFile, FaTrash } from "react-icons/fa";
import { MdQueryBuilder, MdVisibility } from "react-icons/md";
import { toast } from "react-toastify";

import { ModalDoc } from "./styles";
import { useAuth } from "~/hooks/useAuth";
import { Alert } from "@material-ui/lab";
import { useModal } from "~/hooks/useModal";
import { format } from "date-fns";

interface IDocItem {
  type: any;
  items?: any[];
  guarantor_id: number;
  onUpdate: (v: any) => void;
  updatable: boolean;
  deletable: boolean;
}

const DocItem: React.FC<IDocItem> = ({
  type,
  items: itemProps,
  guarantor_id,
  onUpdate,
  updatable,
  deletable,
}) => {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [items, setItems] = useState(itemProps);

  const modal = useModal();

  async function handleSend(file: File) {
    setLoading(true);
    try {
      const obj = new FormData();
      obj.append("document_type_id", String(type.id));
      obj.append("guarantor_id", String(guarantor_id));
      obj.append("file", file);
      const { data } = await api.post(`/register/documents`, obj, {
        onUploadProgress: function (progressEvent: any) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
        },
      });

      onUpdate(data);

      setItems([data, ...(items || [])]);

      toast.info("Documento Enviado");
    } catch (err) {
      //
    }
    setLoading(false);
  }

  const item = (items && items[0]) || {};

  return (
    <div key={type.id} className="document_item shadow">
      <span
        className={`icon_status ${item?.url ? "bg-success" : "bg-secondary"}`}
      >
        {loading && <Spinner animation="border" />}
        {item?.url && !loading && <FaCheckCircle />}
        {!item?.url && !loading && <MdQueryBuilder />}
      </span>
      <div className="d-flex justify-content-between align-items-center pt-3 pb-4 pl-3 pr-3">
        {loading && <ProgressBar animated now={progress} />}

        <div className="d-flex align-items-start" style={{ maxWidth: "80%" }}>
          <div>
            <small className="d-block m-0">{type.name} <span className="text-danger">{type?.is_mandatory ? '(Obrigatório)' : ''}</span></small>
            {item?.url ? (
              <a href={item?.url} target="blank">
                <MdVisibility /> Visualizar
              </a>
            ) : (
              <span style={{ opacity: "0.4" }}>Nenhum Documento</span>
            )}
            {items && items?.length > 0 ? (
              <button
                type="button"
                onClick={() => {
                  modal.show({
                    title: "Histórico",
                    message: (
                      <div className="mb-3">
                        {items?.map((doc) => (
                          <div
                            key={doc.id}
                            className="d-flex justify-content-between p-2 radius-4 bg-gray mb-2 align-items-center"
                          >
                            <label>
                              <a
                                href={doc.url}
                                target="blank"
                                className="btn d-flex align-items-center btn-outline-primary  pt-0 pb-0 pl-1 pr-1"
                              >
                                <FaFile className="mr-1" /> Abrir
                              </a>
                            </label>
                            {deletable && (
                              <label
                                onClick={() => {
                                  modal.warning({
                                    title: "Excluir Documento",
                                    message: (
                                      <div>
                                        <p>
                                          Tem certeza que deseja excluir este
                                          documento?
                                        </p>
                                        <button
                                          type="button"
                                          onClick={async () => {
                                            await api.delete(
                                              `/register/documents/${doc.id}`
                                            );
                                            onUpdate(
                                              items.filter(
                                                (i) => i.id !== doc.id
                                              )
                                            );
                                            modal.dismiss();
                                          }}
                                          className="btn btn-danger"
                                        >
                                          Excluir
                                        </button>
                                      </div>
                                    ),
                                  });
                                }}
                                className="btn d-flex align-items-center btn-outline-danger pt-0 pb-0 pl-1 pr-1"
                              >
                                <FaTrash className="mr-1" /> Excluir
                              </label>
                            )}
                            <small>
                              {format(
                                new Date(doc.created_at),
                                "dd/MM/yyyy HH:mm"
                              )}
                            </small>
                          </div>
                        ))}
                      </div>
                    ),
                  });
                }}
                className="btn pt-1 pb-1 pl-2 pr-2 btn-outline-secondary ml-2"
              >
                <FaClock /> Ver Histórico
              </button>
            ) : null}
          </div>
        </div>
        {updatable && (
          <label
            htmlFor={`file_upload_${type.id}`}
            className="btn btn-outline-secondary btn-sm"
          >
            <span>
              {loading ? (
                <>
                  <Spinner animation="border" size="sm" /> ENVIANDO
                </>
              ) : (
                <>
                  <AiOutlineUpload />
                  {item?.url && " REENVIAR"}
                  {!item?.url && " ENVIAR"}
                </>
              )}
            </span>
            <input
              type="file"
              id={`file_upload_${type.id}`}
              style={{ display: "none" }}
              disabled={loading || !updatable}
              onChange={(e) =>
                e?.target?.files && handleSend(e.target.files[0])
              }
            />
          </label>
        )}
      </div>
    </div>
  );
};

interface IProps {
  guarantor_id: number;
  reference_id: number;
  guarantor_name: string;
  type: "guarantor" | "guarantor-pj";
  onClose: () => void;
}

export const ModalDocuments: React.FC<IProps> = ({
  guarantor_id,
  reference_id,
  guarantor_name,
  type,
  onClose,
}) => {
  const [documents, setDocuments] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const { pendencies, currentAssignor } = useAuth();

  const pend = pendencies.filter(
    (p) => p.type === "guarantor" && p.guarantor_id === reference_id
  );

  const { data: docTypes } = useFetch(`/document-types?type=${type}`);

  async function loadData() {
    setLoading(true);
    try {
      const { data } = await api.get(
        `/register/documents?guarantor_id=${guarantor_id}`
      );
      setDocuments(data);
    } catch (err) {
      //
    }
    setLoading(false);
  }
  useEffect(() => {
    loadData();
  }, [guarantor_id]);

  return (
    <Modal show onHide={onClose} size="lg" centered>
      <Modal.Header closeButton>{guarantor_name}</Modal.Header>
      <Modal.Body>
        <ModalDoc>
          {loading && <Spinner animation="border" size="sm" />}
          {pend.length > 0 && (
            <Row>
              <Col lg={12} className="mt-3">
                {pend.map((p) => (
                  <Alert severity="error" key={p.id}>
                    <strong className="d-block">{p.title}</strong>
                    <small>{p.message}</small>
                  </Alert>
                ))}
              </Col>
            </Row>
          )}
          {!loading &&
            docTypes?.map((d) => {
              const items = documents
                .sort(
                  (a, b) =>
                    new Date(b.created_at).getTime() -
                    new Date(a.created_at).getTime()
                )
                .filter((i) => i.document_type_id === d.id);
              return (
                <DocItem
                  key={d.id}
                  type={d}
                  items={d.history ? items : [items[0]]}
                  guarantor_id={guarantor_id}
                  deletable={!currentAssignor.is_finished}
                  updatable={
                    currentAssignor.is_finished ? pend.length > 0 : true
                  }
                  onUpdate={(i) => {
                    loadData();
                  }}
                />
              );
            })}
        </ModalDoc>
      </Modal.Body>
    </Modal>
  );
};

export default function printDanfe(html: string): void {
  const mywindow = window.open('', 'PRINT')
  if(mywindow) {
    mywindow.document.write(html)
    mywindow.document.close()
    mywindow.focus()

    setTimeout(() => {
      mywindow.print()
      mywindow.close()
    }, 1500)
  }
  
}

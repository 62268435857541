function mod10(interval: string): string {
  let digits = 0;
  let factor = 2;

  const reverse_interval = interval.split('').reverse();

  for (let i = 0; i < reverse_interval.length; i += 1) {
    if (i % 2) {
      factor = 1;
    } else {
      factor = 2;
    }

    const arrayOfNumbers = String(Number(reverse_interval[i]) * factor).split(
      ''
    );
    let sumBetweenValues = 0;
    arrayOfNumbers.forEach((c: string) => {
      sumBetweenValues += Number(c);
    });
    digits += sumBetweenValues;
  }

  const remainder = digits % 10;

  if (remainder > 0) {
    return String(10 - remainder);
  }
  return '0';
}

export function cmc7IsValid(cmc7: string): boolean {
  if (!cmc7[21] || !cmc7[8] || !cmc7[32]) {
    return false;
  }
  if (cmc7[21] !== mod10(cmc7.slice(1, 8))) {
    return false;
  }
  if (cmc7[8] !== mod10(cmc7.slice(10, 20))) {
    return false;
  }
  if (cmc7[32] !== mod10(cmc7.slice(22, 32))) {
    return false;
  }
  return true;
}

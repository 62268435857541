import produce from 'immer';
import {
  RegisterActionTypes,
  IInitialState,
  EDIT_ACTIVE,
  EDIT_UNACTIVE,
  LOADING_ACTIVE,
  LOADING_UNACTIVE,
  MODAL_OPEN,
  MODAL_CLOSE,
} from './types';

const INITIAL_STATE: IInitialState = {
  isEditing: false,
  loading: false,
  modal: false,
  modal_to: '',
};

export default function user(
  state = INITIAL_STATE,
  action: RegisterActionTypes
): IInitialState {
  return produce(state, (draft) => {
    switch (action.type) {
      case EDIT_ACTIVE: {
        draft.isEditing = true;
        break;
      }
      case EDIT_UNACTIVE: {
        draft.isEditing = false;
        break;
      }
      case LOADING_ACTIVE: {
        draft.loading = true;
        break;
      }
      case LOADING_UNACTIVE: {
        draft.loading = false;
        break;
      }
      case MODAL_OPEN: {
        draft.modal = true;
        draft.modal_to = action.payload.modal_to;
        break;
      }
      case MODAL_CLOSE: {
        draft.modal = false;
        draft.modal_to = '';
        break;
      }
      default:
    }
  });
}

import React, { memo } from 'react';

import { Container } from './styles';

import TableOne from './components/TableOne';
import BoxFloat from './components/BoxFloat';

const Anticipation: React.FC = () => {
  return (
    <Container>
      <BoxFloat />
      <TableOne />
    </Container>
  );
};

export default memo(Anticipation);

export const EDIT_ACTIVE = '@register/EDIT_ACTIVE';
export const EDIT_UNACTIVE = '@register/EDIT_UNACTIVE';
export const LOADING_ACTIVE = '@register/LOADING_ACTIVE';
export const LOADING_UNACTIVE = '@register/LOADING_UNACTIVE';
export const MODAL_OPEN = '@register/MODAL_OPEN';
export const MODAL_CLOSE = '@register/MODAL_CLOSE';

export interface IInitialState {
  isEditing: boolean;
  loading: boolean;
  modal: boolean;
  modal_to: string;
}

export interface IEditActiveAction {
  type: typeof EDIT_ACTIVE;
}
export interface IEditUnactiveAction {
  type: typeof EDIT_UNACTIVE;
}
export interface ILoadingActiveAction {
  type: typeof LOADING_ACTIVE;
}
export interface ILoadingUnactiveAction {
  type: typeof LOADING_UNACTIVE;
}
export interface IModalOpenAction {
  type: typeof MODAL_OPEN;
  payload: {
    modal_to: string;
  };
}
export interface IModalCloseAction {
  type: typeof MODAL_CLOSE;
}

export type RegisterActionTypes =
  | IEditActiveAction
  | IEditUnactiveAction
  | ILoadingActiveAction
  | ILoadingUnactiveAction
  | IModalOpenAction
  | IModalCloseAction;

import { ISwitchCompanyAction } from '../user/types';
import { ISignOutAction } from '../auth/types';

export const STORE_OPERATION = '@checkAnticipation/STORE_OPERATION';
export const STORE_TITLES = '@checkAnticipation/STORE_TITLES';
export const RESET_ANTICIPATION = '@checkAnticipation/RESET_ANTICIPATION';
export const ADD_BANK_ACCOUNT = '@checkAnticipation/ADD_BANK_ACCOUNT';

export interface ITitle {
  id: string;
  cmc7: string;
  value: number;
  sacado: string;
  sacadoCnpj: string;
  status: string;
  titleDocument: string;
  titleNumber: string;
}

export interface IInitialState {
  operationId: null | string;
  titles: ITitle[];
  bankAccount: string | null;
}

export interface IStoreOperationAction {
  type: typeof STORE_OPERATION;
  payload: {
    operationId: string;
  };
}
export interface IStoreTitlesAction {
  type: typeof STORE_TITLES;
  payload: {
    titles: ITitle[];
  };
}
export interface IResetAnticipationAction {
  type: typeof RESET_ANTICIPATION;
}
export interface IAddBankAccountAction {
  type: typeof ADD_BANK_ACCOUNT;
  bankAccount: string;
}

export type AnticipationActionTypes =
  | IStoreOperationAction
  | IStoreTitlesAction
  | IResetAnticipationAction
  | IAddBankAccountAction
  | ISwitchCompanyAction
  | ISignOutAction;

import React, { useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { api } from "~/hooks/api";
import {
  normalizeBrl,
  normalizeDate,
  normalizeNumber,
  parseToDate,
} from "../../utils/normalize";

interface IProps {
  value: string;
  id: number;
  onHide: () => void;
}

export const ModalDate: React.FC<IProps> = ({ id, onHide, value }) => {
  const [digitedValue, setDigitedValue] = useState(value);
  const [loading, setLoading] = useState(false);

  async function handleSave() {
    setLoading(true);
    try {
      await api.put(`/titles/${id}/date`, {
        date: parseToDate(digitedValue),
      });
      toast.success("Data de vencimento atualizado");
      onHide();
    } catch (err) {
      //
    }
    setLoading(false);
  }

  if (loading) {
    return (
      <Modal show size="sm" centered>
        <Modal.Body>
          <Spinner animation="border" size="sm" />
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <Modal show onHide={() => onHide()} size="sm" centered>
      <Modal.Header closeButton>Editar data de vencimento</Modal.Header>
      <Modal.Body>
        <Form.Control
          value={normalizeDate(digitedValue)}
          onChange={(e) => {
            setDigitedValue(normalizeDate(e.target.value));
          }}
          disabled={loading}
          required
        />
        <div className="d-flex justify-content-center mt-3">
          <Button type="button" onClick={handleSave}>
            Salvar
          </Button>
          <Button
            type="button"
            onClick={() => onHide()}
            variant="outline-secondary"
            className="ml-2"
          >
            Cancelar
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

import styled from "styled-components";

export const Container = styled.div`
  div.logo-auth {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    img {
      max-width: 140px;
    }
    background: rgb(55, 87, 161);
    background: linear-gradient(
      180deg,
      rgba(55, 87, 161, 1) 0%,
      rgba(22, 44, 93, 1) 100%
    );
  }
  div.content-auth {
    background: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 768px) {
    div.logo-auth {
      flex-direction: row;
      text-align: left;
      img {
        max-width: 100px;
      }
      h2 {
        font-size: 1.4rem;
      }
    }
  }
`;

import { Button, Checkbox, LinearProgress, TextField } from "@material-ui/core";
import { Delete, Edit, Keyboard, Warning } from "@material-ui/icons";
import { addHours, format, isAfter } from "date-fns";
import React, { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";

import { FaTimes } from "react-icons/fa";

import Lottie from "react-lottie";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import animationData from "~/animations/box_empty.json";

import { api } from "~/hooks/api";
import { useAuth } from "~/hooks/useAuth";
import { useOperation } from "~/hooks/useOperation";
import { ModalDiscount } from "../../components/ModalDiscount";
import {
  normalizeBrl,
  payerIsComplete,
  stringDateToISOString,
} from "../../utils/normalize";
import {
  normalizeCpfCnpj,
  normalizeCurrency,
  normalizeNumber,
} from "../utils/normalize";
import ModalDelete from "./ModalDelete";
import ModalDeleteAll from "./ModalDeleteAll";
import ModalEditPayer from "./ModalEditPayer";
import ModalTapNote from "./ModalTapNote";

const TableOne: React.FC = () => {
  const dispatch = useDispatch();
  const [isSearch, setIsSearch] = useState(false);
  const [titles, setTitles] = useState<Title[]>([]);
  const [loading, setLoading] = useState(false);

  const { currentAssignor } = useAuth();
  const [editDiscount, setEditDiscount] = useState({
    open: false,
    id: 0,
    value: 0,
    originalValue: 0,
  });

  const [filters, setFilters] = useState({
    dataTituStart: "",
    dataTituEnd: "",
    valoTituStart: "",
    valoTituEnd: "",
    chave: "",
    sacaId: "",
    numeDoct: "",
  });
  const [updatePayer, setUpdatePayer] = useState({
    open: false,
    title: {} as Title,
  });
  const [confirmDelete, setConfirmDelete] = useState({
    open: false,
    id: 0,
  });
  const [confirmDeleteAll, setConfirmDeleteAll] = useState({
    open: false,
  });
  const [digitOpen, setDigitOpen] = useState(false);

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  async function loadData(refresh?: boolean) {
    setLoading(true);
    try {
      const query: string[] = [];
      query.push("type=CCB");
      if (filters.dataTituStart !== "") {
        query.push(
          `start_date=${stringDateToISOString(filters.dataTituStart)}`
        );
      }
      if (filters.dataTituEnd !== "") {
        query.push(`end_date=${stringDateToISOString(filters.dataTituEnd)}`);
      }
      if (filters.valoTituStart !== "") {
        query.push(
          `start_value=${parseFloat(
            filters.valoTituStart.replace(/[^\d]/g, "")
          )}`
        );
      }
      if (filters.valoTituEnd !== "") {
        query.push(
          `end_value=${parseFloat(filters.valoTituEnd.replace(/[^\d]/g, ""))}`
        );
      }
      if (filters.chave !== "") {
        query.push(`key=${filters.chave}`);
      }
      if (filters.sacaId !== "") {
        query.push(`document_number=${filters.sacaId.replace(/[^\d]/g, "")}`);
      }
      if (filters.numeDoct !== "") {
        query.push(`number=${filters.numeDoct}`);
      }
      const { data } = await api.get(
        `duplicates?${query.map((q) => q).join("&")}`
      );
      setTitles(data);
    } catch (error) {} // eslint-disable-line
    setLoading(false);
  }

  useEffect(() => {
    loadData();
  }, [dispatch]); // eslint-disable-line

  const { titles: selected, handleTitle, resetOperation } = useOperation();

  async function handleDelete() {
    setLoading(true);
    try {
      await api.delete(`duplicates/${confirmDelete.id}`);
      handleTitle(selected.filter((t) => t.id !== confirmDelete.id));
      setTitles(titles.filter((t) => t.id !== confirmDelete.id));
      setConfirmDelete({
        open: false,
        id: 0,
      });
      toast.info("Título excluído");
    } catch (err) {
      if ((err as any)?.response?.status === 400) {
        toast.error((err as any)?.response?.data?.message);
      }
    }
    setLoading(false);
  }

  async function handleDeleteAll() {
    setLoading(true);
    for (let i = 0; i < selected.length; i += 1) {
      const title = selected[i];
      try {
        await api.delete(`duplicates/${title.id}`);
        handleTitle(selected.filter((t) => t.id !== title.id));
      } catch (err) {} // eslint-disable-line
    }
    setConfirmDeleteAll({
      open: false,
    });
    loadData();
    toast.info("Títulos excluídos");
    setLoading(false);
  }

  useEffect(() => {
    resetOperation();
  }, []); // eslint-disable-line

  function handleAll() {
    handleTitle(titles);
  }

  return (
    <div id="anticipation_table_wrap">
      {editDiscount.open && (
        <ModalDiscount
          id={editDiscount.id}
          onHide={() => {
            setEditDiscount({
              open: false,
              id: 0,
              value: 0,
              originalValue: 0,
            });
            loadData();
          }}
          value={editDiscount.value}
          originalValue={editDiscount.originalValue}
        />
      )}
      <div className="d-flex justify-content-between align-items-center">
        <h5>
          <strong>Antecipar Recebíveis - CCB</strong>
        </h5>
        <div className="d-flex flex-wrap justify-content-end">
          <Button
            type="button"
            onClick={() => setDigitOpen(true)}
            color="primary"
            className="mr-2 text-white"
            variant="contained"
          >
            Digitar CCB
          </Button>
          <Button
            type="button"
            onClick={() => {
              if (isSearch) {
                loadData();
              } else {
                setIsSearch(true);
              }
            }}
            className="mr-2"
            disabled={loading}
            color="primary"
            variant="outlined"
          >
            {isSearch ? "Filtrar" : "Exibir filtro"}
          </Button>
          {isSearch &&
            Object.entries(filters).filter(([k, v], i) => !!v).length > 0 && ( // eslint-disable-line
              <Button
                color="default"
                variant="contained"
                type="button"
                onClick={() => {
                  setFilters({
                    dataTituStart: "",
                    dataTituEnd: "",
                    valoTituStart: "",
                    valoTituEnd: "",
                    chave: "",
                    sacaId: "",
                    numeDoct: "",
                  });
                  loadData(true);
                }}
              >
                <FaTimes className="mr-2" />
                Limpar filtros
              </Button>
            )}
          {selected.length > 0 && (
            <Button
              type="button"
              onClick={() => {
                setConfirmDeleteAll({
                  open: true,
                });
              }}
              disabled={loading}
              className="mt-2 text-danger"
              color="secondary"
              variant="outlined"
            >
              Excluir selecionados
            </Button>
          )}
        </div>
      </div>
      {isSearch && (
        <Row className="mb-3 mt-0 filters-wrap">
          <Col lg={6} className="mb-0 mt-0">
            <small className="d-block mb-2">Data de Emissão</small>
            <div className="d-flex align-items-center">
              <TextField
                type="date"
                value={filters.dataTituStart}
                onChange={(e) =>
                  setFilters({ ...filters, dataTituStart: e.target.value })
                }
              />
              <small className="d-block ml-2 mr-2">até</small>
              <TextField
                type="date"
                value={filters.dataTituEnd}
                onChange={(e) =>
                  setFilters({ ...filters, dataTituEnd: e.target.value })
                }
              />
            </div>
          </Col>
          <Col lg={6} className="mb-0 mt-0">
            <small className="d-block mb-2">Valor</small>
            <div className="d-flex align-items-center">
              <TextField
                placeholder="0,00"
                value={filters.valoTituStart}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    valoTituStart: normalizeCurrency(
                      Number(e.target.value.replace(/[^\d]/g, ""))
                    ),
                  })
                }
              />
              <small className="d-block ml-2 mr-2">até</small>
              <TextField
                placeholder="0,00"
                value={filters.valoTituEnd}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    valoTituEnd: normalizeCurrency(
                      Number(e.target.value.replace(/[^\d]/g, ""))
                    ),
                  })
                }
              />
            </div>
          </Col>
          <Col lg={3} className="mb-0 mt-0 d-flex align-items-end">
            <TextField
              label="Cód. de Verificação"
              value={filters.chave}
              onChange={(e) =>
                setFilters({ ...filters, chave: e.target.value.toUpperCase() })
              }
            />
          </Col>
          <Col lg={3} className="mb-0 mt-0 d-flex align-items-end">
            <TextField
              label="Num. da Nota"
              value={filters.numeDoct}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  numeDoct: normalizeNumber(e.target.value),
                })
              }
            />
          </Col>
          <Col lg={6} className="mb-0 mt-0 d-flex align-items-end">
            <TextField
              label="CPF/CNPJ Sacado"
              value={filters.sacaId}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  sacaId: normalizeCpfCnpj(e.target.value),
                })
              }
            />
          </Col>
        </Row>
      )}
      <ModalDelete
        open={confirmDelete.open}
        setOpen={() =>
          setConfirmDelete({
            id: 0,
            open: false,
          })
        }
        onConfirm={() => handleDelete()}
      />
      <ModalDeleteAll
        open={confirmDeleteAll.open}
        setOpen={() =>
          setConfirmDeleteAll({
            open: false,
          })
        }
        onConfirm={() => handleDeleteAll()}
      />
      <ModalEditPayer
        open={updatePayer.open}
        onAdd={() => {
          setUpdatePayer({
            open: false,
            title: {} as Title,
          });
          loadData();
        }}
        setOpen={() =>
          setUpdatePayer({
            open: false,
            title: {} as Title,
          })
        }
        note={updatePayer.title}
      />
      <ModalTapNote
        open={digitOpen}
        setOpen={(e) => setDigitOpen(e)}
        onAdd={() => loadData()}
      />
      {loading && <LinearProgress />}
      <Table responsive hover>
        <thead>
          <tr>
            <th className="text-left">Tomador</th>
            <th>Nº da Nota</th>
            <th>Documento</th>
            <th>Vencimento</th>
            <th>Valor</th>
            {currentAssignor.apply_discount && <th>Desconto</th>}
            <th />
            <th>
              <Checkbox
                checked={selected.length === titles.length}
                onClick={handleAll}
                color="primary"
                disabled={loading}
              />
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {titles.map((n) => {
            const checkedV = selected.find((i) => `${i.id}` === `${n.id}`);
            const isExpired = isAfter(new Date(), new Date(n.date));
            return (
              <tr
                key={`${n.id}`}
                className={checkedV ? "selected" : isExpired ? "disabled" : ""}
              >
                <td>
                  <strong>{n.payer.name}</strong>
                  <br />
                  <small>
                    {normalizeCpfCnpj(n.payer.document_number || "")}
                  </small>
                </td>
                <td>{n.ccb?.number}</td>
                <td>{n.number}</td>
                <td>
                  {n.date
                    ? format(addHours(new Date(n.date), 3), "dd/MM/yyyy")
                    : ""}
                </td>

                <td>{normalizeBrl(n.value)}</td>
                {currentAssignor.apply_discount && (
                  <td>
                    <div className="d-flex align-items-center">
                      {normalizeBrl(n.discount_value)}
                      <button
                        type="button"
                        onClick={() => {
                          setEditDiscount({
                            open: true,
                            id: n.id,
                            value: n.discount_value,
                            originalValue: n.value,
                          });
                        }}
                      >
                        <Edit />
                      </button>
                    </div>
                  </td>
                )}
                <td style={{ width: "10px" }}>
                  {!isExpired &&
                    currentAssignor.require_payer_details &&
                    !payerIsComplete(
                      currentAssignor.require_payer_details,
                      n
                    ) && (
                      <button
                        type="button"
                        onClick={() =>
                          setUpdatePayer({
                            open: true,
                            title: n,
                          })
                        }
                      >
                        <Warning className="text-warning" />
                      </button>
                    )}
                </td>
                <td
                  style={{ width: "10px" }}
                  onClick={() => {
                    if (isExpired) {
                      return;
                    }
                    if (
                      !payerIsComplete(currentAssignor.require_payer_details, n)
                    ) {
                      setUpdatePayer({
                        open: true,
                        title: n,
                      });
                    } else if (!loading) {
                      handleTitle(n);
                    }
                  }}
                >
                  <Checkbox
                    checked={!!checkedV}
                    disabled={isExpired}
                    color="primary"
                  />
                </td>
                <td style={{ width: "20px" }}>
                  <button
                    type="button"
                    onClick={() =>
                      setConfirmDelete({
                        open: true,
                        id: n.id || 0,
                      })
                    }
                  >
                    <Delete className="text-danger" />
                  </button>
                </td>
              </tr>
            );
          })}
          {!loading && titles.length === 0 && (
            <tr style={{ height: "350px" }}>
              <td className="text-center" colSpan={9}>
                <Lottie options={defaultOptions} height={160} width={160} />
                <h4>Nenhuma CCB encontrada.</h4>
                <h5>
                  Digite CCB's que deseja antecipar para torná-las visíveis
                  nesta lista
                </h5>
                <div className="d-flex justify-content-center mt-3">
                  <Button
                    type="button"
                    startIcon={<Keyboard />}
                    onClick={() => setDigitOpen(true)}
                    color="primary"
                    variant="contained"
                    className="text-white"
                  >
                    Digitar CCB
                  </Button>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default TableOne;

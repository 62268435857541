import {
  AuthActionTypes,
  SIGN_FAILURE,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_OUT,
  IUser,
  ICompany,
} from './types';

export interface IPermission {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
}

export interface ISignInRequest {
  email: string;
  password: string;
}
export interface ISignInSuccess {
  token: string;
  user: IUser;
  company: ICompany;
  permissions: IPermission[];
}

export function signInRequest({
  email,
  password,
}: ISignInRequest): AuthActionTypes {
  return {
    type: SIGN_IN_REQUEST,
    payload: { email, password },
  };
}

export function signInSuccess({
  token,
  user,
  company,
  permissions,
}: ISignInSuccess): AuthActionTypes {
  return {
    type: SIGN_IN_SUCCESS,
    payload: { token, user, company, permissions },
  };
}

export function signFailure(): AuthActionTypes {
  return {
    type: SIGN_FAILURE,
  };
}

export function signOut(): AuthActionTypes {
  return {
    type: SIGN_OUT,
  };
}

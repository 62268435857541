import React, { useRef, useState } from "react";
import { api } from "~/hooks/api";
import {
  normalizeCep,
  normalizeCpf,
  normalizeDate,
  normalizeNumber,
  normalizePhone,
  parseToDate,
} from "~/pages/main/utils/normalize";
import { states } from "~/pages/main/utils/states";
import consultCep from "~/services/consultCep";

import {
  FormControl,
  InputLabel,
  NativeSelect,
  TextField,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { useAuth } from "~/hooks/useAuth";

interface IProps {
  onClose: () => void;
  onAdd: () => void;
  onEdit: () => void;
  onDelete: (id: number) => void;
  data?: any;
}

export const AddGuarantorPF: React.FC<IProps> = ({
  onClose,
  data,
  onAdd,
  onEdit,
  onDelete,
}) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [values, setValues] = useState(() => {
    if (data) {
      return {
        ...data,
        birth_date: normalizeDate(data.birth_date),
        phone: normalizePhone(data.phone),
        document_number: normalizeCpf(data.document_number),
        zip_code: normalizeCep(data.zip_code),
      };
    }

    return {
      name: "José",
      street: "Rua Dona Virtuosa de Lucena",
      city: "Recife",
      district: "Beberibe",
      state: "Recife",
      number: "65",
      complement: "casa",
      rg_ie: "9241914",
      profession: "Programador",
      email: "jrobertofonsecajr@gmail.com",
      has_spouse: false,
      guarantor_types: [],
    } as any;
  });
  const [spouse, setSpouse] = useState(() => {
    if (data && data?.spouse) {
      return data.spouse;
    }
    return {} as any;
  });

  const { pendencies, currentAssignor, returnPendencies } = useAuth();
  const pend = pendencies.filter(
    (p) =>
      data.id && p.guarantor_id === data.guarantor_id && p.type === "guarantor"
  );

  const numberAddress = useRef<any>(null);

  const [loading, setLoading] = useState(false);

  async function handleCep() {
    setLoading(true);
    try {
      const data = await consultCep(normalizeNumber(values.zip_code));
      if (typeof data !== "boolean") {
        setValues({
          ...values,
          street: data.logradouro,
          city: data.cidade,
          district: data.bairro,
          state: data.estado,
          number: "",
          complement: "",
        });
        setLoading(false);
        numberAddress.current?.focus();
      } else {
        setValues({
          ...values,
          street: "",
          city: "",
          district: "",
          state: "",
          number: "",
          complement: "",
        });
      }
    } catch (err) {
      setValues({
        ...values,
        street: "",
        city: "",
        district: "",
        state: "",
        number: "",
        complement: "",
      });
      toast.error("CEP não encontrado");
    }
    setLoading(false);
  }

  async function handleAdd(): Promise<any> {
    return await api.post(`/register/guarantors`, {
      ...values,
      birth_date: parseToDate(values.birth_date),
      document_number: normalizeNumber(values.document_number),
      zip_code: normalizeNumber(values.zip_code),
      phone: normalizeNumber(values.phone)?.slice(0, 11),
      guarantor_types: [],
      has_signature: null,
      spouse: values.has_spouse
        ? {
            ...values, // eslint-disable-line
            ...spouse, // eslint-disable-line
            profession: spouse.profession,
            birth_date: parseToDate(spouse.birth_date),
            has_signature: null, // eslint-disable-line
            has_ecpf: spouse.has_ecpf, // eslint-disable-line
            has_spouse: true, // eslint-disable-line
            document_number: normalizeNumber(spouse.document_number), // eslint-disable-line
            zip_code: normalizeNumber(values.zip_code), // eslint-disable-line
            phone: normalizeNumber(spouse.phone)?.slice(0, 11), // eslint-disable-line
          } // eslint-disable-line
        : null,
    });
  }

  async function handleEdit(): Promise<any> {
    return await api.put(`/register/guarantors/${values.id}`, {
      ...values,
      birth_date: parseToDate(values.birth_date),
      document_number: normalizeNumber(values.document_number),
      zip_code: normalizeNumber(values.zip_code),
      phone: normalizeNumber(values.phone)?.slice(0, 11),
      guarantor_types: [],
      has_signature: null,
      spouse: values.has_spouse
        ? {
            ...values, // eslint-disable-line
            ...spouse, // eslint-disable-line
            name: spouse.name,
            profession: spouse.profession,
            birth_date: parseToDate(normalizeDate(spouse.birth_date)),
            has_signature: null, // eslint-disable-line
            has_ecpf: spouse.has_ecpf, // eslint-disable-line
            has_spouse: true, // eslint-disable-line
            document_number: normalizeNumber(spouse.document_number), // eslint-disable-line
            zip_code: normalizeNumber(values.zip_code), // eslint-disable-line
            phone: normalizeNumber(spouse.phone)?.slice(0, 11), // eslint-disable-line
          } // eslint-disable-line
        : undefined,
    });
  }

  async function handleDelete(): Promise<any> {
    await api.delete(`/register/guarantors/${values.id}`);
    onDelete(values.id);

    toast.success("Avalista excluido");

    onClose();
  }

  async function handleSubmit(proceed?: boolean) {
    setLoading(true);
    try {
      if (!values.id) {
        await handleAdd();
        onAdd();
        toast.success("Novo Avalista Salvo");
        if (!proceed) {
          onClose();
        }
      } else {
        await handleEdit();
        onEdit();
        await returnPendencies(pend);
        toast.success("Avalista Atualizado");
        if (!proceed) {
          onClose();
        }
      }
    } catch (err) {
      //
    }
    setLoading(false);
  }

  return (
    <Modal show centered size="lg" onHide={onClose}>
      <Modal.Header closeButton>
        <div>
          {loading && <Spinner animation="border" size="sm" />}
          {values.id ? "Editar Avalista (CPF)" : "Adicionar Avalista (CPF)"}
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          {pend.length > 0 && (
            <Col lg={12} className="mt-3">
              {pend.map((p) => (
                <Alert severity="error" key={p.id}>
                  <strong className="d-block">{p.title}</strong>
                  <small>{p.message}</small>
                </Alert>
              ))}
            </Col>
          )}
          <Col lg={12} className="mt-3">
            <strong>Dados Gerais</strong>
          </Col>
          <Col lg={6} className="mt-1">
            <TextField
              label="Nome Completo"
              required
              disabled={
                loading ||
                (currentAssignor.is_finished ? pend.length === 0 : false) ||
                currentAssignor.is_approved
              }
              value={values.name}
              onChange={(e) =>
                setValues({ ...values, name: e.target.value.toUpperCase() })
              }
            />
          </Col>
          <Col lg={3} className="mt-1">
            <TextField
              label="CPF"
              required
              disabled={
                loading ||
                (currentAssignor.is_finished ? pend.length === 0 : false) ||
                currentAssignor.is_approved
              }
              value={normalizeCpf(values.document_number)}
              onChange={(e) =>
                setValues({
                  ...values,
                  document_number: normalizeNumber(e.target.value),
                })
              }
            />
          </Col>

          <Col lg={3} className="mt-1">
            <TextField
              label="Data Nascimento"
              value={values.birth_date ? normalizeDate(values.birth_date) : ""}
              onChange={(e) =>
                setValues({
                  ...values,
                  birth_date: normalizeDate(e.target.value),
                })
              }
              disabled={
                loading ||
                (currentAssignor.is_finished ? pend.length === 0 : false) ||
                currentAssignor.is_approved
              }
            />
          </Col>
          <Col lg={3} className="mt-1">
            <TextField
              label="RG"
              value={values.rg_ie}
              onChange={(e) =>
                setValues({
                  ...values,
                  rg_ie: e.target.value,
                })
              }
              disabled={
                loading ||
                (currentAssignor.is_finished ? pend.length === 0 : false) ||
                currentAssignor.is_approved
              }
            />
          </Col>
          <Col lg={3} className="mt-1">
            <TextField
              label="Órgão Expedidor"
              value={values.document_emitter}
              onChange={(e) =>
                setValues({
                  ...values,
                  document_emitter: e.target.value,
                })
              }
              disabled={
                loading ||
                (currentAssignor.is_finished ? pend.length === 0 : false) ||
                currentAssignor.is_approved
              }
            />
          </Col>
          <Col lg={6} className="mt-1">
            <TextField
              label="Profissão"
              value={values.profession}
              onChange={(e) =>
                setValues({
                  ...values,
                  profession: e.target.value,
                })
              }
              disabled={
                loading ||
                (currentAssignor.is_finished ? pend.length === 0 : false) ||
                currentAssignor.is_approved
              }
            />
          </Col>
          <Col lg={3} className="mt-1">
            <FormControl>
              <InputLabel variant="standard" shrink>
                Estado Civil
              </InputLabel>
              <NativeSelect
                value={values.marital_status}
                onChange={(e) =>
                  setValues({
                    ...values,
                    marital_status: e.target.value,
                  })
                }
                disabled={
                  loading ||
                  (currentAssignor.is_finished ? pend.length === 0 : false) ||
                  currentAssignor.is_approved
                }
              >
                <option value="">Selecionar</option>
                {[
                  "Solteiro(a)",
                  "Casado(a)",
                  "Divorciado(a)",
                  "Viúvo(a)",
                  "Separado(a)",
                  "União Estável",
                ].map((status) => (
                  <option value={status} key={status}>
                    {status}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          </Col>
          {values.marital_status === "Casado(a)" && (
            <Col lg={3} className="mt-1">
              <FormControl>
                <InputLabel variant="standard" shrink>
                  Regime de Casamento
                </InputLabel>
                <NativeSelect
                  value={values.marital_regime}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      marital_regime: e.target.value,
                    })
                  }
                  disabled={
                    loading ||
                    (currentAssignor.is_finished ? pend.length === 0 : false) ||
                    currentAssignor.is_approved
                  }
                >
                  <option value="">Selecionar</option>
                  {[
                    "Comunhão Parcial de Bens",
                    "Comunhão Universal de Bens",
                    "Separação de Bens",
                  ].map((regime) => (
                    <option value={regime} key={regime}>
                      {regime}
                    </option>
                  ))}
                </NativeSelect>
              </FormControl>
            </Col>
          )}
          <Col lg={6} className="mt-1">
            <TextField
              label="Nome da Mãe"
              value={values.mother_name}
              onChange={(e) =>
                setValues({
                  ...values,
                  mother_name: e.target.value,
                })
              }
              disabled={
                loading ||
                (currentAssignor.is_finished ? pend.length === 0 : false) ||
                currentAssignor.is_approved
              }
            />
          </Col>
          <Col lg={3} className="mt-1">
            <TextField
              label="Nacionalidade"
              value={values.nacionality}
              onChange={(e) =>
                setValues({
                  ...values,
                  nacionality: e.target.value,
                })
              }
              disabled={
                loading ||
                (currentAssignor.is_finished ? pend.length === 0 : false) ||
                currentAssignor.is_approved
              }
            />
          </Col>
          <Col lg={12} className="mt-3">
            <strong>Dados de Contato</strong>
          </Col>
          <Col lg={3} className="mt-1">
            <TextField
              label="Telefone"
              disabled={
                loading ||
                (currentAssignor.is_finished ? pend.length === 0 : false) ||
                currentAssignor.is_approved
              }
              value={normalizePhone(values.phone)}
              onChange={(e) =>
                setValues({
                  ...values,
                  phone: normalizeNumber(e.target.value),
                })
              }
            />
          </Col>
          <Col lg={4} className="mt-1">
            <TextField
              label="E-mail"
              disabled={
                loading ||
                (currentAssignor.is_finished ? pend.length === 0 : false) ||
                currentAssignor.is_approved
              }
              value={values.email}
              onChange={(e) =>
                setValues({
                  ...values,
                  email: e.target.value,
                })
              }
            />
          </Col>
          <Col lg={12} className="mt-3">
            <strong>Endereço</strong>
          </Col>
          <Col lg={3} className="mt-1">
            <TextField
              label="CEP"
              disabled={
                loading ||
                (currentAssignor.is_finished ? pend.length === 0 : false) ||
                currentAssignor.is_approved
              }
              value={values.zip_code || ""}
              onChange={(e) =>
                setValues({
                  ...values,
                  zip_code: normalizeCep(e.target.value),
                })
              }
              onBlur={handleCep}
            />
          </Col>
          <Col lg={9} className="mt-1">
            <TextField
              label="Logradouro"
              disabled={
                loading ||
                (currentAssignor.is_finished ? pend.length === 0 : false) ||
                currentAssignor.is_approved
              }
              value={values.street || ""}
              onChange={(e) =>
                setValues({
                  ...values,
                  street: e.target.value,
                })
              }
            />
          </Col>
          <Col lg={3} className="mt-1">
            <TextField
              label="Nº"
              disabled={
                loading ||
                (currentAssignor.is_finished ? pend.length === 0 : false) ||
                currentAssignor.is_approved
              }
              value={values.number || ""}
              onChange={(e) =>
                setValues({
                  ...values,
                  number: e.target.value,
                })
              }
              ref={numberAddress}
            />
          </Col>
          <Col lg={9} className="mt-1">
            <TextField
              label="Complemento"
              disabled={
                loading ||
                (currentAssignor.is_finished ? pend.length === 0 : false) ||
                currentAssignor.is_approved
              }
              value={values.complement || ""}
              onChange={(e) =>
                setValues({
                  ...values,
                  complement: e.target.value,
                })
              }
            />
          </Col>
          <Col lg={5} className="mt-1">
            <TextField
              label="Bairro"
              disabled={
                loading ||
                (currentAssignor.is_finished ? pend.length === 0 : false) ||
                currentAssignor.is_approved
              }
              value={values.district || ""}
              onChange={(e) =>
                setValues({
                  ...values,
                  district: e.target.value,
                })
              }
            />
          </Col>
          <Col lg={5} className="mt-1">
            <TextField
              label="Cidade"
              disabled={
                loading ||
                (currentAssignor.is_finished ? pend.length === 0 : false) ||
                currentAssignor.is_approved
              }
              value={values.city || ""}
              onChange={(e) =>
                setValues({
                  ...values,
                  city: e.target.value,
                })
              }
            />
          </Col>
          <Col lg={2} className="mt-1">
            <FormControl>
              <InputLabel variant="standard" shrink>
                UF
              </InputLabel>
              <NativeSelect
                value={values.state || ""}
                onChange={(e) =>
                  setValues({
                    ...values,
                    state: e.target.value,
                  })
                }
                disabled={
                  loading ||
                  (currentAssignor.is_finished ? pend.length === 0 : false) ||
                  currentAssignor.is_approved
                }
              >
                <option value="">Selecionar</option>
                {states.map((state) => (
                  <option value={state.uf} key={state.uf}>
                    {state.uf}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          </Col>
          <Col lg={12} className="mt-3">
            <strong>Configurações</strong>
          </Col>
          <Col lg={3} className="mt-1">
            <Form.Group className="m-0" controlId="spouse">
              <Form.Check
                disabled={loading}
                label="Possui cônjuge"
                checked={
                  values.has_spouse ||
                  (currentAssignor.is_finished ? pend.length === 0 : false) ||
                  currentAssignor.is_approved
                }
                onChange={() =>
                  setValues({
                    ...values,
                    has_spouse: !values.has_spouse,
                  })
                }
              />
            </Form.Group>
          </Col>
          <Col lg={3} className="mt-1">
            <Form.Group className="m-0" controlId="ecpf">
              <Form.Check
                disabled={loading}
                label="Possui e-CPF"
                checked={
                  values.has_ecpf ||
                  (currentAssignor.is_finished ? pend.length === 0 : false) ||
                  currentAssignor.is_approved
                }
                onChange={() =>
                  setValues({
                    ...values,
                    has_ecpf: !values.has_ecpf,
                  })
                }
              />
            </Form.Group>
          </Col>
          {values.has_spouse && (
            <Col lg={12} className="mt-3">
              <strong>Cônjuge</strong>
              <Row
                className="m-0 mt-2 pb-3"
                style={{
                  backgroundColor: "rgba(0,0,0,.05)",
                  borderRadius: "0.5rem",
                }}
              >
                <Col lg={12} className="mt-3">
                  <strong>Dados Gerais</strong>
                </Col>
                <Col lg={6} className="mt-1">
                  <TextField
                    label="Nome Completo"
                    required
                    disabled={
                      loading ||
                      (currentAssignor.is_finished
                        ? pend.length === 0
                        : false) ||
                      currentAssignor.is_approved
                    }
                    value={spouse.name}
                    onChange={(e) =>
                      setSpouse({
                        ...spouse,
                        name: e.target.value.toUpperCase(),
                      })
                    }
                  />
                </Col>
                <Col lg={3} className="mt-1">
                  <TextField
                    label="CPF"
                    required
                    disabled={
                      loading ||
                      (currentAssignor.is_finished
                        ? pend.length === 0
                        : false) ||
                      currentAssignor.is_approved
                    }
                    value={normalizeCpf(spouse.document_number)}
                    onChange={(e) =>
                      setSpouse({
                        ...spouse,
                        document_number: normalizeNumber(e.target.value),
                      })
                    }
                  />
                </Col>
                <Col lg={3} className="mt-1">
                  <TextField
                    label="Data Nascimento"
                    value={
                      spouse.birth_date ? normalizeDate(spouse.birth_date) : ""
                    }
                    onChange={(e) =>
                      setSpouse({
                        ...spouse,
                        birth_date: normalizeDate(e.target.value),
                      })
                    }
                    disabled={
                      loading ||
                      (currentAssignor.is_finished
                        ? pend.length === 0
                        : false) ||
                      currentAssignor.is_approved
                    }
                  />
                </Col>
                <Col lg={3} className="mt-1">
                  <TextField
                    label="RG"
                    disabled={
                      loading ||
                      (currentAssignor.is_finished
                        ? pend.length === 0
                        : false) ||
                      currentAssignor.is_approved
                    }
                    value={spouse.rg_ie}
                    onChange={(e) =>
                      setSpouse({
                        ...spouse,
                        rg_ie: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col lg={3} className="mt-1">
                  <TextField
                    label="Órgão Expedidor"
                    disabled={
                      loading ||
                      (currentAssignor.is_finished
                        ? pend.length === 0
                        : false) ||
                      currentAssignor.is_approved
                    }
                    value={spouse.document_emitter}
                    onChange={(e) =>
                      setSpouse({
                        ...spouse,
                        document_emitter: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col lg={3} className="mt-1">
                  <TextField
                    label="Profissão"
                    value={spouse.profession}
                    disabled={
                      loading ||
                      (currentAssignor.is_finished
                        ? pend.length === 0
                        : false) ||
                      currentAssignor.is_approved
                    }
                    onChange={(e) =>
                      setSpouse({
                        ...spouse,
                        profession: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col lg={3} className="mt-1">
                  <TextField
                    label="Nacionalidade"
                    value={spouse.nacionality}
                    onChange={(e) =>
                      setSpouse({
                        ...spouse,
                        nacionality: e.target.value,
                      })
                    }
                    disabled={
                      loading ||
                      (currentAssignor.is_finished
                        ? pend.length === 0
                        : false) ||
                      currentAssignor.is_approved
                    }
                  />
                </Col>
                <Col lg={12} className="mt-3">
                  <strong>Dados de Contato</strong>
                </Col>
                <Col lg={3} className="mt-1">
                  <TextField
                    label="Telefone"
                    disabled={
                      loading ||
                      (currentAssignor.is_finished
                        ? pend.length === 0
                        : false) ||
                      currentAssignor.is_approved
                    }
                    value={normalizePhone(spouse.phone)}
                    onChange={(e) =>
                      setSpouse({
                        ...spouse,
                        phone: normalizeNumber(e.target.value),
                      })
                    }
                  />
                </Col>
                <Col lg={4} className="mt-1">
                  <TextField
                    label="E-mail"
                    disabled={
                      loading ||
                      (currentAssignor.is_finished
                        ? pend.length === 0
                        : false) ||
                      currentAssignor.is_approved
                    }
                    value={spouse.email}
                    onChange={(e) =>
                      setSpouse({
                        ...spouse,
                        email: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col lg={12} className="mt-3">
                  <strong>Configurações</strong>
                </Col>
                <Col lg={3} className="mt-3">
                  <Form.Group className="m-0" controlId="ecpf_spouse">
                    <Form.Check
                      disabled={
                        loading ||
                        (currentAssignor.is_finished
                          ? pend.length === 0
                          : false) ||
                        currentAssignor.is_approved
                      }
                      label="Possui e-CPF"
                      checked={spouse.has_ecpf}
                      onChange={() =>
                        setSpouse({
                          ...spouse,
                          has_ecpf: !spouse.has_ecpf,
                        })
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        {confirmDelete && (
          <div
            className="d-flex flex-column align-items-start"
            style={{ width: "100%" }}
          >
            <small className="d-block mb-2">
              Você realmente deseja excluir este avalista?
            </small>
            <div className="d-flex">
              <Button
                type="button"
                variant="danger"
                onClick={() => handleDelete()}
                disabled={
                  loading ||
                  (currentAssignor.is_finished ? pend.length === 0 : false) ||
                  currentAssignor.is_approved
                }
              >
                Sim, excluir
              </Button>
              <Button
                type="button"
                className="ml-2"
                variant="outline-danger"
                onClick={() => setConfirmDelete(false)}
                disabled={
                  loading ||
                  (currentAssignor.is_finished ? pend.length === 0 : false) ||
                  currentAssignor.is_approved
                }
              >
                Cancelar
              </Button>
            </div>
          </div>
        )}
        {!confirmDelete && (
          <div
            className={`d-flex justify-content-${
              values.id && !currentAssignor.is_finished ? "between" : "end"
            } mb-3`}
            style={{ width: "100%" }}
          >
            {values.id && !currentAssignor.is_finished && (
              <Button
                type="button"
                variant="outline-danger"
                onClick={() => setConfirmDelete(true)}
                disabled={loading}
              >
                Excluir
              </Button>
            )}
            <div className="d-flex">
              <Button
                type="button"
                variant="outline-primary"
                onClick={() => handleSubmit(true)}
                disabled={
                  loading ||
                  (currentAssignor.is_finished ? pend.length === 0 : false) ||
                  currentAssignor.is_approved
                }
              >
                Salvar e Continuar
              </Button>
              <Button
                type="button"
                className="ml-2"
                variant="outline-primary"
                onClick={() => handleSubmit()}
                disabled={
                  loading ||
                  (currentAssignor.is_finished ? pend.length === 0 : false) ||
                  currentAssignor.is_approved
                }
              >
                Salvar
              </Button>
              <Button
                type="button"
                className="ml-2"
                variant="outline-secondary"
                onClick={onClose}
                disabled={loading}
              >
                Cancelar
              </Button>
            </div>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

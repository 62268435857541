import { TextField } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { api } from '~/hooks/api'
import { useAuth } from '~/hooks/useAuth'
import { normalizeNumber, normalizePhone } from '~/pages/main/utils/normalize'
import React, { useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { toast } from 'react-toastify'

interface IProps {
  onClose: () => void
  contact?: any
  onAdd: (item: any) => void
  onUpdate: (item: any) => void
  onDelete: (id: number) => void
}

export const ModalAdd: React.FC<IProps> = ({
  contact,
  onClose,
  onAdd,
  onUpdate,
  onDelete
}) => {
  const { pendencies, currentAssignor, returnPendencies } = useAuth()
  const pend = pendencies.filter(p => p.type === 'financial_reference' && contact?.id && p.financial_reference_id === contact?.id)

  const [values, setValues] = useState(() => {
    if (contact) {
      return {
        ...contact,
        phone: normalizePhone(contact.phone),
      }
    }

    return {} as any
  })

  const [confirmDelete, setConfirmDelete] = useState(false)

  const [loading, setLoading] = useState(false)

  async function handleAdd(): Promise<any> {
    return await api.post(`/register/financialReferences`, {
      ...values,
      phone: normalizeNumber(values.phone)
    })
  }

  async function handleEdit(): Promise<any> {
    return await api.put(`/register/financialReferences/${values.id}`, {
      ...values,
      phone: normalizeNumber(values.phone)
    })
  }

  async function handleDelete(): Promise<any> {
    setLoading(true)
    try {
      await api.delete(`/register/financialReferences/${values.id}`)
      onDelete(contact.id)
      toast.success('Referência Financeira deletado')
      onClose()
    } catch (err) {
      //
    }
    setLoading(false)
  }

  async function handleSubmit() {
    setLoading(true)
    try {
      if (values.id) {
        const { data } = await handleEdit()
        onUpdate(data)
        await returnPendencies(pend)
        toast.success('Referência Financeira atualizado')
        onClose()
      } else {
        const { data } = await handleAdd()
        onAdd(data)
        toast.success('Referência Financeira adicionado')
        onClose()
      }
    } catch (err) {
      //
    }
    setLoading(false)
  }

  return (
    <Modal show centered onHide={onClose}>
      <Modal.Header closeButton>
        {contact?.id ? 'Atualizar Referência Financeira' : 'Adicionar Referência Financeira'}
      </Modal.Header>
      <Modal.Body>
        <Row>
          {pend.length > 0 && (
            <Col lg={12}>
              {pend.map(p => (
                <Alert severity="error">
                  <strong className="d-block">{p.title}</strong>
                  <small>{p.message}</small>
                </Alert>
              ))}
            </Col>
          )}
          <Col lg={12}>
            <Form.Group className="mb-2">
              <TextField
                label="Empresa"
                value={values.name || ''}
                onChange={e => setValues({ ...values, name: e.target.value })}
                disabled={loading || (currentAssignor.is_finished ? pend.length === 0 : false) || currentAssignor.is_approved}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <Form.Group className="mb-2">
              <TextField
                label="Tipo de Negócio"
                value={values.type || ''}
                onChange={e =>
                  setValues({
                    ...values,
                    type: e.target.value
                  })
                }
                disabled={loading || (currentAssignor.is_finished ? pend.length === 0 : false) || currentAssignor.is_approved}
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mb-2">
              <Form.Label></Form.Label>
              <TextField
                label="Telefone"
                value={values.phone || ''}
                onChange={e =>
                  setValues({
                    ...values,
                    phone: normalizePhone(e.target.value)
                  })
                }
                disabled={loading || (currentAssignor.is_finished ? pend.length === 0 : false) || currentAssignor.is_approved}
              />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        {!confirmDelete && (
          <div
            className={`d-flex justify-content-${
              values.id && !currentAssignor.is_finished ? 'between' : 'end'
            } mb-2`}
            style={{ width: '100%' }}
          >
            {values.id && !currentAssignor.is_finished && (
                <Button
                  type="button"
                  variant="outline-danger"
                  className="btn-sm"
                  onClick={() => setConfirmDelete(true)}
                >
                  Excluir
                </Button>
            )}

            <div className="d-flex">
              <Button
                type="button"
                variant="primary"
                onClick={handleSubmit}
                className="btn-sm"
                disabled={loading || (currentAssignor.is_finished ? pend.length === 0 : false) || currentAssignor.is_approved}
              >
                Salvar
              </Button>
              <Button
                type="button"
                variant="outline-secondary"
                disabled={loading}
                onClick={onClose}
                className="ml-2 btn-sm"
              >
                Cancelar
              </Button>
            </div>
          </div>
        )}
        {confirmDelete && (
          <div style={{ width: '100%' }} className="mb-2">
            <small className="d-block mb-2 text-right w-100">Você tem certeza que deseja excluir?</small>
            <div className="d-flex justify-content-end">
              <Button
                type="button"
                variant="danger"
                onClick={handleDelete}
                className="btn-sm"
                disabled={loading || (currentAssignor.is_finished ? pend.length === 0 : false) || currentAssignor.is_approved}
              >
                Sim, excluir
              </Button>
              <Button
                type="button"
                variant="outline-secondary"
                className="ml-2 btn-sm"
                disabled={loading || (currentAssignor.is_finished ? pend.length === 0 : false) || currentAssignor.is_approved}
                onClick={() => setConfirmDelete(false)}
              >
                Cancelar
              </Button>
            </div>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  )
}

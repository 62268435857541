import React, { useState } from "react";

import { Link } from "react-router-dom";
import { TextField } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { cpfIsValid, normalizeCpf } from "~/pages/main/utils/normalize";
import { useAuth } from "~/hooks/useAuth";
import { FaChevronLeft } from "react-icons/fa";
import { Layout } from "../components/Layout";

export default function Home() {
  const { signUp } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  const [name, setName] = useState("");
  const [cnpj, setCnpj] = useState("");

  const [msgUser, setMsgUser] = useState({ type: "", value: "" });
  const [msgCompany, setMsgCompany] = useState({ type: "", value: "" });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    password: "",
    cnpj: "",
  });

  const normalizeCnpj = (value: string) => {
    if (!value) {
      return value;
    }

    const onlyNums = value.replace(/[^\d]/g, "");

    if (onlyNums.slice(0, 3) === "000" && cpfIsValid(onlyNums.slice(3))) {
      return normalizeCpf(onlyNums.slice(3));
    }

    if (onlyNums.length <= 2) {
      return onlyNums;
    }
    if (onlyNums.length <= 5) {
      return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}`;
    }
    if (onlyNums.length <= 8) {
      return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
        5,
        8
      )}`;
    }
    if (onlyNums.length <= 12) {
      return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
        5,
        8
      )}/${onlyNums.slice(8, 12)}`;
    }
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
      5,
      8
    )}/${onlyNums.slice(8, 12)}-${onlyNums.slice(12, 14)}`;
  };

  async function handleSubmit(e: any) {
    e.preventDefault();
    setMsgUser({ type: "", value: "" });
    setMsgCompany({ type: "", value: "" });
    setErrors({
      name: "",
      email: "",
      password: "",
      cnpj: "",
    });
    function fullnameIsValid(name: string) {
      if (name.indexOf(" ") === -1) {
        return false;
      }
      return true;
    }
    function emailIsValid(email: string) {
      if (email.indexOf("@") === -1) {
        return false;
      }
      const after = email.split("@");
      if (after[1].indexOf(".") === -1) {
        return false;
      }
      return true;
    }
    function passwordIsValid(senha: string) {
      const regex = /^(?=(?:.*?[0-9]){1})(?=(?:.*?[a-zA-Z]){1})(?!.*\s)[0-9a-zA-Z!@#$%;*(){}_+^&]*$/;
      if (senha.length < 8) {
        return false;
      }
      if (!regex.exec(senha)) {
        return false;
      }
      return true;
    }
    function cnpjIsValid(cnpj: any) {
      cnpj = cnpj.replace(/[^\d]+/g, "");

      if (cnpj === "") return false;

      if (cnpj.length !== 14) return false;

      // Elimina CNPJs invalidos conhecidos
      if (
        cnpj === "00000000000000" ||
        cnpj === "11111111111111" ||
        cnpj === "22222222222222" ||
        cnpj === "33333333333333" ||
        cnpj === "44444444444444" ||
        cnpj === "55555555555555" ||
        cnpj === "66666666666666" ||
        cnpj === "77777777777777" ||
        cnpj === "88888888888888" ||
        cnpj === "99999999999999"
      )
        return false;

      let tamanho = cnpj.length - 2;
      let numeros = cnpj.substring(0, tamanho);
      const digitos = cnpj.substring(tamanho);
      let soma = 0;
      let pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado !== Number(digitos.charAt(0))) return false;

      tamanho += 1;
      numeros = cnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado !== Number(digitos.charAt(1))) return false;

      return true;
    }
    const lock_error = {
      name: "",
      email: "",
      password: "",
      cnpj: "",
    };
    if (!fullnameIsValid(name)) {
      lock_error.name = "Preencha seu nome completo";
    }
    if (!emailIsValid(email)) {
      lock_error.email = "Email não é válido";
    }
    if (password.length === 0) {
      lock_error.password = "Preencha este campo";
    } else if (password.length < 8) {
      lock_error.password = "A senha deve conter no minímo 8 digitos!";
    } else if (!passwordIsValid(password)) {
      lock_error.password =
        "A senha deve possuir no mínimo um caracter e um número.";
    }
    if (name.length === 0) {
      lock_error.name = "Preencha este campo";
    }
    if (!cnpjIsValid(cnpj)) {
      lock_error.cnpj = "CNPJ não é válido";
    }
    if (
      lock_error.email !== "" ||
      lock_error.password !== "" ||
      lock_error.name !== "" ||
      lock_error.cnpj !== ""
    ) {
      return setErrors(lock_error);
    }

    await signUp(cnpj, name, email, password);
  }

  return (
    <Layout>
      <div className="p-4">
        <h4 className="text-main mb-0">
          <strong>Realizar Cadastro</strong>
        </h4>
        <Link to="/auth/login" className="text-muted p-0 m-0">
          <small>
            <u>
              <FaChevronLeft /> Voltar para o Login
            </u>
          </small>
        </Link>

        <form onSubmit={handleSubmit} className="mt-1">
          <div className="row w-100">
            <div className="col-md-12">
              <h5 className="text-muted mb-1 mt-3">Seus dados de acesso</h5>
              {msgUser.value !== "" && (
                <Alert
                  severity={msgUser.type === "error" ? "error" : "success"}
                  className="mb-2"
                >
                  {msgUser.value}
                </Alert>
              )}
              <TextField
                type="text"
                label="Nome Completo"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  if (errors.name !== "") {
                    setErrors({
                      ...errors,
                      name: "",
                    });
                  }
                }}
                style={{ animationDelay: "300ms" }}
                error={errors.name !== ""}
                helperText={errors.name}
                inputProps={{
                  autocomplete: "off",
                }}
                className="mt-2"
                size="small"
                variant="outlined"
                required
              />
              <TextField
                type="email"
                label="E-mail"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  if (errors.email !== "") {
                    setErrors({
                      ...errors,
                      email: "",
                    });
                  }
                }}
                style={{ animationDelay: "600ms" }}
                error={errors.email !== ""}
                helperText={errors.email}
                inputProps={{
                  autocomplete: "off",
                }}
                className="mt-2"
                size="small"
                variant="outlined"
                required
              />
              <div className="d-flex justify-content-center mt-2 align-items-center">
                <TextField
                  label="Senha"
                  type={passwordVisible ? "text" : "password"}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    if (errors.password !== "") {
                      setErrors({
                        ...errors,
                        password: "",
                      });
                    }
                  }}
                  style={{ animationDelay: "750ms" }}
                  error={errors.password !== ""}
                  helperText={errors.password}
                  inputProps={{
                    autocomplete: "new-password",
                  }}
                  size="small"
                  variant="outlined"
                  required
                />
                <button
                  type="button"
                  style={{ animationDelay: "800ms" }}
                  className="ml-2 mt-2 inputIcon"
                  onClick={() => setPasswordVisible(!passwordVisible)}
                >
                  {!passwordVisible ? <VisibilityOff /> : <Visibility />}
                </button>
              </div>
              <small className="text-muted d-block mt-2">
                Senha deverá conter no mínimo oito caracteres, sendo pelo menos
                um número.
              </small>
            </div>
            <div className="col-md-12">
              <h5 className="text-muted mb-1 mt-3">Dados da Empresa</h5>
              {msgCompany.value !== "" && (
                <Alert
                  severity={msgCompany.type === "error" ? "error" : "success"}
                >
                  {msgCompany.value}
                </Alert>
              )}
              <TextField
                type="text"
                label="CNPJ"
                value={cnpj}
                onChange={(e) => {
                  setCnpj(normalizeCnpj(e.target.value));
                  if (errors.cnpj !== "") {
                    setErrors({
                      ...errors,
                      cnpj: "",
                    });
                  }
                }}
                style={{ animationDelay: "450ms" }}
                error={errors.cnpj !== ""}
                helperText={errors.cnpj}
                inputProps={{
                  autocomplete: "off",
                }}
                className="mt-2"
                size="small"
                variant="outlined"
                required
              />
            </div>
          </div>
          <div className="d-flex flex-wrap align-items-center mt-5 mb-5">
            <button type="submit" className="btn btn-primary bg-main">
              Criar Conta
            </button>
            <Link to="/auth/login" className="btn btn-outline-secondary ml-2">
              Voltar
            </Link>
          </div>
        </form>
      </div>
    </Layout>
  );
}

import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Danfe from "./Danfe";
import LetterConfirm from "./LetterConfirm";

export default function PayerPage() {
  return (
    <Switch>
      <Route path="/payer/danfe/:token/:key" component={Danfe} />
      <Route path="/payer/letter-confirm/:token" component={LetterConfirm} />
    </Switch>
  );
}

import { TextField } from '@material-ui/core';
import { api } from '~/hooks/api';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Layout } from '../components/Layout';

const ActivePassword: React.FC = ({ match }: any) => {
  const [values, setValues] = useState({
    password: '',
    confirmPassword: '',
  });
  const [error, setError] = useState({
    password: '',
    confirmPassword: '',
  });

  const { token } = match.params;
  const history = useHistory();

  if (!token || token.length === 0) {
    history.push('/auth/home');
  }

  async function handleSubmit(e: any) {
    e.preventDefault();
    setError({
      password: '',
      confirmPassword: '',
    });
    let lock_error = {
      password: '',
      confirmPassword: '',
    };
    if (values.password.length === 0) {
      lock_error.password = 'Preencha esse Campo';
    }
    if (values.confirmPassword.length === 0) {
      lock_error.confirmPassword = 'Preencha esse Campo';
    }
    if (values.password !== values.confirmPassword) {
      lock_error = {
        password: 'Senhas não coincidem',
        confirmPassword: 'Senhas não coincidem',
      };
    }
    if (lock_error.password !== '' || lock_error.confirmPassword !== '') {
      return setError(lock_error);
    }
    try {
      await api.post(`users/user/active`, {
        newPassword: values.password,
        token,
      });
      history.push('/auth/login');
      toast.success('Conta ativada');
    } catch (err) {
      //
    }
  }

  return (
    <Layout>
      <div className="p-4">
        <h4 className="text-main"><strong>Ativar Conta</strong></h4>
        <p>
          Falta muito pouco para você acessar sua conta! Adicione uma senha
          e comece a utilizar.
        </p>
        <form onSubmit={handleSubmit} className="row">
          <div className="col-md-4">
            <TextField
              type="password"
              label="Senha"
              value={values.password}
              onChange={(e) =>
                setValues({ ...values, password: e.target.value })
              }
              style={{ animationDelay: '150ms' }}
              error={error.password !== ''}
              helperText={error.password}
              variant='outlined'
              size="small"
            />
            <TextField
              type="password"
              label="Confirmar Senha"
              value={values.confirmPassword}
              onChange={(e) =>
                setValues({ ...values, confirmPassword: e.target.value })
              }
              style={{ animationDelay: '300ms' }}
              error={error.confirmPassword !== ''}
              helperText={error.confirmPassword}
              variant='outlined'
              size="small"
              className="mt-2"
            />
            <div className="mt-4">
              <button type="submit" className="btn btn-primary bg-main">
                Ativar Conta
              </button>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  )
};

export default ActivePassword;

import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { useAuth } from '~/hooks/useAuth';

import AuthPage from '../pages/auth/AuthPage';
import MainPage from '../pages/main/MainPage';
import PayerPage from '../pages/payer/PayerPage';

const Routes: React.FC = () => {
  const { signed } = useAuth();

  const isPayerPage = window.location.href.indexOf('payer/') > -1

  return (
    <Switch>
      {isPayerPage && (
        <PayerPage />
      )}
      {!signed ? <AuthPage /> : <MainPage />}
      {!signed ? <Redirect to="/auth" /> : <Redirect to="/" />}
    </Switch>
  );
};

export default Routes;

import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  background: url("/assets/images/bg-header.jpg") no-repeat 50% 50%;
  background-size: cover;
  padding: 1rem 0.8rem 0.8rem 0.8rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  justify-content: center;
  align-items: center;
  h6 {
    color: #fff;
  }
  img {
    width: 100%;
    max-width: 60px;
  }

  @media screen and (max-width: 500px) {
    .company-slug {
      display: none;
    }
  }
`;

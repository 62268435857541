import styled from 'styled-components';

export const Container = styled.div`
  background: ${(props) => props.theme.colors.background};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  #operations_table_wrap {
    > div:nth-child(1) {
      padding: 30px 30px 10px 30px;
    }
    div.footer_component {
      padding: 15px;
    }
    div.filters {
      padding: 0px 30px;
      margin-bottom: 16px;
    }
    table {
      thead {
        tr {
          background: rgb(55 87 161 / 20%);
          th {
            padding: 10px 7.5px;
            border: none;
            font-weight: 500;
            font-size: 14px;
            text-align: center;
            vertical-align: middle;
            &:first-child {
              padding-left: 20px;
            }
          }
        }
      }
      tbody {
        tr {
          &.selected {
            background: rgba(0, 73, 255, 0.03);
          }
          cursor: pointer;
          td {
            padding: 6px 7.5px;
            text-align: center;
            vertical-align: middle;
            font-size: 13px;
            line-height: 1;
            &:first-child {
              padding-left: 20px;
            }
            svg {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
`;

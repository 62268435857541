import React from "react";
import "dotenv/config";

import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { ToastContainer, Slide } from "react-toastify";
import Routes from "./routes";
import { store, persitor } from "./store";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { AuthProvider } from "./hooks/useAuth";
import { ModalProvider } from "./hooks/useModal";

function App() {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#3757a1",
        contrastText: "#fff",
      },
      secondary: {
        main: "#e03226",
        contrastText: "#fff",
      },
    },
  });
  return (
    <Provider store={store}>
      <PersistGate persistor={persitor}>
        <ModalProvider>
          <AuthProvider>
            <ToastContainer transition={Slide} position="bottom-right" />
            <Router>
              <ThemeProvider theme={theme}>
                <Routes />
              </ThemeProvider>
            </Router>
          </AuthProvider>
        </ModalProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;

import React, { useEffect, useState, useRef } from "react";
import { useFetch, api } from "~/hooks/api";
import consultCep from "~/services/consultCep";
import {
  normalizeCep,
  normalizeCpf,
  normalizeNumber,
  normalizePhone,
  normalizeCnpj,
  normalizeDate,
  parseToDate,
} from "~/pages/main/utils/normalize";
import { states } from "~/pages/main/utils/states";

import {
  Modal,
  Form,
  Row,
  Col,
  Button,
  Spinner,
  Accordion,
  Card,
} from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import { useAuth } from "~/hooks/useAuth";
import { Alert } from "@material-ui/lab";
import {
  FormControl,
  InputLabel,
  NativeSelect,
  TextField,
} from "@material-ui/core";

interface IProps {
  onClose: () => void;
  onAdd: () => void;
  onEdit: () => void;
  onDelete: (id: number) => void;
  data?: any;
}

export const AddGuarantorPJ: React.FC<IProps> = ({
  onClose,
  data,
  onAdd,
  onEdit,
  onDelete,
}) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [values, setValues] = useState(() => {
    if (data) {
      return {
        ...data,
        birth_date: normalizeDate(data.birth_date),
        phone: normalizePhone(data.phone),
        document_number: normalizeCpf(data.document_number),
        zip_code: normalizeCep(data.zip_code),
      };
    }
    return {} as any;
  });
  const [partners, setPartners] = useState(() => {
    if (data && data.partners) {
      return data.partners.map((p) => ({
        ...p,
        birth_date: normalizeDate(p.birth_date),
        phone: normalizePhone(p.phone),
        document_number: normalizeCpf(p.document_number),
        zip_code: normalizeCep(p.zip_code),
      }));
    }
    return [] as any;
  });

  const { pendencies, currentAssignor, returnPendencies } = useAuth();
  const pend = pendencies.filter(
    (p) =>
      data.id && p.guarantor_id === data.guarantor_id && p.type === "guarantor"
  );

  const [deletedPartners, setDeletedPartners] = useState<number[]>([]);

  const numberAddress = useRef<any>(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadData() {
      const { data: guarantorData } = await api.get(
        `/register/guarantors/${data.id}`
      );

      setValues({
        ...data,
        birth_date: normalizeDate(data.birth_date),
        phone: normalizePhone(data.phone),
        document_number: normalizeCnpj(data.document_number),
        zip_code: normalizeCep(data.zip_code),
      });
      setPartners(
        guarantorData.partners.map((p) => ({
          ...p,
          birth_date: normalizeDate(p.birth_date),
          phone: normalizePhone(p.phone),
          document_number: normalizeCpf(p.document_number),
          zip_code: normalizeCep(p.zip_code),
        }))
      );
    }

    if (data.id) {
      loadData();
    } else {
      setPartners([{} as any]);
    }
  }, [data.id]); // eslint-disable-line

  async function handleCep() {
    setLoading(true);
    try {
      const data = await consultCep(normalizeNumber(values.zip_code));
      if (typeof data !== "boolean") {
        setValues({
          ...values,
          street: data.logradouro,
          city: data.cidade,
          district: data.bairro,
          state: data.estado,
          number: "",
          complement: "",
        });
        setLoading(false);
        numberAddress.current?.focus();
      } else {
        setValues({
          ...values,
          street: "",
          city: "",
          district: "",
          state: "",
          number: "",
          complement: "",
        });
      }
    } catch (err) {
      //
    }
    setLoading(false);
  }
  async function handleCepPartner(index: number) {
    setLoading(true);
    try {
      const data = await consultCep(normalizeNumber(partners[index].zip_code));
      const i = [...partners];
      if (typeof data !== "boolean") {
        i[index] = {
          ...i[index],
          street: data.logradouro,
          city: data.cidade,
          district: data.bairro,
          state: data.estado,
          number: "",
          complement: "",
        };
        setLoading(false);
        document.getElementById(`partner_${index}_number`)?.focus();
      } else {
        i[index] = {
          ...i[index],
          street: "",
          city: "",
          district: "",
          state: "",
          number: "",
          complement: "",
        };
      }
      setPartners(i);
    } catch (err) {
      //
    }
    setLoading(false);
  }

  async function handleAdd(): Promise<any> {
    return await api.post(`/register/guarantors`, {
      ...values,
      birth_date: parseToDate(values.birth_date),
      document_number: normalizeNumber(values.document_number),
      zip_code: normalizeNumber(values.zip_code),
      phone: normalizeNumber(values.phone),
      guarantor_types: values.guarantorTypes?.map((i) => i.id) || [],
      partners: partners.map((p) => ({
        ...p,
        birth_date: parseToDate(p.birth_date),
        document_number: normalizeNumber(p.document_number),
        zip_code: normalizeNumber(p.zip_code),
        phone: normalizeNumber(p.phone),
      })),
    });
  }

  async function handleEdit(): Promise<any> {
    return await api.put(`/register/guarantors/${values.id}`, {
      ...values,
      birth_date: parseToDate(values.birth_date),
      document_number: normalizeNumber(values.document_number),
      zip_code: normalizeNumber(values.zip_code),
      phone: normalizeNumber(values.phone),
      guarantor_types: values.guarantorTypes?.map((i) => i.id) || [],
      partners: partners.map((p) => ({
        ...p,
        birth_date: parseToDate(p.birth_date),
        document_number: normalizeNumber(p.document_number),
        zip_code: normalizeNumber(p.zip_code),
        phone: normalizeNumber(p.phone),
      })),
    });
  }

  async function handleDelete(): Promise<any> {
    await api.delete(`/register/guarantors/${values.id}`);
    onDelete(values.id);

    toast.success("Avalista excluido");

    onClose();
  }

  function changePartnerValue(index: number, column: string, value: any) {
    const i = [...partners];
    i[index][column] = value;
    setPartners(i);
  }

  async function handleSubmit(proceed?: boolean) {
    setLoading(true);
    try {
      if (!values.id) {
        await handleAdd();
        onAdd();
        toast.success("Novo Avalista Salvo");
        if (!proceed) {
          onClose();
        }
      } else {
        for (let c = 0; c < deletedPartners.length; c += 1) {
          try {
            await api.delete(
              `/register/guarantors/${values.id}/partners/${deletedPartners[c]}`
            );
          } catch (err) {
            //
          }
        }
        await handleEdit();
        await returnPendencies(pend);
        onEdit();
        toast.success("Avalista Atualizado");
        if (!proceed) {
          onClose();
        }
      }
    } catch (err) {
      //
    }
    setLoading(false);
  }

  return (
    <Modal show centered size="lg" onHide={onClose}>
      <Modal.Header closeButton>
        <div>
          {loading && <Spinner animation="border" size="sm" />}
          {values.id ? "Editar Avalista (CNPJ)" : "Adicionar Avalista (CNPJ)"}
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          {pend.length > 0 && (
            <Col lg={12} className="mt-3">
              {pend.map((p) => (
                <Alert severity="error" key={p.id}>
                  <strong className="d-block">{p.title}</strong>
                  <small>{p.message}</small>
                </Alert>
              ))}
            </Col>
          )}
          <Col lg={12} className="mt-3">
            <strong>Dados Gerais</strong>
          </Col>
          <Col lg={3} className="mt-1">
            <TextField
              label="CNPJ"
              disabled={
                loading ||
                (currentAssignor.is_finished ? pend.length === 0 : false) ||
                currentAssignor.is_approved
              }
              value={values.document_number}
              onChange={(e) =>
                setValues({
                  ...values,
                  document_number: normalizeCnpj(e.target.value),
                })
              }
            />
          </Col>
          <Col lg={9} className="mt-1">
            <TextField
              label="Razão Social"
              required
              disabled={
                loading ||
                (currentAssignor.is_finished ? pend.length === 0 : false) ||
                currentAssignor.is_approved
              }
              value={values.name}
              onChange={(e) =>
                setValues({ ...values, name: e.target.value.toUpperCase() })
              }
            />
          </Col>

          <Col lg={3} className="mt-1">
            <TextField
              label="Inscrição Estadual"
              value={values.rg_ie}
              onChange={(e) =>
                setValues({
                  ...values,
                  rg_ie: normalizeNumber(e.target.value),
                })
              }
              disabled={
                loading ||
                (currentAssignor.is_finished ? pend.length === 0 : false) ||
                currentAssignor.is_approved
              }
            />
          </Col>
          <Col lg={3} className="mt-1">
            <TextField
              label="Data Fundação"
              value={values.birth_date}
              onChange={(e) =>
                setValues({
                  ...values,
                  birth_date: normalizeDate(e.target.value),
                })
              }
              disabled={
                loading ||
                (currentAssignor.is_finished ? pend.length === 0 : false) ||
                currentAssignor.is_approved
              }
            />
          </Col>
          <Col lg={12} className="mt-3">
            <strong>Dados de Contato</strong>
          </Col>
          <Col lg={3} className="mt-1">
            <TextField
              label="Telefone"
              disabled={
                loading ||
                (currentAssignor.is_finished ? pend.length === 0 : false) ||
                currentAssignor.is_approved
              }
              value={values.phone}
              onChange={(e) =>
                setValues({
                  ...values,
                  phone: normalizePhone(e.target.value),
                })
              }
            />
          </Col>
          <Col lg={4} className="mt-1">
            <TextField
              label="E-mail"
              disabled={
                loading ||
                (currentAssignor.is_finished ? pend.length === 0 : false) ||
                currentAssignor.is_approved
              }
              value={values.email}
              onChange={(e) =>
                setValues({
                  ...values,
                  email: e.target.value,
                })
              }
            />
          </Col>
          <Col lg={12} className="mt-3">
            <strong>Endereço</strong>
          </Col>
          <Col lg={3} className="mt-1">
            <TextField
              label="CEP"
              disabled={
                loading ||
                (currentAssignor.is_finished ? pend.length === 0 : false) ||
                currentAssignor.is_approved
              }
              value={values.zip_code || ""}
              onChange={(e) =>
                setValues({
                  ...values,
                  zip_code: normalizeCep(e.target.value),
                })
              }
              onBlur={handleCep}
            />
          </Col>
          <Col lg={9} className="mt-1">
            <TextField
              label="Logradouro"
              disabled={
                loading ||
                (currentAssignor.is_finished ? pend.length === 0 : false) ||
                currentAssignor.is_approved
              }
              value={values.street || ""}
              onChange={(e) =>
                setValues({
                  ...values,
                  street: e.target.value,
                })
              }
            />
          </Col>
          <Col lg={3} className="mt-1">
            <TextField
              label="Nº"
              disabled={
                loading ||
                (currentAssignor.is_finished ? pend.length === 0 : false) ||
                currentAssignor.is_approved
              }
              value={values.number || ""}
              onChange={(e) =>
                setValues({
                  ...values,
                  number: e.target.value,
                })
              }
              ref={numberAddress}
            />
          </Col>
          <Col lg={9} className="mt-1">
            <TextField
              label="Complemento"
              disabled={
                loading ||
                (currentAssignor.is_finished ? pend.length === 0 : false) ||
                currentAssignor.is_approved
              }
              value={values.complement || ""}
              onChange={(e) =>
                setValues({
                  ...values,
                  complement: e.target.value,
                })
              }
            />
          </Col>
          <Col lg={5} className="mt-1">
            <TextField
              label="Bairro"
              disabled={
                loading ||
                (currentAssignor.is_finished ? pend.length === 0 : false) ||
                currentAssignor.is_approved
              }
              value={values.district || ""}
              onChange={(e) =>
                setValues({
                  ...values,
                  district: e.target.value,
                })
              }
            />
          </Col>
          <Col lg={5} className="mt-1">
            <TextField
              label="Cidade"
              disabled={
                loading ||
                (currentAssignor.is_finished ? pend.length === 0 : false) ||
                currentAssignor.is_approved
              }
              value={values.city || ""}
              onChange={(e) =>
                setValues({
                  ...values,
                  city: e.target.value,
                })
              }
            />
          </Col>
          <Col lg={2} className="mt-1">
            <FormControl>
              <InputLabel variant="standard" shrink>
                UF
              </InputLabel>
              <NativeSelect
                value={values.state || ""}
                onChange={(e) =>
                  setValues({
                    ...values,
                    state: e.target.value,
                  })
                }
                disabled={
                  loading ||
                  (currentAssignor.is_finished ? pend.length === 0 : false) ||
                  currentAssignor.is_approved
                }
              >
                <option value="">Selecionar</option>
                {states.map((state) => (
                  <option value={state.uf} key={state.uf}>
                    {state.uf}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          </Col>
          <Col lg={12} className="mt-3">
            <strong>Representantes</strong>
          </Col>
          <Col lg={12} className="mt-1">
            {partners?.map((partner, index) => (
              <Accordion
                key={String(index)}
                defaultActiveKey={
                  values.id && partner.id ? undefined : String(index)
                }
              >
                <Card>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Button}
                      variant="link"
                      eventKey={String(index)}
                      style={{ width: "100%", textAlign: "left" }}
                    >
                      #{index + 1} - {partner.name} /{" "}
                      {normalizeCpf(partner.document_number)}
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey={String(index)}>
                    <Card.Body>
                      <Row>
                        <Col lg={12} className="mt-1">
                          <strong>Dados Gerais</strong>
                        </Col>
                        <Col lg={6} className="mt-1">
                          <TextField
                            label="Nome Completo"
                            disabled={
                              loading ||
                              (currentAssignor.is_finished
                                ? pend.length === 0
                                : false) ||
                              currentAssignor.is_approved
                            }
                            value={partner.name}
                            onChange={(e) =>
                              changePartnerValue(
                                index,
                                "name",
                                e.target.value.toUpperCase()
                              )
                            }
                          />
                        </Col>
                        <Col lg={3} className="mt-1">
                          <TextField
                            label="CPF"
                            disabled={
                              loading ||
                              (currentAssignor.is_finished
                                ? pend.length === 0
                                : false) ||
                              currentAssignor.is_approved
                            }
                            value={partner.document_number}
                            onChange={(e) =>
                              changePartnerValue(
                                index,
                                "document_number",
                                normalizeCpf(e.target.value)
                              )
                            }
                          />
                        </Col>
                        <Col lg={3} className="mt-1">
                          <TextField
                            label="RG"
                            value={partner.rg_ie}
                            onChange={(e) =>
                              changePartnerValue(index, "rg_ie", e.target.value)
                            }
                            disabled={
                              loading ||
                              (currentAssignor.is_finished
                                ? pend.length === 0
                                : false) ||
                              currentAssignor.is_approved
                            }
                          />
                        </Col>
                        <Col lg={3} className="mt-1">
                          <TextField
                            label="Data Nascimento"
                            value={partner.birth_date}
                            onChange={(e) =>
                              changePartnerValue(
                                index,
                                "birth_date",
                                normalizeDate(e.target.value)
                              )
                            }
                            disabled={
                              loading ||
                              (currentAssignor.is_finished
                                ? pend.length === 0
                                : false) ||
                              currentAssignor.is_approved
                            }
                          />
                        </Col>
                        <Col lg={12} className="mt-3">
                          <strong>Dados de Contato</strong>
                        </Col>
                        <Col lg={3} className="mt-1">
                          <TextField
                            label="Telefone"
                            disabled={
                              loading ||
                              (currentAssignor.is_finished
                                ? pend.length === 0
                                : false) ||
                              currentAssignor.is_approved
                            }
                            value={partner.phone}
                            onChange={(e) =>
                              changePartnerValue(
                                index,
                                "phone",
                                normalizePhone(e.target.value)
                              )
                            }
                          />
                        </Col>
                        <Col lg={4} className="mt-1">
                          <TextField
                            label="E-mail"
                            disabled={
                              loading ||
                              (currentAssignor.is_finished
                                ? pend.length === 0
                                : false) ||
                              currentAssignor.is_approved
                            }
                            value={partner.email}
                            onChange={(e) =>
                              changePartnerValue(index, "email", e.target.value)
                            }
                          />
                        </Col>
                        <Col lg={12} className="mt-3">
                          <strong>Endereço</strong>
                        </Col>
                        <Col lg={3} className="mt-1">
                          <TextField
                            label="CEP"
                            disabled={
                              loading ||
                              (currentAssignor.is_finished
                                ? pend.length === 0
                                : false) ||
                              currentAssignor.is_approved
                            }
                            value={partner.zip_code}
                            onChange={(e) =>
                              changePartnerValue(
                                index,
                                "zip_code",
                                normalizeCep(e.target.value)
                              )
                            }
                            onBlur={() => handleCepPartner(index)}
                          />
                        </Col>
                        <Col lg={9} className="mt-1">
                          <TextField
                            label="Logradouro"
                            disabled={
                              loading ||
                              (currentAssignor.is_finished
                                ? pend.length === 0
                                : false) ||
                              currentAssignor.is_approved
                            }
                            value={partner.street || ""}
                            onChange={(e) =>
                              changePartnerValue(
                                index,
                                "street",
                                e.target.value
                              )
                            }
                          />
                        </Col>
                        <Col lg={3} className="mt-1">
                          <TextField
                            label="Nº"
                            disabled={
                              loading ||
                              (currentAssignor.is_finished
                                ? pend.length === 0
                                : false) ||
                              currentAssignor.is_approved
                            }
                            value={partner.number || ""}
                            id={`partner_${index}_number`}
                            onChange={(e) =>
                              changePartnerValue(
                                index,
                                "number",
                                e.target.value
                              )
                            }
                          />
                        </Col>
                        <Col lg={9} className="mt-1">
                          <TextField
                            label="Complemento"
                            disabled={
                              loading ||
                              (currentAssignor.is_finished
                                ? pend.length === 0
                                : false) ||
                              currentAssignor.is_approved
                            }
                            value={partner.complement || ""}
                            onChange={(e) =>
                              changePartnerValue(
                                index,
                                "complement",
                                e.target.value
                              )
                            }
                          />
                        </Col>
                        <Col lg={5} className="mt-1">
                          <TextField
                            label="Bairro"
                            disabled={
                              loading ||
                              (currentAssignor.is_finished
                                ? pend.length === 0
                                : false) ||
                              currentAssignor.is_approved
                            }
                            value={partner.district || ""}
                            onChange={(e) =>
                              changePartnerValue(
                                index,
                                "district",
                                e.target.value
                              )
                            }
                          />
                        </Col>
                        <Col lg={5} className="mt-1">
                          <TextField
                            label="Cidade"
                            disabled={
                              loading ||
                              (currentAssignor.is_finished
                                ? pend.length === 0
                                : false) ||
                              currentAssignor.is_approved
                            }
                            value={partner.city || ""}
                            onChange={(e) =>
                              changePartnerValue(index, "city", e.target.value)
                            }
                          />
                        </Col>
                        <Col lg={2} className="mt-1">
                          <FormControl>
                            <InputLabel variant="standard" shrink>
                              UF
                            </InputLabel>
                            <NativeSelect
                              value={partner.state || ""}
                              onChange={(e) =>
                                changePartnerValue(
                                  index,
                                  "state",
                                  e.target.value
                                )
                              }
                              disabled={
                                loading ||
                                (currentAssignor.is_finished
                                  ? pend.length === 0
                                  : false) ||
                                currentAssignor.is_approved
                              }
                            >
                              <option value="">Selecionar</option>
                              {states.map((state) => (
                                <option value={state.uf} key={state.uf}>
                                  {state.uf}
                                </option>
                              ))}
                            </NativeSelect>
                          </FormControl>
                        </Col>
                        <Col lg={12} className="mt-3">
                          <strong>Configurações</strong>
                        </Col>
                        <Col lg={3} className="mt-1">
                          <Form.Group
                            className="m-0"
                            controlId={`signature_${index}`}
                          >
                            <Form.Check
                              disabled={
                                loading ||
                                (currentAssignor.is_finished
                                  ? pend.length === 0
                                  : false) ||
                                currentAssignor.is_approved
                              }
                              label="Assina Contrato"
                              checked={partner.has_signature}
                              onChange={() =>
                                changePartnerValue(
                                  index,
                                  "has_signature",
                                  !partner.has_signature
                                )
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={3} className="mt-1">
                          <Form.Group
                            className="m-0"
                            controlId={`ecpf_${index}`}
                          >
                            <Form.Check
                              disabled={
                                loading ||
                                (currentAssignor.is_finished
                                  ? pend.length === 0
                                  : false) ||
                                currentAssignor.is_approved
                              }
                              label="Possui e-CPF"
                              checked={partner.has_ecpf}
                              onChange={() =>
                                changePartnerValue(
                                  index,
                                  "has_ecpf",
                                  !partner.has_ecpf
                                )
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      {index >= 0 && (
                        <div className="d-flex justify-content-end mt-3">
                          <Button
                            type="button"
                            variant="outline-danger"
                            onClick={() => {
                              if (partner.id) {
                                setDeletedPartners([
                                  ...deletedPartners,
                                  partner.id,
                                ]);
                              }
                              setPartners(
                                partners.filter((p, i) => i !== index)
                              );
                            }}
                          >
                            Excluir
                          </Button>
                        </div>
                      )}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            ))}
            <div className="d-flex justify-content-end mt-3">
              <Button
                type="button"
                variant="outline-primary"
                onClick={() =>
                  setPartners([
                    ...partners,
                    {
                      has_signature: true,
                    } as any,
                  ])
                }
              >
                <FaPlus className="m-0" />
              </Button>
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        {confirmDelete && (
          <div
            className="d-flex flex-column align-items-start"
            style={{ width: "100%" }}
          >
            <small className="d-block mb-2">
              Você realmente deseja excluir este avalista?
            </small>
            <div className="d-flex">
              <Button
                type="button"
                variant="danger"
                onClick={() => handleDelete()}
                disabled={
                  loading ||
                  (currentAssignor.is_finished ? pend.length === 0 : false) ||
                  currentAssignor.is_approved
                }
              >
                Sim, excluir
              </Button>
              <Button
                type="button"
                className="ml-2"
                variant="outline-danger"
                onClick={() => setConfirmDelete(false)}
                disabled={
                  loading ||
                  (currentAssignor.is_finished ? pend.length === 0 : false) ||
                  currentAssignor.is_approved
                }
              >
                Cancelar
              </Button>
            </div>
          </div>
        )}
        {!confirmDelete && (
          <div
            className={`d-flex justify-content-${
              values.id && !currentAssignor.is_finished ? "between" : "end"
            } mb-3`}
            style={{ width: "100%" }}
          >
            {values.id && !currentAssignor.is_finished && (
              <Button
                type="button"
                variant="outline-danger"
                onClick={() => handleDelete()}
                disabled={
                  loading ||
                  (currentAssignor.is_finished ? pend.length === 0 : false) ||
                  currentAssignor.is_approved
                }
              >
                Excluir
              </Button>
            )}
            <div className="d-flex">
              <Button
                type="button"
                variant="outline-primary"
                onClick={() => handleSubmit(true)}
                disabled={
                  loading ||
                  (currentAssignor.is_finished ? pend.length === 0 : false) ||
                  currentAssignor.is_approved
                }
              >
                Salvar e Continuar
              </Button>
              <Button
                type="button"
                className="ml-2"
                variant="outline-primary"
                onClick={() => handleSubmit()}
                disabled={
                  loading ||
                  (currentAssignor.is_finished ? pend.length === 0 : false) ||
                  currentAssignor.is_approved
                }
              >
                Salvar
              </Button>
              <Button
                type="button"
                className="ml-2"
                variant="outline-secondary"
                onClick={onClose}
                disabled={loading}
              >
                Cancelar
              </Button>
            </div>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

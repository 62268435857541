import React, { memo } from "react";

import { Container } from "./styles";

import BoxFloat from "./components/BoxFloat";
import TableOne from "./components/TableOne";

const CcbAnticipation: React.FC = () => {
  return (
    <Container>
      <BoxFloat />
      <TableOne />
    </Container>
  );
};

export default memo(CcbAnticipation);

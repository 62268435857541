import React from "react";

import { Container } from "./styles";

export const Layout: React.FC = ({ children }) => {
  return (
    <Container className="row w-100 min-height-100vh m-0">
      <div className="col-md-6 p-0">
        <div
          className="p-4 logo-auth text-center text-white"
          style={{ minHeight: "100%" }}
        >
          <img
            src="/assets/images/logo-branca.png"
            alt="Logo BFC Capital Partners"
            className="w-100"
          />
          <h2>Plataforma do Cliente</h2>
        </div>
      </div>
      <div className="col-md-6 p-0 content-auth">{children}</div>
    </Container>
  );
};

import { lighten } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  padding: 2rem;
  border-radius: 1rem;
  background: #fff;
  box-shadow: 0 0 5px rgba(0,0,0,.1);
  input[type='file'] {
    display: none;
  }
  > h4 {
    font-weight: 700;
    padding-bottom: 15px;
    border-bottom: 1px solid rgb(247, 247, 247);
  }
  .ecnpj_box {
    label#e_cnpj_label {
      padding: 0.5rem 1.5rem;
      text-align: center;
      border: 1px solid ${props => props.theme.colors.primary};
      border-radius: 4px;
      text-transform: uppercase;
      color: ${props => props.theme.colors.primary};
      cursor: pointer;
      transition: 300ms;
      font-size: 0.8rem;
      &:hover {
        background: ${props => lighten(0.1, props.theme.colors.background)};
      }
    }
  }
  #detached_file {
    padding: 0.8rem;
    background: ${props => props.theme.colors.primary};
    border-radius: 6px;
    #grid_detail {
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-gap: 30px;
      width: 100%;
      .item_grid {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        .d-flex {
          width: 100%;
        }
        h5 {
          font-size: 16px;
          color: #fff;
        }
        &:nth-child(1) {
          background: ${props => props.theme.colors.primary};
          padding: 20px 15px;
          border-radius: 6px;
        }
        &:nth-child(2) {
          padding: 0.8rem;
          border: 1px solid ${props => props.theme.colors.primary};
          border-radius: 6px;
          background: #fff;
        }
      }
    }
  }
`

import React from 'react';
import { useHistory } from 'react-router-dom';

import { Layout } from '../components/Layout';

const Confirm: React.FC = ({ match }: any) => {
  const history = useHistory();

  async function handleSubmit(e: any) {
    e.preventDefault();

    history.push('/auth/login');
  }

  return (
    <Layout>
      <div className="p-4">

        <form onSubmit={handleSubmit}>
          <div className="form-container">
            <h4 className="text-main"><strong>Estamos quase lá!</strong></h4>
            <p>
              Enviamos uma mensagem de verificação para seu e-mail (se não
              encontrá-lo, confira sua caixa de spam).
              <br />O seu acesso será liberado assim que seu cadastro for
              verificado.
            </p>
            <div className="mt-4">
              <button type="submit" className="bg-main btn btn-primary pl-3 pr-3">
                Entrar
              </button>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  )
};

export default Confirm;

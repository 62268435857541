import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useDispatch } from "react-redux";

import { CircularProgress, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useAuth } from "~/hooks/useAuth";
import { editUnActive } from "../../../store/modules/register/actions";
import { Layout } from "../components/Layout";

const Login: React.FC = () => {
  const { signIn, loading } = useAuth();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(editUnActive());
  }, []); // eslint-disable-line
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });
  const [msg, setMsg] = useState("");

  const [successLogin] = useState(false);

  const handleSubmit = async (e: any) => {
    //eslint-disable-line
    e.preventDefault();
    setErrors({
      email: "",
      password: "",
    });
    setMsg("");
    function emailIsValid(mail: string) {
      if (mail.indexOf("@") === -1) {
        return false;
      }
      const after = mail.split("@");
      if (after[1].indexOf(".") === -1) {
        return false;
      }
      return true;
    }
    const lock_error = {
      email: "",
      password: "",
    };
    if (!emailIsValid(email)) {
      lock_error.email = "Email não é válido";
    }
    if (password.length === 0) {
      lock_error.password = "Preencha este campo";
    }
    if (lock_error.email !== "" || lock_error.password !== "") {
      return setErrors(lock_error);
    }

    await signIn(email, password);
  };

  return (
    <Layout>
      <div className="p-4">
        <h4 className="text-main">
          <strong>Acessar Conta</strong>
        </h4>
        <form onSubmit={handleSubmit}>
          <div>
            {msg !== "" && <Alert severity="error">{msg}</Alert>}
            <div className="row mt-3">
              <div className="col-md-12">
                <TextField
                  label="E-mail"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{ animationDelay: "300ms" }}
                  error={errors.email !== ""}
                  helperText={errors.email}
                  inputProps={{
                    autocomplete: "off",
                  }}
                  size="small"
                  variant="outlined"
                  required
                />
                <TextField
                  label="Senha"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  style={{ animationDelay: "300ms" }}
                  error={errors.email !== ""}
                  helperText={errors.email}
                  inputProps={{
                    autocomplete: "off",
                  }}
                  size="small"
                  variant="outlined"
                  className="mt-2"
                  required
                />

                <div className="mt-4 d-flex align-items-center flex-wrap">
                  <button
                    type="submit"
                    className="bg-main btn btn-primary pl-4 pr-4 mr-2"
                  >
                    {loading ? (
                      <CircularProgress size={14} style={{ color: "#fff" }} />
                    ) : (
                      "Entrar"
                    )}
                  </button>

                  <Link
                    to="/auth/forgot-password"
                    className="btn btn-outline-secondary"
                  >
                    Esqueceu sua Senha?
                  </Link>
                </div>
              </div>
            </div>
            <hr className="mt-5" />
            <p>
              Não tem conta?{" "}
              <Link to="/auth/home">
                <u>Clique aqui e cadastre-se!</u>
              </Link>
            </p>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default Login;

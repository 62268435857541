import React, { useState, useEffect } from "react";

import { toast } from "react-toastify";

import { LinearProgress } from "@material-ui/core";
import { GoFile } from "react-icons/go";
import { format, addMinutes } from "date-fns";
import { Container } from "./styles";

import { IRepository } from "./IRepository";
import { useAuth } from "~/hooks/useAuth";
import { api } from "~/hooks/api";

const RepositoriesReturn: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [repositories, setRepositories] = useState<IRepository[]>([]);

  const { signOut } = useAuth();

  async function loadRepositories(refresh?: boolean) {
    setLoading(true);
    try {
      const { data } = await api.get(
        `/assignor_cnab_returns`
      );
      setRepositories(data);
    } catch (err) {
      if ((err as any)?.response && (err as any)?.response.status === 401) {
        signOut();
        toast.error("Sua sessão expirou, entre novamente");
      } else if ((err as any)?.response && (err as any)?.response.status === 403) {
        toast.error("Você não está autor izado a acessar este recurso");
      } else if ((err as any)?.response && (err as any)?.response.status === 500) {
        toast.error(
          "Ocorreu um erro em nossos servidores, tente novamente mais tarde"
        );
      } else {
        toast.error("Ocorreu um erro, tente novamente mais tarde");
      }
    }
    setLoading(false);
  }
  useEffect(() => {
    loadRepositories();
  }, []); // eslint-disable-line

  return (
    <Container>
      <div id="billets_table_wrap">
        <div className="align-items-center d-flex justify-content-between">
          <div>
            <h4>Repositório de Arquivos de Retorno</h4>
          </div>
        </div>
        {loading && <LinearProgress />}
        <div className="content_component">
          {repositories.length > 0 &&
            repositories.map((repositoriy, index) => {
              return (
                <div key={String(index)}>
                  <a
                    href={repositoriy.path}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <GoFile />
                  </a>
                  <p>
                    Data do retorno
                    <span>
                      {format(
                        addMinutes(
                          new Date(repositoriy.created_at),
                          new Date(repositoriy.created_at).getTimezoneOffset()
                        ),
                        "dd/MM/yyyy HH:mm"
                      )}
                    </span>
                  </p>
                </div>
              );
            })}
        </div>
      </div>
    </Container>
  );
};

export default RepositoriesReturn;

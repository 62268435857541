import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  .float-icon {
    position: absolute;
    top: 5px;
    right: 0.5rem;
    background: #fff;
    &.sm {
      top: 1px;
    }
  }
`
export const Results = styled.div`
  position: absolute;
  background: #fff;
  border-radius: 4px;
  width: 100%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  button {
    padding: 0.75rem 1rem;
    color: #666;
    width: 100%;
    border-radius: 0px;
    font-size: 0.8rem;
    transition: 150ms background;
    &:hover {
      background: #eee;
    }
    text-align: left;
  }
  button + button {
    border-top: 1px solid #c9c9c9;
  }
  z-index: 100;
`

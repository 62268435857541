import styled from 'styled-components'

export const Container = styled.div`
  border-radius: 1rem;
  box-shadow: 0 0 5px rgba(0,0,0,.1);
  background: #fff;
  > h4 {
    font-weight: 700;
    padding-bottom: 15px;
    border-bottom: 1px solid rgb(247, 247, 247);
  }
  span.label_type {
    padding: 0.25rem 0.5rem;
    background: #444;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;
    color: #fff;
    font-weight: bold;
    font-size: 0.8rem;
  }
`

import styled from 'styled-components'

export const ModalDoc = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 0.75rem;
  width: 100%;
  div.document_item {
    display: flex;
    span.icon_status {
      svg {
        font-size: 2rem;
      }
      min-width: 70px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.25rem 0rem 0rem 0.25rem;
    }
    > div {
      width: 100%;
      position: relative;
      div.progress {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
      }
    }
    border-radius: 0.25rem;
    background: rgba(255, 255, 255, 0.7);
  }
`

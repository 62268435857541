import styled from 'styled-components'

export const Container = styled.div`
  padding: 2rem;
  border-radius: 1rem;
  background: #fff;
  box-shadow: 0 0 5px rgba(0,0,0,.1);
  > h4 {
    font-weight: 700;
    padding-bottom: 15px;
    border-bottom: 1px solid rgb(247, 247, 247);
  }
`

export const BlockDocuments = styled.div`
  display: flex;
  div.main_name {
    border: 2px solid #dee2e6;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem 0rem 0rem 1rem;
    text-align: center;
    min-width: 200px;
  }
  div.list_documents {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.75rem;
    width: 100%;
    div.document_item {
      display: flex;
      span.icon_status {
        svg {
          font-size: 2rem;
        }
        min-width: 70px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.25rem 0rem 0rem 0.25rem;
      }
      > div {
        width: 100%;
        position: relative;
        div.progress {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
        }
      }
      border-radius: 0.25rem;
      background: rgba(255, 255, 255, 0.7);
    }
  }
`

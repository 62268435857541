import { ResponsivePie } from "@nivo/pie";
import { format, differenceInDays } from "date-fns";
import React, { Fragment, useMemo, useState } from "react";
import { normalizeCurrency } from "../../../utils/normalize";

export const TitlesTable: React.FC<{ titles: any[] }> = ({ titles }) => {
  const [titleFilter, setTitleFilter] = useState<"all" | "open" | "expired">(
    "all"
  );

  const payers = useMemo(() => {
    const arr: number[] = [];
    titles.forEach((title) => {
      if (!arr.includes(title.payer_id)) {
        arr.push(title.payer_id);
      }
    });
    return arr;
  }, [titles]);

  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <strong className="d-block">Títulos em Aberto</strong>
        <button
          className={
            "btn btn-sm ml-2 " +
            (titleFilter === "all" ? "btn-primary" : "btn-outline-primary")
          }
          type="button"
          onClick={() => setTitleFilter("all")}
        >
          Todos
        </button>
        <button
          className={
            "btn btn-sm ml-2 " +
            (titleFilter === "open" ? "btn-primary" : "btn-outline-primary")
          }
          type="button"
          onClick={() => setTitleFilter("open")}
        >
          A vencer
        </button>
        <button
          className={
            "btn btn-sm ml-2 " +
            (titleFilter === "expired" ? "btn-primary" : "btn-outline-primary")
          }
          type="button"
          onClick={() => setTitleFilter("expired")}
        >
          Vencidos
        </button>
      </div>
      <div style={{ height: "35vh", overflow: "auto" }}>
        <table className="table table-sm table-striped table-hover mb-0 w-100 table-fixed-top">
          <thead>
            <tr>
              <th className="small">Operação</th>
              <th className="small">Documento</th>
              <th className="small white-space-no-wrap text-right">
                Valor Original
              </th>
              <th className="small white-space-no-wrap text-right">
                Valor em Aberto
              </th>
              <th className="small text-right">Vencimento</th>
            </tr>
          </thead>
          <tbody>
            {payers.map((payerId) => {
              const payerTitles = titles
                .filter((title) => title.payer_id === payerId)
                .filter((title) => {
                  if (titleFilter === "all") {
                    return true;
                  } else if (
                    titleFilter === "open" &&
                    differenceInDays(
                      new Date(String(title.business_date)),
                      new Date()
                    ) >= 0
                  ) {
                    return true;
                  } else if (
                    titleFilter === "expired" &&
                    differenceInDays(
                      new Date(String(title.business_date)),
                      new Date()
                    ) < 0
                  ) {
                    return true;
                  }
                  return false;
                });

              if (payerTitles.length === 0) {
                return <></>;
              }

              return (
                <Fragment key={payerId}>
                  <tr>
                    <td colSpan={5} className="small">
                      <strong>{payerTitles[0]?.payer?.name}</strong>
                    </td>
                  </tr>
                  {payerTitles.map((title, index) => (
                    <tr key={title.id}>
                      <td
                        className="small"
                        style={{
                          borderBottom:
                            index === payerTitles.length - 1
                              ? "2px solid #000"
                              : "",
                        }}
                      >
                        {title.operation.code}
                      </td>
                      <td
                        className="small"
                        style={{
                          borderBottom:
                            index === payerTitles.length - 1
                              ? "2px solid #000"
                              : "",
                        }}
                      >
                        {title.document_number}
                      </td>
                      <td
                        className="small text-right"
                        style={{
                          borderBottom:
                            index === payerTitles.length - 1
                              ? "2px solid #000"
                              : "",
                        }}
                      >
                        R$ {normalizeCurrency(title.value || "000")}
                      </td>
                      <td
                        className="small text-right"
                        style={{
                          borderBottom:
                            index === payerTitles.length - 1
                              ? "2px solid #000"
                              : "",
                        }}
                      >
                        R$ {normalizeCurrency(title.open_value || "000")}
                      </td>
                      <td
                        className="text-right small"
                        style={{
                          borderBottom:
                            index === payerTitles.length - 1
                              ? "2px solid #000"
                              : "",
                        }}
                      >
                        {title.date
                          ? format(new Date(title.date), "dd/MM/yyyy")
                          : ""}
                      </td>
                    </tr>
                  ))}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export const TitlesSharp: React.FC<{ titles: any[] }> = ({ titles }) => {
  return (
    <ResponsivePie
      data={[
        {
          id: "A vencer",
          label: "A vencer",
          value: titles.filter(
            (title) =>
              differenceInDays(
                new Date(String(title.business_date)),
                new Date()
              ) >= 0
          ).length,
        },
        {
          id: "Vencidos",
          label: "Vencidos",
          value: titles.filter(
            (title) =>
              differenceInDays(
                new Date(String(title.business_date)),
                new Date()
              ) < 0
          ).length,
        },
      ]}
      margin={{ top: 40, right: 0, bottom: 40, left: 0 }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      colors={["#ecb50e", "#dc3545"]}
      borderWidth={0}
      radialLabelsSkipAngle={10}
      radialLabelsTextXOffset={0}
      radialLabelsTextColor="#333333"
      radialLabelsLinkOffset={0}
      radialLabelsLinkDiagonalLength={0}
      radialLabelsLinkHorizontalLength={24}
      radialLabelsLinkStrokeWidth={1}
      slicesLabelsSkipAngle={10}
      slicesLabelsTextColor="#fff"
      animate
      motionStiffness={90}
      motionDamping={15}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
    />
  );
};

import React from "react";

import { format } from "date-fns";

import { Container } from "./styles";

import {
  normalizeCurrency,
  normalizeDate,
  normalizeNumber,
  parseToDate,
} from "../utils/normalize";

import { api } from "~/hooks/api";
import { TableComponent } from "../components/TableComponent";
import { useAuth } from "~/hooks/useAuth";

const Sendings: React.FC = () => {
  const { currentAssignor } = useAuth();

  return (
    <Container>
      <TableComponent
        title="Remessas"
        dataReciever={async (params) => {
          const { data } = await api.get(`/operations-in-processing`, {
            params: {
              ...params,
              start_date: params.start_date
                ? parseToDate(params.start_date)
                : undefined,
              end_date: params.end_date
                ? parseToDate(params.end_date)
                : undefined,
              start_value: params.start_value
                ? normalizeNumber(params.start_value)
                : undefined,
              end_value: params.end_value
                ? normalizeNumber(params.end_value)
                : undefined,
            },
          });
          return data;
        }}
        traitResponse={(data) => {
          return data.map((d) => ({
            ...d,
            date: format(new Date(d.date), "dd/MM/yyyy"),
            titlesQtd: d.titles_qtd,
            grossValue: normalizeCurrency(d.gross_value || "000", true),
            netValue: normalizeCurrency(d.net_value || "000", true),
            statusId: d?.status?.name,
          }));
        }}
        dataType={[]}
        filters={[
          {
            name: "code",
            title: "Operação",
            normalize: normalizeNumber,
            col: 2,
          },
          {
            name: "start_date",
            title: "Data (de)",
            normalize: normalizeDate,
            col: 2,
          },
          {
            name: "end_date",
            title: "Data (até)",
            normalize: normalizeDate,
            col: 2,
          },
          {
            name: "start_value",
            title: "Valor (de)",
            normalize: normalizeCurrency,
            col: 2,
          },
          {
            name: "end_value",
            title: "Valor (até)",
            normalize: normalizeCurrency,
            col: 2,
          },
        ]}
        labels={[
          {
            name: "code",
            title: "Operação",
          },
          {
            name: "date",
            title: "Data",
          },
          {
            name: "titlesQtd",
            title: "Qtd de Títulos",
          },
          {
            name: "grossValue",
            title: "Vlr. Total",
          },
        ]}
        addItem={false}
        aditionalActions={(item) => (
          <a
            href={`${process.env.REACT_APP_API}/pre_printed_tickets/${
              currentAssignor.token
            }?titleTypeId=1&ids=${item.allTitles
              .map((title: any) => title.duplicate_id)
              .join(",")}`}
            target="_blank"
            className="btn btn-primary btn-sm"
          >
            Baixar boletos
          </a>
        )}
      />
    </Container>
  );
};

export default Sendings;

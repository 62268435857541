import React, { useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { api } from '~/hooks/api';
import { normalizeBrl, normalizeNumber } from '../../utils/normalize';

// import { Container } from './styles';

interface IProps {
  value: number
  originalValue: number
  id: number
  onHide: () => void
}

export const ModalDiscount: React.FC<IProps> = ({ id, onHide, value, originalValue }) => {
  const [digitedValue, setDigitedValue] = useState(value)
  const [loading, setLoading] = useState(false)

  async function handleSave() {
    if(originalValue < digitedValue) {
      toast.error('Valor digitado maior que valor original')
      return;
    }
    setLoading(true)
    try {
      await api.put(`/titles/${id}/discount`, {
        discount_value: digitedValue
      })
      toast.success('Valor de desconto atualizado')
      onHide()
    }catch(err) {
      //
    }
    setLoading(false)
  }

  if(loading) {
    return (
      <Modal show size="sm" centered>
        <Modal.Body>
          <Spinner animation="border" size="sm" />
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <Modal show onHide={() => onHide()} size="sm" centered>
      <Modal.Header closeButton>
        Editar Desconto
      </Modal.Header>
      <Modal.Body>
        <Form.Control value={normalizeBrl(digitedValue)} onChange={e => {
          setDigitedValue(Number(normalizeNumber(e.target.value)))
        }}/>
        <div className="d-flex justify-content-center mt-3">
          <Button type="button" onClick={handleSave}>
            Salvar
          </Button>
          <Button type="button" onClick={() => onHide()} variant="outline-secondary" className="ml-2">
            Cancelar
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
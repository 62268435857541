import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Home from './Home';
import Login from './Login';
import ForgotPassword from './ForgotPassword';
import Recovery from './Recovery';
import ActiveAcc from './ActiveAcc';
import Confirm from './Confirm';
import ActivePassword from './ActivePassword';

export default function AuthPage() {
  return (
    <Switch>
      <Route path="/auth/home" component={Home} />
      <Route path="/auth/confirm" component={Confirm} />
      <Route path="/auth/login" component={Login} />
      <Route path="/auth/forgot-password" component={ForgotPassword} />
      <Route path="/auth/recovery/:token" component={Recovery} />
      <Route path="/auth/active-password/:token" component={ActivePassword} />
      <Route path="/auth/active/:token" component={ActiveAcc} />
      <Redirect to="/auth/login" />
    </Switch>
  );
}

import styled from 'styled-components';

export const Container = styled.div`
  padding: 30px;
  background: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  @media screen and (max-width: 780px) {
    & {
      padding: 20px;
    }
  }
  h3 {
    font-weight: 700;
    padding-bottom: 15px;
    border-bottom: 1px solid #eee;
  }
  div.col-md-4 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-right: 1px solid #eee;
    padding: 15px;
    > div {
      width: 100px;
      height: 100px;
      background: #3757a1;
      color: #f7f7f7;
      font-size: 50px;
    }
    h6 {
      margin-top: 15px;
    }
    span {
      color: #3757a1;
      font-weight: 700;
      font-size: 12px;
    }
  }
  div.col-md-8 {
    padding: 25px;
    * {
      font-size: 12px;
    }
    h5 {
      font-size: 16px !important;
    }
    button {
      margin: 15px 0px 30px 0px;
    }
  }
  div#users_company {
    padding: 15px;
    h5 {
      font-size: 16px;
    }
    div#grid_users {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
      padding: 20px 0px;
      div.grid-card {
        height: 100%;
        padding: 15px;
        border: 1px solid #eee;
        display: flex;
        justify-content: space-between;
        align-items: center;
        div.MuiAvatar-root {
          background: #3757a1;
          color: #f7f7f7;
          margin-right: 20px;
          &.admin {
            background: #6f6755;
          }
        }
        div {
          h5 {
            font-size: 16px;
            margin: 0px;
            padding: 0px;
          }
          span {
            font-size: 14px;
            margin: 0px;
            padding: 0px;
            display: block;
          }
          strong {
            display: block;
            color: #3757a1;
            text-transform: uppercase;
            font-size: 12px;
          }
        }
      }
      div.new-user {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border: 1px dashed #3757a1;
        button {
          flex: 1;
        }
        &.new-user-active {
          * {
            font-size: 12px;
          }
          .MuiFormControl-root {
            width: 100%;
            margin: 0px;
            margin-top: 10px;
          }
          .MuiSvgIcon-root {
            font-size: 20px;
            margin-right: 6px;
          }
        }
      }
    }
    @media screen and (max-width: 780px) {
      & {
        padding: 10px;
      }
      div#grid_users {
        grid-template-columns: 1fr;
      }
    }
  }
`;

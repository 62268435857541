import React, { useState } from "react";

import { Avatar, Button, TextField } from "@material-ui/core";

import { toast } from "react-toastify";

import { api } from "~/hooks/api";
import { useAuth } from "~/hooks/useAuth";
import { Container } from "./styles";

const Profile: React.FC = () => {
  const {
    user: { name: fullname, email },
    signOut,
    updateName,
  } = useAuth();
  const [name, setName] = useState(fullname);
  const [password, setPassword] = useState({
    old: "",
    newPassword: "",
    newPasswordConfirmation: "",
  });
  const [passwordError, setPasswordError] = useState({
    old: "",
    newPassword: "",
    newPasswordConfirmation: "",
  });
  const handleUpdateName = async () => {
    try {
      await api.put(`me/profile`, {
        name,
      });
      toast.success("Nome atualizado");
      updateName(name);
    } catch (err) {
      if ((err as any)?.response && (err as any)?.response.status === 401) {
        signOut();
        toast.error("Sua sessão expirou, entre novamente");
      } else if (
        (err as any)?.response &&
        (err as any)?.response.status === 403
      ) {
        toast.error("Você não está autorizado a acessar este recurso");
      } else if (
        (err as any)?.response &&
        (err as any)?.response.status === 500
      ) {
        toast.error(
          "Ocorreu um erro em nossos servidores, tente novamente mais tarde"
        );
      } else {
        toast.error("Ocorreu um erro, tente novamente mais tarde");
      }
    }
  };
  const handleUpdatePassword = async () => {
    setPasswordError({
      old: "",
      newPassword: "",
      newPasswordConfirmation: "",
    });
    function passwordIsValid(senha: string) {
      const regex = /^(?=(?:.*?[0-9]){1})(?=(?:.*?[a-zA-Z]){1})(?!.*\s)[0-9a-zA-Z!@#$%;*(){}_+^&]*$/;
      if (senha.length < 8) {
        return false;
      }
      if (!regex.exec(senha)) {
        return false;
      }
      return true;
    }
    const lock_error = {
      old: "",
      newPassword: "",
      newPasswordConfirmation: "",
    };
    if (password.old.length === 0) {
      lock_error.old = "Preencha a senha atual";
    }
    if (password.newPassword.length === 0) {
      lock_error.newPassword = "Preencha uma nova senha";
    } else if (password.newPassword.length < 8) {
      lock_error.newPassword = "A senha deve conter no minímo 8 digitos!";
    } else if (!passwordIsValid(password.newPassword)) {
      lock_error.newPassword =
        "A senha deve possuir no mínimo um caracter e um número.";
    }
    if (password.newPasswordConfirmation.length === 0) {
      lock_error.newPasswordConfirmation = "Repita a nova senha";
    } else if (password.newPassword !== password.newPasswordConfirmation) {
      lock_error.newPassword = "Senhas não coincidem";
      lock_error.newPasswordConfirmation = "Senhas não coincidem";
    }
    if (
      lock_error.old !== "" ||
      lock_error.newPassword !== "" ||
      lock_error.newPasswordConfirmation !== ""
    ) {
      return setPasswordError(lock_error);
    }
    try {
      await api.put(`me/password`, {
        old_password: password.old,
        new_password: password.newPassword,
      });
      setPassword({
        newPassword: "",
        newPasswordConfirmation: "",
        old: "",
      });
      toast.success("Senha atualizada");
    } catch (err) {
      if ((err as any)?.response && (err as any)?.response.status === 401) {
        signOut();
        toast.error("Sua sessão expirou, entre novamente");
      } else if (
        (err as any)?.response &&
        (err as any)?.response.status === 400
      ) {
        toast.error("Senha antiga incorreta");
      } else if (
        (err as any)?.response &&
        (err as any)?.response.status === 403
      ) {
        toast.error("Você não está autorizado a acessar este recurso");
      } else if (
        (err as any)?.response &&
        (err as any)?.response.status === 500
      ) {
        toast.error(
          "Ocorreu um erro em nossos servidores, tente novamente mais tarde"
        );
      } else {
        toast.error("Ocorreu um erro, tente novamente mais tarde");
      }
    }
  };

  const [firstName, lastName] = fullname?.split(" ");
  const inicials =
    firstName && lastName
      ? firstName.slice(0, 1) + lastName.slice(0, 1)
      : firstName.slice(0, 2);
  return (
    <Container>
      <div className="row">
        <div className="col-md-4">
          <Avatar>{inicials}</Avatar>
          <h6>{fullname}</h6>
          <span>{email}</span>
        </div>
        <div className="col-md-8">
          <h5 className="mb-3">Informação Básica</h5>
          <TextField
            label="Nome Completo"
            style={{ width: "100%" }}
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={name.length === 0}
          />
          <Button
            variant="contained"
            color="primary"
            disabled={name.length === 0}
            onClick={() => handleUpdateName()}
          >
            Salvar
          </Button>
          <h5 className="mt-3 mb-3">Senha</h5>
          <p>
            Escolha uma nova senha com no mínimo oito caracteres, sendo pelo
            menos um número.
          </p>
          <div className="row">
            <div className="col-md-6">
              <TextField
                className="mt-2"
                label="Senha Antiga"
                type="password"
                style={{ width: "100%" }}
                value={password.old}
                onChange={(e) => {
                  setPassword({
                    ...password,
                    old: e.target.value,
                  });
                  if (passwordError.old !== "") {
                    setPasswordError({
                      ...passwordError,
                      old: "",
                    });
                  }
                }}
                error={passwordError.old !== ""}
                helperText={passwordError.old}
              />
            </div>
            <div className="col-md-6">
              <TextField
                className="mt-2"
                label="Senha Nova"
                type="password"
                style={{ width: "100%" }}
                value={password.newPassword}
                onChange={(e) => {
                  setPassword({
                    ...password,
                    newPassword: e.target.value,
                  });
                  if (passwordError.newPassword !== "") {
                    setPasswordError({
                      ...passwordError,
                      newPassword: "",
                    });
                  }
                }}
                error={passwordError.newPassword !== ""}
                helperText={passwordError.newPassword}
              />
            </div>
            <div className="col-md-6">
              <TextField
                className="mt-2"
                label="Confirmar Senha Nova"
                type="password"
                style={{ width: "100%" }}
                value={password.newPasswordConfirmation}
                onChange={(e) => {
                  setPassword({
                    ...password,
                    newPasswordConfirmation: e.target.value,
                  });
                  if (passwordError.newPasswordConfirmation !== "") {
                    setPasswordError({
                      ...passwordError,
                      newPasswordConfirmation: "",
                    });
                  }
                }}
                error={passwordError.newPasswordConfirmation !== ""}
                helperText={passwordError.newPasswordConfirmation}
              />
            </div>
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleUpdatePassword()}
          >
            Salvar
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default Profile;

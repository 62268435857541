import React from 'react';

interface Props {
  open: boolean;
}

const Dropdown: React.FC<Props> = ({ open, children }) => {
  return (
    <ul className={open ? 'dropdown-open_menu' : 'dropdown-close_menu'}>
      {children}
    </ul>
  );
};

export default Dropdown;

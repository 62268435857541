import React from 'react';

import { Container } from './styles';
import ConsultProtest from './components/ConsultProtest';
import ConsultCompany from './components/ConsultCompany';

const Service: React.FC = ({ match }: any) => {
  const current = String(match.params.current);

  return (
    <Container>
      <div className="row">
        <div className="col-md-12">
          {current === 'empresa' && <ConsultCompany />}
          {current === 'protesto' && <ConsultProtest />}
        </div>
      </div>
    </Container>
  );
};

export default Service;

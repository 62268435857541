import React, { useState, useEffect } from "react";

import { Form, Row, Col } from "react-bootstrap";

import { Alert } from "@material-ui/lab";
import { Button, CircularProgress } from "@material-ui/core";
import { api } from "~/hooks/api";

import {
  normalizeCpfCnpj,
  normalizeNumber,
  normalizeCep,
  normalizeTelephone,
  cnpjIsValid,
  cpfIsValid,
} from "../../utils/normalize";
import consultCep from "~/services/consultCep";

import { Container } from "./styles";
import { states } from "~/pages/main/utils/states";

interface IProps {
  open: boolean;
  setOpen: () => void;
  onAdd: () => void;
  note?: Title;
}

const ModalEditPayer: React.FC<IProps> = ({ open, setOpen, onAdd, note }) => {
  const [values, setValues] = useState<any>({});
  const [payerExists, setPayerExists] = useState(false);
  const [step, setStep] = useState(0);
  const [refreshing, setRefreshing] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const [loading, setLoading] = useState(false);

  function resetForm() {
    setValues({});
    setPayerExists(true);
    setOpen();
    setError("");
    setSuccess(false);
  }

  function handleNormalize(name: string, value: string) {
    switch (name) {
      case "payerDocument":
        return normalizeCpfCnpj(value);
      case "payerZipcode":
        return normalizeCep(value);
      case "payerPhone1":
        return normalizeTelephone(value);
      case "payerPhone2":
        return normalizeTelephone(value);
      default:
        return value;
    }
  }
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setError("");
    setSuccess(false);
    const { name, value } = e.target;
    setValues({ ...values, [name]: handleNormalize(name, value) });
  }

  useEffect(() => {
    if (open && note) {
      setValues({
        ...values,
        payerRg: note.payer.ie_rg,
        payerDocument: normalizeCpfCnpj(note.payer.document_number || ""),
        payerName: note.payer.name,
        payerAddress: note.payer.street,
        payerZipcode: note.payer.zip_code,
        payerNumber: note.payer.number,
        payerComplement: note.payer.complement,
        payerDistrict: note.payer.district,
        payerCity: note.payer.city,
        payerUf: note.payer.state,
        payerPhone1: normalizeTelephone(note.payer.phone || ""),
        payerEmail: note.payer.email,
      });
    }
  }, [note, open]); // eslint-disable-line

  async function handleCepAddress() {
    const consult = await consultCep(
      values.payerZipcode?.replace("-", "") || ""
    );
    if (consult) {
      setValues({
        ...values,
        payerAddress: consult.logradouro,
        payerDistrict: consult.bairro,
        payerCity: consult.cidade,
        payerUf: consult.estado,
        payerNumber: '',
        payerComplement: '',
      });
      document.getElementsByName('payerNumber')[0].focus();
    }else {
      setValues({
        ...values,
        payerAddress: '',
        payerDistrict: '',
        payerCity: '',
        payerUf: '',
        payerNumber: '',
        payerComplement: '',
      });
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    if (normalizeNumber(values.payerDocument).length === 11) {
      if (!cpfIsValid(normalizeNumber(values.payerDocument))) {
        return setError("CPF Inválido");
      }
    } else if (normalizeNumber(values.payerDocument).length === 14) {
      if (!cnpjIsValid(normalizeNumber(values.payerDocument))) {
        return setError("CNPJ Inválido");
      }
    }
    try {
      let { payerUf } = values;
      if (values.payerUf.length > 2) {
        payerUf =
          states.find((i) => i.desc === values.payerUf)?.uf || values.payerUf;
      }
      const body = {
        name: values.payerName,
        ie_rg: values.payerRg,
        street: values.payerAddress,
        zip_code: values.payerZipcode?.replace(/\D/g, ""),
        number: values.payerNumber,
        complement: values.payerComplement,
        district: values.payerDistrict,
        city: values.payerCity,
        state: payerUf,
        phone: `${values.payerPhone1?.replace(/\D/g, "") || ""}`,
        email: values.payerEmail,
      };

      await api.put(`assignor/payer/${note?.payer.id}`, body);
      const emptyValues: any = {};
      Object.keys(values).forEach((i) => {
        emptyValues[i] = "";
      });
      setValues(emptyValues);
      setPayerExists(true);
      setSuccess(true);
      onAdd();
      setRefreshing(true);
    } catch (err) {
      //
    }
    return setLoading(false);
  }

  useEffect(() => {
    if (!open) {
      setStep(0);
    }
  }, [open]);

  useEffect(() => {
    if (refreshing) {
      setTimeout(() => {
        setRefreshing(false);
      }, 500);
    }
  }, [refreshing]);

  return (
    <Container size="lg" show={open} onHide={resetForm} centered>
      {!refreshing && (
        <>
          {step === 0 && (
            <Container.Body>
              <div className="text-center">
                <h4>Cheque não hábil para antecipação</h4>
                <p className="mb-0">
                  Para antecipar este cheque deve ser informado dados de contato
                  do sacado.<br />
                  É obrigatório o preenchimento do endereço completo, telefone e e-mail do sacado.
                </p>
                <div className="d-flex justify-content-center mt-3">
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    onClick={() => setStep(1)}
                  >
                    Informar Dados
                  </Button>
                  <Button
                    type="button"
                    color="primary"
                    className="ml-2"
                    variant="outlined"
                    onClick={() => setOpen()}
                  >
                    Cancelar
                  </Button>
                </div>
              </div>
            </Container.Body>
          )}
          {step === 1 && note && (
            <Form onSubmit={handleSubmit}>
              <Container.Body>
                <div className="header">
                  <img src="/logo192.png" alt="BFC Digital" />
                  <h4>Dados do Sacado</h4>
                </div>
                <hr />
                {error !== "" && <Alert severity="error">{error}</Alert>}
                {/* {success && <Alert severity="success">Sacado atualizado</Alert>} */}
                {!success && error === "" && payerExists && (
                  <Alert severity="success" className="mb-2">
                    Dados do Sacado já encontrado em nossa base de dados
                  </Alert>
                )}
                <Row>
                  <Col lg={4}>
                    <Form.Group>
                      <Form.Label>CNPJ/CPF</Form.Label>
                      <Form.Control
                        name="payerDocument"
                        value={values.payerDocument}
                        onChange={handleChange}
                        disabled
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={8}>
                    <Form.Group>
                      <Form.Label>Nome</Form.Label>
                      <Form.Control
                        name="payerName"
                        value={values.payerName}
                        onChange={handleChange}
                        disabled={payerExists || loading}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={3}>
                    <Form.Group>
                      <Form.Label>RG/CGF</Form.Label>
                      <Form.Control
                        name="payerRg"
                        value={values.payerRg}
                        onChange={handleChange}
                        disabled={loading}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={3}>
                    <Form.Group>
                      <Form.Label>CEP</Form.Label>
                      <Form.Control
                        name="payerZipcode"
                        value={values.payerZipcode}
                        onChange={handleChange}
                        disabled={loading}
                        onBlur={handleCepAddress}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group>
                      <Form.Label>Endereço</Form.Label>
                      <Form.Control
                        name="payerAddress"
                        value={values.payerAddress}
                        onChange={handleChange}
                        disabled={loading}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={2}>
                    <Form.Group>
                      <Form.Label>Número</Form.Label>
                      <Form.Control
                        name="payerNumber"
                        value={values.payerNumber}
                        onChange={handleChange}
                        disabled={loading}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={2}>
                    <Form.Group>
                      <Form.Label>Complemento</Form.Label>
                      <Form.Control
                        name="payerComplement"
                        value={values.payerComplement}
                        onChange={handleChange}
                        disabled={loading}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={3}>
                    <Form.Group>
                      <Form.Label>Bairro</Form.Label>
                      <Form.Control
                        name="payerDistrict"
                        value={values.payerDistrict}
                        onChange={handleChange}
                        disabled={loading}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={3}>
                    <Form.Group>
                      <Form.Label>Cidade</Form.Label>
                      <Form.Control
                        name="payerCity"
                        value={values.payerCity}
                        onChange={handleChange}
                        disabled={loading}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={2}>
                    <Form.Group>
                      <Form.Label>UF</Form.Label>
                      <Form.Control
                        name="payerUf"
                        as="select"
                        value={values.payerUf}
                        onChange={handleChange}
                        disabled={loading}
                        required
                      >
                        <option />
                        {states.map((s) => (
                          <option key={s.uf} value={s.uf}>
                            {s.desc}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group>
                      <Form.Label>Telefone</Form.Label>
                      <Form.Control
                        name="payerPhone1"
                        value={values.payerPhone1}
                        onChange={handleChange}
                        disabled={loading}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={8}>
                    <Form.Group>
                      <Form.Label>E-mail</Form.Label>
                      <Form.Control
                        type="email"
                        name="payerEmail"
                        value={values.payerEmail}
                        onChange={handleChange}
                        disabled={loading}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Container.Body>
              <Container.Footer>
                <div className="d-flex justify-content-between">
                  {loading ? <CircularProgress /> : <span />}
                  <div>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={loading}
                    >
                      Salvar
                    </Button>
                    <Button
                      type="button"
                      color="primary"
                      variant="outlined"
                      disabled={loading}
                      onClick={resetForm}
                      className="ml-2"
                    >
                      Cancelar
                    </Button>
                  </div>
                </div>
              </Container.Footer>
            </Form>
          )}
        </>
      )}
    </Container>
  );
};

export default ModalEditPayer;

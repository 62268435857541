import { Alert } from "@material-ui/lab";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Modal, Row, Spinner } from "react-bootstrap";
import { FaKey, FaTimes } from "react-icons/fa";
import { api } from "~/hooks/api";
import { normalizeCnpj } from "~/pages/main/utils/normalize";

import { AddEcnpj } from "./AddEcnpj";
import { Container } from "./styles";

const AssignorECnpjs: React.FC = () => {
  const [ecnpjs, setEcnpjs] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [eCnpj, setECnpj] = useState<any>(null);

  async function loadData() {
    setLoading(true);
    try {
      const { data } = await api.get(`/register/ecnpjs?all=1`);
      setEcnpjs(data);
    } catch (err) {
      //
    }
    setLoading(false);
  }

  useEffect(() => {
    loadData();
  }, []);

  async function handleDelete(eCnpj: number) {
    try {
      await api.delete(`/register/ecnpjs/${eCnpj}`);
      setECnpj(null);
      setOpen(false);
      loadData();
    } catch (err) {
      //
    }
  }

  return (
    <Container>
      <h4 className="m-0">
        {loading && <Spinner animation="border" size="sm" />} e-CNPJ Instalados
      </h4>
      <Alert severity="info" className="mt-3">
        <p className="mb-0">
          Ter e-CNPJ instalado em nossa plataforma agiliza a{" "}
          <strong>análise</strong> de seus recebíveis. Diminuindo as taxas e
          tarifas que incidem em suas operações.
        </p>
      </Alert>
      <Modal show={open} onHide={setOpen} centered>
        <Modal.Body>
          <div className="d-flex align-items-center justify-content-center">
            <div>
              <h6 className="mb-3 mt-2 text-center font-weight-bolder">
                Você tem certeza que deseja excluir ?
              </h6>
              <p className="alert alert-warning text-center">
                Ao remover o e-CNPJ, existe a possibilidade de uma diminuição no
                seu limite de crédito, o que por sua vez irá restringir o acesso
                ao serviços de consultas.
              </p>
              <div className="d-flex justify-content-center">
                <Button
                  color="primary"
                  className="btn-sm"
                  onClick={() => handleDelete(eCnpj)}
                >
                  SIM, EXCLUIR
                </Button>
                <Button
                  color="primary"
                  className="ml-2 btn-sm"
                  onClick={() => setOpen(false)}
                >
                  CANCELAR
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Row className="mt-3">
        {ecnpjs.length > 0 &&
          ecnpjs.map((ecnpj) => (
            <Col lg={6} className="mb-3" key={ecnpj.id}>
              <Card>
                <Card.Body>
                  <Card.Text>
                    <FaKey /> {ecnpj.name}
                  </Card.Text>
                  <Card.Text>
                    CNPJ: {normalizeCnpj(ecnpj.cnpj)}
                    <br />
                    Expira em{" "}
                    {ecnpj.expires_in
                      ? format(new Date(ecnpj.expires_in), "dd/MM/yyyy")
                      : ""}
                  </Card.Text>
                  <div className="d-flex justify-content-between">
                    <Button
                      type="button"
                      variant="danger"
                      onClick={() => {
                        setOpen(true);
                        setECnpj(ecnpj.id);
                      }}
                    >
                      <FaTimes className="m-0" />
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        {!loading && (
          <Col lg={6} className="mb-3" style={{ minHeight: "100%" }}>
            <Card style={{ height: "100%" }}>
              <Card.Body className="d-flex justify-content-center align-items-centers">
                <AddEcnpj
                  onRefresh={() => {
                    loadData();
                  }}
                />
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default AssignorECnpjs;

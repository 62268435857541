import React, { useState, useEffect } from "react";

import { toast } from "react-toastify";

import { LinearProgress, Button } from "@material-ui/core";
import { FaFileUpload } from "react-icons/fa";
import { GoFile } from "react-icons/go";
import { AiOutlineDelete } from "react-icons/ai";
import { format, addMinutes } from "date-fns";
import { Container } from "./styles";

import { IRepository } from "./IRepository";
import { useAuth } from "~/hooks/useAuth";
import ModalFile from "./ModalFile";
import ModalDelete from "./ModalDelete";
import { api } from "~/hooks/api";

const Repositories: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [repositories, setRepositories] = useState<IRepository[]>([]);
  const [fileOpen, setFileOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState({
    open: false,
    id: 0,
  });

  const { signOut } = useAuth();

  async function loadRepositories(refresh?: boolean) {
    setLoading(true);
    try {
      const { data } = await api.get(
        `/assignor/cnab`
      );
      setRepositories(data);
    } catch (err) {
      if ((err as any)?.response && (err as any)?.response.status === 401) {
        signOut();
        toast.error("Sua sessão expirou, entre novamente");
      } else if ((err as any)?.response && (err as any)?.response.status === 403) {
        toast.error("Você não está autor izado a acessar este recurso");
      } else if ((err as any)?.response && (err as any)?.response.status === 500) {
        toast.error(
          "Ocorreu um erro em nossos servidores, tente novamente mais tarde"
        );
      } else {
        toast.error("Ocorreu um erro, tente novamente mais tarde");
      }
    }
    setLoading(false);
  }

  async function handleDelete() {
    setLoading(true);
    try {
      await api.delete(
        `/assignor/cnab/${confirmDelete.id}`
      );
      setConfirmDelete({
        open: false,
        id: 0,
      });
      toast.info("Arquivo excluído");
      loadRepositories();
    } catch (err) {
      if ((err as any)?.response && (err as any)?.response.status === 401) {
        signOut();
        toast.error("Sua sessão expirou, entre novamente");
      } else if ((err as any)?.response && (err as any)?.response.status === 403) {
        toast.error("Você não está autor izado a acessar este recurso");
      } else if ((err as any)?.response && (err as any)?.response.status === 500) {
        toast.error(
          "Ocorreu um erro em nossos servidores, tente novamente mais tarde"
        );
      } else {
        toast.error("Ocorreu um erro, tente novamente mais tarde");
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    loadRepositories();
  }, []); // eslint-disable-line

  return (
    <Container>
      <div id="billets_table_wrap">
        <div className="align-items-center d-flex justify-content-between">
          <div>
            <h4>Repositório de Arquivos de Remessa</h4>
          </div>
          <div>
            <Button
              color="primary"
              variant="contained"
              className="mr-2"
              type="button"
              onClick={() => setFileOpen(true)}
            >
              <FaFileUpload className="mr-2" />
              Enviar arquivo
            </Button>
          </div>
        </div>
        {loading && <LinearProgress />}
        <div className="content_component">
          <ModalFile
            open={fileOpen}
            setOpen={() => {
              sessionStorage.setItem("file_uploaded", "[]");
              setFileOpen(false);
              loadRepositories();
            }}
          />
          <ModalDelete
            open={confirmDelete.open}
            setOpen={() =>
              setConfirmDelete({
                id: 0,
                open: false,
              })
            }
            onConfirm={() => handleDelete()}
          />
          {repositories.length > 0 &&
            repositories.map((repositoriy, index) => {
              return (
                <div key={String(index)}>
                  <a
                    href={repositoriy.path}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <GoFile />
                  </a>
                  <p>
                    Data de envio
                    <span>
                      {format(
                        addMinutes(
                          new Date(repositoriy.created_at),
                          new Date(repositoriy.created_at).getTimezoneOffset()
                        ),
                        "dd/MM/yyyy HH:mm"
                      )}
                    </span>
                  </p>
                  <button
                    type="button"
                    onClick={() => {
                      setConfirmDelete({
                        open: true,
                        id: repositoriy.id || 0,
                      });
                    }}
                  >
                    <AiOutlineDelete /> Excluir
                  </button>
                </div>
              );
            })}
        </div>
      </div>
    </Container>
  );
};

export default Repositories;

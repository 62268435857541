import { Alert } from "@material-ui/lab";
import { api } from "~/hooks/api";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import { FaCheck, FaEdit } from "react-icons/fa";
import { MdClose } from "react-icons/md";

import { ModalAdd } from "./components/ModalAdd";
import { Container } from "./styles";

const R: React.FC = () => {
  const [reports, setReports] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const [editReport, setEditReport] = useState({
    open: false,
    report: {} as any,
  });

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      try {
        const { data } = await api.get(`/register/reports`);
        setReports(data);
      } catch (err) {
        //
      }
      setLoading(false);
    }
    loadData();
  }, []);

  return (
    <Container>
      <h4 className="m-0">
        {loading && <Spinner animation="border" size="sm" />} Configuração de
        recebimento diário
      </h4>
      <Row className="mt-3">
        <Col lg={12} className="mb-3">
          <Alert severity="info">
            Configure o recebimento diário de relatórios e arquivos CNAB por
            email.
            <br />
            Se precisar de alteração no arquivo de retorno, entre em contato com
            nosso suporte.
          </Alert>
        </Col>
      </Row>
      {editReport.open && (
        <ModalAdd
          onClose={() =>
            setEditReport({
              open: false,
              report: {} as any,
            })
          }
          onAdd={(a) => setReports([...reports, a])}
          onUpdate={(a) =>
            setReports(
              reports.map((i) => {
                if (i.id === a.id) {
                  return a;
                }
                return i;
              })
            )
          }
          onDelete={(id) => setReports(reports.filter((i) => i.id !== id))}
          dataReport={editReport.report}
        />
      )}
      <Row className="mt-3">
        {reports.map((report) => {
          return (
            <Col lg={6} className="mb-3" key={report.id}>
              <Card>
                <Card.Header>
                  <Card.Text>{report.email}</Card.Text>
                </Card.Header>

                <Card.Body>
                  <p className="mb-1">
                    <strong>CNAB de retorno</strong>{" "}
                    {report.cnab ? (
                      <FaCheck className="text-success ml-1" />
                    ) : (
                      <MdClose className="text-danger ml-1" />
                    )}
                  </p>
                  <p className="mb-1">
                    <strong>Títulos Liquidados (CNAB)</strong>{" "}
                    {report.cnab_paid ? (
                      <FaCheck className="text-success ml-1" />
                    ) : (
                      <MdClose className="text-danger ml-1" />
                    )}
                  </p>
                  <p className="mb-1">
                    <strong>Títulos Registrados (CNAB)</strong>{" "}
                    {report.cnab_accepted ? (
                      <FaCheck className="text-success ml-1" />
                    ) : (
                      <MdClose className="text-danger ml-1" />
                    )}
                  </p>
                  <p className="mb-1">
                    <strong>Títulos Rejeitados (CNAB)</strong>{" "}
                    {report.cnab_rejected ? (
                      <FaCheck className="text-success ml-1" />
                    ) : (
                      <MdClose className="text-danger ml-1" />
                    )}
                  </p>
                  <p className="mb-1">
                    <strong>Títulos e tarifas liquidadas</strong>{" "}
                    {report.paid ? (
                      <FaCheck className="text-success ml-1" />
                    ) : (
                      <MdClose className="text-danger ml-1" />
                    )}
                  </p>
                  <p className="mb-1">
                    <strong>Títulos e tarifas em aberto</strong>{" "}
                    {report.open ? (
                      <FaCheck className="text-success ml-1" />
                    ) : (
                      <MdClose className="text-danger ml-1" />
                    )}
                  </p>
                  <p className="mb-1">
                    <strong>Títulos devolvidos</strong>{" "}
                    {report.returned ? (
                      <FaCheck className="text-success ml-1" />
                    ) : (
                      <MdClose className="text-danger ml-1" />
                    )}
                  </p>

                  <div className="d-flex justify-content-end">
                    <Button
                      type="button"
                      className="btn-sm"
                      variant={"primary"}
                      onClick={() =>
                        setEditReport({
                          open: true,
                          report: report,
                        })
                      }
                      disabled={loading}
                    >
                      <FaEdit className="m-0" /> Editar
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
        {!loading && (
          <Col lg={6} className="mb-3" style={{ minHeight: "100%" }}>
            <Card style={{ height: "100%" }}>
              <Card.Body className="d-flex justify-content-center align-items-center">
                <Button
                  type="button"
                  variant="outline-primary"
                  onClick={() =>
                    setEditReport({
                      open: true,
                      report: {} as any,
                    })
                  }
                  disabled={loading}
                >
                  Adicionar email
                </Button>
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default R;

import { addHours, format, isDate, isValid, parseISO } from "date-fns";

export function parseToDate(value: string) {
  if (!value) {
    return value;
  }

  const splitedDate = String(value).split("/");
  return addHours(
    parseISO(`${splitedDate[2]}-${splitedDate[1]}-${splitedDate[0]}`),
    3
  );
}

export const normalizeCurrency = (value: any, styled?: boolean): string => {
  if (!value) {
    return value;
  }

  const isNegative = value < 0;

  const parsedValue = isNegative
    ? (Number(String(value).replace(/\D/g, "")) / 100) * -1
    : Number(String(value).replace(/\D/g, "")) / 100;

  if (styled) {
    return parsedValue.toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
  }
  return parsedValue.toLocaleString("pt-br", {
    minimumFractionDigits: 2,
  });
};

export const normalizeBrl = (value?: number): string => {
  if (!value) {
    return "";
  }

  return new Intl.NumberFormat("pt-BR", {
    currency: "BRL",
    style: "currency",
  }).format(value / 100);
};

export const normalizeAccBank = (value: string): string => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/\D/g, "");

  if (onlyNums.length === 1) {
    return onlyNums;
  }

  return `${onlyNums.slice(0, onlyNums.length - 1)}-${onlyNums.slice(
    onlyNums.length - 1,
    onlyNums.length
  )}`;
};

export const normalizeNumber = (value: string): string => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/\D/g, "");

  return onlyNums;
};

export function cpfIsValid(cpf: any): boolean {
  let soma;
  let resto;
  soma = 0;
  const strCPF = cpf.replace(/\D/g, "");
  if (strCPF === "00000000000") return false;

  for (let i = 1; i <= 9; i += 1)
    soma += parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(strCPF.substring(9, 10))) return false;

  soma = 0;
  for (let i = 1; i <= 10; i += 1)
    soma += parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(strCPF.substring(10, 11))) return false;
  return true;
}

export const normalizeCpf = (value: string): string => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, "");
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}`;
  }
  if (onlyNums.length <= 9) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(
      6,
      9
    )}`;
  }
  return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(
    6,
    9
  )}-${onlyNums.slice(9, 11)}`;
};

export const normalizeCpfCnpj = (value: string): string => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, "");
  if (onlyNums.slice(0, 3) === "000" && cpfIsValid(onlyNums.slice(3))) {
    return normalizeCpf(onlyNums.slice(3));
  }
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}`;
  }
  if (onlyNums.length <= 9) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(
      6,
      9
    )}`;
  }
  if (onlyNums.length <= 11) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(
      6,
      9
    )}-${onlyNums.slice(9, 11)}`;
  }
  if (onlyNums.length <= 12) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
      5,
      8
    )}/${onlyNums.slice(8, 12)}`;
  }
  return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
    5,
    8
  )}/${onlyNums.slice(8, 12)}-${onlyNums.slice(12, 14)}`;
};

export const normalizeDate = (value: string): string => {
  if (!value) {
    return value;
  }

  try {
    if (
      String(value).indexOf("/") === -1 &&
      String(value).length > 3 &&
      parseISO(value)
    ) {
      return format(parseISO(value), "dd/MM/yyyy");
    }
  } catch (err) {
    //
  }

  const formated = String(value)
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{4})\d+?$/, "$1");

  return formated;
};

export const normalizeCnpj = (value: string) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, "");

  if (onlyNums.slice(0, 3) === "000" && cpfIsValid(onlyNums.slice(3))) {
    return normalizeCpf(onlyNums.slice(3));
  }

  if (onlyNums.length <= 2) {
    return onlyNums;
  }
  if (onlyNums.length <= 5) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}`;
  }
  if (onlyNums.length <= 8) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
      5,
      8
    )}`;
  }
  if (onlyNums.length <= 12) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
      5,
      8
    )}/${onlyNums.slice(8, 12)}`;
  }
  return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
    5,
    8
  )}/${onlyNums.slice(8, 12)}-${onlyNums.slice(12, 14)}`;
};

export const normalizeKey = (value: string): string => {
  if (!value) {
    return value;
  }

  return `${value.slice(0, 4)} ${value.slice(4, 8)} ${value.slice(
    8,
    12
  )} ${value.slice(12, 16)} ${value.slice(16, 20)} ${value.slice(
    20,
    24
  )} ${value.slice(24, 28)} ${value.slice(32, 36)} ${value.slice(
    36,
    40
  )} ${value.slice(40, 44)}`;
};

export const dateIsValid = (value: string | Date): boolean => {
  if (!value) {
    return false;
  }
  try {
    if (isDate(new Date(value))) {
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};

export const normalizePaymentType = (value: string): string => {
  switch (value) {
    case "1":
      return "1 - Pagamento em Dinheiro";
    case "01":
      return "1 - Pagamento em Dinheiro";
    case "2":
      return "2 - Cheque";
    case "02":
      return "2 - Cheque";
    case "3":
      return "3 - Cartão de Crédito";
    case "03":
      return "3 - Cartão de Crédito";
    case "4":
      return "4 - Débito";
    case "04":
      return "4 - Débito";
    case "5":
      return "5 - Crédito Loja";
    case "05":
      return "5 - Crédito Loja";
    case "10":
      return "10 - Vale Alimentação";
    case "11":
      return "11 - Vale Refeição";
    case "12":
      return "12 - Vale Presente";
    case "13":
      return "13 - Vale Combustível";
    case "15":
      return "15 - Boleto Bancário";
    case "90":
      return "90 - Sem Pagamento";
    case "99":
      return "99 - Outros";
    default:
      return "";
  }
};

export function payerIsComplete(
  require_payer_details: boolean,
  item: Title
): boolean {
  if (!require_payer_details) {
    return true;
  } else if (
    !item.payer.name ||
    item.payer.name === "" ||
    !item.payer.street ||
    item.payer.street === "" ||
    !item.payer.district ||
    item.payer.district === "" ||
    !item.payer.city ||
    item.payer.city === "" ||
    !item.payer.state ||
    item.payer.state === ""
  ) {
    return false;
  }
  return true;
}

export const stringDateToISOString = (value: string): string | boolean => {
  if (!value) {
    return false;
  }

  const date = parseISO(value);

  if (isValid(date)) {
    return addHours(date, 3).toISOString();
  }

  return false;
};

export const normalizeCep = (value: string): string => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, "");
  if (onlyNums.length <= 5) {
    return onlyNums;
  }
  return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5, 8)}`;
};

export const normalizePhone = (value: string): string => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, "");

  if (onlyNums.length === 0) {
    return "";
  }

  if (onlyNums.length <= 2) {
    return `(${onlyNums.slice(0, 2)}`;
  }

  if (onlyNums.length <= 6 && onlyNums.length > 2) {
    return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 6)}`;
  }

  if (onlyNums.length > 6 && onlyNums.length < 11) {
    return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 6)}-${onlyNums.slice(
      6,
      10
    )}`;
  }

  return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 7)}-${onlyNums.slice(
    7,
    11
  )}`;
};

export const subString = (value: string, max = 100): string => {
  if (String(value).length > max) {
    return `${String(value).slice(0, max - 3)}...`;
  }
  return value;
};

export const formatDate = (value: any, hours?: boolean): string => {
  try {
    const d = dateIsValid(value);
    if (d) {
      if (!hours) {
        const iso = new Date(value).toISOString();
        const [year, month, day] = iso.split("T")[0]?.split("-");
        return `${day}/${month}/${year}`;
      }
      const date = new Date(value).toLocaleString("pt-BR", {
        timeZone: "America/Recife",
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: hours ? "numeric" : undefined,
        minute: hours ? "numeric" : undefined,
      });

      return date;
    }
    return "";
  } catch (err) {
    return "";
  }
};

export const normalizeDateTime = (
  value: string,
  format?: "datetime" | "date"
): string => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/\D/g, "");

  if (format === "date") {
    return `${onlyNums.slice(6, 8)}/${onlyNums.slice(4, 6)}/${onlyNums.slice(
      0,
      4
    )}`;
  } else {
    return `${onlyNums.slice(6, 8)}/${onlyNums.slice(4, 6)}/${onlyNums.slice(
      0,
      4
    )} ${onlyNums.slice(8, 10)}:${onlyNums.slice(10, 12)}:${onlyNums.slice(
      12,
      14
    )}`;
  }
};

export const normalizeDateWithoutTime = (value: string): string => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/\D/g, "");

  return `${onlyNums.slice(6, 8)}/${onlyNums.slice(4, 6)}/${onlyNums.slice(
    0,
    4
  )}`;
};

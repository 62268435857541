import React, { useEffect, useState } from "react";

import Header from "../components/Header";
import { api } from "~/hooks/api";
import { Container } from "./styles";
import Footer from "../components/Footer";

import { Spinner } from "react-bootstrap";

export default function Danfe({ match }: any) {
  const token = String(match.params.token);
  const key = String(match.params.key);

  const [loading, setLoading] = useState(false)

  const [html, setHtml] = useState();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function loadData() {
      setLoading(true)
      try {
        const { data } = await api.get(`payer/danfe/${token}/${key}`);
        setHtml(data.html);
        setError(null);
      } catch (error) {
        setError("Nota fiscal não encontrada");
      }
      setLoading(false)
    }
    loadData();
  }, []);

  return (
    <Container>
      <Header />
      <div>
        {loading && (
          <div style={{ minHeight: '40vh', margin: '0 auto', maxWidth: '1100px' }} className="pt-5">
            <Spinner animation="border"/> Carregando DANFE...
          </div>
        )}
        {html && (
          <>
            <div className="mb-5 mt-5 text-center no-print">
              <button
                className="btn btn-primary"
                onClick={() => window.print()}
              >
                Imprimir Danfe
              </button>
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: html }}
              className="mb-5"
            ></div>
          </>
        )}
        {error && (
          <div className="text-center mt-100" style={{ marginTop: "100px" }}>
            {error}
          </div>
        )}
      </div>
      <Footer />
    </Container>
  );
}

import { combineReducers } from "redux";

import anticipation from "./anticipation/reducer";
import auth from "./auth/reducer";
import ccbAnticipation from "./ccbAnticipation/reducer";
import checkAnticipation from "./checkAnticipation/reducer";
import contractAnticipation from "./contractAnticipation/reducer";
import nfseAnticipation from "./nfseAnticipation/reducer";
import register from "./register/reducer";
import user from "./user/reducer";

export default combineReducers({
  auth,
  user,
  register,
  anticipation,
  checkAnticipation,
  nfseAnticipation,
  ccbAnticipation,
  contractAnticipation,
});

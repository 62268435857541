import React, { useState } from "react";

import { Close } from "@material-ui/icons";

interface IProps {
  file: File | null;
  setFile(value: File | null): void;
  handleRemove: () => void;
}

const ModalXml: React.FC<IProps> = ({ file, setFile, handleRemove }) => {
  const [success, setSuccess] = useState(false);

  if (!file) {
    return null;
  }

  return (
    <div className="file_item">
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div className="d-flex align-items-center">
          {!success && (
            <button type="button" onClick={() => handleRemove()}>
              <Close />
            </button>
          )}
          <h4 className={`mb-0 ${success ? "text-success" : ""}`}>
            {file?.name}
          </h4>
        </div>
      </div>
    </div>
  );
};

export default ModalXml;

export function downloadBillet(
  titlesIds: string[] | number[],
  assignorToken: string
) {
  window.open(
    `${
      process.env.REACT_APP_API
    }/tickets/${assignorToken}/?titles=${titlesIds
      .map((t: string | number) => t.toString())
      .join(",")}`
  );
}

export function titleIsInRegistryOffice(title: any) {
  return (
    title.billing_status_id ===
    title.collectingAgent?.title_in_registry_office_id
  );
}

export function titleIsProtested(title: any) {
  return title.billing_status_id === title.collectingAgent?.protested_title_id;
}

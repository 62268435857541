import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Button, CircularProgress } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';
import { Table, Spinner } from 'react-bootstrap';
import isBefore from 'date-fns/isBefore';
import {
  normalizeCurrency,
  normalizeCnpj,
  normalizeDate,
  normalizeCep,
  cnpjIsValid,
} from '../../utils';
import { matriz } from './utils/matriz';
import { cnae } from './utils/cnae';
import { natureza } from './utils/natureza';
import { partner } from './utils/partner';
import { porte } from './utils/porte';
import { situacao } from './utils/situacao';
import { situacaoCadastral } from './utils/situacaoCadastral';

import { Container, NoEcnpjWarning } from './styles';
import { useAuth } from '~/hooks/useAuth';
import { api } from '~/hooks/api';

interface ICompany {
  cnpj?: string;
  matrizFilial?: string;
  razaoSocial?: string;
  nomeFantasia?: string;
  situacao?: string;
  dataSituacao?: string;
  motivoSituacao?: string;
  nomeCidadeExterior?: string;
  codigoPais?: string;
  nomePais?: string;
  codigoNaturezaJuridica?: string;
  dataInicioAtividade?: string;
  cnaeFiscal?: string;
  tipoLogradouro?: string;
  logradouro?: string;
  numero?: string;
  complemento?: string;
  bairro?: string;
  cep?: string;
  uf?: string;
  codigoMunicipio?: string;
  municipio?: string;
  ddd1?: string;
  telefone1?: string;
  ddd2?: string;
  telefone2?: string;
  dddFax?: string;
  numFax?: string;
  email?: string;
  qualificacaoResponsavel?: string;
  capitalSocial?: number;
  porte?: string;
  opcaoSimples?: string;
  dataOpcaoSimples?: string;
  dataExclusaoSimples?: string;
  opcaoMei?: string;
  situacaoEspecial?: string;
  dataSituacaoEspecial?: string;
}

interface IPartners {
  cnpj: string;
  cnpjCpfSocio?: string;
  codigoPaisExt?: string;
  codigoQualificacao?: string;
  codigoQualificacaoRepresentante?: string;
  cpfRepresentante?: string;
  dataEntrada?: string;
  nomePaisExt?: string;
  nomeRepresentante?: string;
  nomeSocio?: string;
  percentualCapital?: number;
  tipoSocio?: string;
}

interface ICnaes {
  cnpj?: string;
  cnae_ordem?: string;
  cnae?: string;
}

const ConsultCompany: React.FC = () => {
  const [error, setError] = useState('');
  const [documentCNPJ, setDocumentCNPJ] = useState('');
  const [documentError, setDocumentError] = useState('');
  const [company, setCompany] = useState<ICompany>({
    cnpj: '',
    matrizFilial: '',
    razaoSocial: '',
    nomeFantasia: '',
    situacao: '',
    dataSituacao: '',
    motivoSituacao: '',
    nomeCidadeExterior: '',
    codigoPais: '',
    nomePais: '',
    codigoNaturezaJuridica: '',
    dataInicioAtividade: '',
    cnaeFiscal: '',
    tipoLogradouro: '',
    logradouro: '',
    numero: '',
    complemento: '',
    bairro: '',
    cep: '',
    uf: '',
    codigoMunicipio: '',
    municipio: '',
    ddd1: '',
    telefone1: '',
    ddd2: '',
    telefone2: '',
    dddFax: '',
    numFax: '',
    email: '',
    qualificacaoResponsavel: '',
    capitalSocial: 0,
    porte: '',
    opcaoSimples: '',
    dataOpcaoSimples: '',
    dataExclusaoSimples: '',
    opcaoMei: '',
    situacaoEspecial: '',
    dataSituacaoEspecial: '',
  });

  const {
    currentAssignor,
    signOut,
  } = useAuth();
  const [partners, setPartners] = useState<IPartners[]>([]);
  const [cnaeSecundaries, setCnaeSecundaries] = useState<ICnaes[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasEcnpj, setHasEcnpj] = useState(false);
  const [sampleCnpj, setSampleCnpj] = useState<string>('');

  useEffect(() => {
    async function loadEcnpjs() {
      setLoading(true);
      try {
        setSampleCnpj(currentAssignor?.cnpj);
        const { data: ecnpjs } = await api.get(`/register/ecnpjs?all=1`);
        if (
          ecnpjs.filter(
            (e: any) => !isBefore(new Date(e.expires_in), new Date())
          ).length > 0
        ) {
          setHasEcnpj(true);
        }
      } catch (err) {
        if (err.response && err.response.status === 401) {
          signOut();
          toast.error('Sua sessão expirou, entre novamente');
        } else if (err.response && err.response.status === 404) {
          setHasEcnpj(false);
        } else if (err.response && err.response.status === 403) {
          toast.error('Você não está autorizado a acessar este recurso');
        } else if (err.response && err.response.status === 500) {
          toast.error(
            'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
          );
        } else {
          console.log(err);
          toast.error('Ocorreu um erro, tente novamente mais tarde');
        }
      }
      setLoading(false);
    }

    loadEcnpjs();
  }, [currentAssignor?.cnpj]); // eslint-disable-line

  function verifyIfIsValid(value: string) {
    setDocumentError('');
    if (value.length === 18) {
      if (!cnpjIsValid(value)) {
        setDocumentError('CNPJ inválido');
      }
    }
  }

  async function handleSubmit(e: any) {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const formattedDocument = documentCNPJ.replace(/[^\d]/g, '');

      if (!hasEcnpj) {
        if (String(sampleCnpj) !== String(formattedDocument)) {
          setError(
            'Você precisa ter um e-CNPJ vinculado a esta empresa para poder fazer esta consulta'
          );
          return;
        }
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_GATEWAY_OVH}/cnpj/companies/${formattedDocument}`
      );
      const { data: dataPartners } = await axios.get(
        `${process.env.REACT_APP_GATEWAY_OVH}/cnpj/companies/${formattedDocument}/partners`
      );
      const { data: dataCnaes } = await axios.get(
        `${process.env.REACT_APP_GATEWAY_OVH}/cnpj/companies/${formattedDocument}/cnaes_secundaries`
      );
      setCompany(data);
      setPartners(dataPartners);
      setCnaeSecundaries(dataCnaes);
    } catch (err) {
      setError('CNPJ não encontrado');
    } finally {
      setLoading(false);
    }
  }

  function copyToClipboard(cnpjI: string) {
    const textarea = document.createElement('input');
    textarea.setAttribute('value', cnpjI);
    document.body.appendChild(textarea);
    textarea.select();
    textarea.setSelectionRange(0, 99999);
    document.execCommand('copy');
    document.body.removeChild(textarea);
  }

  return (
    <Container>
      <div className="align-items-center d-flex header_component justify-content-between pb-3">
        <h4>
          {loading && <Spinner size="sm" animation="border" />} Consulta de
          Empresa
        </h4>
        {company.cnpj !== '' && !loading && (
          <Button
            type="button"
            color="primary"
            variant="outlined"
            className="ml-auto"
            onClick={() => {
              setCompany({
                cnpj: '',
                matrizFilial: '',
                razaoSocial: '',
                nomeFantasia: '',
                situacao: '',
                dataSituacao: '',
                motivoSituacao: '',
                nomeCidadeExterior: '',
                codigoPais: '',
                nomePais: '',
                codigoNaturezaJuridica: '',
                dataInicioAtividade: '',
                cnaeFiscal: '',
                tipoLogradouro: '',
                logradouro: '',
                numero: '',
                complemento: '',
                bairro: '',
                cep: '',
                uf: '',
                codigoMunicipio: '',
                municipio: '',
                ddd1: '',
                telefone1: '',
                ddd2: '',
                telefone2: '',
                dddFax: '',
                numFax: '',
                email: '',
                qualificacaoResponsavel: '',
                capitalSocial: 0,
                porte: '',
                opcaoSimples: '',
                dataOpcaoSimples: '',
                dataExclusaoSimples: '',
                opcaoMei: '',
                situacaoEspecial: '',
                dataSituacaoEspecial: '',
              });
              setPartners([]);
              setCnaeSecundaries([]);
              setDocumentCNPJ('');
              setError('');
            }}
          >
            Voltar
          </Button>
        )}
      </div>
      {company.cnpj === '' && !loading && (
        <NoEcnpjWarning className="mt-3">
          <img src="/assets/images/avatar-company.png" alt="Avatar Company" />
          <div id="text-no-ecnpj">
            <div className="detached">
              {!hasEcnpj ? (
                <>
                  <img src="/assets/images/hand.png" alt="Hand" />
                  <p>
                    Parece que você ainda não enviou nenhum{' '}
                    <strong>e-CNPJ</strong> para nossa plataforma.
                    <br />{' '}
                    <strong>
                      Envie agora e obtenha acesso a serviços exclusivos!
                    </strong>
                  </p>
                </>
              ) : (
                <>
                  <img src="/assets/images/magnifier.png" alt="Search" />
                  <p>
                    Disponibilizamos o serviço de
                    <br />
                    consulta detalhada de empresas
                  </p>
                </>
              )}
            </div>
            {!hasEcnpj && (
              <>
                <br />
                <p>Os seguintes CNPJ estão disponíveis para consulta:</p>
                <ul>
                  <li>
                    {normalizeCnpj(sampleCnpj)}
                    <button
                      type="button"
                      className="ml-2"
                      onClick={() => copyToClipboard(sampleCnpj)}
                    >
                      <img
                        src="/assets/images/clipboard.png"
                        alt="Clipboard"
                        style={{ width: '16px' }}
                      />
                    </button>
                  </li>
                </ul>
              </>
            )}
            <form className="content_component" onSubmit={handleSubmit}>
              {error && <div className="alert alert-danger">{error}</div>}
              <div className="input_base mb-3">
                <input
                  type="text"
                  placeholder="Digite o CNPJ"
                  value={documentCNPJ}
                  onChange={(e) => {
                    verifyIfIsValid(normalizeCnpj(e.target.value));
                    setDocumentCNPJ(normalizeCnpj(e.target.value));
                    if (error !== '') {
                      setError('');
                    }
                  }}
                />
                <button
                  type="submit"
                  disabled={
                    documentCNPJ.length < 18 || documentError !== '' || loading
                  }
                >
                  {loading ? (
                    <CircularProgress
                      color="primary"
                      size="14px"
                      className="mr-3"
                    />
                  ) : (
                    <ArrowForwardIos style={{ fontSize: '12px' }} />
                  )}
                </button>
              </div>
              {documentError !== '' && (
                <span className="text-danger">{documentError}</span>
              )}
            </form>
          </div>
        </NoEcnpjWarning>
      )}
      {company.cnpj !== '' && !loading && (
        <div style={{ padding: '25px', width: '100%' }}>
          <div className="content_section">
            <div className="content_section__box mb-3">
              <div className="box_header">
                <h4>SITUAÇÃO DO CNPJ - RECEITA FEDERAL</h4>
              </div>
              <div className="box_content">
                <div className="row no-gutters">
                  <div className="col-4">
                    <strong>Número de Inscrição</strong>
                    <p>{normalizeCnpj(String(company.cnpj))}</p>
                  </div>
                  <div className="col-2">
                    <strong>Abertura</strong>
                    <p>{normalizeDate(String(company.dataInicioAtividade))}</p>
                  </div>
                  <div className="col-2">
                    <strong>Matriz</strong>
                    <p>{matriz(Number(company.matrizFilial))}</p>
                  </div>
                  <div className="col-2">
                    <strong>Porte</strong>
                    <p>{porte(String(company.porte))}</p>
                  </div>
                </div>
                <div className="row no-gutters">
                  <div className="col-6">
                    <strong>Nome Empresarial</strong>
                    <p>{company.razaoSocial}</p>
                  </div>
                  {company.nomeFantasia !== '' && (
                    <div className="col-6">
                      <strong>Nome Fantasia</strong>
                      <p>{company.nomeFantasia}</p>
                    </div>
                  )}
                </div>
                <div className="row no-gutters">
                  <div className="col-3">
                    <strong>Capital Social</strong>
                    <p>R$ {normalizeCurrency(Number(company.capitalSocial))}</p>
                  </div>
                </div>
                <div className="row no-gutters">
                  <div className="col-12">
                    <strong>
                      Código e Descrição da Atividade Econômica Principal
                    </strong>
                  </div>
                  <div className="col-12">
                    <p>
                      {company.cnaeFiscal} - {cnae(Number(company.cnaeFiscal))}
                    </p>
                  </div>
                </div>
                {cnaeSecundaries.length > 0 && (
                  <div className="row no-gutters">
                    <div className="col-12">
                      <strong>
                        Código e Descrição das Atividades Econômicas Secundárias
                      </strong>
                    </div>
                    <div className="col-12">
                      <p>
                        {cnaeSecundaries.map((e, i) => (
                          <>
                            {e.cnae} - {cnae(Number(e.cnae))}
                            <br />
                          </>
                        ))}
                      </p>
                    </div>
                  </div>
                )}
                <div className="row no-gutters">
                  <div className="col-12">
                    <strong>Código e Descrição da Natureza Jurídica</strong>
                  </div>
                  <div className="col-12">
                    <p>
                      {company.codigoNaturezaJuridica} -{' '}
                      {natureza(Number(company.codigoNaturezaJuridica))}
                    </p>
                  </div>
                </div>
                <div className="row no-gutters">
                  <div className="col-2">
                    <strong>CEP</strong>
                    <p>{normalizeCep(String(company.cep))}</p>
                  </div>
                  <div className="col-4">
                    <strong>Logradouro</strong>
                    <p>
                      {company.tipoLogradouro} {company.logradouro}
                    </p>
                  </div>
                  <div className="col-2">
                    <strong>Numero</strong>
                    <p>{company.numero}</p>
                  </div>
                  <div className="col-2">
                    <strong>Complemento</strong>
                    <p>{company.complemento || '-'}</p>
                  </div>
                </div>
                <div className="row no-gutters">
                  <div className="col-2">
                    <strong>Bairro</strong>
                    <p>{company.bairro}</p>
                  </div>
                  <div className="col-4">
                    <strong>Município</strong>
                    <p>{company.municipio}</p>
                  </div>
                  <div className="col-1">
                    <strong>UF</strong>
                    <p>{company.uf}</p>
                  </div>
                </div>
                <div className="row no-gutters">
                  {company.email && (
                    <div className="col-6">
                      <strong>E-mail</strong>
                      <p>{company.email}</p>
                    </div>
                  )}
                  {company.ddd1 !== '' && company.telefone1 !== '' && (
                    <div className="col-6">
                      <strong>Telefone</strong>
                      <p>
                        ({company.ddd1}) {company.telefone1}
                        {company.ddd2 !== '' && company.telefone2 !== ''
                          ? ` - (${company.ddd2}) ${company.telefone2}`
                          : ''}
                      </p>
                    </div>
                  )}
                </div>
                {company.situacao !== '' && (
                  <div className="row no-gutters">
                    <div className="col-3">
                      <strong>Situação Cadastral</strong>
                      <p>{situacaoCadastral(String(company.situacao))}</p>
                    </div>
                    <div className="col-1">
                      <strong>Data</strong>
                      <p>{normalizeDate(String(company.dataSituacao))}</p>
                    </div>
                    {company.motivoSituacao !== '' &&
                      company.motivoSituacao !== '00' && (
                        <div className="col-8">
                          <strong>Motivo</strong>
                          <p>{situacao(Number(company.motivoSituacao))}</p>
                        </div>
                      )}
                  </div>
                )}
                {company.situacaoEspecial !== '' && (
                  <div className="row no-gutters">
                    <div className="col-3">
                      <strong>Situação Especial</strong>
                    </div>
                    <div className="col-6">
                      <p>{company.situacaoEspecial}</p>
                    </div>
                    <div className="col-1">
                      <strong>Data</strong>
                    </div>
                    <div className="col-2">
                      <p>{company.dataSituacaoEspecial}</p>
                    </div>
                  </div>
                )}
                <div className="box_header mt-3">
                  <h4>SÓCIOS E ADMINISTRADORES</h4>
                </div>
                <Table hover>
                  <thead className="bg-light">
                    <tr>
                      <th>Nome</th>
                      <th>Tipo</th>
                      <th>Representante</th>
                      <th>Data da entrada</th>
                    </tr>
                  </thead>
                  <tbody>
                    {partners.length > 0 &&
                      partners.map((e, i) => (
                        <tr key={String(i)}>
                          <td>
                            <p>{e.nomeSocio || '-'}</p>
                            <span>{partner(Number(e.tipoSocio)) || '-'}</span>
                          </td>
                          <td>
                            <p>
                              {partner(Number(e.codigoQualificacao)) || '-'}
                            </p>
                          </td>
                          <td>
                            {e.nomeRepresentante &&
                            e.codigoQualificacaoRepresentante ? (
                              <>
                                <p>{e.nomeRepresentante}</p>
                                <span>
                                  {partner(
                                    Number(e.codigoQualificacaoRepresentante)
                                  )}
                                </span>
                              </>
                            ) : (
                              '-'
                            )}
                          </td>
                          <td>
                            <p>{normalizeDate(String(e.dataEntrada)) || '-'}</p>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

export default ConsultCompany;

import React from 'react';

import { Modal, Button } from '@material-ui/core';

import { useHistory } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { Container } from './styles';

import useSelector from '~/store/useSelector';
import {
  modalClose,
  editUnActive,
} from '../../../../store/modules/register/actions';

const Confirmation: React.FC = () => {
  const { modal: open, modal_to } = useSelector((state) => state.register);
  const dispatch = useDispatch();
  const history = useHistory();

  const closeModal = () => {
    dispatch(modalClose());
  };

  const pushWithoutSave = () => {
    history.push(modal_to);
    dispatch(modalClose());
    dispatch(editUnActive());
  };

  return (
    <Modal
      open={open}
      onClose={() => closeModal()}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Container>
        <h3 style={{ textAlign: 'center' }}>Tem certeza que deseja sair?</h3>
        <p>As alterações não serão salvas.</p>
        <div className="d-flex">
          <Button
            variant="outlined"
            className="mr-4"
            onClick={() => pushWithoutSave()}
            color="primary"
          >
            Sair sem salvar
          </Button>
          <Button variant="contained" color="primary" onClick={closeModal}>
            Continuar editando
          </Button>
        </div>
      </Container>
    </Modal>
  );
};

export default Confirmation;

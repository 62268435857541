export const cnae = (value: number) => {
  switch (value) {
    case 111301:
      return 'Cultivo de arroz';
    case 111302:
      return 'Cultivo de milho';
    case 111303:
      return 'Cultivo de trigo';
    case 111399:
      return 'Cultivo de outros cereais não especificados anteriormente';
    case 112101:
      return 'Cultivo de algodão herbáceo';
    case 112102:
      return 'Cultivo de juta';
    case 112199:
      return 'Cultivo de outras fibras de lavoura temporária não especificadas anteriormente';
    case 113000:
      return 'Cultivo de cana-de-açúcar';
    case 114800:
      return 'Cultivo de fumo';
    case 115600:
      return 'Cultivo de soja';
    case 116401:
      return 'Cultivo de amendoim';
    case 116402:
      return 'Cultivo de girassol';
    case 116403:
      return 'Cultivo de mamona';
    case 116499:
      return 'Cultivo de outras oleaginosas de lavoura temporária não especificadas anteriormente';
    case 119901:
      return 'Cultivo de abacaxi';
    case 119902:
      return 'Cultivo de alho';
    case 119903:
      return 'Cultivo de batata-inglesa';
    case 119904:
      return 'Cultivo de cebola';
    case 119905:
      return 'Cultivo de feijão';
    case 119906:
      return 'Cultivo de mandioca';
    case 119907:
      return 'Cultivo de melão';
    case 119908:
      return 'Cultivo de melancia';
    case 119909:
      return 'Cultivo de tomate rasteiro';
    case 119999:
      return 'Cultivo de outras plantas de lavoura temporária não especificadas anteriormente';
    case 121101:
      return 'Horticultura, exceto morango';
    case 121102:
      return 'Cultivo de morango';
    case 122900:
      return 'Cultivo de flores e plantas ornamentais';
    case 131800:
      return 'Cultivo de laranja';
    case 132600:
      return 'Cultivo de uva';
    case 133401:
      return 'Cultivo de açaí';
    case 133402:
      return 'Cultivo de banana';
    case 133403:
      return 'Cultivo de caju';
    case 133404:
      return 'Cultivo de cítricos, exceto laranja';
    case 133405:
      return 'Cultivo de coco-da-baía';
    case 133406:
      return 'Cultivo de guaraná';
    case 133407:
      return 'Cultivo de maçã';
    case 133408:
      return 'Cultivo de mamão';
    case 133409:
      return 'Cultivo de maracujá';
    case 133410:
      return 'Cultivo de manga';
    case 133411:
      return 'Cultivo de pêssego';
    case 133499:
      return 'Cultivo de frutas de lavoura permanente não especificadas anteriormente';
    case 134200:
      return 'Cultivo de café';
    case 135100:
      return 'Cultivo de cacau';
    case 139301:
      return 'Cultivo de chá-da-índia';
    case 139302:
      return 'Cultivo de erva-mate';
    case 139303:
      return 'Cultivo de pimenta-do-reino';
    case 139304:
      return 'Cultivo de plantas para condimento, exceto pimenta-do-reino';
    case 139305:
      return 'Cultivo de dendê';
    case 139306:
      return 'Cultivo de seringueira';
    case 139399:
      return 'Cultivo de outras plantas de lavoura permanente não especificadas anteriormente';
    case 141501:
      return 'Produção de sementes certificadas, exceto de forrageiras para pasto';
    case 141502:
      return 'Produção de sementes certificadas de forrageiras para formação de pasto';
    case 142300:
      return 'Produção de mudas e outras formas de propagação vegetal, certificadas';
    case 151201:
      return 'Criação de bovinos para corte';
    case 151202:
      return 'Criação de bovinos para leite';
    case 151203:
      return 'Criação de bovinos, exceto para corte e leite';
    case 152101:
      return 'Criação de bufalinos';
    case 152102:
      return 'Criação de eqüinos';
    case 152103:
      return 'Criação de asininos e muares';
    case 153901:
      return 'Criação de caprinos';
    case 153902:
      return 'Criação de ovinos, inclusive para produção de lã';
    case 154700:
      return 'Criação de suínos';
    case 155501:
      return 'Criação de frangos para corte';
    case 155502:
      return 'Produção de pintos de um dia';
    case 155503:
      return 'Criação de outros galináceos, exceto para corte';
    case 155504:
      return 'Criação de aves, exceto galináceos';
    case 155505:
      return 'Produção de ovos';
    case 159801:
      return 'Apicultura';
    case 159802:
      return 'Criação de animais de estimação';
    case 159803:
      return 'Criação de escargô';
    case 159804:
      return 'Criação de bicho-da-seda';
    case 159899:
      return 'Criação de outros animais não especificados anteriormente';
    case 161001:
      return 'Serviço de pulverização e controle de pragas agrícolas';
    case 161002:
      return 'Serviço de poda de árvores para lavouras';
    case 161003:
      return 'Serviço de preparação de terreno, cultivo e colheita';
    case 161099:
      return 'Atividades de apoio à agricultura não especificadas anteriormente';
    case 162801:
      return 'Serviço de inseminação artificial em animais';
    case 162802:
      return 'Serviço de tosquiamento de ovinos';
    case 162803:
      return 'Serviço de manejo de animais';
    case 162899:
      return 'Atividades de apoio à pecuária não especificadas anteriormente';
    case 163600:
      return 'Atividades de pós-colheita';
    case 170900:
      return 'Caça e serviços relacionados';
    case 210101:
      return 'Cultivo de eucalipto';
    case 210102:
      return 'Cultivo de acácia-negra';
    case 210103:
      return 'Cultivo de pinus';
    case 210104:
      return 'Cultivo de teca';
    case 210105:
      return 'Cultivo de espécies madeireiras, exceto eucalipto, acácia-negra, pinus e teca';
    case 210106:
      return 'Cultivo de mudas em viveiros florestais';
    case 210107:
      return 'Extração de madeira em florestas plantadas';
    case 210108:
      return 'Produção de carvão vegetal - florestas plantadas';
    case 210109:
      return 'Produção de casca de acácia-negra - florestas plantadas';
    case 210199:
      return 'Produção de produtos não-madeireiros não especificados anteriormente em florestas plantadas';
    case 220901:
      return 'Extração de madeira em florestas nativas';
    case 220902:
      return 'Produção de carvão vegetal - florestas nativas';
    case 220903:
      return 'Coleta de castanha-do-pará em florestas nativas';
    case 220904:
      return 'Coleta de látex em florestas nativas';
    case 220905:
      return 'Coleta de palmito em florestas nativas';
    case 220906:
      return 'Conservação de florestas nativas';
    case 220999:
      return 'Coleta de produtos não-madeireiros não especificados anteriormente em florestas nativas';
    case 230600:
      return 'Atividades de apoio à produção florestal';
    case 311601:
      return 'Pesca de peixes em água salgada';
    case 311602:
      return 'Pesca de crustáceos e moluscos em água salgada';
    case 311603:
      return 'Coleta de outros produtos marinhos';
    case 311604:
      return 'Atividades de apoio à pesca em água salgada';
    case 312401:
      return 'Pesca de peixes em água doce';
    case 312402:
      return 'Pesca de crustáceos e moluscos em água doce';
    case 312403:
      return 'Coleta de outros produtos aquáticos de água doce';
    case 312404:
      return 'Atividades de apoio à pesca em água doce';
    case 321301:
      return 'Criação de peixes em água salgada e salobra';
    case 321302:
      return 'Criação de camarões em água salgada e salobra';
    case 321303:
      return 'Criação de ostras e mexilhões em água salgada e salobra';
    case 321304:
      return 'Criação de peixes ornamentais em água salgada e salobra';
    case 321305:
      return 'Atividades de apoio à aqüicultura em água salgada e salobra';
    case 321399:
      return 'Cultivos e semicultivos da aqüicultura em água salgada e salobra não especificados anteriormente';
    case 322101:
      return 'Criação de peixes em água doce';
    case 322102:
      return 'Criação de camarões em água doce';
    case 322103:
      return 'Criação de ostras e mexilhões em água doce';
    case 322104:
      return 'Criação de peixes ornamentais em água doce';
    case 322105:
      return 'Ranicultura';
    case 322106:
      return 'Criação de jacaré';
    case 322107:
      return 'Atividades de apoio à aqüicultura em água doce';
    case 322199:
      return 'Cultivos e semicultivos da aqüicultura em água doce não especificados anteriormente';
    case 500301:
      return 'Extração de carvão mineral';
    case 500302:
      return 'Beneficiamento de carvão mineral';
    case 600001:
      return 'Extração de petróleo e gás natural';
    case 600002:
      return 'Extração e beneficiamento de xisto';
    case 600003:
      return 'Extração e beneficiamento de areias betuminosas';
    case 710301:
      return 'Extração de minério de ferro';
    case 710302:
      return 'Pelotização, sinterização e outros beneficiamentos de minério de ferro';
    case 721901:
      return 'Extração de minério de alumínio';
    case 721902:
      return 'Beneficiamento de minério de alumínio';
    case 722701:
      return 'Extração de minério de estanho';
    case 722702:
      return 'Beneficiamento de minério de estanho';
    case 723501:
      return 'Extração de minério de manganês';
    case 723502:
      return 'Beneficiamento de minério de manganês';
    case 724301:
      return 'Extração de minério de metais preciosos';
    case 724302:
      return 'Beneficiamento de minério de metais preciosos';
    case 725100:
      return 'Extração de minerais radioativos';
    case 729401:
      return 'Extração de minérios de nióbio e titânio';
    case 729402:
      return 'Extração de minério de tungstênio';
    case 729403:
      return 'Extração de minério de níquel';
    case 729404:
      return 'Extração de minérios de cobre, chumbo, zinco e outros minerais metálicos não-ferrosos não especificados anteriormente';
    case 729405:
      return 'Beneficiamento de minérios de cobre, chumbo, zinco e outros minerais metálicos não-ferrosos não especificados anteriormente';
    case 810001:
      return 'Extração de ardósia e beneficiamento associado';
    case 810002:
      return 'Extração de granito e beneficiamento associado';
    case 810003:
      return 'Extração de mármore e beneficiamento associado';
    case 810004:
      return 'Extração de calcário e dolomita e beneficiamento associado';
    case 810005:
      return 'Extração de gesso e caulim';
    case 810006:
      return 'Extração de areia, cascalho ou pedregulho e beneficiamento associado';
    case 810007:
      return 'Extração de argila e beneficiamento associado';
    case 810008:
      return 'Extração de saibro e beneficiamento associado';
    case 810009:
      return 'Extração de basalto e beneficiamento associado';
    case 810010:
      return 'Beneficiamento de gesso e caulim associado à extração';
    case 810099:
      return 'Extração e britamento de pedras e outros materiais para construção e beneficiamento associado';
    case 891600:
      return 'Extração de minerais para fabricação de adubos, fertilizantes e outros produtos químicos';
    case 892401:
      return 'Extração de sal marinho';
    case 892402:
      return 'Extração de sal-gema';
    case 892403:
      return 'Refino e outros tratamentos do sal';
    case 893200:
      return 'Extração de gemas (pedras preciosas e semipreciosas)';
    case 899101:
      return 'Extração de grafita';
    case 899102:
      return 'Extração de quartzo';
    case 899103:
      return 'Extração de amianto';
    case 899199:
      return 'Extração de outros minerais não-metálicos não especificados anteriormente';
    case 910600:
      return 'Atividades de apoio à extração de petróleo e gás natural';
    case 990401:
      return 'Atividades de apoio à extração de minério de ferro';
    case 990402:
      return 'Atividades de apoio à extração de minerais metálicos não-ferrosos';
    case 990403:
      return 'Atividades de apoio à extração de minerais não-metálicos';
    case 1011201:
      return 'Frigorífico - abate de bovinos';
    case 1011202:
      return 'Frigorífico - abate de eqüinos';
    case 1011203:
      return 'Frigorífico - abate de ovinos e caprinos';
    case 1011204:
      return 'Frigorífico - abate de bufalinos';
    case 1011205:
      return 'Matadouro - abate de reses sob contrato, exceto abate de suínos';
    case 1012101:
      return 'Abate de aves';
    case 1012102:
      return 'Abate de pequenos animais';
    case 1012103:
      return 'Frigorífico - abate de suínos';
    case 1012104:
      return 'Matadouro - abate de suínos sob contrato';
    case 1013901:
      return 'Fabricação de produtos de carne';
    case 1013902:
      return 'Preparação de subprodutos do abate';
    case 1020101:
      return 'Preservação de peixes, crustáceos e moluscos';
    case 1020102:
      return 'Fabricação de conservas de peixes, crustáceos e moluscos';
    case 1031700:
      return 'Fabricação de conservas de frutas';
    case 1032501:
      return 'Fabricação de conservas de palmito';
    case 1032599:
      return 'Fabricação de conservas de legumes e outros vegetais, exceto palmito';
    case 1033301:
      return 'Fabricação de sucos concentrados de frutas, hortaliças e legumes';
    case 1033302:
      return 'Fabricação de sucos de frutas, hortaliças e legumes, exceto concentrados';
    case 1041400:
      return 'Fabricação de óleos vegetais em bruto, exceto óleo de milho';
    case 1042200:
      return 'Fabricação de óleos vegetais refinados, exceto óleo de milho';
    case 1043100:
      return 'Fabricação de margarina e outras gorduras vegetais e de óleos não-comestíveis de animais';
    case 1051100:
      return 'Preparação do leite';
    case 1052000:
      return 'Fabricação de laticínios';
    case 1053800:
      return 'Fabricação de sorvetes e outros gelados comestíveis';
    case 1061901:
      return 'Beneficiamento de arroz';
    case 1061902:
      return 'Fabricação de produtos do arroz';
    case 1062700:
      return 'Moagem de trigo e fabricação de derivados';
    case 1063500:
      return 'Fabricação de farinha de mandioca e derivados';
    case 1064300:
      return 'Fabricação de farinha de milho e derivados, exceto óleos de milho';
    case 1065101:
      return 'Fabricação de amidos e féculas de vegetais';
    case 1065102:
      return 'Fabricação de óleo de milho em bruto';
    case 1065103:
      return 'Fabricação de óleo de milho refinado';
    case 1066000:
      return 'Fabricação de alimentos para animais';
    case 1069400:
      return 'Moagem e fabricação de produtos de origem vegetal não especificados anteriormente';
    case 1071600:
      return 'Fabricação de açúcar em bruto';
    case 1072401:
      return 'Fabricação de açúcar de cana refinado';
    case 1072402:
      return 'Fabricação de açúcar de cereais (dextrose) e de beterraba';
    case 1081301:
      return 'Beneficiamento de café';
    case 1081302:
      return 'Torrefação e moagem de café';
    case 1082100:
      return 'Fabricação de produtos à base de café';
    case 1091101:
      return 'Fabricação de produtos de panificação industrial';
    case 1091102:
      return 'Fabricação de produtos de padaria e confeitaria com predominância de produção própria';
    case 1092900:
      return 'Fabricação de biscoitos e bolachas';
    case 1093701:
      return 'Fabricação de produtos derivados do cacau e de chocolates';
    case 1093702:
      return 'Fabricação de frutas cristalizadas, balas e semelhantes';
    case 1094500:
      return 'Fabricação de massas alimentícias';
    case 1095300:
      return 'Fabricação de especiarias, molhos, temperos e condimentos';
    case 1096100:
      return 'Fabricação de alimentos e pratos prontos';
    case 1099601:
      return 'Fabricação de vinagres';
    case 1099602:
      return 'Fabricação de pós alimentícios';
    case 1099603:
      return 'Fabricação de fermentos e leveduras';
    case 1099604:
      return 'Fabricação de gelo comum';
    case 1099605:
      return 'Fabricação de produtos para infusão (chá, mate, etc.)';
    case 1099606:
      return 'Fabricação de adoçantes naturais e artificiais';
    case 1099607:
      return 'Fabricação de alimentos dietéticos e complementos alimentares';
    case 1099699:
      return 'Fabricação de outros produtos alimentícios não especificados anteriormente';
    case 1111901:
      return 'Fabricação de aguardente de cana-de-açúcar';
    case 1111902:
      return 'Fabricação de outras aguardentes e bebidas destiladas';
    case 1112700:
      return 'Fabricação de vinho';
    case 1113501:
      return 'Fabricação de malte, inclusive malte uísque';
    case 1113502:
      return 'Fabricação de cervejas e chopes';
    case 1121600:
      return 'Fabricação de águas envasadas';
    case 1122401:
      return 'Fabricação de refrigerantes';
    case 1122402:
      return 'Fabricação de chá mate e outros chás prontos para consumo';
    case 1122403:
      return 'Fabricação de refrescos, xaropes e pós para refrescos, exceto refrescos de frutas';
    case 1122404:
      return 'Fabricação de bebidas isotônicas';
    case 1122499:
      return 'Fabricação de outras bebidas não-alcoólicas não especificadas anteriormente';
    case 1210700:
      return 'Processamento industrial do fumo';
    case 1220401:
      return 'Fabricação de cigarros';
    case 1220402:
      return 'Fabricação de cigarrilhas e charutos';
    case 1220403:
      return 'Fabricação de filtros para cigarros';
    case 1220499:
      return 'Fabricação de outros produtos do fumo, exceto cigarros, cigarrilhas e charutos';
    case 1311100:
      return 'Preparação e fiação de fibras de algodão';
    case 1312000:
      return 'Preparação e fiação de fibras têxteis naturais, exceto algodão';
    case 1313800:
      return 'Fiação de fibras artificiais e sintéticas';
    case 1314600:
      return 'Fabricação de linhas para costurar e bordar';
    case 1321900:
      return 'Tecelagem de fios de algodão';
    case 1322700:
      return 'Tecelagem de fios de fibras têxteis naturais, exceto algodão';
    case 1323500:
      return 'Tecelagem de fios de fibras artificiais e sintéticas';
    case 1330800:
      return 'Fabricação de tecidos de malha';
    case 1340501:
      return 'Estamparia e texturização em fios, tecidos, artefatos têxteis e peças do vestuário';
    case 1340502:
      return 'Alvejamento, tingimento e torção em fios, tecidos, artefatos têxteis e peças do vestuário';
    case 1340599:
      return 'Outros serviços de acabamento em fios, tecidos, artefatos têxteis e peças do vestuário';
    case 1351100:
      return 'Fabricação de artefatos têxteis para uso doméstico';
    case 1352900:
      return 'Fabricação de artefatos de tapeçaria';
    case 1353700:
      return 'Fabricação de artefatos de cordoaria';
    case 1354500:
      return 'Fabricação de tecidos especiais, inclusive artefatos';
    case 1359600:
      return 'Fabricação de outros produtos têxteis não especificados anteriormente';
    case 1411801:
      return 'Confecção de roupas íntimas';
    case 1411802:
      return 'Facção de roupas íntimas';
    case 1412601:
      return 'Confecção de peças do vestuário, exceto roupas íntimas e as confeccionadas sob medida';
    case 1412602:
      return 'Confecção, sob medida, de peças do vestuário, exceto roupas íntimas';
    case 1412603:
      return 'Facção de peças do vestuário, exceto roupas íntimas';
    case 1413401:
      return 'Confecção de roupas profissionais, exceto sob medida';
    case 1413402:
      return 'Confecção, sob medida, de roupas profissionais';
    case 1413403:
      return 'Facção de roupas profissionais';
    case 1414200:
      return 'Fabricação de acessórios do vestuário, exceto para segurança e proteção';
    case 1421500:
      return 'Fabricação de meias';
    case 1422300:
      return 'Fabricação de artigos do vestuário, produzidos em malharias e tricotagens, exceto meias';
    case 1510600:
      return 'Curtimento e outras preparações de couro';
    case 1521100:
      return 'Fabricação de artigos para viagem, bolsas e semelhantes de qualquer material';
    case 1529700:
      return 'Fabricação de artefatos de couro não especificados anteriormente';
    case 1531901:
      return 'Fabricação de calçados de couro';
    case 1531902:
      return 'Acabamento de calçados de couro sob contrato';
    case 1532700:
      return 'Fabricação de tênis de qualquer material';
    case 1533500:
      return 'Fabricação de calçados de material sintético';
    case 1539400:
      return 'Fabricação de calçados de materiais não especificados anteriormente';
    case 1540800:
      return 'Fabricação de partes para calçados, de qualquer material';
    case 1610203:
      return 'Serrarias com desdobramento de madeira em bruto';
    case 1610204:
      return 'Serrarias sem desdobramento de madeira em bruto - Resserragem';
    case 1610205:
      return 'Serviço de tratamento de madeira realizado sob contrato';
    case 1621800:
      return 'Fabricação de madeira laminada e de chapas de madeira compensada, prensada e aglomerada';
    case 1622601:
      return 'Fabricação de casas de madeira pré-fabricadas';
    case 1622602:
      return 'Fabricação de esquadrias de madeira e de peças de madeira para instalações industriais e comerciais';
    case 1622699:
      return 'Fabricação de outros artigos de carpintaria para construção';
    case 1623400:
      return 'Fabricação de artefatos de tanoaria e de embalagens de madeira';
    case 1629301:
      return 'Fabricação de artefatos diversos de madeira, exceto móveis';
    case 1629302:
      return 'Fabricação de artefatos diversos de cortiça, bambu, palha, vime e outros materiais trançados, exceto móveis';
    case 1710900:
      return 'Fabricação de celulose e outras pastas para a fabricação de papel';
    case 1721400:
      return 'Fabricação de papel';
    case 1722200:
      return 'Fabricação de cartolina e papel-cartão';
    case 1731100:
      return 'Fabricação de embalagens de papel';
    case 1732000:
      return 'Fabricação de embalagens de cartolina e papel-cartão';
    case 1733800:
      return 'Fabricação de chapas e de embalagens de papelão ondulado';
    case 1741901:
      return 'Fabricação de formulários contínuos';
    case 1741902:
      return 'Fabricação de produtos de papel, cartolina, papel-cartão e papelão ondulado para uso comercial e de escritório, exceto formulário contínuo';
    case 1742701:
      return 'Fabricação de fraldas descartáveis';
    case 1742702:
      return 'Fabricação de absorventes higiênicos';
    case 1742799:
      return 'Fabricação de produtos de papel para uso doméstico e higiênico-sanitário não especificados anteriormente';
    case 1749400:
      return 'Fabricação de produtos de pastas celulósicas, papel, cartolina, papel-cartão e papelão ondulado não especificados anteriormente';
    case 1811301:
      return 'Impressão de jornais';
    case 1811302:
      return 'Impressão de livros, revistas e outras publicações periódicas';
    case 1812100:
      return 'Impressão de material de segurança';
    case 1813001:
      return 'Impressão de material para uso publicitário';
    case 1813099:
      return 'Impressão de material para outros usos';
    case 1821100:
      return 'Serviços de pré-impressão';
    case 1822901:
      return 'Serviços de encadernação e plastificação';
    case 1822999:
      return 'Serviços de acabamentos gráficos, exceto encadernação e plastificação';
    case 1830001:
      return 'Reprodução de som em qualquer suporte';
    case 1830002:
      return 'Reprodução de vídeo em qualquer suporte';
    case 1830003:
      return 'Reprodução de software em qualquer suporte';
    case 1910100:
      return 'Coquerias';
    case 1921700:
      return 'Fabricação de produtos do refino de petróleo';
    case 1922501:
      return 'Formulação de combustíveis';
    case 1922502:
      return 'Rerrefino de óleos lubrificantes';
    case 1922599:
      return 'Fabricação de outros produtos derivados do petróleo, exceto produtos do refino';
    case 1931400:
      return 'Fabricação de álcool';
    case 1932200:
      return 'Fabricação de biocombustíveis, exceto álcool';
    case 2011800:
      return 'Fabricação de cloro e álcalis';
    case 2012600:
      return 'Fabricação de intermediários para fertilizantes';
    case 2013401:
      return 'Fabricação de adubos e fertilizantes organo-minerais';
    case 2013402:
      return 'Fabricação de adubos e fertilizantes, exceto organo-minerais';
    case 2014200:
      return 'Fabricação de gases industriais';
    case 2019301:
      return 'Elaboração de combustíveis nucleares';
    case 2019399:
      return 'Fabricação de outros produtos químicos inorgânicos não especificados anteriormente';
    case 2021500:
      return 'Fabricação de produtos petroquímicos básicos';
    case 2022300:
      return 'Fabricação de intermediários para plastificantes, resinas e fibras';
    case 2029100:
      return 'Fabricação de produtos químicos orgânicos não especificados anteriormente';
    case 2031200:
      return 'Fabricação de resinas termoplásticas';
    case 2032100:
      return 'Fabricação de resinas termofixas';
    case 2033900:
      return 'Fabricação de elastômeros';
    case 2040100:
      return 'Fabricação de fibras artificiais e sintéticas';
    case 2051700:
      return 'Fabricação de defensivos agrícolas';
    case 2052500:
      return 'Fabricação de desinfestantes domissanitários';
    case 2061400:
      return 'Fabricação de sabões e detergentes sintéticos';
    case 2062200:
      return 'Fabricação de produtos de limpeza e polimento';
    case 2063100:
      return 'Fabricação de cosméticos, produtos de perfumaria e de higiene pessoal';
    case 2071100:
      return 'Fabricação de tintas, vernizes, esmaltes e lacas';
    case 2072000:
      return 'Fabricação de tintas de impressão';
    case 2073800:
      return 'Fabricação de impermeabilizantes, solventes e produtos afins';
    case 2091600:
      return 'Fabricação de adesivos e selantes';
    case 2092401:
      return 'Fabricação de pólvoras, explosivos e detonantes';
    case 2092402:
      return 'Fabricação de artigos pirotécnicos';
    case 2092403:
      return 'Fabricação de fósforos de segurança';
    case 2093200:
      return 'Fabricação de aditivos de uso industrial';
    case 2094100:
      return 'Fabricação de catalisadores';
    case 2099101:
      return 'Fabricação de chapas, filmes, papéis e outros materiais e produtos químicos para fotografia';
    case 2099199:
      return 'Fabricação de outros produtos químicos não especificados anteriormente';
    case 2110600:
      return 'Fabricação de produtos farmoquímicos';
    case 2121101:
      return 'Fabricação de medicamentos alopáticos para uso humano';
    case 2121102:
      return 'Fabricação de medicamentos homeopáticos para uso humano';
    case 2121103:
      return 'Fabricação de medicamentos fitoterápicos para uso humano';
    case 2122000:
      return 'Fabricação de medicamentos para uso veterinário';
    case 2123800:
      return 'Fabricação de preparações farmacêuticas';
    case 2211100:
      return 'Fabricação de pneumáticos e de câmaras-de-ar';
    case 2212900:
      return 'Reforma de pneumáticos usados';
    case 2219600:
      return 'Fabricação de artefatos de borracha não especificados anteriormente';
    case 2221800:
      return 'Fabricação de laminados planos e tubulares de material plástico';
    case 2222600:
      return 'Fabricação de embalagens de material plástico';
    case 2223400:
      return 'Fabricação de tubos e acessórios de material plástico para uso na construção';
    case 2229301:
      return 'Fabricação de artefatos de material plástico para uso pessoal e doméstico';
    case 2229302:
      return 'Fabricação de artefatos de material plástico para usos industriais';
    case 2229303:
      return 'Fabricação de artefatos de material plástico para uso na construção, exceto tubos e acessórios';
    case 2229399:
      return 'Fabricação de artefatos de material plástico para outros usos não especificados anteriormente';
    case 2311700:
      return 'Fabricação de vidro plano e de segurança';
    case 2312500:
      return 'Fabricação de embalagens de vidro';
    case 2319200:
      return 'Fabricação de artigos de vidro';
    case 2320600:
      return 'Fabricação de cimento';
    case 2330301:
      return 'Fabricação de estruturas pré-moldadas de concreto armado, em série e sob encomenda';
    case 2330302:
      return 'Fabricação de artefatos de cimento para uso na construção';
    case 2330303:
      return 'Fabricação de artefatos de fibrocimento para uso na construção';
    case 2330304:
      return 'Fabricação de casas pré-moldadas de concreto';
    case 2330305:
      return 'Preparação de massa de concreto e argamassa para construção';
    case 2330399:
      return 'Fabricação de outros artefatos e produtos de concreto, cimento, fibrocimento, gesso e materiais semelhantes';
    case 2341900:
      return 'Fabricação de produtos cerâmicos refratários';
    case 2342701:
      return 'Fabricação de azulejos e pisos';
    case 2342702:
      return 'Fabricação de artefatos de cerâmica e barro cozido para uso na construção, exceto azulejos e pisos';
    case 2349401:
      return 'Fabricação de material sanitário de cerâmica';
    case 2349499:
      return 'Fabricação de produtos cerâmicos não-refratários não especificados anteriormente';
    case 2391501:
      return 'Britamento de pedras, exceto associado à extração';
    case 2391502:
      return 'Aparelhamento de pedras para construção, exceto associado à extração';
    case 2391503:
      return 'Aparelhamento de placas e execução de trabalhos em mármore, granito, ardósia e outras pedras';
    case 2392300:
      return 'Fabricação de cal e gesso';
    case 2399101:
      return 'Decoração, lapidação, gravação, vitrificação e outros trabalhos em cerâmica, louça, vidro e cristal';
    case 2399102:
      return 'Fabricação de abrasivos';
    case 2399199:
      return 'Fabricação de outros produtos de minerais não-metálicos não especificados anteriormente';
    case 2411300:
      return 'Produção de ferro-gusa';
    case 2412100:
      return 'Produção de ferroligas';
    case 2421100:
      return 'Produção de semi-acabados de aço';
    case 2422901:
      return 'Produção de laminados planos de aço ao carbono, revestidos ou não';
    case 2422902:
      return 'Produção de laminados planos de aços especiais';
    case 2423701:
      return 'Produção de tubos de aço sem costura';
    case 2423702:
      return 'Produção de laminados longos de aço, exceto tubos';
    case 2424501:
      return 'Produção de arames de aço';
    case 2424502:
      return 'Produção de relaminados, trefilados e perfilados de aço, exceto arames';
    case 2431800:
      return 'Produção de tubos de aço com costura';
    case 2439300:
      return 'Produção de outros tubos de ferro e aço';
    case 2441501:
      return 'Produção de alumínio e suas ligas em formas primárias';
    case 2441502:
      return 'Produção de laminados de alumínio';
    case 2442300:
      return 'Metalurgia dos metais preciosos';
    case 2443100:
      return 'Metalurgia do cobre';
    case 2449101:
      return 'Produção de zinco em formas primárias';
    case 2449102:
      return 'Produção de laminados de zinco';
    case 2449103:
      return 'Fabricação de ânodos para galvanoplastia';
    case 2449199:
      return 'Metalurgia de outros metais não-ferrosos e suas ligas não especificados anteriormente';
    case 2451200:
      return 'Fundição de ferro e aço';
    case 2452100:
      return 'Fundição de metais não-ferrosos e suas ligas';
    case 2511000:
      return 'Fabricação de estruturas metálicas';
    case 2512800:
      return 'Fabricação de esquadrias de metal';
    case 2513600:
      return 'Fabricação de obras de caldeiraria pesada';
    case 2521700:
      return 'Fabricação de tanques, reservatórios metálicos e caldeiras para aquecimento central';
    case 2522500:
      return 'Fabricação de caldeiras geradoras de vapor, exceto para aquecimento central e para veículos';
    case 2531401:
      return 'Produção de forjados de aço';
    case 2531402:
      return 'Produção de forjados de metais não-ferrosos e suas ligas';
    case 2532201:
      return 'Produção de artefatos estampados de metal';
    case 2532202:
      return 'Metalurgia do pó';
    case 2539001:
      return 'Serviços de usinagem, tornearia e solda';
    case 2539002:
      return 'Serviços de tratamento e revestimento em metais';
    case 2541100:
      return 'Fabricação de artigos de cutelaria';
    case 2542000:
      return 'Fabricação de artigos de serralheria, exceto esquadrias';
    case 2543800:
      return 'Fabricação de ferramentas';
    case 2550101:
      return 'Fabricação de equipamento bélico pesado, exceto veículos militares de combate';
    case 2550102:
      return 'Fabricação de armas de fogo, outras armas e munições';
    case 2591800:
      return 'Fabricação de embalagens metálicas';
    case 2592601:
      return 'Fabricação de produtos de trefilados de metal padronizados';
    case 2592602:
      return 'Fabricação de produtos de trefilados de metal, exceto padronizados';
    case 2593400:
      return 'Fabricação de artigos de metal para uso doméstico e pessoal';
    case 2599301:
      return 'Serviços de confecção de armações metálicas para a construção';
    case 2599302:
      return 'Serviços de corte e dobra de metais';
    case 2599399:
      return 'Fabricação de outros produtos de metal não especificados anteriormente';
    case 2610800:
      return 'Fabricação de componentes eletrônicos';
    case 2621300:
      return 'Fabricação de equipamentos de informática';
    case 2622100:
      return 'Fabricação de periféricos para equipamentos de informática';
    case 2631100:
      return 'Fabricação de equipamentos transmissores de comunicação, peças e acessórios';
    case 2632900:
      return 'Fabricação de aparelhos telefônicos e de outros equipamentos de comunicação, peças e acessórios';
    case 2640000:
      return 'Fabricação de aparelhos de recepção, reprodução, gravação e amplificação de áudio e vídeo';
    case 2651500:
      return 'Fabricação de aparelhos e equipamentos de medida, teste e controle';
    case 2652300:
      return 'Fabricação de cronômetros e relógios';
    case 2660400:
      return 'Fabricação de aparelhos eletromédicos e eletroterapêuticos e equipamentos de irradiação';
    case 2670101:
      return 'Fabricação de equipamentos e instrumentos ópticos, peças e acessórios';
    case 2670102:
      return 'Fabricação de aparelhos fotográficos e cinematográficos, peças e acessórios';
    case 2680900:
      return 'Fabricação de mídias virgens, magnéticas e ópticas';
    case 2710401:
      return 'Fabricação de geradores de corrente contínua e alternada, peças e acessórios';
    case 2710402:
      return 'Fabricação de transformadores, indutores, conversores, sincronizadores e semelhantes, peças e acessórios';
    case 2710403:
      return 'Fabricação de motores elétricos, peças e acessórios';
    case 2721000:
      return 'Fabricação de pilhas, baterias e acumuladores elétricos, exceto para veículos automotores';
    case 2722801:
      return 'Fabricação de baterias e acumuladores para veículos automotores';
    case 2722802:
      return 'Recondicionamento de baterias e acumuladores para veículos automotores';
    case 2731700:
      return 'Fabricação de aparelhos e equipamentos para distribuição e controle de energia elétrica';
    case 2732500:
      return 'Fabricação de material elétrico para instalações em circuito de consumo';
    case 2733300:
      return 'Fabricação de fios, cabos e condutores elétricos isolados';
    case 2740601:
      return 'Fabricação de lâmpadas';
    case 2740602:
      return 'Fabricação de luminárias e outros equipamentos de iluminação';
    case 2751100:
      return 'Fabricação de fogões, refrigeradores e máquinas de lavar e secar para uso doméstico, peças e acessórios';
    case 2759701:
      return 'Fabricação de aparelhos elétricos de uso pessoal, peças e acessórios';
    case 2759799:
      return 'Fabricação de outros aparelhos eletrodomésticos não especificados anteriormente, peças e acessórios';
    case 2790201:
      return 'Fabricação de eletrodos, contatos e outros artigos de carvão e grafita para uso elétrico, eletroímãs e isoladores';
    case 2790202:
      return 'Fabricação de equipamentos para sinalização e alarme';
    case 2790299:
      return 'Fabricação de outros equipamentos e aparelhos elétricos não especificados anteriormente';
    case 2811900:
      return 'Fabricação de motores e turbinas, peças e acessórios, exceto para aviões e veículos rodoviários';
    case 2812700:
      return 'Fabricação de equipamentos hidráulicos e pneumáticos, peças e acessórios, exceto válvulas';
    case 2813500:
      return 'Fabricação de válvulas, registros e dispositivos semelhantes, peças e acessórios';
    case 2814301:
      return 'Fabricação de compressores para uso industrial, peças e acessórios';
    case 2814302:
      return 'Fabricação de compressores para uso não-industrial, peças e acessórios';
    case 2815101:
      return 'Fabricação de rolamentos para fins industriais';
    case 2815102:
      return 'Fabricação de equipamentos de transmissão para fins industriais, exceto rolamentos';
    case 2821601:
      return 'Fabricação de fornos industriais, aparelhos e equipamentos não-elétricos para instalações térmicas, peças e acessórios';
    case 2821602:
      return 'Fabricação de estufas e fornos elétricos para fins industriais, peças e acessórios';
    case 2822401:
      return 'Fabricação de máquinas, equipamentos e aparelhos para transporte e elevação de pessoas, peças e acessórios';
    case 2822402:
      return 'Fabricação de máquinas, equipamentos e aparelhos para transporte e elevação de cargas, peças e acessórios';
    case 2823200:
      return 'Fabricação de máquinas e aparelhos de refrigeração e ventilação para uso industrial e comercial, peças e acessórios';
    case 2824101:
      return 'Fabricação de aparelhos e equipamentos de ar condicionado para uso industrial';
    case 2824102:
      return 'Fabricação de aparelhos e equipamentos de ar condicionado para uso não-industrial';
    case 2825900:
      return 'Fabricação de máquinas e equipamentos para saneamento básico e ambiental, peças e acessórios';
    case 2829101:
      return 'Fabricação de máquinas de escrever, calcular e outros equipamentos não-eletrônicos para escritório, peças e acessórios';
    case 2829199:
      return 'Fabricação de outras máquinas e equipamentos de uso geral não especificados anteriormente, peças e acessórios';
    case 2831300:
      return 'Fabricação de tratores agrícolas, peças e acessórios';
    case 2832100:
      return 'Fabricação de equipamentos para irrigação agrícola, peças e acessórios';
    case 2833000:
      return 'Fabricação de máquinas e equipamentos para a agricultura e pecuária, peças e acessórios, exceto para irrigação';
    case 2840200:
      return 'Fabricação de máquinas-ferramenta, peças e acessórios';
    case 2851800:
      return 'Fabricação de máquinas e equipamentos para a prospecção e extração de petróleo, peças e acessórios';
    case 2852600:
      return 'Fabricação de outras máquinas e equipamentos para uso na extração mineral, peças e acessórios, exceto na extração de petróleo';
    case 2853400:
      return 'Fabricação de tratores, peças e acessórios, exceto agrícolas';
    case 2854200:
      return 'Fabricação de máquinas e equipamentos para terraplenagem, pavimentação e construção, peças e acessórios, exceto tratores';
    case 2861500:
      return 'Fabricação de máquinas para a indústria metalúrgica, peças e acessórios, exceto máquinas-ferramenta';
    case 2862300:
      return 'Fabricação de máquinas e equipamentos para as indústrias de alimentos, bebidas e fumo, peças e acessórios';
    case 2863100:
      return 'Fabricação de máquinas e equipamentos para a indústria têxtil, peças e acessórios';
    case 2864000:
      return 'Fabricação de máquinas e equipamentos para as indústrias do vestuário, do couro e de calçados, peças e acessórios';
    case 2865800:
      return 'Fabricação de máquinas e equipamentos para as indústrias de celulose, papel e papelão e artefatos, peças e acessórios';
    case 2866600:
      return 'Fabricação de máquinas e equipamentos para a indústria do plástico, peças e acessórios';
    case 2869100:
      return 'Fabricação de máquinas e equipamentos para uso industrial específico não especificados anteriormente, peças e acessórios';
    case 2910701:
      return 'Fabricação de automóveis, camionetas e utilitários';
    case 2910702:
      return 'Fabricação de chassis com motor para automóveis, camionetas e utilitários';
    case 2910703:
      return 'Fabricação de motores para automóveis, camionetas e utilitários';
    case 2920401:
      return 'Fabricação de caminhões e ônibus';
    case 2920402:
      return 'Fabricação de motores para caminhões e ônibus';
    case 2930101:
      return 'Fabricação de cabines, carrocerias e reboques para caminhões';
    case 2930102:
      return 'Fabricação de carrocerias para ônibus';
    case 2930103:
      return 'Fabricação de cabines, carrocerias e reboques para outros veículos automotores, exceto caminhões e ônibus';
    case 2941700:
      return 'Fabricação de peças e acessórios para o sistema motor de veículos automotores';
    case 2942500:
      return 'Fabricação de peças e acessórios para os sistemas de marcha e transmissão de veículos automotores';
    case 2943300:
      return 'Fabricação de peças e acessórios para o sistema de freios de veículos automotores';
    case 2944100:
      return 'Fabricação de peças e acessórios para o sistema de direção e suspensão de veículos automotores';
    case 2945000:
      return 'Fabricação de material elétrico e eletrônico para veículos automotores, exceto baterias';
    case 2949201:
      return 'Fabricação de bancos e estofados para veículos automotores';
    case 2949299:
      return 'Fabricação de outras peças e acessórios para veículos automotores não especificadas anteriormente';
    case 2950600:
      return 'Recondicionamento e recuperação de motores para veículos automotores';
    case 3011301:
      return 'Construção de embarcações de grande porte';
    case 3011302:
      return 'Construção de embarcações para uso comercial e para usos especiais, exceto de grande porte';
    case 3012100:
      return 'Construção de embarcações para esporte e lazer';
    case 3031800:
      return 'Fabricação de locomotivas, vagões e outros materiais rodantes';
    case 3032600:
      return 'Fabricação de peças e acessórios para veículos ferroviários';
    case 3041500:
      return 'Fabricação de aeronaves';
    case 3042300:
      return 'Fabricação de turbinas, motores e outros componentes e peças para aeronaves';
    case 3050400:
      return 'Fabricação de veículos militares de combate';
    case 3091101:
      return 'Fabricação de motocicletas';
    case 3091102:
      return 'Fabricação de peças e acessórios para motocicletas';
    case 3092000:
      return 'Fabricação de bicicletas e triciclos não-motorizados, peças e acessórios';
    case 3099700:
      return 'Fabricação de equipamentos de transporte não especificados anteriormente';
    case 3101200:
      return 'Fabricação de móveis com predominância de madeira';
    case 3102100:
      return 'Fabricação de móveis com predominância de metal';
    case 3103900:
      return 'Fabricação de móveis de outros materiais, exceto madeira e metal';
    case 3104700:
      return 'Fabricação de colchões';
    case 3211601:
      return 'Lapidação de gemas';
    case 3211602:
      return 'Fabricação de artefatos de joalheria e ourivesaria';
    case 3211603:
      return 'Cunhagem de moedas e medalhas';
    case 3212400:
      return 'Fabricação de bijuterias e artefatos semelhantes';
    case 3220500:
      return 'Fabricação de instrumentos musicais, peças e acessórios';
    case 3230200:
      return 'Fabricação de artefatos para pesca e esporte';
    case 3240001:
      return 'Fabricação de jogos eletrônicos';
    case 3240002:
      return 'Fabricação de mesas de bilhar, de sinuca e acessórios não associada à locação';
    case 3240003:
      return 'Fabricação de mesas de bilhar, de sinuca e acessórios associada à locação';
    case 3240099:
      return 'Fabricação de outros brinquedos e jogos recreativos não especificados anteriormente';
    case 3250701:
      return 'Fabricação de instrumentos não-eletrônicos e utensílios para uso médico, cirúrgico, odontológico e de laboratório';
    case 3250702:
      return 'Fabricação de mobiliário para uso médico, cirúrgico, odontológico e de laboratório';
    case 3250703:
      return 'Fabricação de aparelhos e utensílios para correção de defeitos físicos e aparelhos ortopédicos em geral sob encomenda';
    case 3250704:
      return 'Fabricação de aparelhos e utensílios para correção de defeitos físicos e aparelhos ortopédicos em geral, exceto sob encomenda';
    case 3250705:
      return 'Fabricação de materiais para medicina e odontologia';
    case 3250706:
      return 'Serviços de prótese dentária';
    case 3250707:
      return 'Fabricação de artigos ópticos';
    case 3250709:
      return 'Serviço de laboratório óptico';
    case 3291400:
      return 'Fabricação de escovas, pincéis e vassouras';
    case 3292201:
      return 'Fabricação de roupas de proteção e segurança e resistentes a fogo';
    case 3292202:
      return 'Fabricação de equipamentos e acessórios para segurança pessoal e profissional';
    case 3299001:
      return 'Fabricação de guarda-chuvas e similares';
    case 3299002:
      return 'Fabricação de canetas, lápis e outros artigos para escritório';
    case 3299003:
      return 'Fabricação de letras, letreiros e placas de qualquer material, exceto luminosos';
    case 3299004:
      return 'Fabricação de painéis e letreiros luminosos';
    case 3299005:
      return 'Fabricação de aviamentos para costura';
    case 3299006:
      return 'Fabricação de velas, inclusive decorativas';
    case 3299099:
      return 'Fabricação de produtos diversos não especificados anteriormente';
    case 3311200:
      return 'Manutenção e reparação de tanques, reservatórios metálicos e caldeiras, exceto para veículos';
    case 3312102:
      return 'Manutenção e reparação de aparelhos e instrumentos de medida, teste e controle';
    case 3312103:
      return 'Manutenção e reparação de aparelhos eletromédicos e eletroterapêuticos e equipamentos de irradiação';
    case 3312104:
      return 'Manutenção e reparação de equipamentos e instrumentos ópticos';
    case 3313901:
      return 'Manutenção e reparação de geradores, transformadores e motores elétricos';
    case 3313902:
      return 'Manutenção e reparação de baterias e acumuladores elétricos, exceto para veículos';
    case 3313999:
      return 'Manutenção e reparação de máquinas, aparelhos e materiais elétricos não especificados anteriormente';
    case 3314701:
      return 'Manutenção e reparação de máquinas motrizes não-elétricas';
    case 3314702:
      return 'Manutenção e reparação de equipamentos hidráulicos e pneumáticos, exceto válvulas';
    case 3314703:
      return 'Manutenção e reparação de válvulas industriais';
    case 3314704:
      return 'Manutenção e reparação de compressores';
    case 3314705:
      return 'Manutenção e reparação de equipamentos de transmissão para fins industriais';
    case 3314706:
      return 'Manutenção e reparação de máquinas, aparelhos e equipamentos para instalações térmicas';
    case 3314707:
      return 'Manutenção e reparação de máquinas e aparelhos de refrigeração e ventilação para uso industrial e comercial';
    case 3314708:
      return 'Manutenção e reparação de máquinas, equipamentos e aparelhos para transporte e elevação de cargas';
    case 3314709:
      return 'Manutenção e reparação de máquinas de escrever, calcular e de outros equipamentos não-eletrônicos para escritório';
    case 3314710:
      return 'Manutenção e reparação de máquinas e equipamentos para uso geral não especificados anteriormente';
    case 3314711:
      return 'Manutenção e reparação de máquinas e equipamentos para agricultura e pecuária';
    case 3314712:
      return 'Manutenção e reparação de tratores agrícolas';
    case 3314713:
      return 'Manutenção e reparação de máquinas-ferramenta';
    case 3314714:
      return 'Manutenção e reparação de máquinas e equipamentos para a prospecção e extração de petróleo';
    case 3314715:
      return 'Manutenção e reparação de máquinas e equipamentos para uso na extração mineral, exceto na extração de petróleo';
    case 3314716:
      return 'Manutenção e reparação de tratores, exceto agrícolas';
    case 3314717:
      return 'Manutenção e reparação de máquinas e equipamentos de terraplenagem, pavimentação e construção, exceto tratores';
    case 3314718:
      return 'Manutenção e reparação de máquinas para a indústria metalúrgica, exceto máquinas-ferramenta';
    case 3314719:
      return 'Manutenção e reparação de máquinas e equipamentos para as indústrias de alimentos, bebidas e fumo';
    case 3314720:
      return 'Manutenção e reparação de máquinas e equipamentos para a indústria têxtil, do vestuário, do couro e calçados';
    case 3314721:
      return 'Manutenção e reparação de máquinas e aparelhos para a indústria de celulose, papel e papelão e artefatos';
    case 3314722:
      return 'Manutenção e reparação de máquinas e aparelhos para a indústria do plástico';
    case 3314799:
      return 'Manutenção e reparação de outras máquinas e equipamentos para usos industriais não especificados anteriormente';
    case 3315500:
      return 'Manutenção e reparação de veículos ferroviários';
    case 3316301:
      return 'Manutenção e reparação de aeronaves, exceto a manutenção na pista';
    case 3316302:
      return 'Manutenção de aeronaves na pista';
    case 3317101:
      return 'Manutenção e reparação de embarcações e estruturas flutuantes';
    case 3317102:
      return 'Manutenção e reparação de embarcações para esporte e lazer';
    case 3319800:
      return 'Manutenção e reparação de equipamentos e produtos não especificados anteriormente';
    case 3321000:
      return 'Instalação de máquinas e equipamentos industriais';
    case 3329501:
      return 'Serviços de montagem de móveis de qualquer material';
    case 3329599:
      return 'Instalação de outros equipamentos não especificados anteriormente';
    case 3511501:
      return 'Geração de energia elétrica';
    case 3511502:
      return 'Atividades de coordenação e controle da operação de geração e transmissão de energia elétrica';
    case 3512300:
      return 'Transmissão de energia elétrica';
    case 3513100:
      return 'Comércio atacadista de energia elétrica';
    case 3514000:
      return 'Distribuição de energia elétrica';
    case 3520401:
      return 'Produção de gás; processamento de gás natural';
    case 3520402:
      return 'Distribuição de combustíveis gasosos por redes urbanas';
    case 3530100:
      return 'Produção e distribuição de vapor, água quente e ar condicionado';
    case 3600601:
      return 'Captação, tratamento e distribuição de água';
    case 3600602:
      return 'Distribuição de água por caminhões';
    case 3701100:
      return 'Gestão de redes de esgoto';
    case 3702900:
      return 'Atividades relacionadas a esgoto, exceto a gestão de redes';
    case 3811400:
      return 'Coleta de resíduos não-perigosos';
    case 3812200:
      return 'Coleta de resíduos perigosos';
    case 3821100:
      return 'Tratamento e disposição de resíduos não-perigosos';
    case 3822000:
      return 'Tratamento e disposição de resíduos perigosos';
    case 3831901:
      return 'Recuperação de sucatas de alumínio';
    case 3831999:
      return 'Recuperação de materiais metálicos, exceto alumínio';
    case 3832700:
      return 'Recuperação de materiais plásticos';
    case 3839401:
      return 'Usinas de compostagem';
    case 3839499:
      return 'Recuperação de materiais não especificados anteriormente';
    case 3900500:
      return 'Descontaminação e outros serviços de gestão de resíduos';
    case 4110700:
      return 'Incorporação de empreendimentos imobiliários';
    case 4120400:
      return 'Construção de edifícios';
    case 4211101:
      return 'Construção de rodovias e ferrovias';
    case 4211102:
      return 'Pintura para sinalização em pistas rodoviárias e aeroportos';
    case 4212000:
      return 'Construção de obras-de-arte especiais';
    case 4213800:
      return 'Obras de urbanização - ruas, praças e calçadas';
    case 4221901:
      return 'Construção de barragens e represas para geração de energia elétrica';
    case 4221902:
      return 'Construção de estações e redes de distribuição de energia elétrica';
    case 4221903:
      return 'Manutenção de redes de distribuição de energia elétrica';
    case 4221904:
      return 'Construção de estações e redes de telecomunicações';
    case 4221905:
      return 'Manutenção de estações e redes de telecomunicações';
    case 4222701:
      return 'Construção de redes de abastecimento de água, coleta de esgoto e construções correlatas, exceto obras de irrigação';
    case 4222702:
      return 'Obras de irrigação';
    case 4223500:
      return 'Construção de redes de transportes por dutos, exceto para água e esgoto';
    case 4291000:
      return 'Obras portuárias, marítimas e fluviais';
    case 4292801:
      return 'Montagem de estruturas metálicas';
    case 4292802:
      return 'Obras de montagem industrial';
    case 4299501:
      return 'Construção de instalações esportivas e recreativas';
    case 4299599:
      return 'Outras obras de engenharia civil não especificadas anteriormente';
    case 4311801:
      return 'Demolição de edifícios e outras estruturas';
    case 4311802:
      return 'Demolição de edifícios e outras estruturas';
    case 4312600:
      return 'Perfurações e sondagens';
    case 4313400:
      return 'Obras de terraplenagem';
    case 4319300:
      return 'Serviços de preparação do terreno não especificados anteriormente';
    case 4321500:
      return 'Instalação e manutenção elétrica';
    case 4322301:
      return 'Instalações hidráulicas, sanitárias e de gás';
    case 4322302:
      return 'Instalação e manutenção de sistemas centrais de ar condicionado, de ventilação e refrigeração';
    case 4322303:
      return 'Instalações de sistema de prevenção contra incêndio';
    case 4329101:
      return 'Instalação de painéis publicitários';
    case 4329102:
      return 'Instalação de equipamentos para orientação à navegação marítima, fluvial e lacustre';
    case 4329103:
      return 'Instalação, manutenção e reparação de elevadores, escadas e esteiras rolantes';
    case 4329104:
      return 'Montagem e instalação de sistemas e equipamentos de iluminação e sinalização em vias públicas, portos e aeroportos';
    case 4329105:
      return 'Tratamentos térmicos, acústicos ou de vibração';
    case 4329199:
      return 'Outras obras de instalações em construções não especificadas anteriormente';
    case 4330401:
      return 'Impermeabilização em obras de engenharia civil';
    case 4330402:
      return 'Instalação de portas, janelas, tetos, divisórias e armários embutidos de qualquer material';
    case 4330403:
      return 'Obras de acabamento em gesso e estuque';
    case 4330404:
      return 'Serviços de pintura de edifícios em geral';
    case 4330405:
      return 'Aplicação de revestimentos e de resinas em interiores e exteriores';
    case 4330499:
      return 'Outras obras de acabamento da construção';
    case 4391600:
      return 'Obras de fundações';
    case 4399101:
      return 'Administração de obras';
    case 4399102:
      return 'Montagem e desmontagem de andaimes e outras estruturas temporárias';
    case 4399103:
      return 'Obras de alvenaria';
    case 4399104:
      return 'Serviços de operação e fornecimento de equipamentos para transporte e elevação de cargas e pessoas para uso em obras';
    case 4399105:
      return 'Perfuração e construção de poços de água';
    case 4399199:
      return 'Serviços especializados para construção não especificados anteriormente';
    case 4511101:
      return 'Comércio a varejo de automóveis, camionetas e utilitários novos';
    case 4511102:
      return 'Comércio a varejo de automóveis, camionetas e utilitários usados';
    case 4511103:
      return 'Comércio por atacado de automóveis, camionetas e utilitários novos e usados';
    case 4511104:
      return 'Comércio por atacado de caminhões novos e usados';
    case 4511105:
      return 'Comércio por atacado de reboques e semi-reboques novos e usados';
    case 4511106:
      return 'Comércio por atacado de ônibus e microônibus novos e usados';
    case 4512901:
      return 'Representantes comerciais e agentes do comércio de veículos automotores';
    case 4512902:
      return 'Representantes comerciais e agentes do comércio de veículos automotores';
    case 4520001:
      return 'Serviços de manutenção e reparação mecânica de veículos automotores';
    case 4520002:
      return 'Serviços de lanternagem ou funilaria e pintura de veículos automotores';
    case 4520003:
      return 'Serviços de manutenção e reparação elétrica de veículos automotores';
    case 4520004:
      return 'Serviços de alinhamento e balanceamento de veículos automotores';
    case 4520005:
      return 'Serviços de lavagem, lubrificação e polimento de veículos automotores';
    case 4520006:
      return 'Serviços de borracharia para veículos automotores';
    case 4520007:
      return 'Serviços de instalação, manutenção e reparação de acessórios para veículos automotores';
    case 4520008:
      return 'Serviços de capotaria';
    case 4530701:
      return 'Comércio por atacado de peças e acessórios novos para veículos automotores';
    case 4530702:
      return 'Comércio por atacado de pneumáticos e câmaras-de-ar';
    case 4530703:
      return 'Comércio a varejo de peças e acessórios novos para veículos automotores';
    case 4530704:
      return 'Comércio a varejo de peças e acessórios usados para veículos automotores';
    case 4530705:
      return 'Comércio a varejo de pneumáticos e câmaras-de-ar';
    case 4530706:
      return 'Representantes comerciais e agentes do comércio de peças e acessórios novos e usados para veículos automotores';
    case 4541201:
      return 'Comércio por atacado de motocicletas e motonetas';
    case 4541202:
      return 'Comércio por atacado de peças e acessórios para motocicletas e motonetas';
    case 4541203:
      return 'Comércio a varejo de motocicletas e motonetas novas';
    case 4541204:
      return 'Comércio a varejo de motocicletas e motonetas usadas';
    case 4541206:
      return 'Comércio a varejo de peças e acessórios novos para motocicletas e motonetas';
    case 4541207:
      return 'Comércio a varejo de peças e acessórios usados para motocicletas e motonetas';
    case 4542101:
      return 'Representantes comerciais e agentes do comércio de motocicletas e motonetas, peças e acessórios';
    case 4542102:
      return 'Comércio sob consignação de motocicletas e motonetas';
    case 4543900:
      return 'Manutenção e reparação de motocicletas e motonetas';
    case 4611700:
      return 'Representantes comerciais e agentes do comércio de matérias-primas agrícolas e animais vivos';
    case 4612500:
      return 'Representantes comerciais e agentes do comércio de combustíveis, minerais, produtos siderúrgicos e químicos';
    case 4613300:
      return 'Representantes comerciais e agentes do comércio de madeira, material de construção e ferragens';
    case 4614100:
      return 'Representantes comerciais e agentes do comércio de máquinas, equipamentos, embarcações e aeronaves';
    case 4615000:
      return 'Representantes comerciais e agentes do comércio de eletrodomésticos, móveis e artigos de uso doméstico';
    case 4616800:
      return 'Representantes comerciais e agentes do comércio de têxteis, vestuário, calçados e artigos de viagem';
    case 4617600:
      return 'Representantes comerciais e agentes do comércio de produtos alimentícios, bebidas e fumo';
    case 4618401:
      return 'Representantes comerciais e agentes do comércio de medicamentos, cosméticos e produtos de perfumaria';
    case 4618402:
      return 'Representantes comerciais e agentes do comércio de instrumentos e materiais odonto-médico-hospitalares';
    case 4618403:
      return 'Representantes comerciais e agentes do comércio de jornais, revistas e outras publicações';
    case 4618499:
      return 'Outros representantes comerciais e agentes do comércio especializado em produtos não especificados anteriormente';
    case 4619200:
      return 'Representantes comerciais e agentes do comércio de mercadorias em geral não especializado';
    case 4621400:
      return 'Comércio atacadista de café em grão';
    case 4622200:
      return 'Comércio atacadista de soja';
    case 4623101:
      return 'Comércio atacadista de animais vivos';
    case 4623102:
      return 'Comércio atacadista de couros, lãs, peles e outros subprodutos não-comestíveis de origem animal';
    case 4623103:
      return 'Comércio atacadista de algodão';
    case 4623104:
      return 'Comércio atacadista de fumo em folha não beneficiado';
    case 4623105:
      return 'Comércio atacadista de cacau';
    case 4623106:
      return 'Comércio atacadista de sementes, flores, plantas e gramas';
    case 4623107:
      return 'Comércio atacadista de sisal';
    case 4623108:
      return 'Comércio atacadista de matérias-primas agrícolas com atividade de fracionamento e acondicionamento associada';
    case 4623109:
      return 'Comércio atacadista de alimentos para animais';
    case 4623199:
      return 'Comércio atacadista de matérias-primas agrícolas não especificadas anteriormente';
    case 4631100:
      return 'Comércio atacadista de leite e laticínios';
    case 4632001:
      return 'Comércio atacadista de cereais e leguminosas beneficiados';
    case 4632002:
      return 'Comércio atacadista de farinhas, amidos e féculas';
    case 4632003:
      return 'Comércio atacadista de cereais e leguminosas beneficiados, farinhas, amidos e féculas, com atividade de fracionamento e acondicionamento associada';
    case 4633801:
      return 'Comércio atacadista de frutas, verduras, raízes, tubérculos, hortaliças e legumes frescos';
    case 4633802:
      return 'Comércio atacadista de aves vivas e ovos';
    case 4633803:
      return 'Comércio atacadista de coelhos e outros pequenos animais vivos para alimentação';
    case 4634601:
      return 'Comércio atacadista de carnes bovinas e suínas e derivados';
    case 4634602:
      return 'Comércio atacadista de aves abatidas e derivados';
    case 4634603:
      return 'Comércio atacadista de pescados e frutos do mar';
    case 4634699:
      return 'Comércio atacadista de carnes e derivados de outros animais';
    case 4635401:
      return 'Comércio atacadista de água mineral';
    case 4635402:
      return 'Comércio atacadista de cerveja, chope e refrigerante';
    case 4635403:
      return 'Comércio atacadista de bebidas com atividade de fracionamento e acondicionamento associada';
    case 4635499:
      return 'Comércio atacadista de bebidas não especificadas anteriormente';
    case 4636201:
      return 'Comércio atacadista de fumo beneficiado';
    case 4636202:
      return 'Comércio atacadista de cigarros, cigarrilhas e charutos';
    case 4637101:
      return 'Comércio atacadista de café torrado, moído e solúvel';
    case 4637102:
      return 'Comércio atacadista de açúcar';
    case 4637103:
      return 'Comércio atacadista de óleos e gorduras';
    case 4637104:
      return 'Comércio atacadista de pães, bolos, biscoitos e similares';
    case 4637105:
      return 'Comércio atacadista de massas alimentícias';
    case 4637106:
      return 'Comércio atacadista de sorvetes';
    case 4637107:
      return 'Comércio atacadista de chocolates, confeitos, balas, bombons e semelhantes';
    case 4637199:
      return 'Comércio atacadista especializado em outros produtos alimentícios não especificados anteriormente';
    case 4639701:
      return 'Comércio atacadista de produtos alimentícios em geral';
    case 4639702:
      return 'Comércio atacadista de produtos alimentícios em geral, com atividade de fracionamento e acondicionamento associada';
    case 4641901:
      return 'Comércio atacadista de tecidos';
    case 4641902:
      return 'Comércio atacadista de artigos de cama, mesa e banho';
    case 4641903:
      return 'Comércio atacadista de artigos de armarinho';
    case 4642701:
      return 'Comércio atacadista de artigos do vestuário e acessórios, exceto profissionais e de segurança';
    case 4642702:
      return 'Comércio atacadista de roupas e acessórios para uso profissional e de segurança do trabalho';
    case 4643501:
      return 'Comércio atacadista de calçados';
    case 4643502:
      return 'Comércio atacadista de bolsas, malas e artigos de viagem';
    case 4644301:
      return 'Comércio atacadista de medicamentos e drogas de uso humano';
    case 4644302:
      return 'Comércio atacadista de medicamentos e drogas de uso veterinário';
    case 4645101:
      return 'Comércio atacadista de instrumentos e materiais para uso médico, cirúrgico, hospitalar e de laboratórios';
    case 4645102:
      return 'Comércio atacadista de próteses e artigos de ortopedia';
    case 4645103:
      return 'Comércio atacadista de produtos odontológicos';
    case 4646001:
      return 'Comércio atacadista de cosméticos e produtos de perfumaria';
    case 4646002:
      return 'Comércio atacadista de produtos de higiene pessoal';
    case 4647801:
      return 'Comércio atacadista de artigos de escritório e de papelaria';
    case 4647802:
      return 'Comércio atacadista de livros, jornais e outras publicações';
    case 4649401:
      return 'Comércio atacadista de equipamentos elétricos de uso pessoal e doméstico';
    case 4649402:
      return 'Comércio atacadista de aparelhos eletrônicos de uso pessoal e doméstico';
    case 4649403:
      return 'Comércio atacadista de bicicletas, triciclos e outros veículos recreativos';
    case 4649404:
      return 'Comércio atacadista de móveis e artigos de colchoaria';
    case 4649405:
      return 'Comércio atacadista de artigos de tapeçaria; persianas e cortinas';
    case 4649406:
      return 'Comércio atacadista de lustres, luminárias e abajures';
    case 4649407:
      return 'Comércio atacadista de filmes, CDs, DVDs, fitas e discos';
    case 4649408:
      return 'Comércio atacadista de produtos de higiene, limpeza e conservação domiciliar';
    case 4649409:
      return 'Comércio atacadista de produtos de higiene, limpeza e conservação domiciliar, com atividade de fracionamento e acondicionamento associada';
    case 4649410:
      return 'Comércio atacadista de jóias, relógios e bijuterias, inclusive pedras preciosas e semipreciosas lapidadas';
    case 4649499:
      return 'Comércio atacadista de outros equipamentos e artigos de uso pessoal e doméstico não especificados anteriormente';
    case 4651601:
      return 'Comércio atacadista de equipamentos de informática';
    case 4651602:
      return 'Comércio atacadista de suprimentos para informática';
    case 4652400:
      return 'Comércio atacadista de componentes eletrônicos e equipamentos de telefonia e comunicação';
    case 4661300:
      return 'Comércio atacadista de máquinas, aparelhos e equipamentos para uso agropecuário; partes e peças';
    case 4662100:
      return 'Comércio atacadista de máquinas, equipamentos para terraplenagem, mineração e construção; partes e peças';
    case 4663000:
      return 'Comércio atacadista de máquinas e equipamentos para uso industrial; partes e peças';
    case 4664800:
      return 'Comércio atacadista de máquinas, aparelhos e equipamentos para uso odonto-médico-hospitalar; partes e peças';
    case 4665600:
      return 'Comércio atacadista de máquinas e equipamentos para uso comercial; partes e peças';
    case 4669901:
      return 'Comércio atacadista de bombas e compressores; partes e peças';
    case 4669999:
      return 'Comércio atacadista de outras máquinas e equipamentos não especificados anteriormente; partes e peças';
    case 4671100:
      return 'Comércio atacadista de madeira e produtos derivados';
    case 4672900:
      return 'Comércio atacadista de ferragens e ferramentas';
    case 4673700:
      return 'Comércio atacadista de material elétrico';
    case 4674500:
      return 'Comércio atacadista de cimento';
    case 4679601:
      return 'Comércio atacadista de tintas, vernizes e similares';
    case 4679602:
      return 'Comércio atacadista de mármores e granitos';
    case 4679603:
      return 'Comércio atacadista de vidros, espelhos e vitrais';
    case 4679604:
      return 'Comércio atacadista especializado de materiais de construção não especificados anteriormente';
    case 4679699:
      return 'Comércio atacadista de materiais de construção em geral';
    case 4681801:
      return 'Comércio atacadista de álcool carburante, biodiesel, gasolina e demais derivados de petróleo, exceto lubrificantes, não realizado por transportador retalhista (TRR)';
    case 4681802:
      return 'Comércio atacadista de combustíveis realizado por transportador retalhista (TRR)';
    case 4681803:
      return 'Comércio atacadista de combustíveis de origem vegetal, exceto álcool carburante';
    case 4681804:
      return 'Comércio atacadista de combustíveis de origem mineral em bruto';
    case 4681805:
      return 'Comércio atacadista de lubrificantes';
    case 4682600:
      return 'Comércio atacadista de gás liqüefeito de petróleo (GLP)';
    case 4683400:
      return 'Comércio atacadista de defensivos agrícolas, adubos, fertilizantes e corretivos do solo';
    case 4684201:
      return 'Comércio atacadista de resinas e elastômeros';
    case 4684202:
      return 'Comércio atacadista de solventes';
    case 4684299:
      return 'Comércio atacadista de outros produtos químicos e petroquímicos não especificados anteriormente';
    case 4685100:
      return 'Comércio atacadista de produtos siderúrgicos e metalúrgicos, exceto para construção';
    case 4686901:
      return 'Comércio atacadista de papel e papelão em bruto';
    case 4686902:
      return 'Comércio atacadista de embalagens';
    case 4687701:
      return 'Comércio atacadista de resíduos de papel e papelão';
    case 4687702:
      return 'Comércio atacadista de resíduos e sucatas não-metálicos, exceto de papel e papelão';
    case 4687703:
      return 'Comércio atacadista de resíduos e sucatas metálicos';
    case 4689301:
      return 'Comércio atacadista de produtos da extração mineral, exceto combustíveis';
    case 4689302:
      return 'Comércio atacadista de fios e fibras têxteis beneficiados';
    case 4689399:
      return 'Comércio atacadista especializado em outros produtos intermediários não especificados anteriormente';
    case 4691500:
      return 'Comércio atacadista de mercadorias em geral, com predominância de produtos alimentícios';
    case 4692300:
      return 'Comércio atacadista de mercadorias em geral, com predominância de insumos agropecuários';
    case 4693100:
      return 'Comércio atacadista de mercadorias em geral, sem predominância de alimentos ou de insumos agropecuários';
    case 4711301:
      return 'Comércio varejista de mercadorias em geral, com predominância de produtos alimentícios - hipermercados';
    case 4711302:
      return 'Comércio varejista de mercadorias em geral, com predominância de produtos alimentícios - supermercados';
    case 4712100:
      return 'Comércio varejista de mercadorias em geral, com predominância de produtos alimentícios - minimercados, mercearias e armazéns';
    case 4713002:
      return 'Lojas de variedades, exceto lojas de departamentos ou magazines';
    case 4713004:
      return 'Lojas de departamentos ou magazines, exceto lojas francas (Duty free)';
    case 4713005:
      return 'Lojas francas (Duty Free) de aeroportos, portos e em fronteiras terrestres';
    case 4721102:
      return 'Padaria e confeitaria com predominância de revenda';
    case 4721103:
      return 'Comércio varejista de laticínios e frios';
    case 4721104:
      return 'Comércio varejista de doces, balas, bombons e semelhantes';
    case 4722901:
      return 'Comércio varejista de carnes - açougues';
    case 4722902:
      return 'Peixaria';
    case 4723700:
      return 'Comércio varejista de bebidas';
    case 4724500:
      return 'Comércio varejista de hortifrutigranjeiros';
    case 4729601:
      return 'Tabacaria';
    case 4729602:
      return 'Comércio varejista de mercadorias em lojas de conveniência';
    case 4729699:
      return 'Comércio varejista de produtos alimentícios em geral ou especializado em produtos alimentícios não especificados anteriormente';
    case 4731800:
      return 'Comércio varejista de combustíveis para veículos automotores';
    case 4732600:
      return 'Comércio varejista de lubrificantes';
    case 4741500:
      return 'Comércio varejista de tintas e materiais para pintura';
    case 4742300:
      return 'Comércio varejista de material elétrico';
    case 4743100:
      return 'Comércio varejista de vidros';
    case 4744001:
      return 'Comércio varejista de ferragens e ferramentas';
    case 4744002:
      return 'Comércio varejista de madeira e artefatos';
    case 4744003:
      return 'Comércio varejista de materiais hidráulicos';
    case 4744004:
      return 'Comércio varejista de cal, areia, pedra britada, tijolos e telhas';
    case 4744005:
      return 'Comércio varejista de materiais de construção não especificados anteriormente';
    case 4744006:
      return 'Comércio varejista de pedras para revestimento';
    case 4744099:
      return 'Comércio varejista de materiais de construção em geral';
    case 4751201:
      return 'Comércio varejista especializado de equipamentos e suprimentos de informática';
    case 4751202:
      return 'Recarga de cartuchos para equipamentos de informática';
    case 4752100:
      return 'Comércio varejista especializado de equipamentos de telefonia e comunicação';
    case 4753900:
      return 'Comércio varejista especializado de eletrodomésticos e equipamentos de áudio e vídeo';
    case 4754701:
      return 'Comércio varejista de móveis';
    case 4754702:
      return 'Comércio varejista de artigos de colchoaria';
    case 4754703:
      return 'Comércio varejista de artigos de iluminação';
    case 4755501:
      return 'Comércio varejista de tecidos';
    case 4755502:
      return 'Comercio varejista de artigos de armarinho';
    case 4755503:
      return 'Comercio varejista de artigos de cama, mesa e banho';
    case 4756300:
      return 'Comércio varejista especializado de instrumentos musicais e acessórios';
    case 4757100:
      return 'Comércio varejista especializado de peças e acessórios para aparelhos eletroeletrônicos para uso doméstico, exceto informática e comunicação';
    case 4759801:
      return 'Comércio varejista de artigos de tapeçaria, cortinas e persianas';
    case 4759899:
      return 'Comércio varejista de outros artigos de uso doméstico não especificados anteriormente';
    case 4761001:
      return 'Comércio varejista de livros';
    case 4761002:
      return 'Comércio varejista de jornais e revistas';
    case 4761003:
      return 'Comércio varejista de artigos de papelaria';
    case 4762800:
      return 'Comércio varejista de discos, CDs, DVDs e fitas';
    case 4763601:
      return 'Comércio varejista de brinquedos e artigos recreativos';
    case 4763602:
      return 'Comércio varejista de artigos esportivos';
    case 4763603:
      return 'Comércio varejista de bicicletas e triciclos; peças e acessórios';
    case 4763604:
      return 'Comércio varejista de artigos de caça, pesca e camping';
    case 4763605:
      return 'Comércio varejista de embarcações e outros veículos recreativos; peças e acessórios';
    case 4771701:
      return 'Comércio varejista de produtos farmacêuticos, sem manipulação de fórmulas';
    case 4771702:
      return 'Comércio varejista de produtos farmacêuticos, com manipulação de fórmulas';
    case 4771703:
      return 'Comércio varejista de produtos farmacêuticos homeopáticos';
    case 4771704:
      return 'Comércio varejista de medicamentos veterinários';
    case 4772500:
      return 'Comércio varejista de cosméticos, produtos de perfumaria e de higiene pessoal';
    case 4773300:
      return 'Comércio varejista de artigos médicos e ortopédicos';
    case 4774100:
      return 'Comércio varejista de artigos de óptica';
    case 4781400:
      return 'Comércio varejista de artigos do vestuário e acessórios';
    case 4782201:
      return 'Comércio varejista de calçados';
    case 4782202:
      return 'Comércio varejista de artigos de viagem';
    case 4783101:
      return 'Comércio varejista de artigos de joalheria';
    case 4783102:
      return 'Comércio varejista de artigos de relojoaria';
    case 4784900:
      return 'Comércio varejista de gás liqüefeito de petróleo (GLP)';
    case 4785701:
      return 'Comércio varejista de antigüidades';
    case 4785799:
      return 'Comércio varejista de outros artigos usados';
    case 4789001:
      return 'Comércio varejista de suvenires, bijuterias e artesanatos';
    case 4789002:
      return 'Comércio varejista de plantas e flores naturais';
    case 4789003:
      return 'Comércio varejista de objetos de arte';
    case 4789004:
      return 'Comércio varejista de animais vivos e de artigos e alimentos para animais de estimação';
    case 4789005:
      return 'Comércio varejista de produtos saneantes domissanitários';
    case 4789006:
      return 'Comércio varejista de fogos de artifício e artigos pirotécnicos';
    case 4789007:
      return 'Comércio varejista de equipamentos para escritório';
    case 4789008:
      return 'Comércio varejista de artigos fotográficos e para filmagem';
    case 4789009:
      return 'Comércio varejista de armas e munições';
    case 4789099:
      return 'Comércio varejista de outros produtos não especificados anteriormente';
    case 4911600:
      return 'Transporte ferroviário de carga';
    case 4912401:
      return 'Transporte ferroviário de passageiros intermunicipal e interestadual';
    case 4912402:
      return 'Transporte ferroviário de passageiros municipal e em região metropolitana';
    case 4912403:
      return 'Transporte metroviário';
    case 4921301:
      return 'Transporte rodoviário coletivo de passageiros, com itinerário fixo, municipal';
    case 4921302:
      return 'Transporte rodoviário coletivo de passageiros, com itinerário fixo, intermunicipal em região metropolitana';
    case 4922101:
      return 'Transporte rodoviário coletivo de passageiros, com itinerário fixo, intermunicipal, exceto em região metropolitana';
    case 4922102:
      return 'Transporte rodoviário coletivo de passageiros, com itinerário fixo, interestadual';
    case 4922103:
      return 'Transporte rodoviário coletivo de passageiros, com itinerário fixo, internacional';
    case 4923001:
      return 'Serviço de táxi';
    case 4923002:
      return 'Serviço de transporte de passageiros - locação de automóveis com motorista';
    case 4924800:
      return 'Transporte escolar';
    case 4929901:
      return 'Transporte rodoviário coletivo de passageiros, sob regime de fretamento, municipal';
    case 4929902:
      return 'Transporte rodoviário coletivo de passageiros, sob regime de fretamento, intermunicipal, interestadual e internacional';
    case 4929903:
      return 'Organização de excursões em veículos rodoviários próprios, municipal';
    case 4929904:
      return 'Organização de excursões em veículos rodoviários próprios, intermunicipal, interestadual e internacional';
    case 4929999:
      return 'Outros transportes rodoviários de passageiros não especificados anteriormente';
    case 4930201:
      return 'Transporte rodoviário de carga, exceto produtos perigosos e mudanças, municipal';
    case 4930202:
      return 'Transporte rodoviário de carga, exceto produtos perigosos e mudanças, intermunicipal, interestadual e internacional';
    case 4930203:
      return 'Transporte rodoviário de produtos perigosos';
    case 4930204:
      return 'Transporte rodoviário de mudanças';
    case 4940000:
      return 'Transporte dutoviário';
    case 4950700:
      return 'Trens turísticos, teleféricos e similares';
    case 5011401:
      return 'Transporte marítimo de cabotagem - Carga';
    case 5011402:
      return 'Transporte marítimo de cabotagem - passageiros';
    case 5012201:
      return 'Transporte marítimo de longo curso - Carga';
    case 5012202:
      return 'Transporte marítimo de longo curso - Passageiros';
    case 5021101:
      return 'Transporte por navegação interior de carga, municipal, exceto travessia';
    case 5021102:
      return 'Transporte por navegação interior de carga, intermunicipal, interestadual e internacional, exceto travessia';
    case 5022001:
      return 'Transporte por navegação interior de passageiros em linhas regulares, municipal, exceto travessia';
    case 5022002:
      return 'Transporte por navegação interior de passageiros em linhas regulares, intermunicipal, interestadual e internacional, exceto travessia';
    case 5030101:
      return 'Navegação de apoio marítimo';
    case 5030102:
      return 'Navegação de apoio portuário';
    case 5030103:
      return 'Serviço de rebocadores e empurradores';
    case 5091201:
      return 'Transporte por navegação de travessia, municipal';
    case 5091202:
      return 'Transporte por navegação de travessia intermunicipal, interestadual e internacional';
    case 5099801:
      return 'Transporte aquaviário para passeios turísticos';
    case 5099899:
      return 'Outros transportes aquaviários não especificados anteriormente';
    case 5111100:
      return 'Transporte aéreo de passageiros regular';
    case 5112901:
      return 'Serviço de táxi aéreo e locação de aeronaves com tripulação';
    case 5112999:
      return 'Outros serviços de transporte aéreo de passageiros não-regular';
    case 5120000:
      return 'Transporte aéreo de carga';
    case 5130700:
      return 'Transporte espacial';
    case 5211701:
      return 'Armazéns gerais - emissão de warrant';
    case 5211702:
      return 'Guarda-móveis';
    case 5211799:
      return 'Depósitos de mercadorias para terceiros, exceto armazéns gerais e guarda-móveis';
    case 5212500:
      return 'Carga e descarga';
    case 5221400:
      return 'Concessionárias de rodovias, pontes, túneis e serviços relacionados';
    case 5222200:
      return 'Terminais rodoviários e ferroviários';
    case 5223100:
      return 'Estacionamento de veículos';
    case 5229001:
      return 'Serviços de apoio ao transporte por táxi, inclusive centrais de chamada';
    case 5229002:
      return 'Serviços de reboque de veículos';
    case 5229099:
      return 'Outras atividades auxiliares dos transportes terrestres não especificadas anteriormente';
    case 5231101:
      return 'Administração da infra-estrutura portuária';
    case 5231102:
      return 'Atividades do Operador Portuário';
    case 5231103:
      return 'Gestão de terminais aquaviários';
    case 5232000:
      return 'Atividades de agenciamento marítimo';
    case 5239701:
      return 'Serviços de praticagem';
    case 5239799:
      return 'Atividades auxiliares dos transportes aquaviários não especificadas anteriormente';
    case 5240101:
      return 'Operação dos aeroportos e campos de aterrissagem';
    case 5240199:
      return 'Atividades auxiliares dos transportes aéreos, exceto operação dos aeroportos e campos de aterrissagem';
    case 5250801:
      return 'Comissaria de despachos';
    case 5250802:
      return 'Atividades de despachantes aduaneiros';
    case 5250803:
      return 'Agenciamento de cargas, exceto para o transporte marítimo';
    case 5250804:
      return 'Organização logística do transporte de carga';
    case 5250805:
      return 'Operador de transporte multimodal - OTM';
    case 5310501:
      return 'Atividades do Correio Nacional';
    case 5310502:
      return 'Atividades de franqueadas e permissionárias do Correio Nacional';
    case 5320201:
      return 'Serviços de malote não realizados pelo Correio Nacional';
    case 5320202:
      return 'Serviços de entrega rápida';
    case 5510801:
      return 'Hotéis';
    case 5510802:
      return 'Apart-hotéis';
    case 5510803:
      return 'Motéis';
    case 5590601:
      return 'Albergues, exceto assistenciais';
    case 5590602:
      return 'Campings';
    case 5590603:
      return 'Pensões (alojamento)';
    case 5590699:
      return 'Outros alojamentos não especificados anteriormente';
    case 5611201:
      return 'Restaurantes e similares';
    case 5611203:
      return 'Serviços ambulantes de alimentação';
    case 5611204:
      return 'Bares e outros estabelecimentos especializados em servir bebidas, sem entretenimento';
    case 5611205:
      return 'Bares e outros estabelecimentos especializados em servir bebidas, com entretenimento';
    case 5612100:
      return 'Serviços ambulantes de alimentação';
    case 5620101:
      return 'Fornecimento de alimentos preparados preponderantemente para empresas';
    case 5620102:
      return 'Serviços de alimentação para eventos e recepções - bufê';
    case 5620103:
      return 'Cantinas - serviços de alimentação privativos';
    case 5620104:
      return 'Fornecimento de alimentos preparados preponderantemente para consumo domiciliar';
    case 5811500:
      return 'Edição de livros';
    case 5812301:
      return 'Edição de jornais diários';
    case 5812302:
      return 'Edição de jornais não diários';
    case 5813100:
      return 'Edição de revistas';
    case 5819100:
      return 'Edição de cadastros, listas e outros produtos gráficos';
    case 5821200:
      return 'Edição integrada à impressão de livros';
    case 5822101:
      return 'Edição integrada à impressão de jornais diários';
    case 5822102:
      return 'Edição integrada à impressão de jornais não diários';
    case 5823900:
      return 'Edição integrada à impressão de revistas';
    case 5829800:
      return 'Edição integrada à impressão de cadastros, listas e outros produtos gráficos';
    case 5911101:
      return 'Estúdios cinematográficos';
    case 5911102:
      return 'Produção de filmes para publicidade';
    case 5911199:
      return 'Atividades de produção cinematográfica, de vídeos e de programas de televisão não especificadas anteriormente';
    case 5912001:
      return 'Serviços de dublagem';
    case 5912002:
      return 'Serviços de mixagem sonora em produção audiovisual';
    case 5912099:
      return 'Atividades de pós-produção cinematográfica, de vídeos e de programas de televisão não especificadas anteriormente';
    case 5913800:
      return 'Distribuição cinematográfica, de vídeo e de programas de televisão';
    case 5914600:
      return 'Atividades de exibição cinematográfica';
    case 5920100:
      return 'Atividades de gravação de som e de edição de música';
    case 6010100:
      return 'Atividades de rádio';
    case 6021700:
      return 'Atividades de televisão aberta';
    case 6022501:
      return 'Programadoras';
    case 6022502:
      return 'Atividades relacionadas à televisão por assinatura, exceto programadoras';
    case 6110801:
      return 'Serviços de telefonia fixa comutada - STFC';
    case 6110802:
      return 'Serviços de redes de transporte de telecomunicações - SRTT';
    case 6110803:
      return 'Serviços de comunicação multimídia - SCM';
    case 6110899:
      return 'Serviços de telecomunicações por fio não especificados anteriormente';
    case 6120501:
      return 'Telefonia móvel celular';
    case 6120502:
      return 'Serviço móvel especializado - SME';
    case 6120599:
      return 'Serviços de telecomunicações sem fio não especificados anteriormente';
    case 6130200:
      return 'Telecomunicações por satélite';
    case 6141800:
      return 'Operadoras de televisão por assinatura por cabo';
    case 6142600:
      return 'Operadoras de televisão por assinatura por microondas';
    case 6143400:
      return 'Operadoras de televisão por assinatura por satélite';
    case 6190601:
      return 'Provedores de acesso às redes de comunicações';
    case 6190602:
      return 'Provedores de voz sobre protocolo internet - VOIP';
    case 6190699:
      return 'Outras atividades de telecomunicações não especificadas anteriormente';
    case 6201501:
      return 'Desenvolvimento de programas de computador sob encomenda';
    case 6201502:
      return 'Web design';
    case 6202300:
      return 'Desenvolvimento e licenciamento de programas de computador customizáveis';
    case 6203100:
      return 'Desenvolvimento e licenciamento de programas de computador não-customizáveis';
    case 6204000:
      return 'Consultoria em tecnologia da informação';
    case 6209100:
      return 'Suporte técnico, manutenção e outros serviços em tecnologia da informação';
    case 6311900:
      return 'Tratamento de dados, provedores de serviços de aplicação e serviços de hospedagem na internet';
    case 6319400:
      return 'Portais, provedores de conteúdo e outros serviços de informação na internet';
    case 6391700:
      return 'Agências de notícias';
    case 6399200:
      return 'Outras atividades de prestação de serviços de informação não especificadas anteriormente';
    case 6410700:
      return 'Banco Central';
    case 6421200:
      return 'Bancos comerciais';
    case 6422100:
      return 'Bancos múltiplos, com carteira comercial';
    case 6423900:
      return 'Caixas econômicas';
    case 6424701:
      return 'Bancos cooperativos';
    case 6424702:
      return 'Cooperativas centrais de crédito';
    case 6424703:
      return 'Cooperativas de crédito mútuo';
    case 6424704:
      return 'Cooperativas de crédito rural';
    case 6431000:
      return 'Bancos múltiplos, sem carteira comercial';
    case 6432800:
      return 'Bancos de investimento';
    case 6433600:
      return 'Bancos de desenvolvimento';
    case 6434400:
      return 'Agências de fomento';
    case 6435201:
      return 'Sociedades de crédito imobiliário';
    case 6435202:
      return 'Associações de poupança e empréstimo';
    case 6435203:
      return 'Companhias hipotecárias';
    case 6436100:
      return 'Sociedades de crédito, financiamento e investimento - financeiras';
    case 6437900:
      return 'Sociedades de crédito ao microempreendedor';
    case 6438701:
      return 'Bancos de câmbio';
    case 6438799:
      return 'Outras instituições de intermediação não-monetária não especificadas anteriormente';
    case 6440900:
      return 'Arrendamento mercantil';
    case 6450600:
      return 'Sociedades de capitalização';
    case 6461100:
      return 'Holdings de instituições financeiras';
    case 6462000:
      return 'Holdings de instituições não-financeiras';
    case 6463800:
      return 'Outras sociedades de participação, exceto holdings';
    case 6470101:
      return 'Fundos de investimento, exceto previdenciários e imobiliários';
    case 6470102:
      return 'Fundos de investimento previdenciários';
    case 6470103:
      return 'Fundos de investimento imobiliários';
    case 6491300:
      return 'Sociedades de fomento mercantil - factoring';
    case 6492100:
      return 'Securitização de créditos';
    case 6493000:
      return 'Administração de consórcios para aquisição de bens e direitos';
    case 6499901:
      return 'Clubes de investimento';
    case 6499902:
      return 'Sociedades de investimento';
    case 6499903:
      return 'Fundo garantidor de crédito';
    case 6499904:
      return 'Caixas de financiamento de corporações';
    case 6499905:
      return 'Concessão de crédito pelas OSCIP';
    case 6499999:
      return 'Outras atividades de serviços financeiros não especificadas anteriormente';
    case 6511101:
      return 'Sociedade seguradora de seguros vida';
    case 6511102:
      return 'Planos de auxílio-funeral';
    case 6512000:
      return 'Sociedade seguradora de seguros não vida';
    case 6520100:
      return 'Sociedade seguradora de seguros saúde';
    case 6530800:
      return 'Resseguros';
    case 6541300:
      return 'Previdência complementar fechada';
    case 6542100:
      return 'Previdência complementar aberta';
    case 6550200:
      return 'Planos de saúde';
    case 6611801:
      return 'Bolsa de valores';
    case 6611802:
      return 'Bolsa de mercadorias';
    case 6611803:
      return 'Bolsa de mercadorias e futuros';
    case 6611804:
      return 'Administração de mercados de balcão organizados';
    case 6612601:
      return 'Corretoras de títulos e valores mobiliários';
    case 6612602:
      return 'Distribuidoras de títulos e valores mobiliários';
    case 6612603:
      return 'Corretoras de câmbio';
    case 6612604:
      return 'Corretoras de contratos de mercadorias';
    case 6612605:
      return 'Agentes de investimentos em aplicações financeiras';
    case 6613400:
      return 'Administração de cartões de crédito';
    case 6619301:
      return 'Serviços de liquidação e custódia';
    case 6619302:
      return 'Correspondentes de instituições financeiras';
    case 6619303:
      return 'Representações de bancos estrangeiros';
    case 6619304:
      return 'Caixas eletrônicos';
    case 6619305:
      return 'Operadoras de cartões de débito';
    case 6619399:
      return 'Outras atividades auxiliares dos serviços financeiros não especificadas anteriormente';
    case 6621501:
      return 'Peritos e avaliadores de seguros';
    case 6621502:
      return 'Auditoria e consultoria atuarial';
    case 6622300:
      return 'Corretores e agentes de seguros, de planos de previdência complementar e de saúde';
    case 6629100:
      return 'Atividades auxiliares dos seguros, da previdência complementar e dos planos de saúde não especificadas anteriormente';
    case 6630400:
      return 'Atividades de administração de fundos por contrato ou comissão';
    case 6810201:
      return 'Compra e venda de imóveis próprios';
    case 6810202:
      return 'Aluguel de imóveis próprios';
    case 6810203:
      return 'Loteamento de imóveis próprios';
    case 6821801:
      return 'Corretagem na compra e venda e avaliação de imóveis';
    case 6821802:
      return 'Corretagem no aluguel de imóveis';
    case 6822600:
      return 'Gestão e administração da propriedade imobiliária';
    case 6911701:
      return 'Serviços advocatícios';
    case 6911702:
      return 'Atividades auxiliares da justiça';
    case 6911703:
      return 'Agente de propriedade industrial';
    case 6912500:
      return 'Cartórios';
    case 6920601:
      return 'Atividades de contabilidade';
    case 6920602:
      return 'Atividades de consultoria e auditoria contábil e tributária';
    case 7020400:
      return 'Atividades de consultoria em gestão empresarial, exceto consultoria técnica específica';
    case 7111100:
      return 'Serviços de arquitetura';
    case 7112000:
      return 'Serviços de engenharia';
    case 7119701:
      return 'Serviços de cartografia, topografia e geodésia';
    case 7119702:
      return 'Atividades de estudos geológicos';
    case 7119703:
      return 'Serviços de desenho técnico relacionados à arquitetura e engenharia';
    case 7119704:
      return 'Serviços de perícia técnica relacionados à segurança do trabalho';
    case 7119799:
      return 'Atividades técnicas relacionadas à engenharia e arquitetura não especificadas anteriormente';
    case 7120100:
      return 'Testes e análises técnicas';
    case 7210000:
      return 'Pesquisa e desenvolvimento experimental em ciências físicas e naturais';
    case 7220700:
      return 'Pesquisa e desenvolvimento experimental em ciências sociais e humanas';
    case 7311400:
      return 'Agências de publicidade';
    case 7312200:
      return 'Agenciamento de espaços para publicidade, exceto em veículos de comunicação';
    case 7319001:
      return 'Criação de estandes para feiras e exposições';
    case 7319002:
      return 'Promoção de vendas';
    case 7319003:
      return 'Marketing direto';
    case 7319004:
      return 'Consultoria em publicidade';
    case 7319099:
      return 'Outras atividades de publicidade não especificadas anteriormente';
    case 7320300:
      return 'Pesquisas de mercado e de opinião pública';
    case 7410202:
      return 'Design de interiores';
    case 7410203:
      return 'Design de produto';
    case 7410299:
      return 'Atividades de design não especificadas anteriormente';
    case 7420001:
      return 'Atividades de produção de fotografias, exceto aérea e submarina';
    case 7420002:
      return 'Atividades de produção de fotografias aéreas e submarinas';
    case 7420003:
      return 'Laboratórios fotográficos';
    case 7420004:
      return 'Filmagem de festas e eventos';
    case 7420005:
      return 'Serviços de microfilmagem';
    case 7490101:
      return 'Serviços de tradução, interpretação e similares';
    case 7490102:
      return 'Escafandria e mergulho';
    case 7490103:
      return 'Serviços de agronomia e de consultoria às atividades agrícolas e pecuárias';
    case 7490104:
      return 'Atividades de intermediação e agenciamento de serviços e negócios em geral, exceto imobiliários';
    case 7490105:
      return 'Agenciamento de profissionais para atividades esportivas, culturais e artísticas';
    case 7490199:
      return 'Outras atividades profissionais, científicas e técnicas não especificadas anteriormente';
    case 7500100:
      return 'Atividades veterinárias';
    case 7711000:
      return 'Locação de automóveis sem condutor';
    case 7719501:
      return 'Locação de embarcações sem tripulação, exceto para fins recreativos';
    case 7719502:
      return 'Locação de aeronaves sem tripulação';
    case 7719599:
      return 'Locação de outros meios de transporte não especificados anteriormente, sem condutor';
    case 7721700:
      return 'Aluguel de equipamentos recreativos e esportivos';
    case 7722500:
      return 'Aluguel de fitas de vídeo, DVDs e similares';
    case 7723300:
      return 'Aluguel de objetos do vestuário, jóias e acessórios';
    case 7729201:
      return 'Aluguel de aparelhos de jogos eletrônicos';
    case 7729202:
      return 'Aluguel de móveis, utensílios e aparelhos de uso doméstico e pessoal; instrumentos musicais';
    case 7729203:
      return 'Aluguel de material médico';
    case 7729299:
      return 'Aluguel de outros objetos pessoais e domésticos não especificados anteriormente';
    case 7731400:
      return 'Aluguel de máquinas e equipamentos agrícolas sem operador';
    case 7732201:
      return 'Aluguel de máquinas e equipamentos para construção sem operador, exceto andaimes';
    case 7732202:
      return 'Aluguel de andaimes';
    case 7733100:
      return 'Aluguel de máquinas e equipamentos para escritório';
    case 7739001:
      return 'Aluguel de máquinas e equipamentos para extração de minérios e petróleo, sem operador';
    case 7739002:
      return 'Aluguel de equipamentos científicos, médicos e hospitalares, sem operador';
    case 7739003:
      return 'Aluguel de palcos, coberturas e outras estruturas de uso temporário, exceto andaimes';
    case 7739099:
      return 'Aluguel de outras máquinas e equipamentos comerciais e industriais não especificados anteriormente, sem operador';
    case 7740300:
      return 'Gestão de ativos intangíveis não-financeiros';
    case 7810800:
      return 'Seleção e agenciamento de mão-de-obra';
    case 7820500:
      return 'Locação de mão-de-obra temporária';
    case 7830200:
      return 'Fornecimento e gestão de recursos humanos para terceiros';
    case 7911200:
      return 'Agências de viagens';
    case 7912100:
      return 'Operadores turísticos';
    case 7990200:
      return 'Serviços de reservas e outros serviços de turismo não especificados anteriormente';
    case 8011101:
      return 'Atividades de vigilância e segurança privada';
    case 8011102:
      return 'Serviços de adestramento de cães de guarda';
    case 8012900:
      return 'Atividades de transporte de valores';
    case 8020001:
      return 'Atividades de monitoramento de sistemas de segurança eletrônico';
    case 8020002:
      return 'Outras atividades de serviços de segurança';
    case 8030700:
      return 'Atividades de investigação particular';
    case 8111700:
      return 'Serviços combinados para apoio a edifícios, exceto condomínios prediais';
    case 8112500:
      return 'Condomínios prediais';
    case 8121400:
      return 'Limpeza em prédios e em domicílios';
    case 8122200:
      return 'Imunização e controle de pragas urbanas';
    case 8129000:
      return 'Atividades de limpeza não especificadas anteriormente';
    case 8130300:
      return 'Atividades paisagísticas';
    case 8211300:
      return 'Serviços combinados de escritório e apoio administrativo';
    case 8219901:
      return 'Fotocópias';
    case 8219999:
      return 'Preparação de documentos e serviços especializados de apoio administrativo não especificados anteriormente';
    case 8220200:
      return 'Atividades de teleatendimento';
    case 8230001:
      return 'Serviços de organização de feiras, congressos, exposições e festas';
    case 8230002:
      return 'Casas de festas e eventos';
    case 8291100:
      return 'Atividades de cobrança e informações cadastrais';
    case 8292000:
      return 'Envasamento e empacotamento sob contrato';
    case 8299701:
      return 'Medição de consumo de energia elétrica, gás e água';
    case 8299702:
      return 'Emissão de vales-alimentação, vales-transporte e similares';
    case 8299703:
      return 'Serviços de gravação de carimbos, exceto confecção';
    case 8299704:
      return 'Leiloeiros independentes';
    case 8299705:
      return 'Serviços de levantamento de fundos sob contrato';
    case 8299706:
      return 'Casas lotéricas';
    case 8299707:
      return 'Salas de acesso à internet';
    case 8299799:
      return 'Outras atividades de serviços prestados principalmente às empresas não especificadas anteriormente';
    case 8411600:
      return 'Administração pública em geral';
    case 8412400:
      return 'Regulação das atividades de saúde, educação, serviços culturais e outros serviços sociais';
    case 8413200:
      return 'Regulação das atividades econômicas';
    case 8421300:
      return 'Relações exteriores';
    case 8422100:
      return 'Defesa';
    case 8423000:
      return 'Justiça';
    case 8424800:
      return 'Segurança e ordem pública';
    case 8425600:
      return 'Defesa Civil';
    case 8430200:
      return 'Seguridade social obrigatória';
    case 8511200:
      return 'Educação infantil - creche';
    case 8512100:
      return 'Educação infantil - pré-escola';
    case 8513900:
      return 'Ensino fundamental';
    case 8520100:
      return 'Ensino médio';
    case 8531700:
      return 'Educação superior - graduação';
    case 8532500:
      return 'Educação superior - graduação e pós-graduação';
    case 8533300:
      return 'Educação superior - pós-graduação e extensão';
    case 8541400:
      return 'Educação profissional de nível técnico';
    case 8542200:
      return 'Educação profissional de nível tecnológico';
    case 8550301:
      return 'Administração de caixas escolares';
    case 8550302:
      return 'Atividades de apoio à educação, exceto caixas escolares';
    case 8591100:
      return 'Ensino de esportes';
    case 8592901:
      return 'Ensino de dança';
    case 8592902:
      return 'Ensino de artes cênicas, exceto dança';
    case 8592903:
      return 'Ensino de música';
    case 8592999:
      return 'Ensino de arte e cultura não especificado anteriormente';
    case 8593700:
      return 'Ensino de idiomas';
    case 8599601:
      return 'Formação de condutores';
    case 8599602:
      return 'Cursos de pilotagem';
    case 8599603:
      return 'Treinamento em informática';
    case 8599604:
      return 'Treinamento em desenvolvimento profissional e gerencial';
    case 8599605:
      return 'Cursos preparatórios para concursos';
    case 8599699:
      return 'Outras atividades de ensino não especificadas anteriormente';
    case 8610101:
      return 'Atividades de atendimento hospitalar, exceto pronto-socorro e unidades para atendimento a urgências';
    case 8610102:
      return 'Atividades de atendimento em pronto-socorro e unidades hospitalares para atendimento a urgências';
    case 8621601:
      return 'UTI móvel';
    case 8621602:
      return 'Serviços móveis de atendimento a urgências, exceto por UTI móvel';
    case 8622400:
      return 'Serviços de remoção de pacientes, exceto os serviços móveis de atendimento a urgências';
    case 8630501:
      return 'Atividade médica ambulatorial com recursos para realização de procedimentos cirúrgicos';
    case 8630502:
      return 'Atividade médica ambulatorial com recursos para realização de exames complementares';
    case 8630503:
      return 'Atividade médica ambulatorial restrita a consultas';
    case 8630504:
      return 'Atividade odontológica';
    case 8630506:
      return 'Serviços de vacinação e imunização humana';
    case 8630507:
      return 'Atividades de reprodução humana assistida';
    case 8630599:
      return 'Atividades de atenção ambulatorial não especificadas anteriormente';
    case 8640201:
      return 'Laboratórios de anatomia patológica e citológica';
    case 8640202:
      return 'Laboratórios clínicos';
    case 8640203:
      return 'Serviços de diálise e nefrologia';
    case 8640204:
      return 'Serviços de tomografia';
    case 8640205:
      return 'Serviços de diagnóstico por imagem com uso de radiação ionizante, exceto tomografia';
    case 8640206:
      return 'Serviços de ressonância magnética';
    case 8640207:
      return 'Serviços de diagnóstico por imagem sem uso de radiação ionizante, exceto ressonância magnética';
    case 8640208:
      return 'Serviços de diagnóstico por registro gráfico - ECG, EEG e outros exames análogos';
    case 8640209:
      return 'Serviços de diagnóstico por métodos ópticos - endoscopia e outros exames análogos';
    case 8640210:
      return 'Serviços de quimioterapia';
    case 8640211:
      return 'Serviços de radioterapia';
    case 8640212:
      return 'Serviços de hemoterapia';
    case 8640213:
      return 'Serviços de litotripsia';
    case 8640214:
      return 'Serviços de bancos de células e tecidos humanos';
    case 8640299:
      return 'Atividades de serviços de complementação diagnóstica e terapêutica não especificadas anteriormente';
    case 8650001:
      return 'Atividades de enfermagem';
    case 8650002:
      return 'Atividades de profissionais da nutrição';
    case 8650003:
      return 'Atividades de psicologia e psicanálise';
    case 8650004:
      return 'Atividades de fisioterapia';
    case 8650005:
      return 'Atividades de terapia ocupacional';
    case 8650006:
      return 'Atividades de fonoaudiologia';
    case 8650007:
      return 'Atividades de terapia de nutrição enteral e parenteral';
    case 8650099:
      return 'Atividades de profissionais da área de saúde não especificadas anteriormente';
    case 8660700:
      return 'Atividades de apoio à gestão de saúde';
    case 8690901:
      return 'Atividades de práticas integrativas e complementares em saúde humana';
    case 8690902:
      return 'Atividades de bancos de leite humano';
    case 8690903:
      return 'Atividades de acupuntura';
    case 8690904:
      return 'Atividades de podologia';
    case 8690999:
      return 'Outras atividades de atenção à saúde humana não especificadas anteriormente';
    case 8711501:
      return 'Clínicas e residências geriátricas';
    case 8711502:
      return 'Instituições de longa permanência para idosos';
    case 8711503:
      return 'Atividades de assistência a deficientes físicos, imunodeprimidos e convalescentes';
    case 8711504:
      return 'Centros de apoio a pacientes com câncer e com AIDS';
    case 8711505:
      return 'Condomínios residenciais para idosos';
    case 8712300:
      return 'Atividades de fornecimento de infra-estrutura de apoio e assistência a paciente no domicílio';
    case 8720401:
      return 'Atividades de centros de assistência psicossocial';
    case 8720499:
      return 'Atividades de assistência psicossocial e à saúde a portadores de distúrbios psíquicos, deficiência mental e dependência química e grupos similares não especificadas anteriormente';
    case 8730101:
      return 'Orfanatos';
    case 8730102:
      return 'Albergues assistenciais';
    case 8730199:
      return 'Atividades de assistência social prestadas em residências coletivas e particulares não especificadas anteriormente';
    case 8800600:
      return 'Serviços de assistência social sem alojamento';
    case 9001901:
      return 'Produção teatral';
    case 9001902:
      return 'Produção musical';
    case 9001903:
      return 'Produção de espetáculos de dança';
    case 9001904:
      return 'Produção de espetáculos circenses, de marionetes e similares';
    case 9001905:
      return 'Produção de espetáculos de rodeios, vaquejadas e similares';
    case 9001906:
      return 'Atividades de sonorização e de iluminação';
    case 9001999:
      return 'Artes cênicas, espetáculos e atividades complementares não especificados anteriormente';
    case 9002701:
      return 'Atividades de artistas plásticos, jornalistas independentes e escritores';
    case 9002702:
      return 'Restauração de obras de arte';
    case 9003500:
      return 'Gestão de espaços para artes cênicas, espetáculos e outras atividades artísticas';
    case 9101500:
      return 'Atividades de bibliotecas e arquivos';
    case 9102301:
      return 'Atividades de museus e de exploração de lugares e prédios históricos e atrações similares';
    case 9102302:
      return 'Restauração e conservação de lugares e prédios históricos';
    case 9103100:
      return 'Atividades de jardins botânicos, zoológicos, parques nacionais, reservas ecológicas e áreas de proteção ambiental';
    case 9200301:
      return 'Casas de bingo';
    case 9200302:
      return 'Exploração de apostas em corridas de cavalos';
    case 9200399:
      return 'Exploração de jogos de azar e apostas não especificados anteriormente';
    case 9311500:
      return 'Gestão de instalações de esportes';
    case 9312300:
      return 'Clubes sociais, esportivos e similares';
    case 9313100:
      return 'Atividades de condicionamento físico';
    case 9319101:
      return 'Produção e promoção de eventos esportivos';
    case 9319199:
      return 'Outras atividades esportivas não especificadas anteriormente';
    case 9321200:
      return 'Parques de diversão e parques temáticos';
    case 9329801:
      return 'Discotecas, danceterias, salões de dança e similares';
    case 9329802:
      return 'Exploração de boliches';
    case 9329803:
      return 'Exploração de jogos de sinuca, bilhar e similares';
    case 9329804:
      return 'Exploração de jogos eletrônicos recreativos';
    case 9329899:
      return 'Outras atividades de recreação e lazer não especificadas anteriormente';
    case 9411100:
      return 'Atividades de organizações associativas patronais e empresariais';
    case 9412001:
      return 'Atividades de fiscalização profissional';
    case 9412099:
      return 'Outras atividades associativas profissionais';
    case 9420100:
      return 'Atividades de organizações sindicais';
    case 9430800:
      return 'Atividades de associações de defesa de direitos sociais';
    case 9491000:
      return 'Atividades de organizações religiosas ou filosóficas';
    case 9492800:
      return 'Atividades de organizações políticas';
    case 9493600:
      return 'Atividades de organizações associativas ligadas à cultura e à arte';
    case 9499500:
      return 'Atividades associativas não especificadas anteriormente';
    case 9511800:
      return 'Reparação e manutenção de computadores e de equipamentos periféricos';
    case 9512600:
      return 'Reparação e manutenção de equipamentos de comunicação';
    case 9521500:
      return 'Reparação e manutenção de equipamentos eletroeletrônicos de uso pessoal e doméstico';
    case 9529101:
      return 'Reparação de calçados, bolsas e artigos de viagem';
    case 9529102:
      return 'Chaveiros';
    case 9529103:
      return 'Reparação de relógios';
    case 9529104:
      return 'Reparação de bicicletas, triciclos e outros veículos não-motorizados';
    case 9529105:
      return 'Reparação de artigos do mobiliário';
    case 9529106:
      return 'Reparação de jóias';
    case 9529199:
      return 'Reparação e manutenção de outros objetos e equipamentos pessoais e domésticos não especificados anteriormente';
    case 9601701:
      return 'Lavanderias';
    case 9601702:
      return 'Tinturarias';
    case 9601703:
      return 'Toalheiros';
    case 9602501:
      return 'Cabeleireiros, manicure e pedicure';
    case 9602502:
      return 'Atividades de estética e outros serviços de cuidados com a beleza';
    case 9603301:
      return 'Gestão e manutenção de cemitérios';
    case 9603302:
      return 'Serviços de cremação';
    case 9603303:
      return 'Serviços de sepultamento';
    case 9603304:
      return 'Serviços de funerárias';
    case 9603305:
      return 'Serviços de somatoconservação';
    case 9603399:
      return 'Atividades funerárias e serviços relacionados não especificados anteriormente';
    case 9609202:
      return 'Agências matrimoniais';
    case 9609204:
      return 'Exploração de máquinas de serviços pessoais acionadas por moeda';
    case 9609205:
      return 'Atividades de sauna e banhos';
    case 9609206:
      return 'Serviços de tatuagem e colocação de piercing';
    case 9609207:
      return 'Alojamento de animais domésticos';
    case 9609208:
      return 'Higiene e embelezamento de animais domésticos';
    case 9609299:
      return 'Outras atividades de serviços pessoais não especificadas anteriormente';
    case 9700500:
      return 'Serviços domésticos';
    case 9900800:
      return 'Organismos internacionais e outras instituições extraterritoriais';

    default:
      return '';
  }
};

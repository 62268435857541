import { Mail, Phone } from "@material-ui/icons";
import { addMinutes, format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Badge, Modal, Tab, Table, Tabs } from "react-bootstrap";
import Lottie from "react-lottie";
import animationData from "~/animations/searching_files.json";
import { api } from "~/hooks/api";

import { normalizeBrl, normalizeCpfCnpj } from "../../../utils/normalize";
import { IOperation } from "../../operation";

interface IProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  operationId?: number;
}

const ModalDetail: React.FC<IProps> = ({ open, setOpen, operationId }) => {
  const [loading, setLoading] = useState(false);
  const [operation, setOperation] = useState<IOperation | null>(null);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      try {
        const { data } = await api.get<IOperation>(
          `/operations/${operationId}`
        );
        setOperation(data);
      } catch (err) {
        //
      }
      setLoading(false);
    }
    if (operationId && operationId > 0) {
      loadData();
    }
  }, [operationId]);

  return (
    <Modal show={open} onHide={() => setOpen(false)} centered size="xl">
      <Modal.Header closeButton className="bg-white" />
      {loading && (
        <Modal.Body className="bg-white">
          <Lottie options={defaultOptions} height={300} width={300} />
        </Modal.Body>
      )}
      {!loading && operation && (
        <Modal.Body>
          <div className="p-3">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <div className="d-flex align-items-center">
                  <h5 className="m-0">
                    Operação {operation.code}
                    {operation.operationDocuments &&
                      operation.operationDocuments.map((doc) => {
                        return (
                          <a
                            href={doc.url}
                            key={doc.id}
                            className="ml-3 btn btn-sm btn-outline-secondary"
                          >
                            {doc.type}
                          </a>
                        );
                      })}
                  </h5>
                </div>
                <div className="d-flex mt-2">
                  {operation.gross_value > 0 && (
                    <div className="d-flex pl-2 pr-2 pt-1 pb-1 radius-4 border-1 border-dark text-dark">
                      <strong>
                        Valor total {normalizeBrl(operation.gross_value)}
                      </strong>
                    </div>
                  )}
                  {[6, 7, 8, 9, 10, 11].includes(
                    operation.operation_status_id
                  ) &&
                    operation.net_value > 0 && (
                      <div className="d-flex ml-2 pl-2 pr-2 pt-1 pb-1 radius-4 border-1 border-primary text-primary">
                        <strong>
                          Valor Líquido{" "}
                          {normalizeBrl(
                            operation.net_value -
                              operation.tariff_discount_value -
                              operation.title_discount_value -
                              operation.financial_pending_discount_value
                          )}
                        </strong>{" "}
                      </div>
                    )}
                  {[6, 7, 8, 9, 10, 11].includes(
                    operation.operation_status_id
                  ) &&
                  operation.additiveTerm &&
                  operation.additiveTerm.file_path &&
                  operation.additiveTerm.type === "Termo de cessão" ? (
                    <div className="bg-primary border-1 border-primary d-flex ml-2 pb-1 pl-2 pr-2 pt-1 radius-4">
                      <a
                        href={operation.additiveTerm.file_path}
                        className=" text-white"
                        target="_blank"
                      >
                        Termo de cessão
                      </a>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="text-right">
                <h6 className="m-0">Dúvidas?</h6>
                <p className="m-0">
                  Entre em contato conosco em um dos canais abaixo
                </p>
                <div className="d-flex justify-content-end flex-wrap">
                  <a href="mailto:contato@bfcdigital.com.br" className="small">
                    <Mail fontSize="small" /> contato@bfcdigital.com.br
                  </a>
                  <a href="tel:8140072614" className="ml-4 small">
                    <Phone fontSize="small" /> (81) 4007-2614
                  </a>
                </div>
              </div>
            </div>
            <hr />
            <div className="mt-3">
              <Tabs defaultActiveKey="accepted" id="sd">
                <Tab
                  eventKey="accepted"
                  title={
                    <span className="text-info">
                      Títulos{" "}
                      <Badge variant="info">
                        {operation.status.id === 2
                          ? operation.titles?.length +
                            operation.titlesRejected?.length
                          : operation.titles?.length}
                      </Badge>
                    </span>
                  }
                >
                  <div className="mt-3">
                    <Table hover responsive="lg">
                      <thead>
                        <tr>
                          <th>Título</th>
                          <th>Sacado</th>
                          <th>Vencimento</th>
                          <th>Documento</th>
                          <th className="text-right">Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        {operation.titles?.map((title) => {
                          return (
                            <tr key={title.id}>
                              <td>{title.id}</td>
                              <td>
                                {title.payer.name}
                                <strong style={{ display: "block" }}>
                                  {normalizeCpfCnpj(
                                    title.payer.document_number
                                  )}
                                </strong>
                              </td>
                              <td>
                                {title.date
                                  ? format(
                                      addMinutes(
                                        new Date(title.date),
                                        new Date(title.date).getTimezoneOffset()
                                      ),
                                      "dd/MM/yyyy"
                                    )
                                  : "-"}
                              </td>
                              <td>{title.document_number}</td>
                              <td className="text-right">
                                {normalizeBrl(title.value)}
                              </td>
                            </tr>
                          );
                        })}
                        {operation.status.id === 2 &&
                          operation.titlesRejected?.map((title) => {
                            return (
                              <tr key={title.id}>
                                <td>{title.id}</td>
                                <td>
                                  {title.payer.name}
                                  <strong style={{ display: "block" }}>
                                    {normalizeCpfCnpj(
                                      title.payer.document_number
                                    )}
                                  </strong>
                                </td>
                                <td>
                                  {title.date
                                    ? format(
                                        addMinutes(
                                          new Date(title.date),
                                          new Date(
                                            title.date
                                          ).getTimezoneOffset()
                                        ),
                                        "dd/MM/yyyy"
                                      )
                                    : "-"}
                                </td>
                                <td>{title.document_number}</td>
                                <td className="text-right">
                                  {normalizeBrl(title.value)}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </div>
                </Tab>
                {operation &&
                  operation.status &&
                  operation.status.id > 2 &&
                  operation.titlesRejected.length > 0 && (
                    <Tab
                      eventKey="rejected"
                      title={
                        <span className="text-info">
                          Títulos Rejeitados
                          <Badge variant="info">
                            {operation.titlesRejected?.length}
                          </Badge>
                        </span>
                      }
                    >
                      <div className="mt-3">
                        <Table hover responsive="lg">
                          <thead>
                            <tr>
                              <th>Título</th>
                              <th>Sacado</th>
                              <th>Vencimento</th>
                              <th>Documento</th>
                              <th className="text-right">Valor</th>
                            </tr>
                          </thead>
                          <tbody>
                            {operation.titlesRejected?.map((title) => {
                              return (
                                <tr key={title.id}>
                                  <td>{title.id}</td>
                                  <td>
                                    {title.payer.name}
                                    <strong style={{ display: "block" }}>
                                      {normalizeCpfCnpj(
                                        title.payer.document_number
                                      )}
                                    </strong>
                                  </td>
                                  <td>
                                    {title.date
                                      ? format(
                                          addMinutes(
                                            new Date(title.date),
                                            new Date(
                                              title.date
                                            ).getTimezoneOffset()
                                          ),
                                          "dd/MM/yyyy"
                                        )
                                      : "-"}
                                  </td>
                                  <td>{title.document_number}</td>
                                  <td className="text-right">
                                    {normalizeBrl(title.value)}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </Tab>
                  )}
              </Tabs>
            </div>
          </div>
        </Modal.Body>
      )}
    </Modal>
  );
};

export default ModalDetail;

import { takeLatest, call, put, all } from 'redux-saga/effects';

import { REHYDRATE, RehydrateAction } from 'redux-persist';
import history from '~/services/history';

import { signInSuccess, signFailure } from './actions';

import { SIGN_IN_REQUEST, SIGN_OUT, ISignInRequestAction } from './types';

import { api } from '~/hooks/api';

type UnsafeReturnType<T> = T extends (...args: any[]) => infer R ? R : any; // eslint-disable-line

export function* signIn({ payload }: ISignInRequestAction): any {
  // eslint-disable-line
  try {
    const { email, password } = payload;
    const response = yield call(
      api.post,
      `/sessions`,
      {
        email,
        password,
      }
    );

    const { token, user } = response.data;

    api.defaults.headers.Authorization = `Bearer ${token}`;

    const { data: me } = yield call(
      api.get,
      `/me`
    );

    console.log(me);

    yield put(
      signInSuccess({
        token,
        user,
        company: {
          clieId: me.company.clie_id,
          id: me.company.clie_id,
          cnpj: me.company.cgc,
          companyName: me.company.fant, // TODO
          fantasyName: me.company.fant,
          limit: me.limit,
        },
        permissions: me.permissions, // TODO
      })
    );

    history.push('/dashboard');
  } catch (err) {
    yield put(signFailure());
  }
}

export function setToken({ payload }: UnsafeReturnType<RehydrateAction>): any {
  if (!payload) return;
  const { token } = payload.auth;
  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
    // if (response.data.status === 401) {
    //   yield put(signOut());
    // }
  }
}

export function signOutRedirect(): any {
  // eslint-disable-line
  history.push('/');
}

export default all([
  takeLatest(REHYDRATE, setToken),
  takeLatest(SIGN_IN_REQUEST, signIn),
  takeLatest(SIGN_OUT, signOutRedirect),
]);

import React, { memo, useEffect, useState } from "react";

import { FaFile, FaInfoCircle, FaPrint } from "react-icons/fa";

import {
  formatDate,
  normalizeNumber,
  subString,
} from "~/pages/main/utils/normalize";
import {
  normalizeCpfCnpj,
  normalizeCurrency2 as normalizeCurrency,
} from "../../utils";

import { api } from "~/hooks/api";
import { TableComponent } from "~/pages/main/components/TableComponent";
import printDanfe from "~/pages/main/utils/printDanfe";
import { Badge, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useAuth } from "~/hooks/useAuth";
import { toast } from "react-toastify";
import {
  downloadBillet,
  titleIsInRegistryOffice,
  titleIsProtested,
} from "~/utils/titleUtils";

interface INextItem {
  (): void;
}

interface IPaymentType {
  code: string;
  name: string;
}

interface IProps {
  handleNextItem: INextItem;
}

const Open: React.FC<IProps> = () => {
  const [writeOffTypes, setWriteOffTypes] = useState<any[]>([]);
  const [titleTypes, setTitleTypes] = useState<any[]>([]);
  const { currentAssignor, getAllCompanies, companies } = useAuth();

  useEffect(() => {
    api
      .get(`/write_off_types`)
      .then((response) => {
        setWriteOffTypes(response.data);
      })
      .finally(() => {});

    api
      .get(`/title_types`)
      .then((response) => {
        setTitleTypes(response.data);
      })
      .finally(() => {});
  }, []);

  async function handleDanfe(key: string) {
    try {
      const { data } = await api.get(`xml/danfe/${key}`);
      printDanfe(
        data.html.replace("<title>DANFE</title>", `<title>DANFE-${key}</title>`)
      );
    } catch (err) {
      //
    }
  }

  useEffect(() => {
    getAllCompanies();
  }, []);

  return (
    <TableComponent
      title="Títulos Liquidados"
      totalsBadges={[
        {
          title: "Valor Total",
          fieldName: "total_value",
          type: "currency",
        },
      ]}
      dataReciever={async (params) => {
        const { data } = await api.get("/assignor/titles/paid", {
          params: {
            ...params,
            startValue: params.start_value
              ? normalizeNumber(params.start_value)
              : "",
            endValue: params.end_value ? normalizeNumber(params.end_value) : "",
            payer_document: params.payer_document
              ? normalizeNumber(params.payer_document)
              : "",
          },
        });
        return data;
      }}
      dataType={[]}
      exportAction={async (params) => {
        if (
          (!params?.start_date_paid || !params?.end_date_paid) &&
          !params?.payer_document
        ) {
          return toast.error(
            "Os campos de data de pagamento ou documento do sacado são obrigatórios"
          );
        }

        const dataInicial = new Date(params?.start_date_paid);
        const dataFinal = new Date(params?.end_date_paid);

        const umDiaEmMilissegundos = 1000 * 60 * 60 * 24;

        const diferencaEmDias = Math.floor(
          (dataFinal.getTime() - dataInicial.getTime()) / umDiaEmMilissegundos
        );

        if (diferencaEmDias > 120) {
          return toast.error(
            "O intervalo de datas não pode ser maior que 120 dias"
          );
        }

        const obj = {
          ...params,
          payer_document: params.payer_document
            ? normalizeNumber(params.payer_document)
            : "",
          startValue: params.start_value
            ? normalizeNumber(params.start_value)
            : "",
          endValue: params.end_value ? normalizeNumber(params.end_value) : "",
        };
        window.open(
          `${process.env.REACT_APP_API}/assignor/titles/paid?${Object.keys(obj)
            .filter((key) => obj[key])
            .map((key) => `${key}=${obj[key]}`)
            .join("&")}`
        );
      }}
      traitResponse={(data: any) => {
        return data.map((d) => {
          return {
            ...d,
          };
        });
      }}
      filters={[
        {
          name: "code",
          title: "ID",
          col: 1,
        },
        {
          name: "operation_code",
          title: "Operação",
          col: 1,
        },
        {
          name: "document_number",
          title: "Documento",
          col: 2,
        },
        {
          name: "title_type_id",
          title: "Tipo",
          select: true,
          options: titleTypes.map((type) => ({
            value: type.id,
            title: type.name,
          })),
          col: 2,
        },
        {
          name: "write_off_type_id",
          title: "Tipo de Baixa",
          select: true,
          options: writeOffTypes
            .filter((w) => w.active)
            .map((type) => ({
              value: type.id,
              title: type.name,
            })),
          col: 2,
        },
        {
          name: "start_date",
          title: "Vencimento (de)",
          type: "date",
          col: 2,
        },
        {
          name: "end_date",
          title: "Vencimento (até)",
          type: "date",
          col: 2,
        },
        {
          name: "start_date_paid",
          title: "Data Pagamento (de)",
          type: "date",
          col: 2,
        },
        {
          name: "end_date_paid",
          title: "Data Pagamento (até)",
          type: "date",
          col: 2,
        },
        {
          name: "start_value",
          title: "Valor (de)",
          normalize: normalizeCurrency,
          col: 2,
        },
        {
          name: "end_value",
          title: "Valor (até)",
          normalize: normalizeCurrency,
          col: 2,
        },
        {
          name: "payer_document",
          title: "CPF/CNPJ do Sacado",
          normalize: normalizeCpfCnpj,
          col: 3,
        },
        {
          name: "product_id",
          title: "Produto",
          select: true,
          defaultValue: 1,
          options: [
            {
              title: "Antecipação de Recebíveis",
              value: 1,
            },
            {
              title: "Cobrança Simples",
              value: 2,
            },
            {
              title: "Cobrança Simples (Recomprados)",
              value: 3,
            },
          ],
          col: 2,
        },
        {
          name: "company_id",
          title: "Empresa",
          select: true,
          defaultValue: 0,
          options: companies?.map((company) => ({
            title: `${company?.code} - ${company?.fantasy_name}`,
            value: company?.id,
          })),
          col: 3,
        },
      ]}
      labels={[
        {
          name: "title.code",
          title: "Título",
          formater: (_, title) => {
            return (
              <>
                {title?.title?.code}
                {title?.title?.product_id === 2 ? (
                  <OverlayTrigger
                    overlay={
                      <Tooltip id={`cs-${title.id}`}>
                        <span>Cobrança Simples</span>
                      </Tooltip>
                    }
                  >
                    <Badge
                      variant="primary"
                      className="ml-1"
                      style={{ fontSize: "0.8rem" }}
                    >
                      CS
                    </Badge>
                  </OverlayTrigger>
                ) : null}
              </>
            );
          },
          ableToOrder: false,
        },
        {
          name: "operation.company_code",
          title: "Empresa",
          formater: (_value, title) => title?.company_code,
        },
        {
          name: "operation_code",
          title: "Operação",
          formater: (_, writeOff) => {
            return writeOff.title.operation.code;
          },
          ableToOrder: false,
        },
        {
          name: "title.document_number",
          title: "Doc.",
          formater: (_, writeOff) => {
            return (
              <span className="white-space-nowrap">
                {writeOff.title.document_number}
              </span>
            );
          },
          ableToOrder: false,
        },
        {
          name: "payer.name",
          title: "Sacado",
          formater: (_, writeOff) => {
            return (
              <div className="white-space-no-wrap">
                {subString(writeOff.payer.name, 30)}
                {writeOff?.payer?.name?.length > 30 ? (
                  <OverlayTrigger
                    overlay={
                      <Tooltip id={`tooltip-${writeOff.id}`}>
                        <span>{writeOff.payer.name}</span>
                      </Tooltip>
                    }
                  >
                    <FaInfoCircle className="text-muted" />
                  </OverlayTrigger>
                ) : null}
                <br />
                {normalizeCpfCnpj(writeOff.payer.document_number)}
              </div>
            );
          },
          ableToOrder: false,
        },
        {
          name: "title.value",
          title: (
            <>
              Valor <br />
              Original
            </>
          ),
          formater: (_, writeOff) => {
            return normalizeCurrency(
              writeOff.title.value + writeOff.title.discount_value || 0,
              true
            );
          },
          ableToOrder: false,
          align: "right",
        },
        {
          name: "title.value",
          title: (
            <>
              Valor <br />
              Desconto
            </>
          ),
          formater: (_, writeOff) => {
            return normalizeCurrency(writeOff.title.discount_value || 0, true);
          },
          ableToOrder: false,
          align: "right",
        },
        {
          name: "title.value",
          title: (
            <>
              Valor <br />
              Título
            </>
          ),
          formater: (_, writeOff) => {
            return normalizeCurrency(writeOff.title.value, true);
          },
          ableToOrder: false,
          align: "right",
        },
        {
          name: "title_open_value",
          title: (
            <>
              Saldo do
              <br />
              Título
            </>
          ),
          formater: (_, writeOff) => {
            return writeOff.title_open_value > 0
              ? normalizeCurrency(writeOff.title_open_value, true)
              : "-";
          },
          ableToOrder: false,
          align: "right",
        },
        {
          name: "value",
          title: <>Encargos</>,
          formater: (_, writeOff) => {
            return writeOff.arrears_value || 0 + writeOff.fine_value || 0 > 0
              ? normalizeCurrency(
                  writeOff.arrears_value || 0 + writeOff.fine_value || 0,
                  true
                )
              : "-";
          },
          ableToOrder: false,
          align: "right",
        },
        {
          name: "value",
          title: (
            <>
              Valor <br />
              Pagamento
            </>
          ),
          formater: (_, writeOff) => {
            return normalizeCurrency(writeOff.paid_value, true);
          },
          ableToOrder: false,
          align: "right",
        },
        {
          name: "title.date",
          title: "Vencimento",
          formater: (_, writeOff) => {
            return formatDate(writeOff.title.date);
          },
          ableToOrder: false,
        },
        {
          name: "title.title_type_id",
          title: "Tipo",
          formater: (_, writeOff) => {
            try {
              const regex = /\(([^)]+)\)/;
              return writeOff.title?.titleType?.name?.match(regex)[1] || "";
            } catch (err) {
              return "";
            }
          },
          ableToOrder: false,
        },
        {
          name: "title.paid_date",
          title: (
            <>
              Data <br />
              Pagamento
            </>
          ),
          formater: (_, writeOff) => {
            return formatDate(writeOff.payment_date);
          },
          ableToOrder: false,
        },
        {
          name: "billing_status.code",
          title: (
            <>
              Sit. de <br />
              Cobrança
            </>
          ),
          formater: (_, writeOff) => {
            return (
              <>
                {writeOff.title?.collectingAgent?.code} -{" "}
                {writeOff.title?.billingStatus?.name}
              </>
            );
          },
          ableToOrder: false,
        },
        {
          name: "typeWriteOffs",
          title: (
            <>
              Tipo <br />
              Baixa
            </>
          ),
          ableToOrder: false,
          formater: (_, writeOff) => {
            return writeOff.type.name;
          },
        },
        {
          name: "billet",
          title: "",
          ableToOrder: false,
          formater: (_, writeOff) => {
            const title = writeOff.title;

            if (!title) {
              return <></>;
            }

            if (titleIsInRegistryOffice(title)) {
              return <>Cartório</>;
            }
            if (titleIsProtested(title)) {
              return <>Protesto</>;
            }

            if (!title.our_number) {
              return <></>;
            }

            return (
              <Button
                type="button"
                size="sm"
                className="p-1"
                onClick={() =>
                  downloadBillet([title.id], currentAssignor.token)
                }
              >
                <FaPrint /> Boleto
              </Button>
            );
          },
        },
      ]}
      editable={false}
      addItem={false}
      aditionalActions={(t) => (
        <div className="ml-2">
          {t?.duplicate?.xml?.key && t?.operation?.titleType?.name === "NFe" && (
            <OverlayTrigger
              overlay={
                <Tooltip id={`tooltip-danfe-${t.id}`}>
                  <span>Imprimir DANFE</span>
                </Tooltip>
              }
              trigger="hover"
            >
              <button
                type="button"
                onClick={() => handleDanfe(t?.duplicate?.xml?.key || "")}
              >
                <FaFile />
              </button>
            </OverlayTrigger>
          )}
        </div>
      )}
    />
  );
};

export default memo(Open);

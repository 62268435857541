import React, { memo, useState, useEffect } from "react";

import { format } from "date-fns";

import { normalizeCpfCnpj, normalizeCurrency } from "./utils";

import { normalizeNumber } from "~/pages/main/utils/normalize";
import { api } from "~/hooks/api";
import { TableComponent } from "~/pages/main/components/TableComponent";

interface INextItem {
  (): void;
}

interface IProps {
  handleNextItem: INextItem;
}

const Prorrogation: React.FC<IProps> = () => {
  const [titleTypes, setTitleTypes] = useState<any[]>([]);

  useEffect(() => {
    api.get(`/title_types`).then((response) => {
      setTitleTypes(response.data);
    });
  }, []);

  return (
    <div
      className="bg-white p-3 shadow-sm radius-4"
      style={{ borderRadius: "20px" }}
    >
      <TableComponent
        title="Prorrogação de Títulos"
        dataReciever={async (params) => {
          const { data } = await api.get("/prorrogation_titles", {
            params: {
              ...params,
              start_date: params.start_date ? params.start_date : "",
              end_date: params.end_date ? params.end_date : "",
              start_value: params.start_value
                ? normalizeNumber(params.start_value)
                : "",
              end_value: params.env_value
                ? normalizeNumber(params.env_value)
                : "",
            },
          });
          return data;
        }}
        dataType={[]}
        traitResponse={(data: any) => {
          return data.map((d) => {
            return {
              ...d,
              id: d.id,
              documentNumber: d.document_number,
              date: d.date ? format(new Date(d.date), "dd/MM/yyyy") : "",
              operation_paid_date: d.operation_paid_date
                ? format(new Date(d.date), "dd/MM/yyyy")
                : "",
              operationId: d.operation_id,
              payerId: `${d.payer?.name || ""} - ${normalizeCpfCnpj(
                d.payer?.document_number
              )}`,
              value: `R$ ${normalizeCurrency(String(d.value))}`,
              openValue: `R$ ${normalizeCurrency(String(d.open_value))}`,
              titleTypeId: d?.operation?.titleType?.name,
            };
          });
        }}
        filters={[
          {
            name: "id",
            title: "ID",
            col: 1,
          },

          {
            name: "document_number",
            title: "Documento",
            col: 2,
          },
          {
            name: "title_type_id",
            title: "Tipo",
            select: true,
            options: titleTypes.map((type) => ({
              value: type.id,
              title: type.name,
            })),
            col: 2,
          },
          {
            name: "start_date",
            title: "Vencimento (de)",
            type: "date",
            col: 2,
          },
          {
            name: "end_date",
            title: "Vencimento (até)",
            type: "date",
            col: 2,
          },
          {
            name: "start_value",
            title: "Valor (de)",
            normalize: normalizeCurrency,
            col: 2,
          },
          {
            name: "end_value",
            title: "Valor (até)",
            normalize: normalizeCurrency,
            col: 2,
          },
        ]}
        labels={[
          {
            name: "id",
            title: "Título",
          },
          {
            name: "documentNumber",
            title: "Documento",
          },
          {
            name: "payerId",
            title: "Sacado",
          },
          {
            name: "value",
            title: "Valor Original",
          },
          {
            name: "openValue",
            title: "Valor em Aberto",
          },
          {
            name: "date",
            title: "Vencimento",
          },
          {
            name: "operation_paid_date",
            title: "Data do Operação",
          },
          {
            name: "titleTypeId",
            title: "Tipo",
          },
        ]}
        editable={false}
        addItem={false}
        // aditionalActions={(t) => (
        //   <div className="ml-2">
        //     {t?.duplicate?.xml?.key && t?.operation?.titleType?.name === "NFe" && (
        //       <Tooltip title="Imprimir DANFE">
        //         <button
        //           type="button"
        //           onClick={() => handleDanfe(t?.duplicate?.xml?.key || "")}
        //         >
        //           <FaFile />
        //         </button>
        //       </Tooltip>
        //     )}
        //   </div>
        // )}
      />
    </div>
  );
};

export default memo(Prorrogation);

import styled from 'styled-components'

export const Container = styled.div`
  padding: 2rem;
  border-radius: 1rem;
  background: #fff;
  box-shadow: 0 0 5px rgba(0,0,0,.1);
  > h4 {
    font-weight: 700;
    padding-bottom: 15px;
    border-bottom: 1px solid rgb(247, 247, 247);
  }s
`

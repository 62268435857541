import produce from 'immer';

import {
  IInitialState,
  AnticipationActionTypes,
  STORE_OPERATION,
  STORE_TITLES,
  ADD_BANK_ACCOUNT,
  RESET_ANTICIPATION,
} from './types';
import { SWITCH_COMPANY } from '../user/types';
import { SIGN_OUT } from '../auth/types';

const INITIAL_STATE: IInitialState = {
  operationId: null,
  titles: [],
  bankAccount: '',
};

export default function auth(
  state = INITIAL_STATE,
  action: AnticipationActionTypes
): IInitialState {
  return produce(state, (draft) => {
    switch (action.type) {
      case STORE_OPERATION: {
        draft.operationId = action.payload.operationId;
        break;
      }
      case STORE_TITLES: {
        draft.titles = action.payload.titles;
        break;
      }
      case ADD_BANK_ACCOUNT: {
        draft.bankAccount = action.bankAccount;
        break;
      }
      case RESET_ANTICIPATION: {
        draft.operationId = null;
        draft.titles = [];
        draft.bankAccount = '';
        break;
      }
      case SWITCH_COMPANY: {
        draft.operationId = null;
        draft.titles = [];
        draft.bankAccount = '';
        break;
      }
      case SIGN_OUT: {
        draft.operationId = null;
        draft.titles = [];
        draft.bankAccount = '';
        break;
      }
      default:
    }
  });
}

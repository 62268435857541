import styled from "styled-components";

export const Container = styled.div`
  padding: 15px 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  div.header_component {
    border-bottom: 1px solid #eee;
    width: 100%;
    padding: 20px 25px;
    h4 {
      margin: 0 0 15px;
    }
  }
  .content_header {
    h3 {
      font-size: 18px;
      font-weight: bold;
      color: #555;
    }
  }
  .content_resume {
    background: #e5f5d4;
    display: flex;
    span {
      background: #cceba8;
      padding: 20px;
      min-width: 100px;
      min-height: 100px;
    }
    div {
      padding: 10px;
      color: #155724;
      strong {
      }
      p {
        margin: 0;
      }
    }
  }
  .content_section {
  }
  .content_section__box {
    .box_header {
      h4 {
        font-size: 18px;
        font-weight: bold;
        color: #555;
        margin: 0 0 20px;
      }
    }
    .box_content {
      p,
      strong {
        color: #555;
      }
    }
  }
  table {
    thead {
      tr {
        th {
          padding: 7.5px;
          color: #555;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 7.5px;
          line-height: 1.25;
          p {
            margin: 0px;
            color: #555;
            font-size: 14px;
          }
          span {
            font-size: 12px;
            color: #555;
            font-weight: 600;
          }
        }
      }
    }
  }
`;

export const NoEcnpjWarning = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px 0px;
  width: 100%;
  position: relative;
  .input_base {
    display: flex;
    align-items: center;
    border: 1px solid #eee;
    width: 100%;
    max-width: 400px;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    input {
      margin: 0px;
      border: none;
      outline: none !important;
      width: 100%;
      &::placeholder {
        color: #999;
      }
    }
    button {
      cursor: pointer;
      color: #4a68a8;
      &:disabled {
        opacity: 0.5;
      }
    }
  }
  > img {
    width: 100%;
    max-width: 180px;
    position: relative;
    z-index: 9;
  }
  div#text-no-ecnpj {
    width: 100%;
    max-width: 600px;
    padding-top: 80px;
    color: #656565;
    .detached {
      position: absolute;
      top: 16px;
      left: 0px;
      width: 100%;
      padding: 15px 0px;
      background: #f7f7f7;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: #656565;
      img {
        width: 45px;
        margin-right: 16px;
      }
      p {
        margin: 0px;
        width: 100%;
        max-width: 600px;
        strong {
          color: #4a68a8;
        }
      }
    }
  }
`;

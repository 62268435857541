import produce from 'immer';

import {
  IInitialState,
  AuthActionTypes,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_FAILURE,
  SIGN_OUT,
} from './types';
import { SWITCH_COMPANY } from '../user/types';

const INITIAL_STATE: IInitialState = {
  token: null,
  signed: false,
  loading: false,
};

export default function auth(
  state = INITIAL_STATE,
  action: AuthActionTypes
): IInitialState {
  return produce(state, (draft) => {
    switch (action.type) {
      case SIGN_IN_REQUEST: {
        draft.loading = true;
        break;
      }
      case SIGN_IN_SUCCESS: {
        draft.token = action.payload.token;
        draft.signed = true;
        draft.loading = false;
        break;
      }
      case SWITCH_COMPANY: {
        draft.token = action.payload.token;
        break;
      }
      case SIGN_FAILURE: {
        draft.token = null;
        draft.loading = false;
        break;
      }
      case SIGN_OUT: {
        draft.token = null;
        draft.signed = false;
        break;
      }
      default:
    }
  });
}

import React, { useState, useEffect } from "react";
import { Checkbox, Button, LinearProgress, TextField } from "@material-ui/core";
import {
  Warning,
  Delete,
  EventBusy,
  Receipt,
  Edit,
  WarningOutlined,
  WarningRounded,
} from "@material-ui/icons";
import { Table, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { format, addHours, isAfter } from "date-fns";

import { useDispatch } from "react-redux";

import Lottie from "react-lottie";

import { toast } from "react-toastify";
import animationData from "~/animations/box_empty.json";

import ModalXml from "./ModalXml";
import ModalCnab from "./ModalCnab";
import ModalSyncPayer from "./ModalSyncPayer";
import ModalDelete from "./ModalDelete";
import ModalDeleteAll from "./ModalDeleteAll";
import {
  normalizeCurrency,
  normalizeCpfCnpj,
  normalizeNumber,
} from "../utils/normalize";
import {
  formatDate,
  normalizeBrl,
  payerIsComplete,
  stringDateToISOString,
  subString,
} from "../../utils/normalize";
import { useOperation } from "~/hooks/useOperation";
import ModalEditPayer from "./ModalEditPayer";
import { useAuth } from "~/hooks/useAuth";
import { api } from "~/hooks/api";
import { ModalDiscount } from "../../components/ModalDiscount";

const TableOne: React.FC = () => {
  const { titles: selected, handleTitle, resetOperation } = useOperation();
  const { currentAssignor } = useAuth();

  const assignorOperatesViaCnab = !!currentAssignor.operate_nfe_via_cnab;

  const [editDiscount, setEditDiscount] = useState({
    open: false,
    id: 0,
    value: 0,
    originalValue: 0,
  });

  const dispatch = useDispatch();
  const [confirmDelete, setConfirmDelete] = useState({
    open: false,
    id: 0,
  });
  const [confirmDeleteAll, setConfirmDeleteAll] = useState({
    open: false,
  });
  const [isSearch, setIsSearch] = useState(false);
  const [titles, setTitles] = useState<Title[]>([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    dataTituStart: "",
    dataTituEnd: "",
    valoTituStart: "",
    valoTituEnd: "",
    chave: "",
    sacaId: "",
  });
  const [updatePayer, setUpdatePayer] = useState({
    open: false,
    title: {} as Title,
  });
  const [xmlOpen, setXmlOpen] = useState(false);
  const [cnabOpen, setCnabOpen] = useState(false);
  const [syncPayerOpen, setSyncPayerOpen] = useState(false);

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  function transformResponse(data: any) {
    const result: any[] = [];
    let lastDate: any = null;
    let fakeInc = 0;

    for (const item of data) {
      const createdAt = new Date(item.created_at);
      const dateStr = createdAt.toISOString().split("T")[0];
      const hourStr = createdAt.getHours();

      const dateTimeGroup = `${dateStr} ${hourStr}h`;
      if (dateTimeGroup !== lastDate) {
        fakeInc = fakeInc + 1;
        result.push({
          ...item,
          id: `fake_${fakeInc}`,
          payer: { name: "" },
          xml: { key: "" },
          number: `Envio: ${formatDate(item.created_at, false)} às ${hourStr}h`,
          date: "",
          value: "",
        });
        lastDate = dateTimeGroup;
      }
      result.push(item);
    }
    return result;
  }

  async function loadData() {
    setLoading(true);
    try {
      const query: string[] = [];
      query.push("type=NFE");
      if (filters.dataTituStart !== "") {
        query.push(
          `start_date=${stringDateToISOString(filters.dataTituStart)}`
        );
      }
      if (filters.dataTituEnd !== "") {
        query.push(`end_date=${stringDateToISOString(filters.dataTituEnd)}`);
      }
      if (filters.valoTituStart !== "") {
        query.push(
          `start_value=${parseFloat(
            filters.valoTituStart.replace(/[^\d]/g, "")
          )}`
        );
      }
      if (filters.valoTituEnd !== "") {
        query.push(
          `end_value=${parseFloat(filters.valoTituEnd.replace(/[^\d]/g, ""))}`
        );
      }
      if (filters.chave !== "") {
        query.push(`key=${filters.chave}`);
      }
      if (filters.sacaId !== "") {
        query.push(`document_number=${filters.sacaId.replace(/[^\d]/g, "")}`);
      }
      const { data } = await api.get(
        `duplicates?${query.map((q) => q).join("&")}`
      );

      const transform = transformResponse(data);
      setTitles(transform);
    } catch (error) {} // eslint-disable-line
    setLoading(false);
  }

  async function handleDelete() {
    setLoading(true);
    try {
      await api.delete(`duplicates/${confirmDelete.id}`);
      handleTitle(selected.filter((t) => t.id !== confirmDelete.id));
      setTitles(titles.filter((t) => t.id !== confirmDelete.id));
      setConfirmDelete({
        open: false,
        id: 0,
      });
      toast.info("Título excluído");
    } catch (err) {
      //
    }
    setLoading(false);
  }

  async function handlePrintBoleto() {
    setLoading(true);
    try {
      window.open(
        `${process.env.REACT_APP_API}/pre_printed_tickets/${
          currentAssignor.token
        }?titleTypeId=1&ids=${selected.map((title) => title.id).join(",")}`
      );
    } catch (err) {
      //
    }
    setLoading(false);
  }

  async function handleDeleteAll() {
    setLoading(true);
    for (let i = 0; i < selected.length; i += 1) {
      const title = selected[i];
      try {
        await api.delete(`duplicates/${title.id}`);
        handleTitle(selected.filter((t) => t.id !== title.id));
      } catch (err) {} // eslint-disable-line
    }
    setConfirmDeleteAll({
      open: false,
    });
    loadData();
    toast.info("Títulos excluídos");
    setLoading(false);
  }

  useEffect(() => {
    resetOperation();
  }, []); // eslint-disable-line

  function handleAll() {
    handleTitle(
      titles.filter(
        (t) =>
          !String(t.id).startsWith("fake") &&
          payerIsComplete(currentAssignor.require_payer_details, t) &&
          !isAfter(new Date(), new Date(t.date))
      )
    );
  }

  useEffect(() => {
    loadData();
  }, [dispatch]); // eslint-disable-line

  return (
    <div id="anticipation_table_wrap">
      {editDiscount.open && (
        <ModalDiscount
          id={editDiscount.id}
          onHide={() => {
            setEditDiscount({
              open: false,
              id: 0,
              value: 0,
              originalValue: 0,
            });
            loadData();
          }}
          value={editDiscount.value}
          originalValue={editDiscount.originalValue}
        />
      )}
      <div className="d-flex justify-content-between align-items-center">
        <h5>
          <strong>Antecipar Recebíveis - NF-e</strong>
        </h5>
        <div className="d-flex flex-wrap justify-content-end">
          {assignorOperatesViaCnab ? (
            <>
              <Button
                type="button"
                onClick={() => setCnabOpen(true)}
                color="primary"
                variant="contained"
                className="mr-2 text-white"
              >
                Enviar CNAB's
              </Button>
              {titles.length > 0 ? (
                <Button
                  type="button"
                  onClick={() => setXmlOpen(true)}
                  color="primary"
                  variant="contained"
                  className="mr-2 text-white"
                >
                  Enviar XML's
                </Button>
              ) : null}
            </>
          ) : (
            <Button
              type="button"
              onClick={() => setXmlOpen(true)}
              color="primary"
              variant="contained"
              className="mr-2 text-white"
            >
              Enviar XML's
            </Button>
          )}

          {titles.length > 0 ? (
            <Button
              type="button"
              onClick={() => setSyncPayerOpen(true)}
              color="primary"
              variant="contained"
              className="mr-2 text-white"
            >
              Sincronizar Sacados
            </Button>
          ) : null}
          <Button
            type="button"
            onClick={() => {
              if (isSearch) {
                loadData();
              } else {
                setIsSearch(true);
              }
            }}
            disabled={loading}
            color="primary"
            variant="outlined"
          >
            {isSearch ? "Filtrar" : "Exibir filtro"}
          </Button>
          {selected.length > 0 && (
            <Button
              type="button"
              onClick={() => {
                setConfirmDeleteAll({
                  open: true,
                });
              }}
              disabled={loading}
              className="mt-2 ml-2 mr-2 text-danger"
              color="secondary"
              variant="outlined"
            >
              Excluir selecionados
            </Button>
          )}
          {selected.length > 0 && currentAssignor.can_pre_print_type_1 && (
            <Button
              type="button"
              onClick={() => {
                handlePrintBoleto();
              }}
              disabled={loading}
              className="mt-2"
              variant="outlined"
            >
              Imprimir Boleto(s)
            </Button>
          )}
        </div>
      </div>
      {isSearch && (
        <Row className="mb-3 mt-0 filters-wrap">
          <Col lg={6} className="mb-2 mt-0">
            <small className="d-block mb-2">Data de Vencimento</small>
            <div className="d-flex align-items-center">
              <TextField
                type="date"
                value={filters.dataTituStart}
                onChange={(e) =>
                  setFilters({ ...filters, dataTituStart: e.target.value })
                }
              />
              <small className="d-block ml-2 mr-2">até</small>
              <TextField
                type="date"
                value={filters.dataTituEnd}
                onChange={(e) =>
                  setFilters({ ...filters, dataTituEnd: e.target.value })
                }
              />
            </div>
          </Col>
          <Col lg={6} className="mb-2 mt-0">
            <small className="d-block mb-2">Valor</small>
            <div className="d-flex align-items-center">
              <TextField
                placeholder="0,00"
                value={filters.valoTituStart}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    valoTituStart: normalizeCurrency(
                      Number(e.target.value.replace(/[^\d]/g, ""))
                    ),
                  })
                }
              />
              <small className="d-block ml-2 mr-2">até</small>
              <TextField
                placeholder="0,00"
                value={filters.valoTituEnd}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    valoTituEnd: normalizeCurrency(
                      Number(e.target.value.replace(/[^\d]/g, ""))
                    ),
                  })
                }
              />
            </div>
          </Col>
          <Col lg={6} className="mb-2 mt-0 d-flex align-items-end">
            <TextField
              label="Chave"
              value={filters.chave}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  chave: normalizeNumber(e.target.value),
                })
              }
            />
          </Col>
          <Col lg={6} className="mb-2 mt-0 d-flex align-items-end">
            <TextField
              label="Cliente (CNPJ/CPF)"
              value={filters.sacaId}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  sacaId: normalizeCpfCnpj(e.target.value),
                })
              }
            />
          </Col>
        </Row>
      )}
      <ModalDelete
        open={confirmDelete.open}
        setOpen={() =>
          setConfirmDelete({
            id: 0,
            open: false,
          })
        }
        onConfirm={() => handleDelete()}
      />
      <ModalDeleteAll
        open={confirmDeleteAll.open}
        setOpen={() =>
          setConfirmDeleteAll({
            open: false,
          })
        }
        onConfirm={() => handleDeleteAll()}
      />
      <ModalEditPayer
        open={updatePayer.open}
        onAdd={() => {
          setUpdatePayer({
            open: false,
            title: {} as Title,
          });
          loadData();
        }}
        setOpen={() =>
          setUpdatePayer({
            open: false,
            title: {} as Title,
          })
        }
        note={updatePayer.title}
      />
      <ModalXml
        open={xmlOpen}
        assignorCode={currentAssignor.code}
        setOpen={() => {
          sessionStorage.setItem("xml_uploaded", "[]");
          setXmlOpen(false);
          loadData();
        }}
      />
      <ModalCnab
        open={cnabOpen}
        setOpen={() => {
          sessionStorage.setItem("cnab_uploaded", "[]");
          setCnabOpen(false);
          loadData();
        }}
      />
      <ModalSyncPayer
        open={syncPayerOpen}
        setOpen={() => {
          sessionStorage.setItem("sync_payer_uploaded", "[]");
          setSyncPayerOpen(false);
          loadData();
        }}
      />
      {loading && <LinearProgress />}
      <Table responsive hover>
        <thead>
          <tr>
            <th className="text-left">Cliente</th>
            <th>Chave</th>
            <th>Documento</th>
            <th>Vencimento</th>
            {currentAssignor.apply_discount && <th>Desconto</th>}
            <th>Valor</th>
            <th />
            <th>
              <Checkbox
                checked={
                  selected.length ===
                  titles.filter((t) => !String(t.id).startsWith("fake")).length
                }
                onClick={handleAll}
                color="primary"
                disabled={loading}
              />
            </th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {titles.map((n) => {
            const checkedV = selected.find((i) => `${i.id}` === `${n.id}`);
            const isExpired = isAfter(new Date(), new Date(n.date));

            return (
              <tr
                key={`${n.id}`}
                className={
                  String(n.id).startsWith("fake")
                    ? ""
                    : checkedV
                    ? "selected"
                    : isExpired
                    ? "disabled"
                    : ""
                }
              >
                <td
                  className="d-flex align-items-center"
                  style={{ width: "100%" }}
                >
                  {n.value && !n.xml?.key && (
                    <OverlayTrigger
                      overlay={
                        <Tooltip
                          className="bg-black"
                          title="Duplicata"
                          id={`tooltip-${Math.random().toString()}`}
                        >
                          <span>Necessário enviar XML da duplicata</span>
                        </Tooltip>
                      }
                    >
                      <WarningRounded className="text-warning mr-2" />
                    </OverlayTrigger>
                  )}
                  <div className="text-left">
                    <strong>{subString(n.payer.name, 22)}</strong>
                    <br />
                    <small>
                      {normalizeCpfCnpj(n.payer.document_number || "")}
                    </small>
                  </div>
                </td>
                <td>
                  {n.xml?.key?.slice(0, Math.floor(n.xml?.key?.length / 2))}
                  <br />
                  {n.xml?.key?.slice(
                    Math.floor(n.xml?.key?.length / 2),
                    n.xml?.key?.length
                  )}
                </td>
                {!String(n.id).startsWith("fake") ? (
                  <td>{n.number}</td>
                ) : (
                  <td>
                    {" "}
                    <strong>{n.number}</strong>
                  </td>
                )}
                <td>
                  {n.date
                    ? format(addHours(new Date(n.date), 3), "dd/MM/yyyy")
                    : ""}
                </td>
                {!String(n.id).startsWith("fake") &&
                  currentAssignor.apply_discount && (
                    <td>
                      <div className="d-flex align-items-center">
                        {normalizeBrl(n.discount_value)}
                        <button
                          type="button"
                          onClick={() => {
                            setEditDiscount({
                              open: true,
                              id: n.id,
                              value: n.discount_value,
                              originalValue: n.value,
                            });
                          }}
                        >
                          <Edit />
                        </button>
                      </div>
                    </td>
                  )}
                <td>{normalizeBrl(n.value)}</td>
                <td style={{ width: "10px" }}>
                  {!String(n.id).startsWith("fake") &&
                    !isExpired &&
                    currentAssignor.require_payer_details &&
                    !payerIsComplete(
                      currentAssignor.require_payer_details,
                      n
                    ) && (
                      <button
                        type="button"
                        onClick={() =>
                          setUpdatePayer({
                            open: true,
                            title: n,
                          })
                        }
                      >
                        <Warning className="text-warning" />
                      </button>
                    )}
                  {!String(n.id).startsWith("fake") && isExpired && (
                    <EventBusy color="error" />
                  )}
                </td>
                {!String(n.id).startsWith("fake") ? (
                  <td
                    style={{ width: "10px" }}
                    onClick={() => {
                      if (isExpired) {
                        return;
                      }
                      if (
                        !payerIsComplete(
                          currentAssignor.require_payer_details,
                          n
                        )
                      ) {
                        setUpdatePayer({
                          open: true,
                          title: n,
                        });
                      } else if (!loading) {
                        handleTitle(n);
                      }
                    }}
                  >
                    <Checkbox
                      disabled={isExpired}
                      checked={!!checkedV}
                      color="primary"
                    />
                  </td>
                ) : (
                  <td style={{ width: "10px" }}></td>
                )}
                {!String(n.id).startsWith("fake") ? (
                  <td style={{ width: "20px" }}>
                    {!isExpired &&
                      currentAssignor.can_pre_print_type_1 &&
                      payerIsComplete(
                        currentAssignor.require_payer_details,
                        n
                      ) && (
                        <button
                          type="button"
                          onClick={() =>
                            window.open(
                              `${process.env.REACT_APP_API}/pre_printed_tickets/${currentAssignor.token}?titleTypeId=1&ids=${n.id}`
                            )
                          }
                        >
                          <Receipt />
                        </button>
                      )}
                  </td>
                ) : (
                  <td style={{ width: "20px" }}></td>
                )}
                {!String(n.id).startsWith("fake") ? (
                  <td style={{ width: "20px" }}>
                    <button
                      type="button"
                      onClick={() =>
                        setConfirmDelete({
                          open: true,
                          id: n.id || 0,
                        })
                      }
                    >
                      <Delete className="text-danger" />
                    </button>
                  </td>
                ) : (
                  <td style={{ width: "20px" }}></td>
                )}
              </tr>
            );
          })}
          {!loading && titles.length === 0 && (
            <tr style={{ height: "350px" }}>
              <td className="text-center" colSpan={9}>
                <Lottie options={defaultOptions} height={160} width={160} />
                <h4>Nenhuma nota encontrada.</h4>
                <h5>
                  {assignorOperatesViaCnab ? (
                    <>
                      Faça o upload de seus CNAB's para listarmos seus
                      recebíveis disponíveis.
                      <br />O envio de XML será liberado após o envio dos
                      CNAB's.
                    </>
                  ) : (
                    "Faça upload de seus XML's para listarmos seus recebíveis disponíveis."
                  )}
                </h5>
                {currentAssignor.operate_nfe_via_cnab ? (
                  <div className="d-flex justify-content-center mt-3">
                    <Button
                      type="button"
                      onClick={() => setCnabOpen(true)}
                      color="primary"
                      variant="contained"
                      className="ml-2 text-white"
                    >
                      Enviar CNAB's
                    </Button>
                  </div>
                ) : null}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default TableOne;

import { cpfIsValid } from "~/pages/main/utils/normalize";

export const normalizeCep = (value: string): string => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, "");
  if (onlyNums.length <= 5) {
    return onlyNums;
  }
  return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5, 8)}`;
};

export const normalizeCpf = (value: string): string => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, "");
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}`;
  }
  if (onlyNums.length <= 9) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(
      6,
      9
    )}`;
  }
  return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(
    6,
    9
  )}-${onlyNums.slice(9, 11)}`;
};

export const normalizeCnpj = (value: string): string => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, "");
  if (onlyNums.slice(0, 3) === "000" && cpfIsValid(onlyNums.slice(3))) {
    return normalizeCpf(onlyNums.slice(3));
  }
  if (onlyNums.length <= 2) {
    return onlyNums;
  }
  if (onlyNums.length <= 5) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}`;
  }
  if (onlyNums.length <= 8) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
      5,
      8
    )}`;
  }
  if (onlyNums.length <= 12) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
      5,
      8
    )}/${onlyNums.slice(8, 12)}`;
  }
  return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
    5,
    8
  )}/${onlyNums.slice(8, 12)}-${onlyNums.slice(12, 14)}`;
};

export const normalizeDate = (value: string): string => {
  if (!value) {
    return value;
  }

  const formated = value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{4})\d+?$/, "$1");

  return formated;
};

export const normalizeAccBank = (value: string): string => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/\D/g, "");

  if (onlyNums.length === 1) {
    return onlyNums;
  }

  return `${onlyNums.slice(0, onlyNums.length - 1)}-${onlyNums.slice(
    onlyNums.length - 1,
    onlyNums.length
  )}`;
};

export const normalizeNumber = (value: string): string => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/\D/g, "");

  return onlyNums;
};

export const normalizePercentage = (value: string): string => {
  let onlyNums = value.replace(/\D/g, "");

  if (onlyNums.length === 0) {
    onlyNums = ``;
  } else if (onlyNums.length === 1) {
    onlyNums = `${onlyNums.slice(0, 1)}`;
  } else if (onlyNums.length === 2) {
    onlyNums = `${onlyNums.slice(0, 2)}`;
  } else if (onlyNums.length === 3) {
    onlyNums = `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 3)}`;
  } else if (onlyNums.length === 4) {
    onlyNums = `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 4)}`;
  } else if (onlyNums.length === 5 && onlyNums === "10000") {
    onlyNums = `100.00`;
  } else {
    onlyNums = `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 4)}`;
  }

  return onlyNums;
};

export const normalizeTelephone = (value: string): string => {
  if (!value) {
    return value;
  }

  let onlyNums = value.replace(/\D/g, "");

  if (onlyNums.length === 0) {
    onlyNums = ``;
  } else if (onlyNums.length === 1) {
    onlyNums = `(${onlyNums.slice(0, 1)}`;
  } else if (onlyNums.length === 2) {
    onlyNums = `(${onlyNums.slice(0, 2)}`;
  } else if (onlyNums.length < 7) {
    onlyNums = `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 7)}`;
  } else if (onlyNums.length < 11) {
    onlyNums = `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(
      2,
      6
    )}-${onlyNums.slice(6, 11)}`;
  } else {
    onlyNums = `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(
      2,
      7
    )}-${onlyNums.slice(7, 11)}`;
  }

  return onlyNums;
};

export const normalizeCpfCnpj = (value: string): string => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, "");
  if (onlyNums.slice(0, 3) === "000" && cpfIsValid(onlyNums.slice(3))) {
    return normalizeCpf(onlyNums.slice(3));
  }
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}`;
  }
  if (onlyNums.length <= 9) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(
      6,
      9
    )}`;
  }
  if (onlyNums.length <= 11) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(
      6,
      9
    )}-${onlyNums.slice(9, 11)}`;
  }
  if (onlyNums.length <= 12) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
      5,
      8
    )}/${onlyNums.slice(8, 12)}`;
  }
  return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
    5,
    8
  )}/${onlyNums.slice(8, 12)}-${onlyNums.slice(12, 14)}`;
};

export const normalizeCurrency = (value: any): string => {
  const stringOfNumber = String(value);
  if (stringOfNumber.length === 2) {
    return `0,${stringOfNumber[0] || 0}${stringOfNumber[1] || 0}`;
  }
  if (stringOfNumber.length === 1) {
    return `0,0${stringOfNumber[0] || 0}`;
  }
  const cents =
    stringOfNumber[stringOfNumber.length - 2] +
    stringOfNumber[stringOfNumber.length - 1];
  const reals = stringOfNumber.slice(0, stringOfNumber.length - 2);
  let pointValue = "";

  const arrayNumber = reals.split("");
  arrayNumber.reverse();
  arrayNumber.forEach((v, index) => {
    if ((index + 1) % 3 === 0) {
      if (index === arrayNumber.length - 1) {
        pointValue = v + pointValue;
      } else {
        pointValue = `.${v}${pointValue}`;
      }
    } else {
      pointValue = v + pointValue;
    }
  });
  return `${pointValue},${cents}`;
};

export const normalizeCurrency2 = (value: any, styled = false): string => {
  if (!value) {
    return value;
  }

  const isNegative = value < 0;

  if (Number(value) === 0) {
    return `${styled ? "R$ " : ""}0,00`;
  }

  const parsedValue = Number(String(value).replace(/\D/g, "")) / 100;

  if (styled) {
    return parsedValue.toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
  }
  return isNegative
    ? `- ${parsedValue.toLocaleString("pt-br", {
        minimumFractionDigits: 2,
      })}`
    : parsedValue.toLocaleString("pt-br", {
        minimumFractionDigits: 2,
      });
};

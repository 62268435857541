import { applyMiddleware, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";

import rootReducer from "./modules/rootReducer";
import rootSaga from "./modules/rootSaga";

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  persistReducer(
    {
      key: "bfcdigital-admin",
      storage,
      whitelist: ["auth", "user", "anticipation", "checkAnticipation"], // RODRIGO
    },
    rootReducer
  ),
  applyMiddleware(sagaMiddleware)
);

const persitor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { persitor, store };

import { CircularProgress, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { api } from "~/hooks/api";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { Layout } from "../components/Layout";

const Recovery: React.FC = ({ match }: any) => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState({
    password: "",
    confirmPassword: "",
  });
  const [msg, setMsg] = useState("");

  const { token } = match.params;
  const history = useHistory();

  if (!token || token.length === 0) {
    history.push("/auth/home");
  }

  async function handleSubmit(e: any) {
    e.preventDefault();
    setMsg("");
    setError({
      password: "",
      confirmPassword: "",
    });
    let lock_error = {
      password: "",
      confirmPassword: "",
    };
    function passwordIsValid(senha: string) {
      const regex = /^(?=(?:.*?[0-9]){1})(?=(?:.*?[a-zA-Z]){1})(?!.*\s)[0-9a-zA-Z!@#$%;*(){}_+^&]*$/;
      if (senha.length < 8) {
        return false;
      }
      if (!regex.exec(senha)) {
        return false;
      }
      return true;
    }
    if (values.password.length === 0) {
      lock_error.password = "Preencha este campo";
    } else if (values.password.length < 8) {
      lock_error.password = "A senha deve conter no minímo 8 digitos!";
    } else if (!passwordIsValid(values.password)) {
      lock_error.password =
        "A senha deve possuir no mínimo um caracter e um número.";
    }
    if (values.confirmPassword.length === 0) {
      lock_error.confirmPassword = "Preencha esse Campo";
    }
    if (values.password !== values.confirmPassword) {
      lock_error = {
        password: "Senhas não coincidem",
        confirmPassword: "Senhas não coincidem",
      };
    }
    if (lock_error.password !== "" || lock_error.confirmPassword !== "") {
      return setError(lock_error);
    }
    setLoading(true);
    try {
      await api.post(
        "/reset-password/assignor",
        {
          password: values.password,
        },
        { params: { token } }
      );
      history.push("/auth/login");
      toast.success("Senha atualizada");
    } catch (err) {
      //
    }
    setLoading(false);
  }

  return (
    <Layout>
      <div className="p-4">
        <h4 className="text-main">
          <strong>Recuperar Conta</strong>
        </h4>
        <form onSubmit={handleSubmit}>
          <div className="form-container">
            <p>
              Senha deverá conter no mínimo oito caracteres, sendo pelo menos um
              número.
            </p>
            {msg !== "" && <Alert severity="error">{msg}</Alert>}
            <div className="row">
              <div className="col-md-12">
                <TextField
                  type="password"
                  label="Senha"
                  value={values.password}
                  onChange={(e) =>
                    setValues({ ...values, password: e.target.value })
                  }
                  style={{ animationDelay: "150ms" }}
                  error={error.password !== ""}
                  helperText={error.password}
                  disabled={loading}
                  variant="outlined"
                  size="small"
                />
                <TextField
                  type="password"
                  className="mt-2"
                  label="Confirmar Senha"
                  value={values.confirmPassword}
                  onChange={(e) =>
                    setValues({ ...values, confirmPassword: e.target.value })
                  }
                  style={{ animationDelay: "300ms" }}
                  error={error.confirmPassword !== ""}
                  helperText={error.confirmPassword}
                  disabled={loading}
                  variant="outlined"
                  size="small"
                />
                <div className="mt-4">
                  <button
                    type="submit"
                    className="btn btn-primary bg-main"
                    disabled={loading}
                  >
                    {loading ? <CircularProgress /> : "Recuperar Conta"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default Recovery;

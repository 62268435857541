import { CircularProgress, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { api } from "~/hooks/api";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Layout } from "../components/Layout";
import { FaChevronLeft } from "react-icons/fa";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const [loading, setLoading] = useState(false);

  const [msg, setMsg] = useState({ type: "", value: "" });

  async function handleSubmit(e: any) {
    e.preventDefault();
    setError("");
    function emailIsValid(email: string) {
      if (email.indexOf("@") === -1) {
        return false;
      }
      const after = email.split("@");
      if (after[1].indexOf(".") === -1) {
        return false;
      }
      return true;
    }
    if (email.length === 0) {
      return setError("Preencha um email");
    }

    if (!emailIsValid(email)) {
      return setError("Email não é válido");
    }

    setLoading(true);
    try {
      await api.post("/forgot-password/assignor", {
        email,
      });
      setMsg({
        type: "success",
        value: "Email enviado com o link de recuperação de senha.",
      });
    } catch (err) {
      //
    }
    setLoading(false);
  }

  return (
    <Layout>
      <div className="p-4">
        <h4 className="text-main mb-0">
          <strong>Esqueceu a Senha?</strong>
        </h4>
        <Link to="/auth/login" className="text-muted">
          <small>
            <u>
              <FaChevronLeft /> Voltar para Login
            </u>
          </small>
        </Link>
        <form onSubmit={handleSubmit} className="mt-4">
          <div className="form-container">
            <p className="descript">
              Preencha seu email para recuperar sua senha.
              <br />
              Enviaremos um e-mail com um link para redefini-la.
            </p>
            {msg.type !== "" && msg.value !== "" && (
              <Alert severity={msg.type === "error" ? "error" : "success"}>
                {msg.value}
              </Alert>
            )}
            <div className="row">
              <div className="col-md-12">
                <TextField
                  label="E-mail"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{ animationDelay: "150ms" }}
                  error={error !== ""}
                  helperText={error}
                  disabled={loading}
                  size="small"
                  variant="outlined"
                  required
                />

                <div className="d-flex align-items-center flex-wrap mt-4">
                  <button
                    type="submit"
                    className="bg-main btn btn-primary pl-3 pr-3 mr-2"
                  >
                    {loading ? (
                      <CircularProgress size={14} style={{ color: "#fff" }} />
                    ) : (
                      "Enviar e-mail"
                    )}
                  </button>
                  <Link to="/auth/login" className="btn btn-outline-secondary">
                    Voltar
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
}

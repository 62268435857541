import { api, useFetch } from "../../../../../hooks/api";
import {
  normalizeCpfCnpj,
  normalizeNumber,
  normalizePhone,
} from "../../../utils/normalize";
import React, { useState } from "react";

import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { useAuth } from "~/hooks/useAuth";
import { Alert } from "@material-ui/lab";
import {
  FormControl,
  InputLabel,
  NativeSelect,
  TextField,
} from "@material-ui/core";

interface IProps {
  onClose: () => void;
  account?: any;
  onAdd?: (item: any) => void;
  onUpdate?: (item: any) => void;
  onDelete?: (id: number) => void;
}

export const ModalAddBankAccount: React.FC<IProps> = ({
  account,
  onClose,
  onAdd,
  onUpdate,
  onDelete,
}) => {
  const { pendencies, currentAssignor, returnPendencies } = useAuth();
  const pend = pendencies.filter(
    (p) => p.type === "accounts" && account?.id && p.account_id === account?.id
  );
  const { data: banks } = useFetch(
    `${process.env.REACT_APP_API?.split("/app").join("")}/banks?all=1`
  );
  const [pix, setPix] = useState(() => {
    if (account) {
      let key = account.pix_key;
      if (account.pix_type === "phone") {
        key = normalizePhone(key);
      } else if (account.pix_type === "document") {
        key = normalizeCpfCnpj(key);
      }
      return {
        keyType: account.pix_type,
        key,
      };
    }
    return {
      keyType: "",
      key: "",
    };
  });
  const [values, setValues] = useState(() => {
    if (account) {
      return {
        ...account,
        owner_document_number: normalizeCpfCnpj(account.owner_document_number),
      };
    }

    return {} as any;
  });

  const [confirmDelete, setConfirmDelete] = useState(false);

  const [loading, setLoading] = useState(false);

  async function handleAdd(): Promise<any> {
    return await api.post(`/register/banks`, {
      ...values,
      pix_type: pix.keyType,
      pix_key:
        pix.keyType === "phone" || pix.keyType === "document"
          ? normalizeNumber(pix.key)
          : pix.key,
      owner_document_number: normalizeNumber(values.owner_document_number),
    });
  }

  async function handleEdit(): Promise<any> {
    return await api.put(`/register/banks/${values.id}`, {
      ...values,
      pix_type: pix.keyType,
      pix_key:
        pix.keyType === "phone" || pix.keyType === "document"
          ? normalizeNumber(pix.key)
          : pix.key,
      owner_document_number: normalizeNumber(values.owner_document_number),
    });
  }

  async function handleDelete(): Promise<any> {
    setLoading(true);
    try {
      await api.delete(`/register/banks/${values.id}`);
      if (onDelete) {
        onDelete(account?.id);
      }
      toast.success("Conta Bancária deletada");
      onClose();
    } catch (err) {
      //
    }
    setLoading(false);
  }

  async function handleSubmit() {
    setLoading(true);
    try {
      if (values.id) {
        const { data } = await handleEdit();
        toast.success("Conta Bancária atualizada");
        if (onUpdate) {
          onUpdate(data);
        }
        await returnPendencies(pend);
        onClose();
      } else {
        const { data } = await handleAdd();
        toast.success("Conta Bancária adicionada");
        if (onAdd) {
          onAdd(data);
        }
        onClose();
      }
    } catch (err) {
      //
    }
    setLoading(false);
  }

  return (
    <Modal show centered onHide={onClose}>
      <Modal.Header closeButton>
        {account?.id ? "Atualizar Conta Bancária" : "Adicionar Conta Bancária"}
      </Modal.Header>
      <Modal.Body>
        <Row>
          {pend.length > 0 && (
            <Col lg={12}>
              {pend.map((p) => (
                <Alert severity="error">
                  <strong className="d-block">{p.title}</strong>
                  <small>{p.message}</small>
                </Alert>
              ))}
            </Col>
          )}
          <Col lg={12}>
            <FormControl>
              <InputLabel variant="standard">Banco</InputLabel>
              <NativeSelect
                value={values.bank_id}
                onChange={(e) =>
                  setValues({ ...values, bank_id: Number(e.target.value) })
                }
                disabled={
                  loading ||
                  (currentAssignor.is_finished ? pend.length === 0 : false) ||
                  currentAssignor.is_approved
                }
              >
                <option value="">Selecionar</option>
                {banks?.map((bank: any) => (
                  <option value={bank.id} key={bank.id}>
                    {bank.code} - {bank.name}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          </Col>
        </Row>
        <Row>
          <Col lg={5}>
            <Form.Group className="mb-1">
              <div className="d-flex align-items-center">
                <TextField
                  label="Agência"
                  value={values.agency}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      agency: normalizeNumber(e.target.value),
                    })
                  }
                  disabled={
                    loading ||
                    (currentAssignor.is_finished ? pend.length === 0 : false) ||
                    currentAssignor.is_approved
                  }
                />
                <span className="d-block ml-2 mr-2">{"-"}</span>
                <TextField
                  label="DV"
                  value={values.agency_digit}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      agency_digit: normalizeNumber(e.target.value),
                    })
                  }
                  style={{ maxWidth: "40px" }}
                  disabled={
                    loading ||
                    (currentAssignor.is_finished ? pend.length === 0 : false) ||
                    currentAssignor.is_approved
                  }
                />
              </div>
            </Form.Group>
          </Col>
          <Col lg={7}>
            <Form.Group className="mb-1">
              <div className="d-flex align-items-center">
                <TextField
                  label="Conta"
                  value={values.account_number}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      account_number: normalizeNumber(e.target.value),
                    })
                  }
                  disabled={
                    loading ||
                    (currentAssignor.is_finished ? pend.length === 0 : false) ||
                    currentAssignor.is_approved
                  }
                />
                <span className="d-block ml-2 mr-2">{"-"}</span>
                <TextField
                  label="DV"
                  value={values.account_digit}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      account_digit: normalizeNumber(e.target.value),
                    })
                  }
                  style={{ maxWidth: "40px" }}
                  disabled={
                    loading ||
                    (currentAssignor.is_finished ? pend.length === 0 : false) ||
                    currentAssignor.is_approved
                  }
                />
              </div>
            </Form.Group>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col lg={4}>
            <Form.Group className="mb-1">
              <TextField
                label="Tp. Chave PIX"
                value={pix.keyType || ""}
                onChange={(e) => setPix({ key: "", keyType: e.target.value })}
                disabled={
                  loading ||
                  (currentAssignor.is_finished ? pend.length === 0 : false) ||
                  currentAssignor.is_approved
                }
                select
              >
                <option value="">Selecionar</option>
                <option value="document">CPF/CNPJ</option>
                <option value="phone">Telefone</option>
                <option value="email">E-mail</option>
                <option value="random">Aleatória</option>
              </TextField>
            </Form.Group>
          </Col>
          <Col lg={8}>
            <Form.Group className="mb-1">
              <TextField
                label="Chave"
                value={pix.key || ""}
                onChange={(e) => {
                  let value = e.target.value;
                  if (pix.keyType === "document") {
                    value = normalizeCpfCnpj(value);
                  } else if (pix.keyType === "phone") {
                    value = normalizePhone(value);
                  }
                  setPix({ ...pix, key: value });
                }}
                disabled={
                  pix.keyType === "" ||
                  loading ||
                  (currentAssignor.is_finished ? pend.length === 0 : false) ||
                  currentAssignor.is_approved
                }
              />
            </Form.Group>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col lg={12}>
            <Form.Group className="mb-1">
              <TextField
                label="Nome do Beneficiário"
                value={values.owner_name}
                onChange={(e) =>
                  setValues({
                    ...values,
                    owner_name: e.target.value.toUpperCase(),
                  })
                }
                disabled={
                  loading ||
                  (currentAssignor.is_finished ? pend.length === 0 : false) ||
                  currentAssignor.is_approved
                }
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Form.Group className="mb-1">
              <TextField
                label="CNPJ/CPF do Beneficiário"
                value={values.owner_document_number}
                onChange={(e) =>
                  setValues({
                    ...values,
                    owner_document_number: normalizeCpfCnpj(e.target.value),
                  })
                }
                disabled={
                  loading ||
                  (currentAssignor.is_finished ? pend.length === 0 : false) ||
                  currentAssignor.is_approved
                }
              />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        {!confirmDelete && (
          <div
            className={`d-flex justify-content-${
              values.id && !currentAssignor.is_finished ? "between" : "end"
            } mb-4`}
            style={{ width: "100%" }}
          >
            {values.id && !currentAssignor.is_finished && (
              <Button
                type="button"
                variant="outline-danger"
                className="btn-sm"
                onClick={() => setConfirmDelete(true)}
              >
                Excluir
              </Button>
            )}

            <div className="d-flex">
              <Button
                type="button"
                variant="primary"
                onClick={handleSubmit}
                className="btn-sm"
                disabled={
                  loading ||
                  (currentAssignor.is_finished ? pend.length === 0 : false) ||
                  currentAssignor.is_approved
                }
              >
                Salvar
              </Button>
              <Button
                type="button"
                variant="outline-secondary"
                disabled={loading}
                onClick={onClose}
                className="btn-sm ml-2"
              >
                Cancelar
              </Button>
            </div>
          </div>
        )}
        {confirmDelete && (
          <div style={{ width: "100%" }} className="mb-4">
            <small>Você tem certeza que deseja excluir?</small>
            <div className="d-flex justify-content-end">
              <Button
                type="button"
                variant="danger"
                onClick={handleDelete}
                className="btn-sm"
                disabled={
                  loading ||
                  (currentAssignor.is_finished ? pend.length === 0 : false) ||
                  currentAssignor.is_approved
                }
              >
                Sim, excluir
              </Button>
              <Button
                type="button"
                variant="outline-danger"
                className="btn-sm ml-2  "
                disabled={
                  loading ||
                  (currentAssignor.is_finished ? pend.length === 0 : false) ||
                  currentAssignor.is_approved
                }
                onClick={() => setConfirmDelete(false)}
              >
                Cancelar
              </Button>
            </div>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

import { TextField } from '@material-ui/core'
import { api } from '~/hooks/api'
import { normalizeCnpj, normalizeNumber } from '~/pages/main/utils/normalize'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { FaKey } from 'react-icons/fa'
import { MdVisibility, MdVisibilityOff } from 'react-icons/md'
import { toast } from 'react-toastify'

interface IProps {
  onRefresh: () => void
}

export const AddEcnpj: React.FC<IProps> = ({ onRefresh }) => {
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState<File | null>(null)
  const [passwordVisible, setPasswordVisible] = useState(false)

  const [values, setValues] = useState({
    password: '',
    cnpj: ''
  })

  function resetForm() {
    setValues({
      password: '',
      cnpj: ''
    })
    setPasswordVisible(false)
    setFile(null)
  }

  async function handleSubmit() {
    if (!file) {
      return toast.error('Nenhum arquivo anexado')
    }
    if (!values.cnpj) {
      return toast.error('Informe o CNPJ')
    }
    if (!values.password) {
      return toast.error('Informe a senha')
    }
    setLoading(true)

    try {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('cnpj', values.cnpj.replace(/[^0-9]/g, ''))
      formData.append('password', values.password)

      await api.post(
        `/register/ecnpjs`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            password: values.password,
            cnpj: normalizeNumber(values.cnpj)
          }
        }
      )
      onRefresh()
      resetForm()
      toast.info('ECNPJ Instalado com Sucesso')
    } catch (err) {
      //
    }
    return setLoading(false)
  }

  return (
    <div className="w-100">
      {file === null ? (
        <div className="d-flex ecnpj_box justify-content-center w-100">
          <label htmlFor="e-cnpj-file" id="e_cnpj_label" className="mb-0">
            Enviar e-CNPJ
          </label>
          <input
            type="file"
            id="e-cnpj-file"
            // accept=".pfx,.p12"
            onChange={e =>
              setFile(e && e.target && e.target.files && e.target.files[0])
            }
            disabled={loading}
          />
        </div>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          id="detached_file"
        >
          <div id="grid_detail">
            <div className="item_grid">
              <div className="bg-white radius-4 pl-2 pr-2 pt-1 pb-1">
                <FaKey />
              </div>
              <small className="mt-2 text-white">{file.name}</small>
            </div>
              <div className="item_grid">
                <TextField
                  label="CNPJ"
                  value={values.cnpj || ""}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      cnpj: normalizeCnpj(e.target.value)
                    })
                  }
                  disabled={loading}
                />

                <div className="d-flex justify-content-center align-items-center mt-2">
                  <TextField
                    label="Senha"
                    value={values.password || ""}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        password: e.target.value
                      })
                    }
                    disabled={loading}
                  />
                  <button
                    type="button"
                    className="ml-2"
                    onClick={() => setPasswordVisible(!passwordVisible)}
                  >
                    {passwordVisible ? <MdVisibilityOff /> : <MdVisibility />}
                  </button>
                </div>
                <div className="d-flex mt-4">
                  <Button
                    type="button"
                    variant="primary"
                    onClick={handleSubmit}
                    size="sm"
                    disabled={
                      values.cnpj.length < 18 ||
                      values.password.length < 4 ||
                      loading
                    }
                  >
                    Salvar
                  </Button>
                  <Button
                    type="button"
                    className="ml-2"
                    size="sm"
                    variant="outline-primary"
                    onClick={resetForm}
                    disabled={loading}
                  >
                    Voltar
                  </Button>
                </div>
              </div>
          </div>
        </div>
      )}
    </div>
  )
}

import styled from "styled-components";

import { Modal } from "react-bootstrap";

export const Container = styled(Modal)`
  #upload-label {
    padding: 15px;
    text-align: center;
    border: 2px dashed ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.primary};
    margin-bottom: 12px;
    width: 100%;
    border-radius: 6px;
    cursor: pointer;
    transition: 350ms;
    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }
  #list-files {
    border-top: 1px solid rgb(238, 238, 238);
    padding-bottom: 15px;
    #no_file {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 150px;
      text-align: center;
      color: #999;
      h4 {
        margin: 0px;
      }
      svg {
        font-size: 44px;
      }
    }
    div.file_item {
      margin-top: 12px;
      padding: 15px;
      background: #eee;
      h4 {
        font-size: 14px;
        color: #444;
      }
      button {
        font-size: 12px;
        color: rgb(153, 153, 153);
        margin: 0px 8px 0px 0px;
        padding: 0px;
      }
    }
  }
`;

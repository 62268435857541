import React, { useState, useEffect } from "react";

import { Container } from "./styles";

import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";
import { api } from "~/hooks/api";
import { AiTwotoneBank } from "react-icons/ai";
import { normalizeCpfCnpj } from "~/pages/main/utils/normalize";
import { ModalAddBankAccount } from "./components/ModalAddAccount";
import { useAuth } from "~/hooks/useAuth";
import { MdWarning } from "react-icons/md";
import { Alert } from "@material-ui/lab";

const AssignorBanks: React.FC = () => {
  const [accountBanks, setAccountBanks] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const { pendencies, currentAssignor } = useAuth();
  const pend = pendencies.filter((p) => p.type === "accounts");

  const [accountBank, setAccountBank] = useState({
    open: false,
    editAcc: {} as any,
  });

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      try {
        const { data } = await api.get(`/register/banks`);
        setAccountBanks(data);
      } catch (err) {
        //
      }
      setLoading(false);
    }
    loadData();
  }, []);

  return (
    <Container>
      <h4 className="m-0">
        {loading && <Spinner animation="border" size="sm" />} Contas Corrente
      </h4>
      {accountBank.open && (
        <ModalAddBankAccount
          onClose={() =>
            setAccountBank({
              open: false,
              editAcc: {} as any,
            })
          }
          onAdd={(a) => setAccountBanks([...accountBanks, a])}
          onUpdate={(a) =>
            setAccountBanks(
              accountBanks.map((i) => {
                if (i.id === a.id) {
                  return a;
                }
                return i;
              })
            )
          }
          onDelete={(id) =>
            setAccountBanks(accountBanks.filter((i) => i.id !== id))
          }
          account={accountBank.editAcc}
        />
      )}
      <Row>
        {pend.length > 0 && !currentAssignor.is_approved && (
          <Col lg={12}>
            <Alert severity="warning" className="mb-2">
              Você possui pendências neste módulo
            </Alert>
          </Col>
        )}
        {currentAssignor.is_approved && (
          <Col lg={12} className="mb-3">
            <Alert severity="info">
              Seu cadastro está aprovado, para atualizar as informações entre em
              contato com nossa Central de Atendimento.
            </Alert>
          </Col>
        )}
        {accountBanks.map((acc) => {
          const hasPendency =
            pend.filter((p) => p.account_id === acc.id && p.type === "accounts")
              .length > 0;
          return (
            <Col lg={6} className="mt-3" key={acc.id}>
              <Card>
                <Card.Body>
                  <Card.Text>
                    <AiTwotoneBank /> {acc.bank?.code} - {acc.bank?.name}
                  </Card.Text>
                  <Card.Text>
                    Ag:{" "}
                    <small>
                      {acc.agency}-{acc.agency_digit}
                    </small>
                    <br />
                    Conta:{" "}
                    <small>
                      {acc.account_number}-{acc.account_digit}
                    </small>
                    <br />
                    <small>
                      {acc.owner_name}
                      <br />
                      {normalizeCpfCnpj(acc.owner_document_number)}
                    </small>
                  </Card.Text>

                  {!currentAssignor.is_approved && (
                    <div className="d-flex justify-content-end align-items-center">
                      {hasPendency ? (
                        <>
                          {pend
                            .filter(
                              (p) =>
                                p.account_id === acc.id && p.type === "accounts"
                            )
                            .map((p) => (
                              <div key={p.id} className="mr-2 w-100 text-left">
                                <small>
                                  <i>{p.message}</i>
                                </small>
                              </div>
                            ))}
                        </>
                      ) : null}
                      <Button
                        type="button"
                        className="btn-sm"
                        onClick={() =>
                          setAccountBank({
                            open: true,
                            editAcc: acc,
                          })
                        }
                        disabled={
                          loading ||
                          (currentAssignor.is_finished
                            ? pend.length === 0
                            : false) ||
                          currentAssignor.is_approved
                        }
                        variant={hasPendency ? "warning" : "primary"}
                      >
                        <FaEdit className="m-0" />
                      </Button>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Col>
          );
        })}
        {!loading && !currentAssignor.is_approved && (
          <Col lg={6} className="mt-3" style={{ minHeight: "100%" }}>
            <Card style={{ height: "100%" }}>
              <Card.Body className="d-flex justify-content-center align-items-center">
                <Button
                  type="button"
                  variant="outline-primary"
                  className="btn-sm"
                  onClick={() =>
                    setAccountBank({
                      open: true,
                      editAcc: {} as any,
                    })
                  }
                  disabled={
                    loading ||
                    (currentAssignor.is_finished ? pend.length === 0 : false) ||
                    currentAssignor.is_approved
                  }
                >
                  <AiTwotoneBank /> Adicionar Conta
                </Button>
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default AssignorBanks;

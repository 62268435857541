import React, { useEffect, useState } from "react";

import { format } from "date-fns";
import ModalDetail from "./components/ModalDetail/index";

import { Container } from "./styles";

import {
  normalizeCurrency,
  normalizeDate,
  normalizeNumber,
  parseToDate,
} from "../utils/normalize";

import { api } from "~/hooks/api";
import { TableComponent } from "../components/TableComponent";
import { MdVisibility } from "react-icons/md";

interface IOperationDetail {
  active: boolean;
  id?: number;
}
const Operations: React.FC = () => {
  const [operationDetail, setOperationDetail] = useState<IOperationDetail>({
    active: false,
  });

  return (
    <Container>
      {operationDetail.active && (
        <ModalDetail
          open={operationDetail.active}
          setOpen={() =>
            setOperationDetail({
              active: false,
            })
          }
          operationId={operationDetail.id}
        />
      )}
      <TableComponent
        title="Operações"
        dataReciever={async (params) => {
          const { data } = await api.get(`/operations`, {
            params: {
              ...params,
              start_date: params.start_date
                ? parseToDate(params.start_date)
                : undefined,
              end_date: params.end_date
                ? parseToDate(params.end_date)
                : undefined,
              start_value: params.start_value
                ? normalizeNumber(params.start_value)
                : undefined,
              end_value: params.end_value
                ? normalizeNumber(params.end_value)
                : undefined,
            },
          });
          return data;
        }}
        traitResponse={(data) => {
          return data.map((d) => ({
            ...d,
            date: format(new Date(d.date), "dd/MM/yyyy"),
            titlesQtd: d.titles_qtd,
            grossValue: normalizeCurrency(d.gross_value || "000", true),
            netValue: [6, 7, 8, 9, 10, 11].includes(d.operation_status_id)
              ? normalizeCurrency(
                  d.net_value -
                    d.tariff_discount_value -
                    d.title_discount_value -
                    d.financial_pending_discount_value || "000",
                  true
                )
              : "",

            status: d?.status?.name,
          }));
        }}
        dataType={[]}
        filters={[
          {
            name: "code",
            title: "Operação",
            normalize: normalizeNumber,
            col: 2,
          },
          {
            name: "start_date",
            title: "Data (de)",
            normalize: normalizeDate,
            col: 2,
          },
          {
            name: "end_date",
            title: "Data (até)",
            normalize: normalizeDate,
            col: 2,
          },
          {
            name: "start_value",
            title: "Valor (de)",
            normalize: normalizeCurrency,
            col: 2,
          },
          {
            name: "end_value",
            title: "Valor (até)",
            normalize: normalizeCurrency,
            col: 2,
          },
        ]}
        labels={[
          {
            name: "code",
            title: "Operação",
          },
          {
            name: "date",
            title: "Data",
          },
          {
            name: "titlesQtd",
            title: "Qtd de Títulos",
          },
          {
            name: "grossValue",
            title: "Vlr. Total",
          },
          {
            name: "netValue",
            title: "Vlr. Líquido",
          },
          {
            name: "status",
            title: "Status",
          },
        ]}
        addItem={false}
        aditionalActions={(item) => (
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={() =>
              setOperationDetail({
                active: true,
                id: item.id,
              })
            }
          >
            <MdVisibility /> Detalhe
          </button>
        )}
      />
    </Container>
  );
};

export default Operations;

import styled from 'styled-components';

import { Modal } from 'react-bootstrap';

export const Container = styled(Modal)`
  .form-label {
    font-size: 12px;
    margin-bottom: 4px;
  }
  .form-group {
    margin: 0px;
  }
  small {
    color: #888;
    display: block;
    margin-bottom: 6px;
  }
`;

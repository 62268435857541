import React, { memo, useEffect, useState } from "react";

import { FaFile, FaInfoCircle, FaPrint } from "react-icons/fa";

import {
  normalizeCpfCnpj,
  normalizeCurrency2 as normalizeCurrency,
} from "../../utils";

import { api } from "~/hooks/api";
import { TableComponent } from "~/pages/main/components/TableComponent";
import {
  formatDate,
  normalizeNumber,
  subString,
} from "~/pages/main/utils/normalize";
import printDanfe from "~/pages/main/utils/printDanfe";
import { Badge, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useAuth } from "~/hooks/useAuth";
import {
  downloadBillet,
  titleIsInRegistryOffice,
  titleIsProtested,
} from "~/utils/titleUtils";

interface INextItem {
  (): void;
}

interface IProps {
  handleNextItem: INextItem;
}

const Open: React.FC<IProps> = () => {
  const [titleTypes, setTitleTypes] = useState<any[]>([]);
  const { currentAssignor, getAllCompanies, companies } = useAuth();

  useEffect(() => {
    api
      .get(`/title_types`)
      .then((response) => {
        setTitleTypes(response.data);
      })
      .finally(() => {});
  }, []);

  async function handleDanfe(key: string) {
    try {
      const { data } = await api.get(`xml/danfe/${key}`);
      printDanfe(
        data.html.replace("<title>DANFE</title>", `<title>DANFE-${key}</title>`)
      );
    } catch (err) {
      //
    }
  }

  useEffect(() => {
    getAllCompanies();
  }, []);

  return (
    <TableComponent
      title="Títulos em Aberto"
      totalsBadges={[
        {
          title: "Valor Total",
          fieldName: "total_value",
          type: "currency",
        },
        {
          title: "Total em aberto",
          fieldName: "total_open_value",
          type: "currency",
        },
      ]}
      dataReciever={async (params) => {
        const { data } = await api.get("/assignor/titles", {
          params: {
            ...params,
            situation: "open",
            startValue: params.start_value
              ? normalizeNumber(params.start_value)
              : "",
            endValue: params.end_value ? normalizeNumber(params.end_value) : "",
            payer_document: params.payer_document
              ? normalizeNumber(params.payer_document)
              : "",
          },
        });
        return data;
      }}
      dataType={[]}
      exportAction={async (params) => {
        const obj = {
          ...params,
          payer_document: params.payer_document
            ? normalizeNumber(params.payer_document)
            : "",
          situation: "open",
          startValue: params.start_value
            ? normalizeNumber(params.start_value)
            : "",
          endValue: params.end_value ? normalizeNumber(params.end_value) : "",
        };
        window.open(
          `${process.env.REACT_APP_API}/assignor/titles?${Object.keys(obj)
            .filter((key) => obj[key])
            .map((key) => `${key}=${obj[key]}`)
            .join("&")}`
        );
      }}
      traitResponse={(data: any) => {
        return data.map((d) => {
          return {
            ...d,
          };
        });
      }}
      filters={[
        {
          name: "code",
          title: "ID",
          col: 1,
        },
        {
          name: "operation_code",
          title: "Operação",
          col: 1,
        },
        {
          name: "document_number",
          title: "Documento",
          col: 2,
        },
        {
          name: "title_type_id",
          title: "Tipo",
          select: true,
          options: titleTypes.map((type) => ({
            value: type.id,
            title: type.name,
          })),
          col: 2,
        },
        {
          name: "start_date",
          title: "Vencimento (de)",
          type: "date",
          col: 2,
        },
        {
          name: "end_date",
          title: "Vencimento (até)",
          type: "date",
          col: 2,
        },
        {
          name: "start_value",
          title: "Valor (de)",
          normalize: normalizeCurrency,
          col: 2,
        },
        {
          name: "end_value",
          title: "Valor (até)",
          normalize: normalizeCurrency,
          col: 2,
        },
        {
          name: "payer_document",
          title: "CPF/CNPJ do Sacado",
          normalize: normalizeCpfCnpj,
          col: 3,
        },
        {
          name: "product_id",
          title: "Produto",
          select: true,
          defaultValue: 1,
          options: [
            {
              title: "Antecipação de Recebíveis",
              value: 1,
            },
            {
              title: "Cobrança Simples",
              value: 2,
            },
            {
              title: "Cobrança Simples (Recomprados)",
              value: 3,
            },
          ],
          col: 2,
        },
        {
          name: "company_id",
          title: "Empresa",
          select: true,
          defaultValue: 0,
          options: companies?.map((company) => ({
            title: `${company?.code} - ${company?.fantasy_name}`,
            value: company?.id,
          })),
          col: 3,
        },
      ]}
      labels={[
        {
          name: "titles.code",
          title: "Título",
          formater: (_, title) => {
            return (
              <>
                {title.code}
                {title.product_id === 2 ? (
                  <OverlayTrigger
                    overlay={
                      <Tooltip id={`cs-${title.id}`}>
                        <span>Cobrança Simples</span>
                      </Tooltip>
                    }
                  >
                    <Badge
                      variant="primary"
                      className="ml-1"
                      style={{ fontSize: "0.8rem" }}
                    >
                      CS
                    </Badge>
                  </OverlayTrigger>
                ) : null}
              </>
            );
          },
        },
        {
          name: "operation.company_code",
          title: "Empresa",
          formater: (_value, title) => title?.company_code,
        },
        {
          name: "operation.code",
          title: "Operação",
          formater: (_, title) => {
            return title.operation.code;
          },
        },
        {
          name: "titles.document_number",
          title: "Doc.",
          formater: (_, title) => {
            return (
              <span className="white-space-nowrap">
                {title.document_number}
              </span>
            );
          },
        },
        {
          name: "payer.name",
          title: "Sacado",
          formater: (_, title) => {
            return (
              <div className="white-space-no-wrap">
                {subString(title.payer.name, 16)}
                {title?.payer?.name?.length > 16 ? (
                  <OverlayTrigger
                    overlay={
                      <Tooltip id={`tooltip-${title.id}`}>
                        <span>{title.payer.name}</span>
                      </Tooltip>
                    }
                  >
                    <FaInfoCircle className="text-muted" />
                  </OverlayTrigger>
                ) : null}
                <br />
                {normalizeCpfCnpj(title.payer.document_number)}
              </div>
            );
          },
        },
        {
          name: "titles.value",
          title: (
            <>
              Valor <br />
              Original
            </>
          ),
          formater: (_, title) => {
            return normalizeCurrency(title.value, true);
          },
          align: "right",
        },
        {
          name: "titles.open_value",
          title: (
            <>
              Valor <br />
              Aberto
            </>
          ),
          formater: (_, title) => {
            return normalizeCurrency(title.open_value, true);
          },
          align: "right",
        },
        {
          name: "titles.date",
          title: "Vencimento",
          formater: (_, title) => {
            return formatDate(title.date);
          },
        },
        {
          name: "titles.title_type_id",
          title: "Tipo",
          formater: (_, title) => {
            try {
              const regex = /\(([^)]+)\)/;
              return title?.titleType?.name?.match(regex)[1] || "";
            } catch (err) {
              return "";
            }
          },
        },
        {
          name: "billing_status.code",
          title: (
            <>
              Sit. de <br />
              Cobrança
            </>
          ),
          formater: (_, title) => {
            return (
              <>
                {title?.collectingAgent?.code} - {title?.billingStatus?.name}
              </>
            );
          },
        },
        {
          name: "title_status.name",
          title: "Situação",
          formater: (_, title) => {
            return (
              <>
                {title?.status?.name}
                {title.product_id === 2 ? (
                  <>
                    <br />
                    Cobrança Simples
                  </>
                ) : null}
              </>
            );
          },
        },
        {
          name: "billet",
          title: "",
          ableToOrder: false,
          formater: (_, title) => {
            if (titleIsInRegistryOffice(title)) {
              return <>Cartório</>;
            }
            if (titleIsProtested(title)) {
              return <>Protesto</>;
            }

            if (!title.our_number) {
              return <></>;
            }

            return (
              <Button
                type="button"
                size="sm"
                className="p-1"
                onClick={() =>
                  downloadBillet([title.id], currentAssignor.token)
                }
              >
                <FaPrint /> Boleto
              </Button>
            );
          },
        },
      ]}
      editable={false}
      addItem={false}
      aditionalActions={(t) => (
        <div className="ml-2">
          {t?.duplicate?.xml?.key && t?.operation?.titleType?.name === "NFe" && (
            <OverlayTrigger
              overlay={
                <Tooltip id={`tooltip-danfe-${t.id}`}>
                  <span>Imprimir DANFE</span>
                </Tooltip>
              }
              trigger="hover"
            >
              <button
                type="button"
                onClick={() => handleDanfe(t?.duplicate?.xml?.key || "")}
              >
                <FaFile />
              </button>
            </OverlayTrigger>
          )}
        </div>
      )}
    />
  );
};

export default memo(Open);

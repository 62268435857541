import styled from 'styled-components';

import { Modal } from 'react-bootstrap';

export const Container = styled(Modal)`
  .form-label {
    font-size: 12px;
    margin-bottom: 4px;
  }
  .form-group {
    margin: 0px;
  }
  div.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 100%;
      max-width: 80px;
    }
    h4 {
      color: #444;
      margin: 0px;
      padding: 0px 15px;
      font-size: 20px;
    }
  }
  small {
    color: #888;
    display: block;
    margin-bottom: 6px;
  }
`;

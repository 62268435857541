import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { MdChevronRight } from "react-icons/md";
import { Spinner } from "react-bootstrap";

import { api } from "~/hooks/api";
import { IOperation } from "../../../Operations/operation";
import { normalizeCurrency } from "~/pages/main/utils/normalize";
import ModalDetail from "~/pages/main/Operations/components/ModalDetail";

const Operations: React.FC = () => {
  const [operations, setOperations] = useState<IOperation[]>([]);
  const [operation, setOperation] = useState({
    open: false,
    id: 0,
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadDashboard() {
      setLoading(true);
      try {
        const { data } = await api.get(
          `/operations?operationStatusId=11&page=1&per_page=15`
        );
        setOperations(data.data);
      } catch (err) {
        //
      }
      setLoading(false);
    }
    loadDashboard();
  }, []); // eslint-disable-line

  return (
    <div>
      {operation.open && operation.id && (
        <ModalDetail
          open={operation.open}
          setOpen={() =>
            setOperation({
              open: false,
              id: 0,
            })
          }
          operationId={operation.id}
        />
      )}
      <h6 className="mb-4">
        <strong>Últimas Operações</strong>
      </h6>
      <div style={{ height: "35vh", overflow: "auto" }}>
        {loading ? (
          <Spinner animation="border" />
        ) : (
          <table className="table table-sm table-striped table-hover mb-0 w-100 table-fixed-top">
            <thead>
              <tr>
                <th className="small">Operação</th>
                <th className="small text-right">Valor Líquido</th>
                <th className="small text-right">Data</th>
                <th className="small"></th>
              </tr>
            </thead>
            <tbody>
              {operations
                .sort((a, b) => b.id - a.id)
                .map((o) => (
                  <tr key={o.id}>
                    <td className="small">{o.code}</td>
                    <td className="text-right small">
                      {normalizeCurrency(o.net_value || "000", true)}
                    </td>
                    <td className="text-right small">
                      {o.date ? format(new Date(o.date), "dd/MM/yyyy") : ""}
                    </td>
                    <td
                      className="small text-center"
                      onClick={() =>
                        setOperation({
                          open: true,
                          id: o.id,
                        })
                      }
                    >
                      <button
                        type="button"
                        onClick={() => {
                          setOperation({
                            open: true,
                            id: o.id,
                          });
                        }}
                      >
                        <MdChevronRight />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default Operations;

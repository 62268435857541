import React, { memo, useCallback, useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import { FaAngleLeft, FaAngleRight, FaMoneyBillWave } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { api } from "~/hooks/api";
import { modalOpen } from "~/store/modules/register/actions";
import useSelector from "~/store/useSelector";
import { Container } from "./styles";

import { MdError } from "react-icons/md";
import { useAuth } from "~/hooks/useAuth";
import Dropdown from "./Dropdown";

interface IChangeCurrent {
  to: string;
  currentNumber: number;
}

interface IProps {
  open: boolean;
  setOpen: (v: boolean) => void;
}

const SubMenu: React.FC<IProps> = ({ open, setOpen }) => {
  const [openDropdown, setOpenDropdown] = useState<null | number>(null);
  const [path, setPath] = useState<null | string>(null);
  const [pathSub, setPathSub] = useState<null | string>(null);
  const [pathSubSub, setPathSubSub] = useState<null | string>(null);
  const [current, setCurrent] = useState<null | number>(null);
  const [menuVisible, setMenuVisible] = useState(false);

  const { currentAssignor, pendencies } = useAuth();

  const [menuItems, setMenuItems] = useState<any[]>([]);
  const { isEditing } = useSelector((state) => state.register);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const register = {
      item: "cadastro",
      page: "",
      icon: "/assets/images/icon-register.png",
      description: "Cadastro",
      isDropdown: true,
      submenu: [
        {
          item: "geral",
          page: "/cadastro/geral",
          description: "Informações Gerais",
          disabled: currentAssignor.rejected
        },
        {
          item: "avalistas",
          page: "/cadastro/avalistas",
          description: "Sócios / Avalistas",
          disabled: currentAssignor.rejected
        },
        {
          item: "contas-corrente",
          page: "/cadastro/contas-corrente",
          description: "Contas Corrente",
          disabled: currentAssignor.rejected
        },
        {
          item: "documentos",
          page: "/cadastro/documentos",
          description: "Documentos",
          disabled: currentAssignor.rejected
        },
        {
          item: "referencias-financeiras",
          page: "/cadastro/referencias-financeiras",
          description: "Referências Financeiras",
          disabled: currentAssignor.rejected
        },
        {
          item: "ecnpjs",
          page: "/cadastro/ecnpjs",
          description: "e-CNPJ",
          disabled: currentAssignor.rejected
        },
        {
          item: "finish",
          page: "/cadastro/finalizar",
          description: "Finalizar Cadastro",
        },
      ],
    };
    if (currentAssignor.is_approved) {
      const submenuAnticipation = [] as any[];
      if (currentAssignor.models_available?.includes("nfe")) {
        submenuAnticipation.push({
          item: "nfe",
          page: "/antecipar-recebiveis/nfe",
          description: "NF-e",
        });
      }
      if (currentAssignor.models_available?.includes("cte")) {
        submenuAnticipation.push({
          item: "cte",
          page: "/antecipar-recebiveis/cte",
          description: "CT-e",
        });
      }
      if (currentAssignor.models_available?.includes("nfse")) {
        submenuAnticipation.push({
          item: "nfse",
          page: "/antecipar-recebiveis/nfse",
          description: "NFS-e",
        });
      }
      if (currentAssignor.models_available?.includes("ccb")) {
        submenuAnticipation.push({
          item: "ccb",
          page: "/antecipar-recebiveis/ccb",
          description: "CCB",
        });
      }
      if (currentAssignor.models_available?.includes("contract")) {
        submenuAnticipation.push({
          item: "contract",
          page: "/antecipar-recebiveis/contract",
          description: "Contrato",
        });
      }
      if (currentAssignor.models_available?.includes("check")) {
        submenuAnticipation.push({
          item: "cheque",
          page: "/antecipar-recebiveis/cheque",
          description: "Cheque",
        });
      }

      const arrayMenuItems = [
        {
          ...register,
          submenu: register.submenu.filter((s) => s.item !== "finalizar"),
        },
        {
          item: "antecipar-recebiveis",
          page: "",
          icon: "/assets/images/icon-antecipation.png",
          description: "Antecipar Recebíveis",
          isDropdown: true,
          submenu: submenuAnticipation,
        },
        {
          item: "operacao-lista",
          page: "/operacoes/lista",
          description: "Operações",
          icon: "/assets/images/icon-antecipation.png",
          isDropdown: false,
          submenu: [],
        },
      ];

      if (currentAssignor.can_pre_print_type_1) {
        arrayMenuItems.push({
          item: "remessa",
          page: "/remessas",
          description: "Remessa",
          icon: "/assets/images/icon-a-enviar.png",
          isDropdown: false,
          submenu: [],
        });
      }

      let titlesSubMenu = [
        {
          item: "em-aberto",
          page: "/titulos/em-aberto",
          description: "Em aberto",
        },
        {
          item: "liquidados",
          page: "/titulos/liquidados",
          description: "Liquidados",
        },
        {
          item: "vencidos",
          page: "/titulos/vencidos",
          description: "Vencidos",
        },
        // {
        //   item: "devolvidos",
        //   page: "/titulos/devolvidos",
        //   description: "Devolvidos",
        // },
      ]

      if(currentAssignor.models_available?.includes("creditcard")) {
        titlesSubMenu.push({
          item: "receivable_values",
          page: "/titulos/rcc",
          description: `Liquidação Cartões`,
        })
      }

      arrayMenuItems.push(
        {
          item: "boletos",
          page: "/boletos",
          icon: "/assets/images/icon-billet.png",
          description: "Boletos",
          isDropdown: false,
          submenu: [],
        },
        {
          item: "titulos",
          page: "",
          icon: "/assets/images/icon-titles.png",
          description: "Títulos",
          isDropdown: true,
          submenu: titlesSubMenu,
        },
        {
          item: "tarifas",
          page: "",
          icon: "/assets/images/icon-fees.png",
          description: "Tarifas",
          isDropdown: true,
          submenu: [
            {
              item: "abertos",
              page: "/tarifas/abertos",
              description: "Em Aberto",
            },
            {
              item: "liquidadas",
              page: "/tarifas/liquidadas",
              description: "Liquidadas",
            },
          ],
        },
        {
          item: "servicos",
          page: "",
          icon: "/assets/images/icon-services.png",
          description: "Serviços de consulta",
          isDropdown: true,
          submenu: [
            {
              item: "empresa",
              page: "/servicos/empresa",
              description: "Consulta de Empresa",
            },
            {
              item: "protesto",
              page: "/servicos/protesto",
              description: "Consulta de Protestos",
            },
          ],
        },
        {
          item: "configuracao",
          page: "",
          icon: "/assets/images/icon-config.png",
          description: "Configurações",
          isDropdown: true,
          submenu: [
            {
              item: "retorno",
              page: "/configuracao-retorno",
              description: "Retorno/Relatórios diário",
            },
          ],
        }
      );
      // {
      //   item: "prorrogacao",
      //   page: "/prorrogacao",
      //   icon: "/assets/images/icon-fees.png", //TODO: mudar icone
      //   description: "Prorrogação",
      //   isDropdown: false,
      //   submenu: [],
      // },
      // {
      //   item: "pendencias",
      //   page: "/pendencias",
      //   icon: "/assets/images/icon-fees.png", //TODO: mudar icone
      //   description: "Pendências Financeiras",
      //   isDropdown: false,
      //   submenu: [],
      // },

      if (currentAssignor.can_pre_print_type_2) {
        arrayMenuItems.push({
          item: "repositorios",
          page: "",
          icon: "/assets/images/icon-repository.png",
          description: "Repositórios",
          isDropdown: true,
          submenu: [
            {
              item: "remessas",
              page: "/repositorios/remessas",
              description: "Remessas",
            },
            // {
            //   item: "retornos",
            //   page: "/repositorios/retornos",
            //   description: "Retornos",
            // },
          ],
        });
      }

      setMenuItems(arrayMenuItems);
    } else {
      setMenuItems([register]);
    }
  }, [currentAssignor.is_approved]);

  function handlePath() {
    const pathArray = window.location.pathname.split("/");
    setPath(pathArray[1]);
    if (pathArray[2]) {
      setPathSub(pathArray[2]);
    }
    if (pathArray[3]) {
      setPathSubSub(pathArray[3]);
    }
    switch (pathArray[1]) {
      case "cadastro":
        setCurrent(1);
        setOpenDropdown(1);
        break;
      case "antecipar-recebiveis":
        setCurrent(2);
        setOpenDropdown(2);
        break;
      case "operacao-lista":
        setCurrent(3);
        setOpenDropdown(3);
        break;
      case "boletos":
        setCurrent(4);
        setOpenDropdown(4);
        break;
      case "titulos":
        setCurrent(5);
        setOpenDropdown(5);
        break;
      case "tarifas":
        setCurrent(6);
        setOpenDropdown(6);
        break;
      case "repositorios":
        setCurrent(7);
        setOpenDropdown(7);
        break;
      case "config":
        setCurrent(8);
        setOpenDropdown(8);
        break;
      default:
        setCurrent(999);
        setOpenDropdown(999);
        break;
    }
  }

  useEffect(() => {
    handlePath();
    // if (currentAssignor.is_approved) {
    // } else {
    //   setPath("cadastro");
    //   setCurrent(1);
    //   setOpenDropdown(1);
    // }
  }, []); // eslint-disable-line

  useEffect(() => {
    async function loadAssignor() {
      await api.get("/register/assignor");
    }
    loadAssignor();
  }, []);

  const handleFixed = () => {
    setMenuVisible(!menuVisible);
    setOpen(!open);
  };

  const handleChangeCurrent = ({ to, currentNumber }: IChangeCurrent) => {
    if (isEditing) {
      if (window.location.pathname !== to) {
        dispatch(modalOpen({ modal_to: to }));
      }
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
      history.push(to);
    }
    handlePath();
  };

  const traitIcon = useCallback(
    (item: string) => {
      if (pendencies.length > 0 && !currentAssignor.is_approved) {
        if (item === "geral" && pendencies.find((p) => p.type === "register")) {
          return <MdError className="text-warning mr-2" />;
        } else if (
          item === "avalistas" &&
          pendencies.find((p) => p.type === "guarantor")
        ) {
          return <MdError className="text-warning mr-2" />;
        } else if (
          item === "contas-corrente" &&
          pendencies.find((p) => p.type === "accounts")
        ) {
          return <MdError className="text-warning mr-2" />;
        } else if (
          item === "documentos" &&
          pendencies.find((p) => p.type === "document")
        ) {
          return <MdError className="text-warning mr-2" />;
        } else if (
          item === "referencias-financeiras" &&
          pendencies.find((p) => p.type === "financial_reference")
        ) {
          return <MdError className="text-warning mr-2" />;
        }
        return null;
      }
      return null;
    },
    [pendencies, currentAssignor.is_approved]
  );

  return (
    <>
      {currentAssignor.is_approved && !open && (
        <div
          className="bg-warning mb-3 shadow text-dark text-center p-3"
          style={{ borderRadius: "0.5rem" }}
        >
          <FaMoneyBillWave size="2rem" />
        </div>
      )}
      <Container
        className={`shadow ${
          !menuVisible ? "opened-side" : open ? "opened-side" : ""
        }`}
        id="menu_items"
        style={{ animationDelay: "300ms", position: "relative" }}
      >
        <ul>
          {current &&
            openDropdown &&
            menuItems.map((li, index) => {
              if (li.isDropdown) {
                return (
                  <li
                    key={String(index)}
                    className={path === li.item ? "active" : ""}
                  >
                    <button
                      type="button"
                      className={path === li.item ? "active" : ""}
                      onClick={() => {
                        setOpenDropdown(index + 1);
                      }}
                    >
                      <span>
                        <img src={li.icon} alt={li.item} />
                      </span>
                      {li.description}
                    </button>
                    <Dropdown open={openDropdown === index + 1}>
                      {li.submenu.map((liSub, ind) => (
                        <li key={String(ind)}>
                          {liSub.isDropdown ? (
                            <>
                              <button
                                type="button"
                                className={
                                  pathSub === liSub.item ? "active" : ""
                                }
                              >
                                {liSub.description}
                              </button>
                              <ul className="subDropdown">
                                {liSub.submenu.map((el, i) => (
                                  <li key={String(i)}>
                                    <button
                                      type="button"
                                      className={
                                        pathSubSub === el.item ? "active" : ""
                                      }
                                      onClick={() =>
                                        handleChangeCurrent({
                                          to: el.page,
                                          currentNumber: index,
                                        })
                                      }
                                    >
                                      {el.description}
                                    </button>
                                  </li>
                                ))}
                              </ul>
                            </>
                          ) : (
                            <button
                              type="button"
                              className={pathSub === liSub.item ? "active" : ""}
                              onClick={() => {
                                let path = liSub.page;

                                if(currentAssignor?.rejected) {
                                  path = '/cadastro/finalizar'
                                }

                                handleChangeCurrent({
                                  to: path,
                                  currentNumber: index,
                                })
                              }}
                            >
                              {traitIcon(liSub.item)} {liSub.description}
                            </button>
                          )}
                        </li>
                      ))}
                    </Dropdown>
                  </li>
                );
              }
              return (
                <li key={String(index)}>
                  <button
                    type="button"
                    className={current === index + 1 ? "active" : ""}
                    onClick={() =>
                      handleChangeCurrent({
                        to: li.page,
                        currentNumber: index,
                      })
                    }
                  >
                    <span>
                      <img src={li.icon} alt={li.item} />
                    </span>
                    {li.description}
                  </button>
                </li>
              );
            })}
        </ul>
        {/* <button
          type="button"
          id="fixed_menu_button"
          style={{
            position: "absolute",
            right: "0",
            bottom: "-55px",
            color: "#3757a1",
            fontSize: "2rem",
            textAlign: "center",
            width: "100%",
          }}
          onClick={() => handleFixed()}
        >
          {menuVisible ? <FaAngleRight /> : <FaAngleLeft />}
        </button> */}
      </Container>
    </>
  );
};

export default memo(SubMenu);

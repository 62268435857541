import { api } from "~/hooks/api";
import { normalizeCpfCnpj } from "~/pages/main/utils/normalize";
import React, { useState, useEffect } from "react";

import { Card, Row, Col, Button, Spinner } from "react-bootstrap";
import { FaEdit, FaFile, FaUser } from "react-icons/fa";
import { IoIosBusiness } from "react-icons/io";
import { AddGuarantorPF } from "./components/AddGuarantorPF";
import { AddGuarantorPJ } from "./components/AddGuarantorPJ";
import { ModalDocuments } from "./components/ModalDocuments";
import { Container } from "./styles";
import { useAuth } from "~/hooks/useAuth";
import { Alert } from "@material-ui/lab";
import { MdWarning } from "react-icons/md";

const Guarantors: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const [guarantors, setGuarantors] = useState<any[]>([]);

  const { pendencies, currentAssignor } = useAuth();

  const pend = pendencies.filter((p) => p.type === "guarantor");

  const [guarantorDocument, setGuarantorDocument] = useState({
    open: false,
    id: 0,
    name: "",
    guarantor_id: 0,
    type: null as any,
  });

  const [guarantor, setGuarantor] = useState({
    open: false,
    data: {} as any,
    type: "",
  });

  async function loadData() {
    setLoading(true);
    try {
      const { data } = await api.get(`/register/guarantors`);
      setGuarantors(data);
    } catch (err) {
      //
    }
    setLoading(false);
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Container>
      {guarantor.open && guarantor.type === "PF" && (
        <AddGuarantorPF
          data={guarantor.data}
          onClose={() =>
            setGuarantor({
              open: false,
              data: {} as any,
              type: "",
            })
          }
          onAdd={() => loadData()}
          onEdit={() => loadData()}
          onDelete={(i) => setGuarantors(guarantors.filter((g) => g.id !== i))}
        />
      )}
      {guarantor.open && guarantor.type === "PJ" && (
        <AddGuarantorPJ
          data={guarantor.data}
          onClose={() =>
            setGuarantor({
              open: false,
              data: {} as any,
              type: "",
            })
          }
          onAdd={() => loadData()}
          onEdit={() => loadData()}
          onDelete={(i) => setGuarantors(guarantors.filter((g) => g.id !== i))}
        />
      )}
      {guarantorDocument.open && (
        <ModalDocuments
          guarantor_id={guarantorDocument.id}
          reference_id={guarantorDocument.guarantor_id}
          guarantor_name={guarantorDocument.name}
          type={guarantorDocument.type}
          onClose={() =>
            setGuarantorDocument({
              open: false,
              id: 0,
              name: "",
              type: null,
              guarantor_id: 0,
            })
          }
        />
      )}
      <h4 className="m-0">
        {loading && <Spinner animation="border" size="sm" />} Sócios / Avalistas
      </h4>
      <Alert severity="info" className="mt-3">
        <p className="mb-0">
          Os sócios / avalistas que aqui apresentados, serão requisitados as
          assinaturas no <strong>contrato de cadastro</strong> e nos{" "}
          <strong>aditivos de operações</strong>
        </p>
      </Alert>
      <Row>
        {pend.length > 0 && !currentAssignor.is_approved && (
          <Col lg={12}>
            <Alert severity="warning" className="mb-3">
              Você possui pendências neste módulo
            </Alert>
          </Col>
        )}
        {currentAssignor.is_approved && (
          <Col lg={12} className="mb-3">
            <Alert severity="info">
              Seu cadastro está aprovado, para atualizar as informações entre em
              contato com nossa Central de Atendimento.
            </Alert>
          </Col>
        )}
        {!loading &&
          guarantors?.map((guarantor) => {
            const type = guarantor.document_number?.length === 14 ? "PJ" : "PF";

            const hasPendency =
              pend.filter((p) => p.guarantor_id === guarantor.guarantor_id)
                .length > 0;
            return (
              <Col lg={6} className="mt-3" key={guarantor.id}>
                <Card>
                  <Card.Body>
                    <div className="d-flex align-items-center">
                      {type === "PF" && (
                        <FaUser
                          style={{ fontSize: "2rem", marginRight: "0.75rem" }}
                        />
                      )}
                      {type === "PJ" && (
                        <IoIosBusiness
                          style={{ fontSize: "2rem", marginRight: "0.75rem" }}
                        />
                      )}
                      <Card.Text>
                        {guarantor.name}
                        <br />
                        <small>
                          {normalizeCpfCnpj(guarantor.document_number)}
                        </small>
                      </Card.Text>
                    </div>
                  </Card.Body>
                  <Card.Footer>
                    <div className="d-flex justify-content-between">
                      <div className="d-flex">
                        <Button
                          type="button"
                          onClick={() =>
                            setGuarantor({
                              open: true,
                              data: guarantor,
                              type,
                            })
                          }
                          size="sm"
                          disabled={
                            loading ||
                            (currentAssignor.is_finished ? !hasPendency : false)
                          }
                        >
                          <FaEdit className="m-0" />
                        </Button>
                        <Button
                          type="button"
                          className="ml-2 btn-sm"
                          onClick={() =>
                            setGuarantorDocument({
                              open: true,
                              id: guarantor?.id,
                              guarantor_id: guarantor?.guarantor_id,
                              name: guarantor?.name,
                              type:
                                guarantor?.document_number?.length === 11
                                  ? "guarantor"
                                  : "guarantor-pj",
                            })
                          }
                        >
                          <FaFile className="m-0" />
                        </Button>
                      </div>
                    </div>
                  </Card.Footer>
                </Card>
              </Col>
            );
          })}
        {!loading && !currentAssignor?.is_finished && (
          <Col lg={6} className="mt-4" style={{ minHeight: "100%" }}>
            <Card style={{ height: "100%" }}>
              <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                <Button
                  type="button"
                  variant="outline-primary"
                  className="btn-sm"
                  onClick={() =>
                    setGuarantor({
                      open: true,
                      data: {} as any,
                      type: "PF",
                    })
                  }
                >
                  Adicionar Pessoa Física
                </Button>
                <Button
                  type="button"
                  className="mt-2 btn-sm"
                  variant="outline-primary"
                  onClick={() =>
                    setGuarantor({
                      open: true,
                      data: {} as any,
                      type: "PJ",
                    })
                  }
                >
                  Adicionar Pessoa Jurídica
                </Button>
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default Guarantors;

import {
  ICompany,
  SWITCH_COMPANY,
  PERSIST_COMPANY,
  UPDATE_NAME,
  SYNC_COMPANY,
  UserActionTypes,
  IPermission,
} from './types';

interface IPersistCompany {
  company: ICompany;
  token: string;
}
interface ISwitchCompany {
  company: ICompany;
  token: string;
  permissions: IPermission[];
}
interface IUpdateName {
  name: string;
}

export function switchCompany({
  company,
  token,
}: IPersistCompany): UserActionTypes {
  return {
    type: SWITCH_COMPANY,
    payload: { company, token },
  };
}

export function persistCompany({
  company,
  token,
  permissions,
}: ISwitchCompany): UserActionTypes {
  return {
    type: PERSIST_COMPANY,
    payload: { company, token, permissions },
  };
}

export function updateName({ name }: IUpdateName): UserActionTypes {
  return {
    type: UPDATE_NAME,
    payload: { name },
  };
}

export function syncCompany(): UserActionTypes {
  return {
    type: SYNC_COMPANY,
  };
}

import React from "react";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";

// import { Container } from './styles';

const Footer: React.FC = () => {
  return (
    <div
      className="mt-3 text-muted container no-print"
      style={{ maxWidth: "1100px" }}
    >
      <div className="row">
        <div className="col-md-4">
          <h6>
            <strong>Dúvidas?</strong>
          </h6>
          <small>
            Entre em contato conosco:
            <br />
            bfc@grupobfc.com.br
            <br />
            <i>*Atendimento de segunda a sexta das 08h às 18h*</i>
          </small>
        </div>
        <div className="col-md-4">
          <h6>
            <strong>Quer saber mais sobre a BFC?</strong>
          </h6>
          <small>
            Acesse{" "}
            <a href="https://www.bfccapital.com.br" target="blank">
              https://www.bfccapital.com.br
            </a>{" "}
            e transforme seus créditos em caixa!
          </small>
        </div>
        <div className="col-md-4">
          <h6 className="mb-0">
            <strong>Nossas Redes</strong>
          </h6>
          <div className="d-flex flex-wrap">
            <a
              href="https://www.linkedin.com/company/bfcdigital/"
              className="ml-1 mr-1"
              style={{ fontSize: "2rem" }}
              target="blank"
            >
              <FaLinkedin />
            </a>
            <a
              href="https://www.instagram.com/grupobfcoficial/"
              className="ml-1 mr-1"
              style={{ fontSize: "2rem" }}
              target="blank"
            >
              <FaInstagram />
            </a>
            <a
              href="https://www.facebook.com/grupobfcoficial"
              className="ml-1 mr-1"
              style={{ fontSize: "2rem" }}
              target="blank"
            >
              <FaFacebook />
            </a>
            <a
              href="https://twitter.com/grupobfcoficial"
              className="ml-1 mr-1"
              style={{ fontSize: "2rem" }}
              target="blank"
            >
              <FaTwitter />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import React from "react";

import { Receipt } from "@material-ui/icons";
import { Container } from "./styles";

import { TextField } from "@material-ui/core";
import FileUpload from "./FileUpload";

interface IProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  description: string;
  setDescription: (value: string) => void;
  file: File | null;
  setFile: (value: File | null) => void;
}

const ModalAssignorFile: React.FC<IProps> = ({
  open,
  setOpen,
  description,
  setDescription,
  file,
  setFile,
}) => {
  function resetForm() {
    setOpen(false);
  }

  function handleRemove() {
    setFile(null);
  }

  return (
    <Container
      show={open}
      size="xl"
      onHide={resetForm}
      centered
      backdrop="static"
    >
      <Container.Body>
        {!file && (
          <label id="upload-label" htmlFor="xml">
            Selecionar Arquivo
            <input
              id="xml"
              type="file"
              onChange={(e) => {
                if (e && e.target && e.target.files) {
                  setFile(e.target.files[0]);
                }
              }}
              style={{ display: "none" }}
            />
          </label>
        )}

        <div id="list-files">
          <FileUpload
            file={file}
            setFile={setFile}
            handleRemove={handleRemove}
          />
          {!file && (
            <div id="no_file">
              <Receipt />
              <h4>Nenhum arquivo selecionado</h4>
            </div>
          )}
        </div>

        <div>
          <TextField
            label="Descrição"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            inputProps={{
              autocomplete: "off",
            }}
          />
        </div>

        <div className="d-flex justify-content-end mt-2">
          <button
            type="button"
            className="btn btn-outline-primary btn-sm"
            onClick={resetForm}
          >
            Salvar
          </button>
        </div>
      </Container.Body>
    </Container>
  );
};

export default ModalAssignorFile;

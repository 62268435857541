import styled, { keyframes } from "styled-components";

import { lighten, darken } from "polished";

const fadeContainers = keyframes`
  0%{
    opacity:0;
    transform:translateX(-20px);
  }100%{
    opacity:1;
    transform:translateX(0);
  }
`;

export const Container = styled.div`
  width: 80px;
  &.opened-side {
    width: 100%;
  }
  border-radius: 15px;
  background: ${(props) => props.theme.colors.background};
  animation: ${fadeContainers} 300ms linear;
  animation-fill-mode: backwards;
  transition: 300ms;
  & + div {
    margin-top: 10px;
  }
  &.help-section-sidemenu {
    padding: 20px;
    span {
      display: block;
      padding: 0px 5px;
      border-radius: 22px 3px 22px 6px;
      width: 50px;
      height: 50px;
      margin-right: 10px;
      background: ${(props) => props.theme.colors.primary}
        url("/assets/images/avatar-suport.png") no-repeat 50% 50%;
      background-size: contain;
      transition: 300ms;
    }
    &:hover {
      span {
        transform: scale(1.1);
        background-color: ${(props) =>
          lighten(0.1, props.theme.colors.primary)};
      }
    }
  }
  
  ul {
    overflow: hidden;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0px;
    padding: 0 10px;
    &.subDropdown {
      li {
        padding-left: 20px;
      }
    }
    li {
      width: 100%;
      overflow: hidden;
      margin: 0px;
      ul.dropdown-close_menu {
        height: 0px;
        overflow: hidden;
        opacity: 0;
        overflow: hidden;
      }
      ul.dropdown-open_menu {
        display: none;
        height: 100%;
        overflow: unset;
        opacity: 1;
        overflow: hidden;
      }
      ul {
        padding: 0px;
        transition: 300ms;
        li button {
          margin: 0px;
          background: none;
          padding: 10px 15px;
        }
        li + li {
          border-top: 1px solid
            ${(props) => darken(0.05, props.theme.colors.background)};
        }
      }
    }
    li {
      overflow: hidden;
    }
    li button {
      overflow: hidden;
      margin: 0px;
      span {
        background: ${(props) => darken(0.05, props.theme.colors.background)};
        min-height: 45px;
        min-width: 45px;
        padding: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        margin-right: 15px;
        transition: 300ms;
        color: ${(props) => props.theme.colors.secondary};
        img {
          transition: 300ms;
          width: 30px;
        }
      }
      text-align: left;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      min-width: 235px;
      max-width: 235px;
      margin: 5px 0px;
      padding: 5px 0px;
      border: none;
      background: none;
      color: ${(props) => lighten(0.2, props.theme.colors.text)};
      text-decoration: none !important;
      transition: 300ms;
      &.active {
        color: ${(props) => props.theme.colors.primary};
        span {
          background: ${(props) => props.theme.colors.primary};
          color: ${(props) => props.theme.colors.background};
          img {
            filter: brightness(0) invert(1);
          }
        }
      }
      &:hover {
        color: ${(props) => props.theme.colors.primary};
        span {
          background: ${(props) => props.theme.colors.primary};
          color: ${(props) => props.theme.colors.background};
          img {
            filter: brightness(0) invert(1);
          }
        }
      }
    }
    li + li {
      border-top: 1px solid
        ${(props) => darken(0.05, props.theme.colors.background)};
    }
  }
  &.opened-side {
    ul.dropdown-open_menu {
      display: block;
    }
    ul li button span {
      min-width: 36px;
      min-height: 36px;
      width: 36px;
      height: 36px;
      padding: 8px;
      margin-right: 6px;
      img {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 980px) {
    #fixed_menu_button {
      display: none;
    }
  }
`;

import { ISignInSuccessAction, ISignOutAction } from '../auth/types';

export const SET_TYPE_EMISSION = '@user/SET_TYPE_EMISSION';
export const UPDATE_NAME = '@user/UPDATE_NAME';
export const SYNC_COMPANY = '@user/SYNC_COMPANY';
export const SWITCH_COMPANY = '@user/SWITCH_COMPANY';
export const PERSIST_COMPANY = '@user/PERSIST_COMPANY';

export interface ICompany {
  id: string;
  clieId: number | null;
  companyName: string | null;
  fantasyName: string | null;
  limit: number | null;
  cnpj: string;
}
export interface IUser {
  id: string;
  name: string;
  email: string;
}
export interface IPermission {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
}

export interface IInitialState {
  profile: IUser;
  id: string;
  clieId: number | null;
  companyName: string | null;
  fantasyName: string | null;
  limit: number | null;
  cnpj: string;
  permissions: IPermission[];
}

export interface IUpdateNameAction {
  type: typeof UPDATE_NAME;
  payload: {
    name: string;
  };
}
export interface ISyncCompanyAction {
  type: typeof SYNC_COMPANY;
}
export interface ISwitchCompanyAction {
  type: typeof SWITCH_COMPANY;
  payload: {
    company: ICompany;
    token: string;
  };
}
export interface IPersistCompanyAction {
  type: typeof PERSIST_COMPANY;
  payload: {
    company: ICompany;
    token: string;
    permissions: IPermission[];
  };
}

export type UserActionTypes =
  | IUpdateNameAction
  | ISyncCompanyAction
  | ISignInSuccessAction
  | ISwitchCompanyAction
  | IPersistCompanyAction
  | ISignOutAction;

import { normalizeCpf } from "../../utils/normalize";

export function cpfIsValid(cpf: string): boolean {
  let soma;
  let resto;
  soma = 0;
  const strCPF = cpf.replace(/\D/g, "");
  if (strCPF === "00000000000") return false;

  for (let i = 1; i <= 9; i++)
    soma += parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(strCPF.substring(9, 10))) return false;

  soma = 0;
  for (let i = 1; i <= 10; i++)
    soma += parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(strCPF.substring(10, 11))) return false;
  return true;
}

export const normalizeCurrency = (value: number) => {
  if (value === 0) {
    return "";
  }
  const stringOfNumber = String(value);
  if (stringOfNumber.length === 2) {
    return `0,${stringOfNumber[0] || 0}${stringOfNumber[1] || 0}`;
  }
  if (stringOfNumber.length === 1) {
    return `0,0${stringOfNumber[0] || 0}`;
  }
  const cents =
    stringOfNumber[stringOfNumber.length - 2] +
    stringOfNumber[stringOfNumber.length - 1];
  const reals = stringOfNumber.slice(0, stringOfNumber.length - 2);
  let pointValue = "";

  const arrayNumber = reals.split("");
  arrayNumber.reverse();
  arrayNumber.forEach((value, index) => {
    if ((index + 1) % 3 === 0) {
      if (index === arrayNumber.length - 1) {
        pointValue = value + pointValue;
      } else {
        pointValue = `.${value}${pointValue}`;
      }
    } else {
      pointValue = value + pointValue;
    }
  });
  return `${pointValue},${cents}`;
};

export const normalizeCep = (value: string) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, "");
  if (onlyNums.length <= 5) {
    return onlyNums;
  }
  return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5, 8)}`;
};

export const normalizeTelephone = (value: string) => {
  if (!value) {
    return value;
  }

  let onlyNums = value.replace(/\D/g, "");

  if (onlyNums.length === 0) {
    onlyNums = ``;
  } else if (onlyNums.length === 1) {
    onlyNums = `(${onlyNums.slice(0, 1)}`;
  } else if (onlyNums.length === 2) {
    onlyNums = `(${onlyNums.slice(0, 2)}`;
  } else if (onlyNums.length < 7) {
    onlyNums = `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 7)}`;
  } else if (onlyNums.length < 11) {
    onlyNums = `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(
      2,
      6
    )}-${onlyNums.slice(6, 11)}`;
  } else {
    onlyNums = `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(
      2,
      7
    )}-${onlyNums.slice(7, 11)}`;
  }

  return onlyNums;
};

export const normalizeAccBank = (value: string) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/\D/g, "");

  if (onlyNums.length === 1) {
    return onlyNums;
  }

  return `${onlyNums.slice(0, onlyNums.length - 1)}-${onlyNums.slice(
    onlyNums.length - 1,
    onlyNums.length
  )}`;
};

export const normalizeNumber = (value: string) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/\D/g, "");

  return onlyNums;
};

export const normalizeNumbersAndSpecialCharacters = (value: string) => {
  if (!value) {
    return value;
  }

  const only = value.replace(/[^0-9-_/]/g, "");

  return only;
};

export const normalizeCpfCnpj = (value: string) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, "");
  if (onlyNums.slice(0, 3) === "000" && cpfIsValid(onlyNums.slice(3))) {
    return normalizeCpf(onlyNums.slice(3));
  }
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}`;
  }
  if (onlyNums.length <= 9) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(
      6,
      9
    )}`;
  }
  if (onlyNums.length <= 11) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(
      6,
      9
    )}-${onlyNums.slice(9, 11)}`;
  }
  if (onlyNums.length <= 12) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
      5,
      8
    )}/${onlyNums.slice(8, 12)}`;
  }
  return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
    5,
    8
  )}/${onlyNums.slice(8, 12)}-${onlyNums.slice(12, 14)}`;
};

export const normalizeDate = (value: string) => {
  if (!value) {
    return value;
  }

  const formated = value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{4})\d+?$/, "$1");

  return formated;
};

export function cnpjIsValid(cnpj: any): boolean {
  cnpj = cnpj.replace(/[^\d]+/g, "");

  if (cnpj === "") return false;

  if (cnpj.length !== 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj === "00000000000000" ||
    cnpj === "11111111111111" ||
    cnpj === "22222222222222" ||
    cnpj === "33333333333333" ||
    cnpj === "44444444444444" ||
    cnpj === "55555555555555" ||
    cnpj === "66666666666666" ||
    cnpj === "77777777777777" ||
    cnpj === "88888888888888" ||
    cnpj === "99999999999999"
  )
    return false;

  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  const digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== Number(digitos.charAt(0))) return false;

  tamanho += 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== Number(digitos.charAt(1))) return false;

  return true;
}

export function payerIsComplete(
  require_payer_details: boolean,
  item: Title
): boolean {
  if (
    require_payer_details &&
    (!item.payer.name ||
      item.payer.name === "" ||
      !item.payer.street ||
      item.payer.street === "" ||
      !item.payer.email ||
      item.payer.email === "" ||
      !item.payer.district ||
      item.payer.district === "" ||
      !item.payer.city ||
      item.payer.city === "" ||
      !item.payer.state ||
      item.payer.state === "")
  ) {
    return false;
  } else if (
    !require_payer_details &&
    (!item.payer.name ||
      item.payer.name === "" ||
      !item.payer.street ||
      item.payer.street === "" ||
      !item.payer.district ||
      item.payer.district === "" ||
      !item.payer.city ||
      item.payer.city === "" ||
      !item.payer.state ||
      item.payer.state === "")
  ) {
    return false;
  }

  return true;
}

import React, { memo, useEffect, useState } from "react";
import { api } from "~/hooks/api";
import { TableComponent } from "~/pages/main/components/TableComponent";
import { formatDate } from "~/pages/main/utils/normalize";
import { useAuth } from "~/hooks/useAuth";
import { normalizeCnpj, normalizeCurrency } from "../../Service/utils";
import { Container } from "./styles";

interface INextItem {
  (): void;
}

interface IProps {
  handleNextItem: INextItem;
}

const Open: React.FC<IProps> = () => {
  const [arrangements, setArrangements] = useState<any[]>([]);
  const [accreditors, setAccreditors] = useState<any[]>([]);
  const { currentAssignor } = useAuth();

  useEffect(() => {
    api
      .get(`/b3-arrangements`)
      .then((response) => {
        setArrangements(response.data);
      })
      .finally(() => {});

    api
      .get(`/b3-accreditors`)
      .then((response) => {
        setAccreditors(response.data);
      })
      .finally(() => {});
  }, []);

  const getData = async (params) => {
    const {
      page,
      per_page,
      expected_settlement_date_from,
      expected_settlement_date_to,
      accreditor_document,
    } = params;

    let filters: any = {
      originatorDocument: currentAssignor?.cnpj,
      obligation_type: "efeitoContratado",
      page,
      per_page,
    };

    let query = {
      ...filters,
      ...params,
    };

    if (expected_settlement_date_from) {
      query.expected_settlement_date_from = expected_settlement_date_from
        ?.toISOString()
        .split("T")[0];
    }

    if (expected_settlement_date_to) {
      query.expected_settlement_date_to = expected_settlement_date_to
        ?.toISOString()
        .split("T")[0];
    }

    if (accreditor_document) {
      query.cnpj_accreditor = accreditor_document;
    }

    const { data } = await api.get(
      `b3-originator-urs?${Object.keys(query)
        .filter((key) => query[key])
        .map((key) => `${key}=${query[key]}`)
        .join("&")}`
    );

    return data;
  };

  const exportData = async (params) => {
    const {
      page,
      per_page,
      expected_settlement_date_from,
      expected_settlement_date_to,
      accreditor_document,
    } = params;

    let filters: any = {
      originatorDocument: currentAssignor?.cnpj,
      obligation_type: "efeitoContratado",
      page,
      per_page,
    };

    let query = {
      ...filters,
      ...params,
    };

    if (expected_settlement_date_from) {
      query.expected_settlement_date_from = expected_settlement_date_from
        ?.toISOString()
        .split("T")[0];
    }

    if (expected_settlement_date_to) {
      query.expected_settlement_date_to = expected_settlement_date_to
        ?.toISOString()
        .split("T")[0];
    }

    if (accreditor_document) {
      query.cnpj_accreditor = accreditor_document;
    }

    window.open(
      `${process.env.REACT_APP_API}/b3-originator-urs?${Object.keys(query)
        .filter((key) => query[key])
        .map((key) => `${key}=${query[key]}`)
        .join("&")}`
    );
  };

  return (
    <Container>
      <TableComponent
        title="Liquidação Cartões"
        totalsBadges={[
          {
            title: "Total de Crédito",
            fieldName: "total_value",
            type: "currency",
          },
          {
            title: "Total Liquidado",
            fieldName: "total_received_value",
            type: "currency",
          },
          {
            title: "Total em aberto",
            fieldName: "total_ur_open_value",
            type: "currency",
          },
        ]}
        dataReciever={async (params) => {
          let data = await getData(params);

          return data;
        }}
        exportAction={async (params) => exportData(params)}
        dataType={[]}
        traitResponse={(data: any) => {
          return data.map((d) => {
            return {
              ...d,
            };
          });
        }}
        filters={[
          {
            name: "accreditor_document",
            title: "Credenciadora",
            select: true,
            defaultValue: null,
            options: accreditors?.map((accreditor) => ({
              title: ` ${accreditor?.name}`,
              value: accreditor?.cnpj,
            })),
            col: 3,
          },
          {
            name: "arrangement_id",
            title: "Arranjo",
            select: true,
            defaultValue: null,
            options: arrangements?.map((arrangement) => ({
              title: ` ${arrangement?.code} - ${arrangement?.name}`,
              value: arrangement?.id,
            })),
            col: 3,
          },
          {
            name: "expected_settlement_date_from",
            title: "Liquidação (de)",
            type: "date",
            col: 2,
          },
          {
            name: "expected_settlement_date_to",
            title: "Liquidação (até)",
            type: "date",
            col: 2,
          },
        ]}
        labels={[
          {
            name: "expected_settlement_date",
            title: "Liquidação",
            formater: (value) => formatDate(value),
          },
          {
            name: "arrangement",
            title: "Arranjo",
          },
          {
            name: "arrangement_name",
            title: "Nome do Arranjo",
          },
          {
            name: "cnpj_accreditor",
            title: "CNPJ da Credenciadora",
            formater: (value) => normalizeCnpj(value),
          },
          {
            name: "accreditor_name",
            title: "Nome da Credenciadora",
          },
          {
            name: "credit_value",
            title: "Valor do Crédito",
            formater: (value) => normalizeCurrency(value),
            align: "right",
          },
          {
            name: "received_value",
            title: "Liquidado",
            formater: (value) => `${normalizeCurrency(value ?? 0)}`,
            align: "right",
            ableToOrder: false,
          },
          {
            name: "ur_open_value",
            title: "Em Aberto",
            formater: (value) => `${normalizeCurrency(value ?? 0)}`,
            align: "right",
            ableToOrder: false,
          },
          {
            name: "updated_at",
            title: "Atualização",
            formater: (value) => formatDate(value),
          },
        ]}
        editable={false}
        addItem={false}
      />
    </Container>
  );
};

export default memo(Open);

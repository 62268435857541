import {
  Divider,
  FormControlLabel,
  Switch,
  TextField,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { api } from "~/hooks/api";
import { useAuth } from "~/hooks/useAuth";
import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";

interface IProps {
  onClose: () => void;
  dataReport?: any;
  onAdd: (item: any) => void;
  onUpdate: (item: any) => void;
  onDelete: (id: number) => void;
}

export const ModalAdd: React.FC<IProps> = ({
  dataReport,
  onClose,
  onAdd,
  onUpdate,
  onDelete,
}) => {
  const [values, setValues] = useState(() => {
    if (dataReport) {
      return dataReport;
    }

    return {} as any;
  });

  const [confirmDelete, setConfirmDelete] = useState(false);

  const [loading, setLoading] = useState(false);

  async function handleAdd(): Promise<any> {
    return await api.post(`/register/report`, {
      ...values,
      cnab: values.cnab || false,
      paid: values.paid || false,
      open: values.open || false,
      returned: values.returned || false,
      cnab_paid: values.cnab_paid || false,
      cnab_accepted: values.cnab_accepted || false,
      cnab_rejected: values.cnab_rejected || false,
    });
  }

  async function handleEdit(): Promise<any> {
    return await api.put(`/register/report/${values.id}`, {
      ...values,
      cnab: values.cnab || false,
      paid: values.paid || false,
      open: values.open || false,
      returned: values.returned || false,
      cnab_paid: values.cnab_paid || false,
      cnab_accepted: values.cnab_accepted || false,
      cnab_rejected: values.cnab_rejected || false,
    });
  }

  async function handleDelete(): Promise<any> {
    setLoading(true);
    try {
      await api.delete(`/register/report/${values.id}`);
      onDelete(dataReport.id);
      toast.success("Email de recebimento excluído com sucesso");
      onClose();
    } catch (err) {
      //
    }
    setLoading(false);
  }

  async function handleSubmit() {
    setLoading(true);
    try {
      if (values.id) {
        const { data } = await handleEdit();
        onUpdate(data);
        toast.success("Email de recebimento atualizado");
        onClose();
      } else {
        const { data } = await handleAdd();
        onAdd(data);
        toast.success("Email de recebimento adicionado");
        onClose();
      }
    } catch (err) {
      //
    }
    setLoading(false);
  }

  return (
    <Modal show centered onHide={onClose} size="lg">
      <Modal.Header closeButton>
        {dataReport?.id ? "Atualizar email" : "Adicionar email"}
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col lg={12}>
            <Form.Group className="mb-5">
              <TextField
                label="E-mail"
                value={values.email || ""}
                onChange={(e) =>
                  setValues({ ...values, email: e.target.value })
                }
                disabled={loading}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <Form.Group className="text-center">
              <FormControlLabel
                labelPlacement="top"
                control={
                  <Switch
                    color="primary"
                    checked={values.paid}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        paid: !values.paid,
                      })
                    }
                  />
                }
                label="Títulos e tarifas liquidadas"
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="text-center">
              <FormControlLabel
                labelPlacement="top"
                control={
                  <Switch
                    color="primary"
                    checked={values.open}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        open: !values.open,
                      })
                    }
                  />
                }
                label="Títulos e tarifas em aberto"
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="text-center">
              <FormControlLabel
                labelPlacement="top"
                control={
                  <Switch
                    color="primary"
                    checked={values.returned}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        returned: !values.returned,
                      })
                    }
                  />
                }
                label="Títulos devolvidos"
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="text-center">
              <FormControlLabel
                labelPlacement="top"
                control={
                  <Switch
                    color="primary"
                    checked={values.cnab}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        cnab: !values.cnab,
                      })
                    }
                  />
                }
                label="CNAB de retorno"
              />
            </Form.Group>
          </Col>
        </Row>

        {values.cnab && (
          <>
            <Divider className="mt-4 mb-4" />
            <p className="text-center mb-3">Incluir no CNAB de retorno</p>
            <Row>
              <Col lg={6}>
                <Form.Group className="text-center">
                  <FormControlLabel
                    labelPlacement="top"
                    control={
                      <Switch
                        color="primary"
                        checked={values.cnab_paid}
                        onChange={(e) =>
                          setValues({
                            ...values,
                            cnab_paid: !values.cnab_paid,
                          })
                        }
                      />
                    }
                    label="Títulos Liquidados"
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="text-center">
                  <FormControlLabel
                    labelPlacement="top"
                    control={
                      <Switch
                        color="primary"
                        checked={values.cnab_accepted}
                        onChange={(e) =>
                          setValues({
                            ...values,
                            cnab_accepted: !values.cnab_accepted,
                          })
                        }
                      />
                    }
                    label="Títulos Registrados"
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="text-center">
                  <FormControlLabel
                    labelPlacement="top"
                    control={
                      <Switch
                        color="primary"
                        checked={values.cnab_rejected}
                        onChange={(e) =>
                          setValues({
                            ...values,
                            cnab_rejected: !values.cnab_rejected,
                          })
                        }
                      />
                    }
                    label="Títulos Rejeitados"
                  />
                </Form.Group>
              </Col>
            </Row>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        {!confirmDelete && (
          <div
            className="d-flex justify-content-between mb-2"
            style={{ width: "100%" }}
          >
            {values.id && (
              <Button
                type="button"
                variant="outline-danger"
                className="btn-sm"
                onClick={() => setConfirmDelete(true)}
              >
                Excluir
              </Button>
            )}

            <div className="d-flex">
              <Button
                type="button"
                variant="primary"
                onClick={handleSubmit}
                className="btn-sm"
                disabled={loading}
              >
                Salvar
              </Button>
              <Button
                type="button"
                variant="outline-secondary"
                disabled={loading}
                onClick={onClose}
                className="ml-2 btn-sm"
              >
                Cancelar
              </Button>
            </div>
          </div>
        )}
        {confirmDelete && (
          <div style={{ width: "100%" }} className="mb-2">
            <small className="d-block mb-2 text-right w-100">
              Você tem certeza que deseja excluir?
            </small>
            <div className="d-flex justify-content-end">
              <Button
                type="button"
                variant="danger"
                onClick={handleDelete}
                className="btn-sm"
                disabled={loading}
              >
                Sim, excluir
              </Button>
              <Button
                type="button"
                variant="outline-secondary"
                className="ml-2 btn-sm"
                disabled={loading}
                onClick={() => setConfirmDelete(false)}
              >
                Cancelar
              </Button>
            </div>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export const states = [
  { uf: 'AC', desc: 'Acre' },
  { uf: 'AL', desc: 'Alagoas' },
  { uf: 'AP', desc: 'Amapá' },
  { uf: 'AM', desc: 'Amazonas' },
  { uf: 'BA', desc: 'Bahia' },
  { uf: 'CE', desc: 'Ceará' },
  { uf: 'DF', desc: 'Distrito Federal' },
  { uf: 'ES', desc: 'Espírito Santo' },
  { uf: 'GO', desc: 'Goiás' },
  { uf: 'MA', desc: 'Maranhão' },
  { uf: 'MT', desc: 'Mato Grosso' },
  { uf: 'MS', desc: 'Mato Grosso do Sul' },
  { uf: 'MG', desc: 'Minas Gerais' },
  { uf: 'PA', desc: 'Pará' },
  { uf: 'PB', desc: 'Paraíba' },
  { uf: 'PR', desc: 'Paraná' },
  { uf: 'PE', desc: 'Pernambuco' },
  { uf: 'PI', desc: 'Piauí' },
  { uf: 'RJ', desc: 'Rio de Janeiro' },
  { uf: 'RN', desc: 'Rio Grande do Norte' },
  { uf: 'RS', desc: 'Rio Grande do Sul' },
  { uf: 'RO', desc: 'Rondônia' },
  { uf: 'RR', desc: 'Roraima' },
  { uf: 'SC', desc: 'Santa Catarina' },
  { uf: 'SP', desc: 'São Paulo' },
  { uf: 'SE', desc: 'Sergipe' },
  { uf: 'TO', desc: 'Tocantis' }
]

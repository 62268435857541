import React, { useEffect, useState } from "react";

import Header from "../components/Header";
import { Container } from "./styles";
import { Form, Modal, Spinner } from "react-bootstrap";
import { FaCheck, FaFolderOpen, FaTimes } from "react-icons/fa";
import Footer from "../components/Footer";
import { api } from "~/hooks/api";
import { normalizeCnpj } from "~/pages/main/Pendings/utils";
import { normalizeCurrency } from "~/pages/main/NfseAnticipation/utils/normalize";
import { toast } from "react-toastify";

export default function LetterConfirm({ match }: any) {
  const token = String(match.params.token);

  const [loading, setLoading] = useState(false);

  const [selectedTitles, setSelectedTitles] = useState([] as number[]);

  const [modalConfirm, setModalConfirm] = useState({
    open: false,
    action: null as "confirm" | "reject" | "aware" | null,
  });

  const [titlesByAssignor, setTitlesByAssignor] = useState([] as any[]);
  const [payerData, setPayerData] = useState({} as any);
  const [error, setError] = useState<string | null>(null);

  async function loadData() {
    setLoading(true);
    try {
      const { data } = await api.get(`payer/titles/${token}`);
      const arr = [] as any[];
      let obj = {} as any;
      data.titles.forEach((title) => {
        if (!obj[title.assignor_id]) {
          obj[title.assignor_id] = {
            ...(title?.assignor || {}),
            titles: [],
          };
        }
        obj[title.assignor_id].titles.push({
          ...title,
          assignor: undefined,
        });
      });
      Object.keys(obj).forEach((key) => {
        arr.push(obj[key]);
      });
      setTitlesByAssignor(arr);
      setPayerData(data.payer);
      setError(null);
    } catch (error) {
      setError("Dados não encontrados");
    }
    setLoading(false);
  }

  useEffect(() => {
    loadData();
  }, []);

  async function handleSubmit() {
    setModalConfirm({
      ...modalConfirm,
      open: false,
    });
    setLoading(true);
    try {
      await api.post(`payer/titles/confirm/${token}`, {
        titles: selectedTitles,
        action: modalConfirm.action,
      });
      toast.info("Títulos processados");
      setSelectedTitles([]);
      await loadData();
    } catch (err) {
      //
    }
    setLoading(false);
  }

  if (loading) {
    return (
      <Container>
        <Header />
        <div className="container mt-4 p-3" style={{ maxWidth: "1100px" }}>
          <Spinner animation="border" /> Carregando informações... Aguarde
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <Modal
        show={modalConfirm.open}
        centered
        onHide={() =>
          setModalConfirm({
            open: false,
            action: null,
          })
        }
      >
        <Modal.Header closeButton>
          {modalConfirm.action === "confirm" ? "Confirmar Títulos" : null}
          {modalConfirm.action === "reject" ? "Rejeitar Títulos" : null}
          {modalConfirm.action === "aware"
            ? "Reconheço os Títulos, mas não recebi a mercadoria"
            : null}
        </Modal.Header>
        <Modal.Body>
          <h5>{selectedTitles.length} Título(s) Selecionado(s)</h5>
          <p>
            Você tem certeza que deseja{" "}
            {modalConfirm.action === "confirm" ? "Confirmar" : null}
            {modalConfirm.action === "reject" ? "Rejeitar" : null}
            {modalConfirm.action === "aware" ? "Recohecer" : null}?
          </p>
          <div className="d-flex justify-content-center mt-3">
            <button
              className={`btn ${
                modalConfirm.action === "confirm" ? "btn-success" : "btn-danger"
              }`}
              onClick={() => {
                handleSubmit();
              }}
              type="button"
            >
              Sim
            </button>
            <button
              className="btn btn-secondary ml-2"
              onClick={() =>
                setModalConfirm({
                  open: false,
                  action: null,
                })
              }
            >
              Cancelar
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Header />
      <div className="container mt-4 p-3" style={{ maxWidth: "1100px" }}>
        <h4>
          Olá, <strong>{payerData.name}</strong>!
        </h4>
        {titlesByAssignor.length === 0 && (
          <div
            className="p-3 mt-3 bg-gray text-center d-flex flex-column align-items-center justify-content-center"
            style={{ minHeight: "20vh" }}
          >
            <FaFolderOpen size="3rem" />
            <p>
              Atualmente não temos nenhum título seu em negociação para ser
              confirmado.
            </p>
          </div>
        )}
        {titlesByAssignor.length > 0 && (
          <>
            <p className="text-muted">
              Por favor, nos confirme a regularidade do(s) título(s)
              relacionado(s) abaixo, cujos direitos direitos cerditórios foram
              cedidos em nosso favor pela(s) empresa(s) abaixo:
            </p>
            <div className="alert alert-warning">
              Esclarecemos que, na hipótese, de não nos ser respondida a
              presente notificação, será presumida a confirmação da regularidade
              dos títulos.
            </div>
            <div className="mt-3">
              {titlesByAssignor.map((assignor) => (
                <div className="mt-3" key={assignor.id}>
                  <div className="p-3 bg-gray">
                    <h5 className="mb-0">
                      <strong>{assignor.name}</strong>
                    </h5>
                    <small>CNPJ: {normalizeCnpj(assignor.cnpj)}</small>
                  </div>
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <td style={{ width: "20px" }}></td>
                        <td>Título</td>
                        <td>Tipo</td>
                        <td>Vencimento</td>
                        <td>Valor</td>
                      </tr>
                    </thead>
                    <tbody>
                      {assignor.titles.map((title) => (
                        <tr
                          key={title.id}
                          onClick={() => {
                            if (selectedTitles.find((i) => i === title.id)) {
                              setSelectedTitles(
                                selectedTitles.filter((i) => i !== title.id)
                              );
                            } else {
                              setSelectedTitles([...selectedTitles, title.id]);
                            }
                          }}
                        >
                          <td>
                            <Form.Check
                              checked={
                                !!selectedTitles.find((i) => i === title.id)
                              }
                            />
                          </td>
                          <td>
                            {title.document_number}{" "}
                            {title?.key?.length === 44 && (
                              <a
                                href={`/payer/danfe/${token}/${title?.key}`}
                                target="blank"
                              >
                                VER DANFE
                              </a>
                            )}
                          </td>
                          <td>{title?.titleType?.name}</td>
                          <td>
                            {title.date
                              ? new Intl.DateTimeFormat("pt-BR").format(
                                  new Date(title.date)
                                )
                              : ""}
                          </td>
                          <td>R$ {normalizeCurrency(title.value)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ))}
            </div>
            <h5>
              Ao confirmar os títulos, o sacado aceita os seguintes termos:
            </h5>
            <div className="alert alert-warning mt-3">
              <strong>CONFIRMAMOS</strong> a regularidade do(s) título(s) acima
              relacionado(s) quanto à emissão, valor, vencimento e respectiva
              entrega da mercadoria, cujos pedidos foram integralmente e
              plenamente entregues na mais perfeita ordem, tudo em conformidade
              com o contratado, lastreando, portanto, as respectivas
              Duplicatas/Faturas/CTRC's e Cheques cedidos pela emitente para V.
              Sas. razão pela qual reconhecemos que é a detentora dos direitos
              creditórios, e que nos comprometemos a efetuar os pagamentos dos
              referidos títulos, única e exclusivamente para BFC, nas datas de
              seus respectivos vencimentos.
            </div>
            <div
              className="d-flex justify-content-between flex-wrap"
              style={{ gap: "0.5em" }}
            >
              <button
                className="btn btn-warning"
                onClick={() => {
                  setModalConfirm({
                    open: true,
                    action: "aware",
                  });
                }}
                disabled={selectedTitles.length === 0}
              >
                Reconheço, mas não recebi a mercadoria
              </button>
              <div className="d-flex" style={{ gap: "0.5em" }}>
                <button
                  className="btn btn-success"
                  onClick={() => {
                    setModalConfirm({
                      open: true,
                      action: "confirm",
                    });
                  }}
                  disabled={selectedTitles.length === 0}
                >
                  <FaCheck /> Confirmar Títulos
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => {
                    setModalConfirm({
                      open: true,
                      action: "reject",
                    });
                  }}
                  disabled={selectedTitles.length === 0}
                >
                  <FaTimes /> Rejeitar Títulos
                </button>
              </div>
            </div>
          </>
        )}
        <hr />
        {error && (
          <div className="text-center mt-100" style={{ marginTop: "100px" }}>
            {error}
          </div>
        )}
        <Footer />
      </div>
    </Container>
  );
}

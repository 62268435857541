import { RegisterActionTypes } from './types';

interface IOpenModal {
  modal_to: string;
}

export function editActive(): RegisterActionTypes {
  return {
    type: '@register/EDIT_ACTIVE',
  };
}

export function editUnActive(): RegisterActionTypes {
  return {
    type: '@register/EDIT_UNACTIVE',
  };
}

export function loadingActive(): RegisterActionTypes {
  return {
    type: '@register/LOADING_ACTIVE',
  };
}

export function loadingUnActive(): RegisterActionTypes {
  return {
    type: '@register/LOADING_UNACTIVE',
  };
}

export function modalOpen({ modal_to }: IOpenModal): RegisterActionTypes {
  return {
    type: '@register/MODAL_OPEN',
    payload: { modal_to },
  };
}

export function modalClose(): RegisterActionTypes {
  return {
    type: '@register/MODAL_CLOSE',
  };
}

import { TypedUseSelectorHook, useSelector } from "react-redux";

import { IInitialState as IAnticipation } from "./modules/anticipation/types";
import { IInitialState as IAuth } from "./modules/auth/types";
import { IInitialState as ICcbAnticipation } from "./modules/ccbAnticipation/types";
import { IInitialState as ICheckAnticipation } from "./modules/checkAnticipation/types";
import { IInitialState as IContractAnticipation } from "./modules/contractAnticipation/types";
import { IInitialState as INfseAnticipation } from "./modules/nfseAnticipation/types";
import { IInitialState as IRegister } from "./modules/register/types";
import { IInitialState as IUser } from "./modules/user/types";

export interface RootState {
  auth: IAuth;
  user: IUser;
  register: IRegister;
  anticipation: IAnticipation;
  checkAnticipation: ICheckAnticipation;
  nfseAnticipation: INfseAnticipation;
  ccbAnticipation: ICcbAnticipation;
  contractAnticipation: IContractAnticipation;
}

const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export default useTypedSelector;

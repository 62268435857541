import React, { useEffect, useState } from "react";

import {
  Box,
  CircularProgress,
  CircularProgressProps,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

// import { useDispatch } from 'react-redux';
// import axios from 'axios';
import { api } from "~/hooks/api";

interface IProps {
  file: File;
  index: number;
  handleRemove: (index: number) => void;
}

const ModalCnab: React.FC<IProps> = ({ file, index, handleRemove }) => {
  const [progress, setProgress] = useState(Math.floor(Math.random() * 101));
  const [error, setError] = useState({ active: false, message: "" });
  const [success, setSuccess] = useState(false);

  // const dispatch = useDispatch();

  function onUpload() {
    let filesUploaded = sessionStorage.getItem("cnab_uploaded");
    if (!filesUploaded) {
      filesUploaded = "[]";
    }
    const arrFiles: string[] = JSON.parse(filesUploaded);

    if (!arrFiles.find((i) => i === file.name)) {
      arrFiles.push(file.name);
      sessionStorage.setItem("cnab_uploaded", JSON.stringify(arrFiles));
    }
  }

  function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number }
  ) {
    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress variant="determinate" {...props} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <small className="text-muted" style={{ fontSize: "8px" }}>
            {`${Math.round(props.value)}%`}
          </small>
        </Box>
      </Box>
    );
  }

  async function uploadItem() {
    setError({
      active: false,
      message: "",
    });
    try {
      const objData = new FormData();
      objData.append("cnab", file);
      await api.post(`uploads/cnab/nfe`, objData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (e) => {
          setProgress(Math.round((100 * e.loaded) / e.total));
        },
      });
      setSuccess(true);
    } catch (err) {
      setError({
        active: true,
        message: err.message,
      });
      console.log("~ err", err);
      //
    }
    onUpload();
  }

  useEffect(() => {
    async function upload() {
      const uploadedString = sessionStorage.getItem("cnab_uploaded");
      const uploaded: string[] = JSON.parse(uploadedString || "[]");
      if (file && !uploaded.find((i) => i === file.name)) {
        await uploadItem();
      } else if (uploaded.find((i) => i === file.name)) {
        setError({
          active: true,
          message: "Arquivo já enviado",
        });
      }
    }
    upload();
  }, [file]); // eslint-disable-line

  return (
    <div key={index} className="file_item">
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div className="d-flex align-items-center">
          {!success && (
            <button type="button" onClick={() => handleRemove(index)}>
              <Close />
            </button>
          )}
          <h4
            className={`mb-0 ${error.active ? "text-danger" : ""} ${
              success ? "text-success" : ""
            }`}
          >
            {file.name}
          </h4>
          {error.active && (
            <i className="d-block ml-2">
              <small className="text-muted">{error.message}</small>
            </i>
          )}
        </div>
        {progress >= 100 && !success && !error.active && (
          <CircularProgress size="24px" />
        )}
        {progress < 100 && !error.active && (
          <CircularProgressWithLabel
            variant="determinate"
            value={progress}
            size="24px"
          />
        )}
      </div>
    </div>
  );
};

export default ModalCnab;

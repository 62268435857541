import React from "react";

const ConsultProtest: React.FC = () => {
  return (
    <div className="widget-services radius-4 w-100">
      <div className="card-body">
        <div className="row">
          <div className="col-12">
            <h4>Consulta de Protesto</h4>
            <p>
              Consultas de restrições cadastrais junto à central de protesto.
            </p>
            <a className="btn" href="/servicos/protesto">
              Consultar
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsultProtest;

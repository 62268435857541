export const situacao = (value: number) => {
  switch (value) {
    case 1:
      return 'EXTINTO POR ENCERRAMENTO LIQUIDACAO VOLUNTARIA';
    case 2:
      return 'INCORPORACAO';
    case 3:
      return 'FUSAO';
    case 4:
      return 'CISAO TOTAL';
    case 5:
      return 'ENCERRAMENTO DA FALENCIA';
    case 6:
      return 'ENCERRAMENTO DA LIQUIDACAO';
    case 7:
      return 'ELEVACAO A MATRIZ';
    case 8:
      return 'TRANSPASSE';
    case 9:
      return 'NO INICIO DE ATIVIDADE';
    case 10:
      return 'EXTINTO PELO ENCERRAMENTO DA LIQUIDACAO JUDICIAL';
    case 11:
      return 'ANULACAO POR MULTICIPLIDADE';
    case 12:
      return 'ANULACAO ONLINE DE OFICIO';
    case 13:
      return 'OMISSA CONTUMAZ';
    case 14:
      return 'OMISSA NAO LOCALIZADA';
    case 15:
      return 'INEXISTENTE DE FATO';
    case 16:
      return 'ANULACAO POR VICIOS';
    case 17:
      return 'BAIXA INICIADA E AINDA NAO DEFERIDA';
    case 18:
      return 'INTERRUPCAO TEMPORARIA DAS ATIVIDADES';
    case 19:
      return 'OMISSO DE DIRPJ ATE 5 EXERCICIOS';
    case 20:
      return 'EM CONDICAO DE INAPTIDAO';
    case 21:
      return 'PEDIDO DE BAIXA INDEFERIDA';
    case 22:
      return 'RESTABELECIMENTO COM CERTIDAO POSITIVA COM EFEITO DE NEGATIVA';
    case 23:
      return 'COM PENDENCIA FISCAL';
    case 24:
      return 'POR EMISSAO CERTIDAO NEGATIVA';
    case 25:
      return 'CERTIDAO POSITIVA COM EFEITO DE NEGATIVA';
    case 26:
      return 'IRREGULARIDADE DE PAGAMENTO';
    case 27:
      return 'IRREGULARIDADE DE RECOLHIMENTO E EXIGIBILIDADE SUSPENSA';
    case 28:
      return 'TRANSFERENCIA FILIAL CONDICAO MATRIZ';
    case 29:
      return 'AGUARDANDO CONF. DE DIRPJ/DIPJ';
    case 30:
      return 'ANR - AGUARDANDO CONF. DE DIRPJ/DIPJ';
    case 31:
      return 'EXTINCAO DA FILIAL';
    case 32:
      return 'INEXISTENTE DE FATO ñ ADE/COSAR';
    case 33:
      return 'TRANSFERENCIA DO ORGAO LOCAL A CONDICAO DE FILIAL DO ORGAO REGIONAL';
    case 34:
      return 'ANULACAO DE INSCRICAO INDEVIDA';
    case 35:
      return 'EMPRESA ESTRANGEIRA AGUARDANDO DOCUMENTACAO';
    case 36:
      return 'PRATICA IRREGULAR DE OPERACAO DE COMERCIO EXTERIOR';
    case 37:
      return 'BAIXA DE PRODUTOR RURAL';
    case 38:
      return 'BAIXA DEFERIDA PELA RFB AGUARDANDO ANALISE DO CONVENENTE';
    case 39:
      return 'BAIXA DEFERIDA PELA RFB E INDEFERIDA PELO CONVENENTE';
    case 40:
      return 'BAIXA INDEFERIDA PELA RFB E AGUARDANDO ANALISE DO CONVENENTE';
    case 41:
      return 'BAIXA INDEFERIDA PELA RFB E DEFERIDA PELO CONVENENTE';
    case 42:
      return 'BAIXA INDEFERIDA PELA RFB E SEFIN, AGUARDANDO ANALISE SEFAZ';
    case 43:
      return 'BAIXA DEFERIDA PELA RFB, AGUARDANDO ANALISE DA SEFAZ E INDEFERIDA PELA SEFIN';
    case 44:
      return 'BAIXA DEFERIDA PELA RFB E SEFAZ, AGUARDANDO ANALISE SEFIN';
    case 45:
      return 'BAIXA DEFERIDA PELA RFB, AGUARDANDO ANALISE DA SEFIN E INDEFERIDA PELA SEFAZ';
    case 46:
      return 'BAIXA DEFERIDA PELA RFB E SEFAZ E INDEFERIDA PELA SEFIN';
    case 47:
      return 'BAIXA DEFERIDA PELA RFB E SEFIN E INDEFERIDA PELA SEFAZ';
    case 48:
      return 'BAIXA INDEFERIDA PELA RFB, AGUARDANDO ANALISE SEFAZ E DEFERIDA PELA SEFIN';
    case 49:
      return 'BAIXA INDEFERIDA PELA RFB, AGUARDANDO ANALISE DA SEFAZ E INDEFERIDA PELA SEFIN';
    case 50:
      return 'BAIXA INDEFERIDA PELA RFB, DEFERIDA PELA SEFAZ E AGUARDANDO ANALISE DA SEFIN';
    case 51:
      return 'BAIXA INDEFERIDA PELA RFB E SEFAZ, AGUARDANDO ANALISE DA SEFIN';
    case 52:
      return 'BAIXA INDEFERIDA PELA RFB, DEFERIDA PELA SEFAZ E INDEFERIDA PELA SEFIN';
    case 53:
      return 'BAIXA INDEFERIDA PELA RFB E SEFAZ E DEFERIDA PELA SEFIN';
    case 54:
      return 'BAIXA - TRATAMENTO DIFERENCIADO DADO AS ME E EPP (LEI COMPLEMENTAR NUMERO 123/2006)';
    case 55:
      return 'DEFERIDO PELO CONVENENTE, AGUARDANDO ANALISE DA RFB';
    case 60:
      return 'ARTIGO 30 VI DA IN 748/2007';
    case 61:
      return 'INDICIO INTERPOS. FRAUDULENTA';
    case 62:
      return 'FALTA DE PLURALIDADE DE SOCIOS';
    case 63:
      return 'OMISSAO DE DECLARACOES';
    case 64:
      return 'LOCALIZACAO DESCONHECIDA';
    case 66:
      return 'INAPTIDAO';
    case 67:
      return 'REGISTRO CANCELADO';
    case 70:
      return 'ANULACAO POR NAO CONFIRMADO ATO DE REGISTRO DO MEI NA JUNTA COMERCIAL';
    case 71:
      return 'INAPTIDAO (LEI 11.941/2009 ART.54)';
    case 72:
      return 'DETERMINACAO JUDICIAL';
    case 73:
      return 'OMISSAO CONTUMAZ';
    case 74:
      return 'INCONSISTENCIA CADASTRAL';
    case 80:
      return 'BAIXA REGISTRADA NA JUNTA, INDEFERIDA NA RFB';

    default:
      return '';
  }
};

import styled from 'styled-components';

export const Container = styled.div`
  background: ${(props) => props.theme.colors.background};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  #billets_table_wrap {
    > div:nth-child(1) {
      padding: 30px;
      p {
        margin: 0px;
      }
    }
    .content_component {
      display: flex;
      flex-direction: column;
      flex-flow: wrap;
      padding: 20px 0 40px;
      > div {
        width: 20%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-bottom: 25px;
        button {
          font-size: 12px;
          display: flex;
          align-items: center;
          color: #fff;
          background: #3757a1;
          border-radius: 5px;
          svg {
            margin-right: 5px;
          }
        }
        a {
          color: #3757a1;
          font-size: 34px;
          margin-bottom: 2.5px;
        }
        p {
          color: #555555;
          font-size: 11.5px;
          text-align: center;
          margin-bottom: 10px;
          span {
            display: block;
            font-weight: bold;
          }
        }
      }
    }
  }
`;

import React from "react";

import { useHistory } from "react-router-dom";
import { Container } from "./styles";

import Open from "./components/Open";
import Expired from "./components/Expired";
import Settled from "./components/Settled";
import Returned from "./components/Returned";

const Titles: React.FC = ({ match }: any) => {
  const current = String(match.params.current);

  const history = useHistory();

  const handleNextItem = (actual: string) => {
    history.push(`/titles/${actual}`);
  };

  return (
    <Container>
      {current === "em-aberto" && (
        <Open handleNextItem={() => handleNextItem("em-aberto")} />
      )}
      {current === "vencidos" && (
        <Expired handleNextItem={() => handleNextItem("vencidos")} />
      )}
      {current === "liquidados" && (
        <Settled handleNextItem={() => handleNextItem("liquidados")} />
      )}
      {current === "devolvidos" && (
        <Returned handleNextItem={() => handleNextItem("devolvidos")} />
      )}
    </Container>
  );
};

export default Titles;

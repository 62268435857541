import styled from 'styled-components';

import { Modal } from 'react-bootstrap';

export const Container = styled(Modal)`
  div.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 100%;
      max-width: 80px;
    }
    h4 {
      color: #444;
      margin: 0px;
      padding: 0px 15px;
      font-size: 20px;
    }
  }
  div.value {
    padding: 15px;
    padding-bottom: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h5 {
      font-weight: 900;
      color: #504b5c;
    }
    h3 {
      color: #6b6a81;
    }
  }
  div.bank {
    padding: 0px 15px;
    padding-bottom: 35px;
    h4 {
      color: #504b5c;
      font-size: 20px;
      font-weight: 900;
      border-bottom: 2px solid #eee;
      padding-bottom: 10px;
    }
    table {
      tr {
        td {
          font-weight: 900;
          color: #6b6a81;
        }
        td:nth-child(2) {
          color: #504b5c;
          padding-left: 16px;
        }
      }
    }
  }
  div.titles {
    padding: 0px 15px;
    padding-bottom: 30px;
    h4 {
      color: #504b5c;
      font-size: 20px;
      font-weight: 900;
      border-bottom: 2px solid #eee;
      padding-bottom: 10px;
    }
    table {
      width: 100%;
      border-collapse: collapse;
      tr {
        td {
          font-weight: 900;
          color: #6b6a81;
          border-bottom: 1px solid #eee;
          padding: 5px 10px;
        }
        td:last-child {
          color: #504b5c;
          text-align: right;
        }
      }
      tr:nth-child(odd) {
        background: #f9f9f9;
      }
    }
  }
`;

import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
  hr {
    border-color: #ededed;
  }
  div.contained {
    overflow: auto;
  }
  table.table thead th {
    border-top: none !important;
    border-bottom: none !important;
  }
  .box_filters {
    background: #fff;
    margin-bottom: 10px;
    border-radius: 0.4rem;
  }
`

import { Alert } from '@material-ui/lab'
import { api } from '~/hooks/api'
import { useAuth } from '~/hooks/useAuth'
import { normalizePhone } from '~/pages/main/utils/normalize'
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Row, Spinner } from 'react-bootstrap'
import { FaEdit } from 'react-icons/fa'
import { MdWarning } from 'react-icons/md'

import { ModalAdd } from './components/ModalAdd'
import { Container } from './styles'

const FinancialReferences: React.FC = () => {
  const [financialReferences, setFinancialReferences] = useState<any[]>([])
  const [loading, setLoading] = useState(false)

  const { pendencies, currentAssignor } = useAuth()

  const pend = pendencies.filter(p => p.type === 'financial_reference')

  const [financialReference, setEditFinancialReference] = useState({
    open: false,
    financialReference: {} as any
  })

  useEffect(() => {
    async function loadData() {
      setLoading(true)
      try {
        const { data } = await api.get(`/register/financialReferences`)
        setFinancialReferences(data)
      } catch (err) {
        //
      }
      setLoading(false)
    }
    loadData()
  }, [])

  return (
    <Container>
      <h4 className="m-0">
        {loading && <Spinner animation="border" size="sm" />} Referências Financeiras
      </h4>
      {financialReference.open && (
        <ModalAdd
          onClose={() =>
            setEditFinancialReference({
              open: false,
              financialReference: {} as any
            })
          }
          onAdd={a => setFinancialReferences([...financialReferences, a])}
          onUpdate={a =>
            setFinancialReferences(
              financialReferences.map(i => {
                if (i.id === a.id) {
                  return a
                }
                return i
              })
            )
          }
          onDelete={id => setFinancialReferences(financialReferences.filter(i => i.id !== id))}
          contact={financialReference.financialReference}
        />
      )}
      <Row className="mt-3">
        {pend.length > 0 && !currentAssignor.is_approved && (
          <Col lg={12}>
            <Alert severity="warning" className="mb-3">
              Você possui pendências neste módulo
            </Alert>
          </Col>
        )}
        {currentAssignor.is_approved && (
          <Col lg={12} className="mb-3">
            <Alert severity="info">
              Seu cadastro está aprovado, para atualizar as informações entre em contato com nossa Central de Atendimento.
            </Alert>
          </Col>
        )}
        {financialReferences.map(financialReference => {
          const hasPendency = pend.filter(p => p.financial_reference_id === financialReference.id && p.type === 'financial_reference').length > 0
          return (
            <Col lg={6} className="mb-3" key={financialReference.id}>
              <Card>
                <Card.Header>
                  <Card.Text>{financialReference.name}</Card.Text>
                </Card.Header>
                <Card.Body>
                  <p className="mb-1"><strong>Tipo de Negócio:</strong> {financialReference.type}</p>
                  <p className="mb-0"><strong>Telefone:</strong> {normalizePhone(financialReference.phone)}</p>
                  {!currentAssignor.is_approved && (
                    <div className="d-flex justify-content-end">
                      <Button
                        type="button"
                        className="btn-sm"
                        variant={hasPendency ? 'warning' : 'primary'}
                        onClick={() =>
                          setEditFinancialReference({
                            open: true,
                            financialReference: financialReference
                          })
                        }
                        disabled={loading || (currentAssignor.is_finished ? pend.length === 0 : false) || currentAssignor.is_approved}
                      >
                        {hasPendency ? <><MdWarning className="m-0" /> Excluir</> : <><FaEdit className="m-0" /> Editar</>}
                      </Button>
                    </div>
                  )}

                </Card.Body>
              </Card>
            </Col>
          )
        })}
        {!loading && !currentAssignor.is_approved && (
          <Col lg={6} className="mb-3" style={{ minHeight: '100%' }}>
            <Card style={{ height: '100%' }}>
              <Card.Body className="d-flex justify-content-center align-items-center">
                <Button
                  type="button"
                  variant="outline-primary"
                  onClick={() =>
                    setEditFinancialReference({
                      open: true,
                      financialReference: {} as any
                    })
                  }
                  disabled={loading || (currentAssignor.is_finished ? pend.length === 0 : false) || currentAssignor.is_approved}
                >
                  Adicionar Referência Financeira
                </Button>
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>
    </Container>
  )
}

export default FinancialReferences
import React, { useEffect, useMemo, useState } from "react";

import { MonetizationOnOutlined, CloseOutlined } from "@material-ui/icons";
import {
  Button,
  FormControl,
  FormLabel,
  MenuItem,
  InputLabel,
  Select,
  LinearProgress,
} from "@material-ui/core";

import { useDispatch } from "react-redux";
import { format, addHours } from "date-fns";

import ModalConclued from "./ModalConclued";
import ModalConfirm from "./ModalConfirm";
import { useOperation } from "~/hooks/useOperation";
import { normalizeBrl } from "../../utils/normalize";
import { api } from "~/hooks/api";
import { useAuth } from "~/hooks/useAuth";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const BoxFloat: React.FC = () => {
  const dispatch = useDispatch();
  const { titles, handleTitle, bankSelected, selectBank } = useOperation();
  const { currentAssignor } = useAuth();
  const history = useHistory();
  const [openConclued, setOpenConclued] = useState(false);
  const [expand, setExpand] = useState<boolean>(false);
  const [confirm, setConfirm] = useState(false);

  const [banks, setBanks] = useState<Bank[]>([]);
  const [loading, setLoading] = useState(false);

  const total = useMemo(() => {
    let amountTotal = 0;
    titles.forEach((n) => {
      amountTotal += Number(n.value || 0);
    });
    return amountTotal;
  }, [titles]);

  useEffect(() => {
    if (
      currentAssignor.models_available?.length > 0 &&
      !currentAssignor.models_available?.includes("check")
    ) {
      history.push("/");
      toast.warn(
        "Sua empresa não tem permissão a antecipar esse tipo de título"
      );
    }
  }, [currentAssignor.models_available]);

  useEffect(() => {
    async function loadBanks() {
      try {
        const { data } = await api.get(`assignor/banks`);
        const banks_d: Bank[] = []; // eslint-disable-line
        data.forEach((i: any) => {
          banks_d.push(i);
        });
        setBanks(banks_d);
      } catch (err) {
        //
      }
    }
    loadBanks();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (titles.length === 0) {
      setExpand(false);
    }
  }, [titles, dispatch]); // eslint-disable-line

  async function handleSubmitConfirm() {
    setLoading(true);
    try {
      await api.post("sendings", {
        type: 11,
        bank_id: bankSelected,
        duplicates: titles.map((t) => {
          return {
            id: t.id,
          };
        }),
      });

      setOpenConclued(true);
    } catch (err) {
      //
    }
    setLoading(false);
  }

  return (
    <div id="antecipate_box" className={titles.length > 0 ? "open" : ""}>
      <ModalConfirm
        total={normalizeBrl(total)}
        bank={banks.find((b) => String(b.id) === String(bankSelected))}
        onConfirm={() => {
          handleSubmitConfirm();
        }}
        open={confirm}
        setOpen={() => setConfirm(false)}
      />
      <ModalConclued open={openConclued} setOpen={(e) => setOpenConclued(e)} />
      <div className="antecipate_box__container">
        <div>
          <h3> {titles.length} Cheque (s) Selecionado(s)</h3>
          <span
            style={{
              fontSize: "14px",
            }}
          >
            Total parcial: {normalizeBrl(total)}
          </span>
        </div>
        <div>
          <Button
            type="button"
            endIcon={<MonetizationOnOutlined />}
            color="primary"
            variant="outlined"
            onClick={() => {
              if (currentAssignor.blocksShipmentSending) {
                console.log("nao envia");
                toast.error(
                  `Não é possível criar remessas no momento. O seu limite atual está excedido. 
                  Entre em contato com o seu gerente.`
                );
                return;
              }
              if (currentAssignor.blockPefin) {
                toast.error(
                  "No momento não é possível enviar operações, existem pendências em sua empresa, entre em contato conosco."
                );
                return;
              }
              setExpand(!expand);
            }}
          >
            {expand ? "Voltar" : "Antecipar"}
          </Button>
        </div>
      </div>
      {expand && (
        <div
          className={`container ${expand ? "opened" : "closed"}`}
          id="expanded"
        >
          {loading && <LinearProgress />}
          <div id="grid_detail">
            <div>
              <p>Cheques a antecipar</p>
              <div className="list-items">
                {titles.length > 0 &&
                  titles.map((title, index) => (
                    <div className="item" key={String(index)}>
                      <div>
                        <p>{title.payer.name}</p>
                        <h4>{normalizeBrl(title.value)}</h4>
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <span>{title.check?.key}</span>
                            <span>
                              {" "}
                              -{" "}
                              {title.date
                                ? format(
                                    addHours(new Date(title.date), 3),
                                    "dd/MM/yyyy"
                                  )
                                : ""}
                            </span>
                          </div>
                          <button
                            type="button"
                            onClick={() => handleTitle(title)}
                            disabled={loading}
                          >
                            <CloseOutlined />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div>
              <FormLabel component="legend">Contas Bancárias:</FormLabel>
              {banks.length === 0 ? (
                <h4>Nenhum banco cadastrado</h4>
              ) : (
                <FormControl className="mb-2" component="fieldset">
                  <FormControl>
                    <InputLabel id="demo-simple-select-helper-label">
                      Selecionar Conta Bancária
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={bankSelected}
                      onChange={(e) =>
                        selectBank((e.target.value as string) || "")
                      }
                    >
                      {banks.map((bank) => (
                        <MenuItem key={bank.id} value={bank.id}>
                          <span>
                            Banco: {bank.bank.code} - {bank.bank.name}
                            <br />
                            Ag: {bank.agency}
                            {bank.agency_digit && `-${bank.agency_digit}`}
                            <br />
                            Cc: {bank.account_number}
                            {bank.account_digit && `-${bank.account_digit}`}
                          </span>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </FormControl>
              )}
              <div id="total_antecipated" className="mt-5">
                <p
                  style={{
                    color: "#585858",
                    fontSize: "14px",
                  }}
                >
                  Resumo da Operação:
                </p>
                <div>
                  <span>Total</span>
                  <strong>{normalizeBrl(total)}</strong>
                </div>
              </div>
              <hr />
              <Button
                type="button"
                className="mt-4"
                disabled={
                  bankSelected === "" ||
                  !bankSelected ||
                  titles.length === 0 ||
                  loading
                }
                color="primary"
                variant="contained"
                onClick={() => setConfirm(true)}
                style={{ width: "100%", color: "#fff" }}
              >
                Enviar Operação
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BoxFloat;

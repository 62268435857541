import React from 'react';

import { Container } from './styles';

const Footer: React.FC = () => {
  return (
    <Container>
      <div className="container-fluid" id="first_footer">
        <div className="container">
          <p>{new Date().getFullYear()} &copy; Todos os Direitos Reservados - BFC Digital</p>
        </div>
      </div>
    </Container>
  );
};

export default Footer;

import React from "react";

const MessageOperation: React.FC = () => {
  return (
    <div className="widget-about">
      <div className="widget-about__box_text">
        <p>
          Uma plataforma para você
          <br />
          antecipar seus recebíveis.
        </p>
        <strong>Venda a prazo, receba à vista!</strong>
      </div>
      <div className="widget-about__box_illustrator">
        <div className="actor">
          <img src="/assets/images/actor.png" alt="" />
        </div>
        <div className="bigPlant">
          <img src="/assets/images/big-plant.png" alt="" />
        </div>
        <div className="smallPlant">
          <img src="/assets/images/small-plant.png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default MessageOperation;

import {
  FormControl,
  InputLabel,
  MenuItem,
  NativeSelect,
  Select,
  TextField,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { api } from "~/hooks/api";
import { useAuth } from "~/hooks/useAuth";
import {
  dateIsValid,
  normalizeCep,
  normalizeCnpj,
  normalizeCurrency,
  normalizeDate,
  normalizeNumber,
  normalizePhone,
  parseToDate,
} from "~/pages/main/utils/normalize";
import { states } from "~/pages/main/utils/states";
import consultCep from "~/services/consultCep";

import { Container } from "./styles";

const AssignorData: React.FC = () => {
  const [values, setValues] = useState({} as any);
  const [loading, setLoading] = useState(false);
  const numberAddress = useRef<any>(null);

  const [activities, setActivities] = useState([]);
  const [sectors, setSectors] = useState([]);
  useEffect(() => {
    api.get("/activities").then((res) => setActivities(res.data));
  }, []);
  useEffect(() => {
    api.get("/sectors").then((res) => setSectors(res.data));
  }, []);

  const { pendencies, currentAssignor, returnPendencies } = useAuth();

  const pend = pendencies.filter((p) => p.type === "register");

  useEffect(() => {
    async function loadData() {
      setLoading(true);

      try {
        const { data } = await api.get(`/register/assignor`);

        setValues({
          ...data,
          foundation_date: dateIsValid(data.foundation_date)
            ? new Intl.DateTimeFormat("pt-BR", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              }).format(new Date(data.foundation_date))
            : undefined,
        });
      } catch (err) {}

      setLoading(false);
    }
    loadData();
  }, []);

  async function handleSubmit() {
    setLoading(true);
    try {
      await api.put(`/register/assignor`, {
        ...values,
        foundation_date: values.foundation_date
          ? parseToDate(values.foundation_date)
          : undefined,
        phone: values.phone ? normalizeNumber(values.phone) : undefined,
        cnpj: values.cnpj ? normalizeNumber(values.cnpj) : undefined,
        cnpj_root: values.cnpj_root
          ? normalizeNumber(values.cnpj_root)
          : undefined,
        zip_code: values.zip_code
          ? normalizeNumber(values.zip_code)
          : undefined,
        cellphone: values.cellphone
          ? normalizeNumber(values.cellphone)
          : undefined,
        cellphone2: values.cellphone2
          ? normalizeNumber(values.cellphone2)
          : undefined,
        share_capital: values.share_capital
          ? normalizeNumber(values.share_capital)
          : undefined,
        revenues: values.revenues
          ? normalizeNumber(values.revenues)
          : undefined,
      });
      await returnPendencies(pend);
      toast.success("Cedente Atualizado");
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }

  async function handleCep() {
    setLoading(true);
    try {
      const data = await consultCep(normalizeNumber(values.zip_code));
      if (typeof data !== "boolean") {
        setValues({
          ...values,
          street: data.logradouro,
          city: data.cidade,
          district: data.bairro,
          state: data.estado,
          municipality_code: data.ibge,
          number: "",
          complement: "",
        });
        setLoading(false);
        numberAddress.current?.focus();
      } else {
        toast.error("CEP não encontrado");
        setValues({
          ...values,
          street: "",
          city: "",
          district: "",
          state: "",
          number: "",
          municipality_code: "",
          complement: "",
        });
      }
    } catch (error) {
      toast.error("Falha ao buscar o CEP");
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
        className="row"
      >
        <Col className="mb-3" lg={12}>
          <h5>{loading && <Spinner animation="border" />} Dados cadastrais</h5>
        </Col>
        {pend.length > 0 && !currentAssignor.is_approved && (
          <Col className="mb-3" lg={12}>
            <Alert severity="warning" className="mb-3">
              Você possui a(s) seguinte(s) pendência(s) neste módulo
            </Alert>
            <Row>
              {pend.map((p) => (
                <Col className="mb-3" lg={3} key={p.id}>
                  <Alert severity="error" className="mb-3">
                    <strong className="d-block">{p.title}</strong>
                    <small>{p.message}</small>
                  </Alert>
                </Col>
              ))}
            </Row>
          </Col>
        )}
        {currentAssignor.is_approved && (
          <Col lg={12} className="mb-3">
            <Alert severity="info">
              Seu cadastro está aprovado, para atualizar as informações entre em
              contato com nossa Central de Atendimento.
            </Alert>
          </Col>
        )}

        <Col className="mb-3" lg={5}>
          <TextField
            label="Razão Social"
            value={values.name || ""}
            onChange={(e) => setValues({ ...values, name: e.target.value })}
            disabled={
              loading ||
              (currentAssignor.is_finished ? pend.length === 0 : false) ||
              currentAssignor.is_approved
            }
          />
        </Col>
        <Col className="mb-3" lg={4}>
          <TextField
            label="Nome Fantasia"
            value={values.fantasy_name || ""}
            onChange={(e) =>
              setValues({
                ...values,
                fantasy_name: e.target.value,
              })
            }
            disabled={
              loading ||
              (currentAssignor.is_finished ? pend.length === 0 : false) ||
              currentAssignor.is_approved
            }
          />
        </Col>
        <Col className="mb-3" lg={3}>
          <TextField
            label="CNPJ"
            value={values.cnpj ? normalizeCnpj(values.cnpj) : ""}
            onChange={(e) =>
              setValues({
                ...values,
                cnpj: normalizeCnpj(e.target.value),
              })
            }
            disabled
          />
        </Col>
        <Col className="mb-3" lg={3}>
          <TextField
            label="CNPJ Matriz"
            value={values.cnpj_root ? normalizeCnpj(values.cnpj_root) : ""}
            onChange={(e) =>
              setValues({
                ...values,
                cnpj_root: normalizeCnpj(e.target.value),
              })
            }
            disabled
          />
        </Col>
        <Col className="mb-3" lg={3}>
          <TextField
            label="Inscrição Estadual"
            value={values.state_registration || ""}
            onChange={(e) =>
              setValues({
                ...values,
                state_registration: e.target.value,
              })
            }
            disabled={
              loading ||
              (currentAssignor.is_finished ? pend.length === 0 : false) ||
              currentAssignor.is_approved
            }
          />
        </Col>
        <Col className="mb-3" lg={3}>
          <TextField
            label="Inscrição Municipal"
            value={values.municipal_registration || ""}
            onChange={(e) =>
              setValues({
                ...values,
                municipal_registration: e.target.value,
              })
            }
            disabled={
              loading ||
              (currentAssignor.is_finished ? pend.length === 0 : false) ||
              currentAssignor.is_approved
            }
          />
        </Col>
        <Col className="mb-3" lg={3}>
          <FormControl component="fieldset">
            <FormControl>
              <InputLabel id="demo-simple-select-helper-label">
                Atividade
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={values.activity_id || ""}
                onChange={(e) =>
                  setValues({
                    ...values,
                    activity_id: (e.target.value as string) || "",
                  })
                }
                disabled={
                  loading ||
                  (currentAssignor.is_finished ? pend.length === 0 : false) ||
                  currentAssignor.is_approved
                }
              >
                {activities.map((activity: any) => (
                  <MenuItem key={activity.id} value={activity.id}>
                    {activity.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormControl>
        </Col>
        <Col className="mb-3" lg={3}>
          <FormControl component="fieldset">
            <FormControl>
              <InputLabel id="demo-simple-select-helper-label">
                Setor
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={values.sector_id || ""}
                onChange={(e) =>
                  setValues({
                    ...values,
                    sector_id: (e.target.value as string) || "",
                  })
                }
                disabled={
                  loading ||
                  (currentAssignor.is_finished ? pend.length === 0 : false) ||
                  currentAssignor.is_approved
                }
              >
                {sectors.map((sector: any) => (
                  <MenuItem key={sector.id} value={sector.id}>
                    {sector.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormControl>
        </Col>
        <Col className="mb-3" lg={3}>
          <TextField
            label="Capital Social"
            value={
              values.share_capital
                ? normalizeCurrency(values.share_capital)
                : ""
            }
            onChange={(e) =>
              setValues({
                ...values,
                share_capital: normalizeCurrency(e.target.value),
              })
            }
            disabled={
              loading ||
              (currentAssignor.is_finished ? pend.length === 0 : false) ||
              currentAssignor.is_approved
            }
          />
        </Col>
        <Col className="mb-3" lg={3}>
          <TextField
            label="Faturamento Mensal"
            value={values.revenues ? normalizeCurrency(values.revenues) : ""}
            onChange={(e) =>
              setValues({
                ...values,
                revenues: normalizeCurrency(e.target.value),
              })
            }
            disabled={
              loading ||
              (currentAssignor.is_finished ? pend.length === 0 : false) ||
              currentAssignor.is_approved
            }
          />
        </Col>
        <Col className="mb-3" lg={3}>
          <TextField
            label="Data de fundação"
            value={
              values.foundation_date
                ? normalizeDate(values.foundation_date)
                : ""
            }
            onChange={(e) =>
              setValues({
                ...values,
                foundation_date: normalizeDate(e.target.value),
              })
            }
            disabled={
              loading ||
              (currentAssignor.is_finished ? pend.length === 0 : false) ||
              currentAssignor.is_approved
            }
          />
        </Col>
        <Col className="mb-3" lg={3}>
          <TextField
            label="E-mail"
            type="email"
            value={values.email || ""}
            onChange={(e) =>
              setValues({
                ...values,
                email: e.target.value,
              })
            }
            disabled={
              loading ||
              (currentAssignor.is_finished ? pend.length === 0 : false) ||
              currentAssignor.is_approved
            }
          />
        </Col>
        <Col className="mb-3" lg={3}>
          <TextField
            label="E-mail NFSE"
            type="email"
            value={values.email_nfse || ""}
            onChange={(e) =>
              setValues({
                ...values,
                email_nfse: e.target.value,
              })
            }
            disabled={
              loading ||
              (currentAssignor.is_finished ? pend.length === 0 : false) ||
              currentAssignor.is_approved
            }
          />
        </Col>
        <Col className="mb-3" lg={2}>
          <TextField
            label="Telefone"
            value={values.phone ? normalizePhone(values.phone) : ""}
            onChange={(e) =>
              setValues({
                ...values,
                phone: normalizePhone(e.target.value),
              })
            }
            disabled={
              loading ||
              (currentAssignor.is_finished ? pend.length === 0 : false) ||
              currentAssignor.is_approved
            }
          />
        </Col>
        <Col className="mb-3" lg={2}>
          <TextField
            label="Celular"
            value={values.cellphone ? normalizePhone(values.cellphone) : ""}
            onChange={(e) =>
              setValues({
                ...values,
                cellphone: normalizePhone(e.target.value),
              })
            }
            disabled={
              loading ||
              (currentAssignor.is_finished ? pend.length === 0 : false) ||
              currentAssignor.is_approved
            }
          />
        </Col>
        <Col className="mb-3" lg={2}>
          <TextField
            label="Celular 2"
            value={values.cellphone2 ? normalizePhone(values.cellphone2) : ""}
            onChange={(e) =>
              setValues({
                ...values,
                cellphone2: normalizePhone(e.target.value),
              })
            }
            disabled={
              loading ||
              (currentAssignor.is_finished ? pend.length === 0 : false) ||
              currentAssignor.is_approved
            }
          />
        </Col>
        <Col lg={12} className="mt-4 mb-2">
          <h5>Endereço</h5>
        </Col>
        <Col className="mb-3" lg={2}>
          <TextField
            label="CEP"
            value={values.zip_code || ""}
            onBlur={handleCep}
            onChange={(e) =>
              setValues({
                ...values,
                zip_code: normalizeCep(e.target.value),
              })
            }
            disabled={
              loading ||
              (currentAssignor.is_finished ? pend.length === 0 : false) ||
              currentAssignor.is_approved
            }
          />
        </Col>
        <Col className="mb-3" lg={4}>
          <TextField
            label="Endereço"
            value={values.street || ""}
            onChange={(e) => setValues({ ...values, street: e.target.value })}
            disabled={
              loading ||
              (currentAssignor.is_finished ? pend.length === 0 : false) ||
              currentAssignor.is_approved
            }
          />
        </Col>
        <Col className="mb-3" lg={3}>
          <TextField
            label="Número"
            ref={numberAddress}
            value={values.number || ""}
            onChange={(e) => setValues({ ...values, number: e.target.value })}
            disabled={
              loading ||
              (currentAssignor.is_finished ? pend.length === 0 : false) ||
              currentAssignor.is_approved
            }
          />
        </Col>
        <Col className="mb-3" lg={3}>
          <TextField
            label="Complemento"
            value={values.complement || ""}
            onChange={(e) =>
              setValues({ ...values, complement: e.target.value })
            }
            disabled={
              loading ||
              (currentAssignor.is_finished ? pend.length === 0 : false) ||
              currentAssignor.is_approved
            }
          />
        </Col>
        <Col className="mb-3" lg={3}>
          <TextField
            label="Bairro"
            value={values.district || ""}
            onChange={(e) => setValues({ ...values, district: e.target.value })}
            disabled={
              loading ||
              (currentAssignor.is_finished ? pend.length === 0 : false) ||
              currentAssignor.is_approved
            }
          />
        </Col>
        <Col className="mb-3" lg={3}>
          <TextField
            label="Cidade"
            value={values.city || ""}
            onChange={(e) => setValues({ ...values, city: e.target.value })}
            disabled={
              loading ||
              (currentAssignor.is_finished ? pend.length === 0 : false) ||
              currentAssignor.is_approved
            }
          />
        </Col>
        <Col className="mb-3" lg={2}>
          <FormControl>
            <InputLabel variant="standard" shrink>
              UF
            </InputLabel>
            <NativeSelect
              value={values.state || ""}
              onChange={(e) => setValues({ ...values, state: e.target.value })}
              disabled={
                loading ||
                (currentAssignor.is_finished ? pend.length === 0 : false) ||
                currentAssignor.is_approved
              }
            >
              <option value="">Selecione uma opção</option>
              {states.map((state) => (
                <option value={state.uf} key={state.uf}>
                  {state.uf}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
        </Col>
        {!currentAssignor.is_approved && (
          <div className="col-md-12 d-flex justify-content-end mt-3">
            <Button
              type="submit"
              variant="primary"
              disabled={
                loading ||
                (currentAssignor.is_finished ? pend.length === 0 : false)
              }
            >
              Salvar
            </Button>
          </div>
        )}
      </Form>
    </Container>
  );
};

export default AssignorData;

import React from "react";

const ConsultCompany: React.FC = () => {
  return (
    <div className="widget-services radius-4 w-100">
      <div className="card-body">
        <div className="row">
          <div className="col-12">
            <h4>Consulta de Empresa</h4>
            <p>Consultas cadastrais de CNPJ junto à Receita Federal.</p>
            <a className="btn" href="/servicos/empresa">
              Consultar
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsultCompany;

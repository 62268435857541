import React from 'react';

import { Fade, Backdrop, Button } from '@material-ui/core';

import { AccountBalanceWallet, MonetizationOn } from '@material-ui/icons';
import { Container } from './styles';

interface ISetOpen {
  (value: boolean): void;
}

interface IProps {
  open: boolean;
  setOpen: ISetOpen;
}

const ModalConclued: React.FC<IProps> = ({ open, setOpen }) => {
  function resetModal() {
    setOpen(false);
    window.location.reload();
  }

  return (
    <Container
      open={open}
      onClose={() => {}}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div id="modal_paper">
          {open && (
            <div className="animation_money">
              <MonetizationOn id="money_1" />
              <MonetizationOn id="money_2" />
              <AccountBalanceWallet id="wallet" />
            </div>
          )}
          <h3>Operação enviada com sucesso!</h3>
          <p>
            Sua operação foi enviada para análise. Em breve, iremos notifica-lo
            por e-mail e em até 10 minutos, sua operação pode ser acompanhada na
            plataforma.
          </p>
          <Button
            type="button"
            color="primary"
            variant="contained"
            onClick={resetModal}
          >
            Ok, entendi
          </Button>
        </div>
      </Fade>
    </Container>
  );
};

export default ModalConclued;

import styled, { keyframes } from 'styled-components';

import { lighten, darken } from 'polished';

const fadeHeader = keyframes`
  0%{
    transform:translateY(-100%);
  }100%{
    transform:translateY(0);
  }
`;

export const Container = styled.div`
  display: flex;
  background: ${(props) =>
    props.theme.title === 'light'
      ? "url('/assets/images/bg-header.jpg') no-repeat 50% 50%"
      : '#444'};
  background-size: cover;
  padding: 1rem 0.8rem 0.8rem 0.8rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  justify-content: center;
  align-items: center;
  animation: ${fadeHeader} 2s cubic-bezier(0.075, 0.82, 0.165, 1);
  div.container {
    position: relative;
    div#wrap-user-company {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
    }
  }
  img {
    width: 100%;
    max-width: 60px;
  }
  .icon-arrow {
        transition: 300ms;
        &.opened {
          transform: rotate(180deg);
        }
      }
  div#section_company-selection {
    position: relative;
    .lock {
      position: absolute;
      top: 23%;
      left: 100%;
      z-index: 10;
    }
    > button {
      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
      }
      span {
        color: #f9f9f9;
        font-size: 14px;
        background: ${(props) =>
          props.theme.title === 'light'
            ? 'rgb(55 87 161 / 49%)'
            : 'rgba(0,0,0,.49)'};
        font-weight: 500;
        padding: 10px 15px;
        margin-right: 15px;
        border-radius: 15px;
        text-align: left;
        width: 100%;
        min-width: 200px;
      }
      
      @media screen and (max-width: 500px) {
        span {
          display: none;
        }
      }
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${(props) => props.theme.colors.background};
      background: none;
      border: none;
      box-shadow: none;
      div {
        color: ${(props) => props.theme.colors.primary} !important;
        background: ${(props) => darken(0.01, props.theme.colors.background)};
      }
    }
  }
  @media screen and (max-width: 500px) {
    .company-slug {
      display: none;
    }
  }
  div#sub-user {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-right: 15px;
    button {
      background: none;
      color: ${(props) => props.theme.colors.background};
      border: none;
      font-size: 12px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  div.section_company-open {
    max-height: 400px;
    padding: 1rem;
    opacity: 1;
    hr {
      width: 100%;
      border-color: #e0e0e0;
      margin: 0px 0px 7.5px;
    }
    .logout {
      color: ${(props) => props.theme.colors.text};
    }
  }
  div.section_company-close {
    height: 0px;
    opacity: 0;
  }
  div#others-companies {
    overflow: auto;
    width: 100%;
  }
  div.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
  }
`;

export const Selection = styled.div`
  display: flex;
  z-index: 30;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 360px;
  overflow: hidden;
  position: absolute;
  top: 40px;
  right: 0;
  background: ${(props) => lighten(0.1, props.theme.colors.background)};
  border-radius: 0.8rem;
  transition: 300ms;
  color: ${(props) => lighten(0.3, props.theme.colors.text)};
  #others-companies {
    button + button {
      border-top: 1px solid #eee;
    }
  }
  div {
    .company-text {
      ${(props) => props.theme.colors.text}
    }
  }
  @media screen and (max-width: 500px) {
    & {
      width: 100vw;
      right: 0;
      top: 100px;
    }
  }

  .btn-company-switch {
    background: #fff;
    border-radius: 6px;
    padding: 0.6rem 0.8rem;
    color: #444;
    border: 1px solid #c9c9c9;
    text-align: left;
    transition: 300ms;
    &:hover {
      background: #eee;
    }
  }

  .container-switch-company {
    max-height: 40vh;
    overflow: auto;
  }
`;

export const Button = styled.button`
  display: flex;
  flex-direction: column;
  border: 0px;
  background: none;
  width: 100%;
  text-align: center;
  align-items: center;
  padding: 15px 5px;
  transition: 300ms;
  color: ${(props) => props.theme.colors.text};
  &:hover {
    background: ${(props) => darken(0.05, props.theme.colors.background)};
    box-shadow: 0px 0px 10px
      ${(props) => darken(0.05, props.theme.colors.background)};
  }
  h4 {
    width: 100%;
    font-size: 14px;
    color: ${(props) => lighten(0.1, props.theme.colors.text)};
    font-weight: 800;
  }
  p {
    width: 100%;
    font-size: 12px;
    margin: 0px;
    color: ${(props) => lighten(0.14, props.theme.colors.text)};
  }
`;

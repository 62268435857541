import React, { createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { api } from "~/hooks/api";

interface User {
  id: string;
  name: string;
  email: string;
  assignor: {
    token: string;
  };
}
interface Assignor {
  id: string;
  cnpj: string;
  name: string;
  code: number;
  can_pre_print_type_1: boolean;
  can_pre_print_type_2: boolean;
  require_payer_details: boolean;
  operate_nfe_via_cnab: boolean;
  token: string;
  apply_discount: boolean;
  update_date: boolean;
  is_finished: boolean;
  is_approved: boolean;
  rejected: boolean;
  limit?: number;
  openValueTotal?: number;
  models_available: ("nfe" | "cte" | "nfse" | "ccb" | "contract" | "check" | "creditcard")[];
  limitExceeded: number;
  blocksShipmentSending: boolean;
  blockPefin: boolean;
  maxExcessToSendings: number;
}
interface Pendency {
  id: number;
  type:
    | "register"
    | "guarantor"
    | "accounts"
    | "financial_reference"
    | "document";
  guarantor_id?: number;
  account_id?: number;
  financial_reference_id?: number;
  document_type_id?: number;
  document_id?: number;
  title: string;
  message: string;
  user_id: number;
}

interface AuthContext {
  token: string | null;
  signed: boolean;
  loading: boolean;
  user: User;
  pendencies: Pendency[];
  companies: any[];
  setLoading: (data: boolean) => void;
  getAllCompanies: () => Promise<void>;
  refreshPendencies: () => Promise<void>;
  returnPendencies: (arr: Pendency[]) => Promise<void>;
  refreshData: () => Promise<void>;
  updateName: (value: string) => void;
  signIn: (email: string, password: string) => Promise<void>;
  signUp: (
    cnpj: string,
    name: string,
    email: string,
    password: string
  ) => Promise<void>;
  signOut: () => void;
  currentAssignor: Assignor;
}

const Auth = createContext<AuthContext>({} as AuthContext);
export const AuthProvider: React.FC = ({ children }) => {
  const [token, setToken] = useState<string | null>(() => {
    const dataToken = localStorage.getItem("@:token_bfcdigital");

    if (dataToken) {
      api.defaults.headers.Authorization = `Bearer ${dataToken}`;

      return dataToken;
    }

    return null;
  });
  const [pendencies, setPendencies] = useState<Pendency[]>([]);
  const [companies, setCompanies] = useState<Array<any>>([]);
  const [signed, setSigned] = useState(() => {
    const i = localStorage.getItem("@:signed_bfcdigital");
    if (i) {
      return true;
    }
    return false;
  });
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(() => {
    const u = localStorage.getItem("@:user_data");
    if (u) {
      return JSON.parse(u);
    }

    return {} as User;
  });

  const [currentAssignor, setCurrentAssignor] = useState(() => {
    const c = localStorage.getItem("@:current_assignor");
    if (c) {
      return JSON.parse(c);
    }
    return {
      name: "  ",
    } as Assignor;
  });

  function signOut() {
    setSigned(false);
    setToken(null);
    setLoading(false);
    localStorage.removeItem("@:token_bfcdigital");
    localStorage.removeItem("@:signed_bfcdigital");
    localStorage.removeItem("@:current_assignor");
    localStorage.removeItem("@:user_data");
  }

  async function signIn(email: string, password: string): Promise<void> {
    setLoading(true);
    try {
      const { data } = await api.post(`sessions/assignor`, {
        email,
        password,
      });

      api.defaults.headers.Authorization = `Bearer ${data.token}`;

      setToken(data.token);
      localStorage.setItem("@:token_bfcdigital", data.token);
      setCurrentAssignor(data.current_assignor);
      localStorage.setItem(
        "@:current_assignor",
        JSON.stringify(data.current_assignor)
      );
      setUser(data.user);
      localStorage.setItem("@:user_data", JSON.stringify(data.user));
      localStorage.setItem("@:signed_bfcdigital", "true");
      setSigned(true);
    } catch (err) {
      // toast.error(err.data.message);
    }
    setLoading(false);
  }

  async function signUp(
    cnpj: string,
    name: string,
    email: string,
    password: string
  ): Promise<void> {
    setLoading(true);
    try {
      const { data } = await api.post(`register/assignor`, {
        cnpj: cnpj.replace(/\D/g, ""),
        name,
        email,
        password,
      });

      api.defaults.headers.Authorization = `Bearer ${data.token}`;
      setToken(data.token);
      localStorage.setItem("@:token_bfcdigital", data.token);
      setCurrentAssignor(data.assignor);
      localStorage.setItem("@:current_assignor", JSON.stringify(data.assignor));
      setUser(data.user);
      localStorage.setItem("@:user_data", JSON.stringify(data.user));
      localStorage.setItem("@:signed_bfcdigital", "true");
      setSigned(true);
    } catch (err) {
      toast.error(err.data.message);
    }
    setLoading(false);
  }

  function updateName(v: string) {
    setUser({ ...user, name: v });
    localStorage.setItem("@:user_data", JSON.stringify({ ...user, name: v }));
  }

  async function refreshPendencies() {
    try {
      const { data } = await api.get(`/register/pendencies`);
      setPendencies(data);
    } catch (err) {
      //
    }
  }

  async function returnPendencies(arr: Pendency[]) {
    if (arr.length > 0) {
      for (let i = 0; i < arr.length; i += 1) {
        try {
          await api.post(`/pendencies/${arr[i].id}/return`);
        } catch (err) {
          //
        }
      }
      await refreshPendencies();
    }
  }

  async function refreshData() {
    try {
      const { data } = await api.get(`/register/assignor`);
      setCurrentAssignor(data);
      localStorage.setItem("@:current_assignor", JSON.stringify(data));
    } catch (err) {
      //
    }
  }

  useEffect(() => {
    if (token) {
      refreshPendencies();
      refreshData();
    }
  }, [token]);

  useEffect(() => {
    api.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error?.response?.status === 401) {
          toast.warn("Você está deslogado, entre novamente");
          signOut();
        } else if (error?.response?.status === 403) {
          toast.error("Você não tem permissão à este recurso");
        } else if (error?.response?.status === 404) {
          if (error?.response?.data?.message) {
            toast.error(error?.response?.data?.message);
          } else {
            toast.error("Recurso não encontrado");
          }
        } else if (
          error?.response?.status === 400 ||
          error?.response?.status === 422
        ) {
          if (error?.response?.data.errors) {
            if (Array.isArray(error?.response.data.errors)) {
              error?.response.data.errors.forEach((d) => {
                toast.error(d.message);
              });
            } else {
              toast.error(error?.response.data.errors.message);
            }
          } else if (Array.isArray(error?.response.data)) {
            error?.response.data.forEach((d) => {
              toast.error(d.message);
            });
          } else {
            toast.error(error?.response.data.message);
          }
        } else if (error?.response?.status === 500) {
          toast.error(
            "Ocorreu um erro inesperado em nossos sistema, contate o suporte"
          );
        } else if (error?.response?.status === 502) {
          toast.error("Sistema fora do ar, contate o suporte");
        }

        return Promise.reject(error.response);
      }
    );
  }, []); // eslint-disable-line

  const getAllCompanies = async () => {
    try {
      let response = await api.get('all-companies')

      setCompanies(response?.data);
    }catch(error) {
      console.error(error);
    }
  }

  return (
    <Auth.Provider
      value={{
        token,
        signed,
        pendencies,
        companies,
        getAllCompanies,
        refreshPendencies,
        returnPendencies,
        setLoading,
        refreshData,
        loading,
        signIn,
        signUp,
        signOut,
        user,
        updateName,
        currentAssignor,
      }}
    >
      {children}
    </Auth.Provider>
  );
};
export const useAuth = (): AuthContext => {
  const context = useContext(Auth);
  if (!context) {
    throw new Error("The hook useAuth must be used within an AuthProvider");
  }
  return context;
};

import React, { useState } from 'react'
import { Table } from 'react-bootstrap'

// import { Container } from './styles';

export const ListSkeleton: React.FC<{ rows?: number; cols?: number }> = ({
  cols = 3,
  rows = 5
}) => {
  const [dataRows] = useState<number[]>(() => {
    const i:number[] = []
    for (let c = 0; c < rows; c += 1) {
      i.push(c)
    }
    return i
  })

  const [dataCols] = useState<number[]>(() => {
    const i:number[] = []
    for (let c = 0; c < cols; c += 1) {
      i.push(c)
    }
    return i
  })

  return (
    <div>
      <Table>
        <thead>
          <tr>
            {dataCols.map(c => (
              <th key={c}>
                <div className="bg-load" style={{ height: '2rem' }}></div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {dataRows.map(i => (
            <tr key={i} style={{ cursor: 'default' }}>
              {dataCols.map(c => (
                <td key={c} style={{ width: c === 0 ? '60%' : undefined }}>
                  <div className="bg-load" style={{ height: '1.2rem' }}></div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import { Form, Spinner } from 'react-bootstrap'
import { FaTimesCircle } from 'react-icons/fa'

import { Container, Results } from './styles'

export const Autocomplete: React.FC<{
  onSelect: (item: any) => void
  columnTitle: string
  columnValue: string
  initialValue?: any
  loadResults: (input: string) => Promise<any[]>
  disabled?: boolean
  size?: 'sm'
  className?: string
}> = ({
  onSelect,
  columnTitle,
  columnValue,
  loadResults,
  disabled = false,
  size,
  initialValue = null,
  className = ''
}) => {
  const [valueDigited, setValueDigited] = useState('')
  const [zeroResults, setZeroResults] = useState(false)
  const [selected, setSelected] = useState(() => {
    return initialValue
  })

  useEffect(() => {
    setSelected(initialValue)
  }, [initialValue])

  const [results, setResults] = useState([] as any[])

  const [loading, setLoading] = useState(false)

  async function handle() {
    setLoading(true)
    try {
      const res = await loadResults(valueDigited)
      setResults(res)
      if (res.length === 0) {
        setZeroResults(true)
      }
    } catch (err) {
      //
    }
    setLoading(false)
  }

  useEffect(() => {
    if (valueDigited.length > 0) {
      handle()
    } else {
      setResults([])
      setZeroResults(false)
    }
  }, [valueDigited]) // eslint-disable-line

  const handleSelect = (value: any) => {
    setSelected(value)
    onSelect(value)
    setResults([])
    setZeroResults(false)
    setValueDigited('')
  }

  if (selected && selected[columnValue]) {
    return (
      <Container className={className}>
        <Form.Control
          value={selected[columnTitle]}
          size={size}
          className={className}
        />
        <button
          type="button"
          onClick={() => handleSelect({})}
          className={'ml-2 float-icon ' + (size || '')}
          disabled={disabled}
        >
          <FaTimesCircle className="m-0 text-muted" />
        </button>
      </Container>
    )
  }

  return (
    <div style={{ position: 'relative' }} className={className}>
      <Container className={className}>
        <Form.Control
          value={valueDigited}
          onChange={e => setValueDigited(e.target.value)}
          onFocus={() => {
            if (valueDigited.length > 0) {
              handle()
            }
          }}
          onBlur={() =>
            setTimeout(() => {
              setResults([])
              setZeroResults(false)
            }, 500)
          }
          className={className}
          disabled={disabled}
          size={size}
        />
        {loading && (
          <Spinner animation="border" size="sm" className="ml-2 float-icon" />
        )}
      </Container>
      {zeroResults && !loading && (
        <Results>
          <div className="pl-3 pr-3 pt-2 pb-2 text-muted">
            Nenhum resultado encontrado
          </div>
        </Results>
      )}
      {results.length > 0 && (
        <Results>
          {results?.map(item => (
            <button
              key={item[columnValue]}
              type="button"
              onClick={() => handleSelect(item)}
            >
              {item[columnTitle]}
            </button>
          ))}
        </Results>
      )}
    </div>
  )
}

import { IPermission, ISwitchCompanyAction } from '../user/types';

export const SIGN_IN_REQUEST = '@auth/SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = '@auth/SIGN_IN_SUCCESS';
export const SIGN_OUT = '@auth/SIGN_OUT';
export const SIGN_UP_REQUEST = '@auth/SIGN_UP_REQUEST';
export const SIGN_FAILURE = '@auth/SIGN_FAILURE';

export interface ICompany {
  id: string;
  clieId: number | null;
  companyName: string | null;
  fantasyName: string | null;
  limit: number | null;
  cnpj: string;
}
export interface IUser {
  id: string;
  name: string;
  email: string;
}

export interface IInitialState {
  token: null | string;
  signed: boolean;
  loading: boolean;
}

export interface ISignInRequestAction {
  type: typeof SIGN_IN_REQUEST;
  payload: {
    email: string;
    password: string;
  };
}
export interface ISignInSuccessAction {
  type: typeof SIGN_IN_SUCCESS;
  payload: {
    token: string;
    user: IUser;
    company: ICompany;
    permissions: IPermission[];
  };
}
export interface ISignOutAction {
  type: typeof SIGN_OUT;
}
export interface ISignUpRequestAction {
  type: typeof SIGN_UP_REQUEST;
  payload: {
    name: string;
    email: string;
    password: string;
  };
}
export interface ISignFailureAction {
  type: typeof SIGN_FAILURE;
}

export type AuthActionTypes =
  | ISignInRequestAction
  | ISignInSuccessAction
  | ISignOutAction
  | ISignUpRequestAction
  | ISignFailureAction
  | ISwitchCompanyAction;
